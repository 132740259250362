import { ChangeEvent } from "react";
import { AddAttachmentsData, useAddAttachmentsMutation } from "@/shared/lib/graphql";
import { useAddNotification } from "@/shared/lib/react";
import { useSendDocuments } from "@/shared/api";

const sliceIntoChunks = <T extends Array<File>, K extends number>(
  arr: T,
  chunkSize: K
): Array<Array<File>> => {
  const res: Array<any> = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk: Array<File> = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const useSendPhotos = ({
  leadId,
  onUploadError,
}: {
  leadId: number;
  onUploadError?: () => void;
}) => {
  const notify = useAddNotification();
  const { mutate: addAttachments, isLoading: isLinkSending } = useAddAttachmentsMutation();
  const { mutate: sendDocuments, isLoading: isImagesUploading } = useSendDocuments();

  const uploadFiles = ({
    reportId,
    event,
    onAllFilesUpload,
    onAllFilesUploadError,
  }: {
    reportId: number;
    event: ChangeEvent<HTMLInputElement>;
    onAllFilesUpload: () => void;
    onAllFilesUploadError?: (error: ErrorEvent) => void;
  }) => {
    if (event?.target?.files) {
      let allFiles: AddAttachmentsData[] = [];
      const chunck = sliceIntoChunks(Array.from(event?.target?.files), 2);
      const chunkPromise: Array<Promise<AddAttachmentsData[]>> = [];
      chunck.forEach((chunkItem, chunkIndex) => {
        const formData = new FormData();
        const preparedChunck: AddAttachmentsData[] = [];
        Array.from(chunkItem).forEach((item) => {
          formData.append("files", item);
          preparedChunck.push({
            reportId,
            fileName: item.name,
            fileSize: +(item.size / 1048576).toFixed(2) || 0.01,
            fileUrl: "",
            filePath: "",
          });
        });

        chunkPromise.push(
          new Promise((resolve: (value: AddAttachmentsData[]) => void, reject: () => void) => {
            sendDocuments({
              data: formData,
              filesType: "attachments",
              onSuccess: (resp) => {
                resolve(
                  preparedChunck.map((item, index) => ({
                    ...item,
                    filePath: resp[index].path,
                    fileUrl: resp[index].fullPath,
                  }))
                );
              },
              onError: () => {
                notify("Error", "error");
                reject();
              },
            });
          })
        );
      });

      Promise.all(chunkPromise)
        .then((resp) => {
          allFiles = resp.flat();

          addAttachments(
            {
              leadId,
              input: {
                attachments: allFiles,
              },
            },
            {
              onSuccess: onAllFilesUpload,
              onError: (error) => {
                notify((error as ErrorEvent)?.message, "error");
                onAllFilesUploadError && onAllFilesUploadError(error as ErrorEvent);
              },
            }
          );
        })
        .catch(() => onUploadError && onUploadError());
    }
  };

  return { uploadFiles, isLinkSending, isImagesUploading };
};
