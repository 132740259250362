import { ModalContext } from "@/shared/lib/react";
import { Modal, ModalBody, ModalFooter, ModalTop } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useContext, type FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { IReminderFormValues, ReminderFormFileds } from "./ReminderFormFileds";
import moment from "moment";
import { ReminderEntity, useUpdateReminderMutation } from "@/shared/lib/graphql";

interface IModalEditReminderProps {
  reminder: ReminderEntity;
  transparentOverlay?: boolean;
  onSuccess?: () => void;
}

export const ModalEditReminder: FC<IModalEditReminderProps> = ({
  reminder,
  transparentOverlay,
  onSuccess,
}) => {
  const [, setModalContext = () => ({})] = useContext(ModalContext);
  const updateReminder = useUpdateReminderMutation();

  const methods = useForm<IReminderFormValues>({
    defaultValues: {
      date: moment(reminder.remindDate).format("YYYY-MM-DD"),
      time: moment(reminder.remindDate).format("HH:mm"),
      title: reminder.title ?? "",
      comment: reminder.comment ?? "",
      notifyTime: reminder.remindNotificationDate,
      leadId: reminder.leadId ?? undefined,
      userId: reminder.userId ?? undefined,
    },
  });

  const onClose = () => {
    setModalContext((prev) => ({ ...prev, modalEditReminder: false }));
  };

  const onSubmit = (values: IReminderFormValues) => {
    updateReminder.mutate(
      {
        input: {
          id: reminder.id,
          remindDate: `${values.date} ${values.time}`,
          remindNotificationDate: values.notifyTime,
          title: values.title,
          userId: values.userId,
          leadId: values.leadId,
          comment: values.comment,
        },
      },
      {
        onSuccess: () => {
          onSuccess?.();
          onClose();
        },
      }
    );
  };

  return (
    <Modal name="modalEditReminder" {...(transparentOverlay && { overlayStyle: { opacity: 0 } })}>
      <ModalTop title="Редактирование события" onClose={onClose} />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalBody>
          <FormProvider {...methods}>
            <ReminderFormFileds />
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={onClose}>
            Отменить
          </Button>
          <Button type="submit">Сохранить</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
