import { ModalContext } from "@/shared/lib/react";
import { Modal } from "@/shared/ui";
import { useContext, type FC } from "react";
import { Reminder } from "./Reminder";

interface IModalEditReminderProps {
  leadId: number;
  userId?: number;
  onSuccess?: () => void;
}

export const ModalReminder: FC<IModalEditReminderProps> = ({ leadId, userId, onSuccess }) => {
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const onClose = () => {
    setModalContext((prev) => ({ ...prev, modalReminder: false }));
  };

  return (
    <Modal name="modalReminder" onClose={onClose} style={{ width: "auto" }}>
      <Reminder leadId={leadId} userId={userId} isModal onSuccess={onSuccess} />
    </Modal>
  );
};
