import { WrappedBlock } from "@/entities/leads";
import { WrappedLeadTitle } from "@/entities/leads";
import { ModalReminderInfo, ReminderRow } from "@/entities/reminder";
import { ModalDeleteReminder, ModalEditReminder } from "@/features/reminder";
import { ReminderEntity, useGetRemindersQuery } from "@/shared/lib/graphql";
import { ModalContext } from "@/shared/lib/react";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Title } from "@yamaha-admin-sb/title";
import { FC, useContext, useState } from "react";

interface LeadRemindersProps {
  leadId: number;
  onEditClick: () => void;
}

export const LeadReminders: FC<LeadRemindersProps> = ({ leadId, onEditClick }) => {
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [selectedReminder, setSelectedReminder] = useState<ReminderEntity | null>(null);

  const { data, refetch } = useGetRemindersQuery({
    input: {
      filter: {
        leadId,
      },
    },
  });

  const handleOpenReminder = (reminder) => {
    setSelectedReminder(reminder);
    setModalContext((prev) => ({ ...prev, modalReminderInfo: true }));
  };

  const handleEditReminder = () => {
    setModalContext((prev) => ({ ...prev, modalReminderInfo: false, modalEditReminder: true }));
  };

  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          События
        </Title>
        <IconButton icon="edit" variant="tertiary" color="gray" onClick={onEditClick} />
      </WrappedLeadTitle>
      {data?.getReminders.data?.map((item, index, arr) => (
        <ReminderRow
          key={item.id}
          remindDate={item.remindDate}
          remindNotificationDate={item.remindNotificationDate}
          status={item.status}
          title={item.title}
          comment={item.comment}
          fullDate
          onClick={() => handleOpenReminder(item)}
          style={index === arr.length - 1 ? { borderBottom: 0 } : undefined}
        />
      ))}
      {modalContext?.modalEditReminder && selectedReminder && (
        <ModalEditReminder reminder={selectedReminder} onSuccess={refetch} />
      )}
      {modalContext?.modalReminderInfo && selectedReminder && (
        <ModalReminderInfo
          title={selectedReminder.title}
          remindDate={selectedReminder.remindDate}
          remindNotificationDate={selectedReminder.remindNotificationDate}
          comment={selectedReminder.comment}
          lead={selectedReminder.lead}
          user={selectedReminder.user}
          onDelete={() => setModalContext((prev) => ({ ...prev, modalDeleteReminder: true }))}
          onEdit={handleEditReminder}
        />
      )}
      {modalContext?.modalDeleteReminder && selectedReminder && (
        <ModalDeleteReminder
          reminderId={selectedReminder.id}
          onSuccess={() => {
            refetch();
            setModalContext((prev) => ({ ...prev, modalReminderInfo: false }));
          }}
        />
      )}
    </WrappedBlock>
  );
};
