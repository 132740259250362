import { ServiceBase } from "./core";

export type FilesType = "attachments" | "docs";

export interface IDocumentResponse {
  path: string;
  fullPath: string;
}

export class DocumentService extends ServiceBase {
  public static sendDocument(data: FormData): Promise<IDocumentResponse> {
    const url = "/media/upload";

    return this.callApi({
      method: "POST",
      url,
      data,
      contentType: "miltipart/form-data",
    });
  }

  public static sendDocuments(data: FormData, filesType: FilesType): Promise<IDocumentResponse[]> {
    const url = "/media/multiple-upload";

    return this.callApi({
      method: "POST",
      url,
      data,
      params: { filesType },
      contentType: "miltipart/form-data",
    });
  }
  public static uploadTechnics(data: FormData): Promise<any> {
    const url = "/media/vehicle/update";
    return this.callApi({
      method: "POST",
      url,
      data,
      contentType: "miltipart/form-data",
    });
  }
}
