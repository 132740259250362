import { ModalContext } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import { Input, IInput } from "@yamaha-admin-sb/input";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import {
  BODY_OPTIONS,
  DRIVE_UNIT_OPTIONS,
  gearboxOptions,
  inputValidationRule,
  requiredValidationRule,
} from "@/shared/helpers/const";
import {
  UpdateLeadTechnicData,
  useGetVehicleMarksQuery,
  useGetVehicleModelsQuery,
  useUpdateLeadTechnicMutation,
} from "@/shared/lib/graphql";

import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { ITextarea, Textarea } from "@yamaha-admin-sb/textarea";
import { toFormatPrice } from "@/shared/helpers/utils";
import { IHandleSuccess, ISelectedTechnic } from "../../model/interfaces";

export interface IModal {
  leadId: number;
  selectedTechnic: ISelectedTechnic;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

export const ModalEditTechnic: React.FC<IModal> = (props): JSX.Element => {
  const { leadId, selectedTechnic, handleSuccess, handleError } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const [form, setForm] = useState<UpdateLeadTechnicData>({
    make: selectedTechnic.technic.make || "",
    model: selectedTechnic.technic.model,
    issueYear: selectedTechnic.technic.issueYear,
    color: selectedTechnic.technic.color,
    minPrice: selectedTechnic.technic.minPrice,
    maxPrice: selectedTechnic.technic.maxPrice,
    parameters: selectedTechnic.technic.parameters,
    body: selectedTechnic.technic.body || [],
    gearbox: selectedTechnic.technic.gearbox,
    driveUnit: selectedTechnic.technic.driveUnit || [],
    mileage: selectedTechnic.technic.mileage,
    equipmentOptions: selectedTechnic.technic.equipmentOptions,
    engineVolume: selectedTechnic.technic.engineVolume,
  });

  useEffect(() => {
    setForm({
      make: selectedTechnic.technic.make || "",
      model: selectedTechnic.technic.model,
      issueYear: selectedTechnic.technic.issueYear,
      color: selectedTechnic.technic.color,
      minPrice: selectedTechnic.technic.minPrice,
      maxPrice: selectedTechnic.technic.maxPrice,
      parameters: selectedTechnic.technic.parameters,
      body: selectedTechnic.technic.body || [],
      gearbox: selectedTechnic.technic.gearbox,
      driveUnit: selectedTechnic.technic.driveUnit || [],
      mileage: selectedTechnic.technic.mileage,
      equipmentOptions: selectedTechnic.technic.equipmentOptions,
      engineVolume: selectedTechnic.technic.engineVolume,
    });
  }, [selectedTechnic]);

  const [selectedMarks, setSelectedMarks] = useState<IDropdownItem[]>([]);
  const [selectedModels, setSelectedModels] = useState<IDropdownItem[]>([]);
  const [selectedFilters, setSelectedFilters] = useState({
    model: {
      name: "",
      id: 0,
    },
    mark: {
      name: "",
    },
  });
  const { mutate: updateTechnic, isLoading } = useUpdateLeadTechnicMutation();
  const { data: models } = useGetVehicleModelsQuery(
    {
      filter: selectedMarks[0]?.id
        ? {
            markId: selectedMarks[0]?.id.toString(),
          }
        : {},
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!selectedMarks[0]?.id,
    }
  );
  const { data: marks, isLoading: isMarksLoading } = useGetVehicleMarksQuery({
    filter: selectedFilters?.mark?.name
      ? {
          name: selectedFilters.mark.name,
        }
      : {},
  });

  useEffect(() => {
    const prevMarks = marks?.getVehicleMarks.find(
      (item) => item.name === selectedTechnic.technic.make
    );
    setValue("make", selectedTechnic.technic.make);
    setValue("parameters", selectedTechnic.technic.parameters);
    setValue("equipmentOptions", selectedTechnic.technic.equipmentOptions);
    prevMarks && setSelectedMarks([prevMarks]);
  }, [selectedTechnic.technic, marks]);

  useEffect(() => {
    const prevModels = models?.getVehicleModels.filter(
      (item) => !!selectedTechnic.technic.model?.split("; ").includes(item.name)
    );

    prevModels && setSelectedModels(prevModels);
  }, [models]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const handleUpdate = () => {
    updateTechnic(
      {
        leadId,
        input: { id: selectedTechnic.technic.id, technic: form },
      },
      {
        onSuccess: () => {
          handleButtonUndoClick();
          handleSuccess({
            text: "Автомобиль изменён",
            disableCompleteStage: true,
          });
        },
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalEditTechnic: false,
    }));
  };

  const handelChange = (
    key: keyof UpdateLeadTechnicData,
    value: string | number | Array<string>
  ) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Modal name="modalEditTechnic">
      <ModalTop
        title={`Редактировать автомобиль №${selectedTechnic.index}`}
        onClose={handleButtonUndoClick}
      />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IDropdown>
              // portal={document.querySelector("body") as HTMLElement}
              name="make"
              label="Марка *"
              searchable={true}
              onSearch={(value) =>
                setSelectedFilters((prev) => ({
                  ...prev,
                  mark: { name: value },
                }))
              }
              placeholder="Выберите марку"
              control={control}
              error={errors.make?.message}
              options={marks?.getVehicleMarks || []}
              isDisabled={isMarksLoading}
              isBordered={true}
              value={selectedMarks}
              rules={requiredValidationRule}
              onChange={(value: IDropdownItem[]) => {
                setSelectedMarks(value);
                value[0]?.name && handelChange("make", value[0].name);
                handelChange("model", "");
              }}
              component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
            />
          </div>

          <div className="space-20">
            <Controller<IDropdown>
              // portal={document.querySelector("body") as HTMLElement}
              name="model"
              label="Модель *"
              placeholder="Выберите модель"
              control={control}
              rules={requiredValidationRule}
              error={errors.model?.message}
              options={models?.getVehicleModels || []}
              isBordered={true}
              value={selectedModels}
              isMultiple={true}
              isDisabled={!selectedMarks[0]?.id || isMarksLoading}
              withTags={true}
              onChange={(value: IDropdownItem[]) => {
                setSelectedModels(value);
                handelChange("model", value.map((item) => item.name).join("; "));
              }}
              component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Dropdown
              label="Привод"
              placeholder="Выберите привод"
              isBordered={true}
              isMultiple={true}
              withTags={true}
              value={[
                DRIVE_UNIT_OPTIONS.filter((item) => form.driveUnit?.includes(item.name)) || [],
              ].flat()}
              options={DRIVE_UNIT_OPTIONS}
              onChange={(value) =>
                value &&
                handelChange(
                  "driveUnit",
                  value.map((item) => item.name)
                )
              }
            />
          </div>
          <div className="space-20">
            <Dropdown
              label="Коробка передач"
              placeholder="Выберите коробку передач"
              isBordered={true}
              options={gearboxOptions}
              isMultiple={true}
              withTags={true}
              value={[
                gearboxOptions.filter((item) => form.gearbox?.includes(item.value)) || [],
              ].flat()}
              onChange={(value) =>
                value &&
                handelChange(
                  "gearbox",
                  value.map((item) => item.value)
                )
              }
            />
          </div>
          <div className="space-20">
            <Dropdown
              label="Kyзов"
              placeholder="Выберите  тип кузова"
              isBordered={true}
              isMultiple={true}
              withTags={true}
              value={[BODY_OPTIONS.filter((item) => form.body?.includes(item.name)) || []].flat()}
              options={BODY_OPTIONS}
              onChange={(value) => handelChange("body", value?.map((item) => item?.name) || [])}
            />
          </div>
          <div className="space-20">
            <Input
              name="issueYear"
              label="Год выпуска"
              placeholder="Выберите год выпуска"
              value={form.issueYear || ""}
              type="number"
              isBordered={true}
              onChange={(value) => {
                handelChange("issueYear", +value);
              }}
            />
          </div>
          <div className="space-20">
            <Input
              name="color"
              label="Цвет"
              placeholder="Введите цвет"
              value={Array.isArray(form.color) ? form.color?.join(", ") : form.color || ""}
              isBordered={true}
              onChange={(value) => handelChange("color", value)}
            />
          </div>
          <div className="space-20">
            {/* <Input
              name="body"
              label="Kyзов"
              placeholder="Введите тип кузова"
              value={form.body || ""}
              isBordered={true}
              rules={inputValidationRule}
              onChange={(value) => {
                handelChange("body", value);
              }}
            /> */}
          </div>
          <div className="space-20">
            <Input
              name="engineVolume"
              label="Объем двигателя"
              placeholder="Введите объем двигателя"
              suffix="л"
              type="number"
              value={form.engineVolume || ""}
              isBordered={true}
              onChange={(value) => {
                handelChange("engineVolume", +value);
              }}
            />
          </div>
          <div className="space-20">
            <Input
              name="mileage"
              label="Пробег"
              suffix="км"
              placeholder="До"
              value={form?.mileage && !isNaN(+form?.mileage) ? toFormatPrice(+form.mileage) : ""}
              isBordered={true}
              rules={inputValidationRule}
              onChange={(value) => {
                const digits = value.split(/\D/).join("");
                digits && handelChange("mileage", +digits);
              }}
            />
          </div>
          <div className="space-20">
            <Input
              name="minPrice"
              label="Цена"
              suffix="₽"
              placeholder="От"
              value={form?.minPrice && !isNaN(+form?.minPrice) ? toFormatPrice(+form.minPrice) : ""}
              isBordered={true}
              onChange={(value) => {
                const digits = value.split(/\D/).join("");
                digits && handelChange("minPrice", +digits);
              }}
            />
          </div>
          <div className="space-20">
            <Input
              name="maxPrice"
              label="Цена"
              suffix="₽"
              placeholder="До"
              value={form?.maxPrice && !isNaN(+form?.maxPrice) ? toFormatPrice(+form.maxPrice) : ""}
              isBordered={true}
              rules={inputValidationRule}
              onChange={(value) => {
                const digits = value.split(/\D/).join("");
                digits && handelChange("maxPrice", +digits);
              }}
            />
          </div>
          <div className="space-40">
            <Controller<IInput>
              name="parameters"
              label="Ссылка на авто"
              placeholder="Введите сслыку на авто"
              value={form.parameters || ""}
              isBordered={true}
              onChange={(value) => handelChange("parameters", value)}
              control={control}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<ITextarea>
              name="equipmentOptions"
              label="Комплектация. Необходимые опции"
              placeholder="Введите текст"
              control={control}
              value={form.equipmentOptions || ""}
              isBordered={true}
              onChange={(value) => handelChange("equipmentOptions", value)}
              component={(inputProps: ITextarea) => <Textarea {...inputProps} />}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={false} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
