import { IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useQueryParams } from "./useQueryParams";
import { parseQueryKey } from "@/shared/helpers/utils";

export type TQueryFilters = {
  [key: number]: IDropdownItem | string | string[];
};

type TFilterKey = "filter" | "sort" | "pagination";

interface ISelectedFilters {
  filter: TQueryFilters;
}

export const useQueryFilters = () => {
  const params = useQueryParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const appendQueryParams = (key: string, type: TFilterKey, value: string, isMulti?: boolean) => {
    searchParams.set(`${key}[${type}${isMulti ? "[multi]" : ""}]`, value);
    // searchParams.append(`${key}[${type}${isMulti ? "[multi]" : ""}]`, value);
    setSearchParams(searchParams);
  };

  const selectedFilters: ISelectedFilters = useMemo(() => {
    let preparedFilters = { filter: {} };
    if (params) {
      Object.keys(params).forEach((key) => {
        const queryName = parseQueryKey(key);
        if (queryName[2] !== "ext") {
          if (key && params[key]) {
            preparedFilters = {
              ...preparedFilters,
              [queryName[1]]: {
                ...preparedFilters[queryName[1]],
                [queryName[0]]:
                  queryName[2] === "multi"
                    ? [params[key]].flat().map((item) => (!isNaN(+item) ? +item : item))
                    : !isNaN(+params[key])
                    ? +params[key]
                    : params[key],
              },
            };
          }
        }
      });
    }
    return preparedFilters;
  }, [params]);

  const setQueryFilters = (
    filtersParams: TQueryFilters,
    additionalFilter?: { [key: string]: string }
  ) => {
    let queryFilters = additionalFilter ? { ...additionalFilter } : {};

    Object.keys(filtersParams).forEach((item) => {
      queryFilters = {
        ...queryFilters,
        [`${item}[filter]${Array.isArray(filtersParams[item]) ? "[multi]" : ""}`]:
          filtersParams[item],
      };
    });

    setSearchParams(queryFilters);
  };
  return { selectedFilters, setQueryFilters, appendQueryParams };
};
