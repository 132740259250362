import { ModalContext } from "@/shared/lib/react";
import React, { useContext } from "react";
import { ModalBody, ModalFooter, Modal, ModalTop } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm, Controller } from "react-hook-form";
import { Textarea } from "@yamaha-admin-sb/textarea";
import { useUpdateLeadCommentMutation } from "@/shared/lib/graphql";

export interface IModal {
  leadId: number;
  comment?: string | null;
  handleSuccess: () => void;
  handleError?: () => void;
}

export const ModalLeadComment: React.FC<IModal> = (props): JSX.Element => {
  const { leadId, comment, handleSuccess, handleError } = props;

  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const updateComment = useUpdateLeadCommentMutation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      comment: comment ?? "",
    },
  });

  const onSubmit = (data: { comment: string }) => {
    updateComment.mutate(
      {
        leadId: leadId,
        input: {
          comment: data.comment,
        },
      },
      {
        onSuccess: () => {
          handleSuccess();
          handleClose();
        },
        onError: () => {
          handleError?.();
        },
      }
    );
  };

  const handleClose = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalLeadComment: false,
    }));
  };

  return (
    <Modal name="modalLeadComment">
      <ModalTop title="Комментарий к заявке" onClose={handleClose} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <Textarea {...field} label="Комментарий" placeholder="Комментарий к заявке" />
            )}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleClose}>
            Отменить
          </Button>
          <Button type="submit">Сохранить</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
