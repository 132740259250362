import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown } from "@yamaha-admin-sb/dropdown";
import { ModalContext } from "@/shared/lib/react";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { useForm } from "react-hook-form";
import { requiredValidationRule } from "@/shared/helpers/const";
import { useRefundPaymentsMutation } from "@/shared/lib/graphql";
import { IHandleSuccess } from "../../model/interfaces";

interface IModalExpertAppointment {
  paymentId: string;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ModalPrepaymentReturn: React.FC<IModalExpertAppointment> = (props): JSX.Element => {
  const { handleSuccess, handleError, paymentId } = props;
  const [selectedAmount, setSelectedAmount] = useState();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ mode: "onBlur" });

  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: refundPayment } = useRefundPaymentsMutation();
  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalPrepaymentReturn: false,
    }));
  };

  const submitCallback = () => {
    selectedAmount &&
      refundPayment(
        {
          input: { payments: [{ id: paymentId, amount: selectedAmount }] },
        },
        {
          onSuccess: () => {
            handleButtonUndoClick();
            handleSuccess({
              text: "Предоплата возвращена",
              disableCompleteStage: true,
            });
          },
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
  };

  return (
    <Modal name="modalPrepaymentReturn">
      <ModalTop title="Возврат предоплаты" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(submitCallback)}>
        <StyledModalBody>
          <StyledFormGroup>
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="cost"
              label="Сумма *"
              placeholder="Выберите сумму"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={errors?.cost?.message}
              options={[
                {
                  name: "5 000 ₽",
                  value: 5000,
                },
                {
                  name: "10 000 ₽",
                  value: 10000,
                },
              ]}
              // value={}
              isDisabled={false}
              onChange={(value) => value[0].value && setSelectedAmount(value[0].value)}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </StyledFormGroup>
        </StyledModalBody>
        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button type="submit" isLoading={false}>
            Вернуть предоплату
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
