import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { ModalTop, Modal, ModalBody, ModalFooter } from "@/shared/ui";
import { Dropzone } from "./Dropzone";
import { useUploadTechnics } from "../model/mutations";

interface IModal {
  onSuccess: () => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ModalUploadTechnics: React.FC<IModal> = (props): JSX.Element => {
  const { onSuccess } = props;
  const [_, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: upload, isLoading } = useUploadTechnics();
  const [file, setFile] = useState<File>();
  const notify = useAddNotification();
  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalUploadTechnics: false,
    }));
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      upload({
        data: formData,
        onSuccess: () => {
          notify("Техника успешно загружена", "success");
          handleButtonUndoClick();
          onSuccess();
        },
        onError: () => notify("Произошла ошибка при загрузки техники", "error"),
      });
    }
  };

  return (
    <Modal name="modalUploadTechnics">
      <ModalTop title="Добавление техники" onClose={handleButtonUndoClick} />

      <StyledModalBody>
        <StyledFormGroup>
          <Dropzone onChange={setFile} />
        </StyledFormGroup>
      </StyledModalBody>
      <ModalFooter>
        <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
          Отменить
        </Button>
        <Button
          isDisabled={!file}
          isLoading={isLoading}
          onClick={() => {
            file && handleUpload();
          }}
        >
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  );
};
