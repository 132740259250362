import React, { useEffect, useState } from "react";
import {
  StyledFlags,
  StyledFormItem,
  StyledGridContainer,
  StyledGridElement,
} from "@/entities/leads";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Controller } from "@/shared/ui";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { useForm } from "react-hook-form";
import {
  emailValidationRule,
  inputValidationRule,
  phoneValidationRule,
  requiredValidationRule,
} from "@/shared/helpers/const";
import { Radio } from "@yamaha-admin-sb/radio";
import styled from "styled-components";
import {
  GenderEnum,
  GetLeadQuery,
  UpdateContactPublicPageInput,
  useCreateLeadContractMutation,
  useUpdateLeadDetailsMutation,
} from "@/shared/lib/graphql";
import { Button } from "@yamaha-admin-sb/button";
import { FormTemplate, ITechnicsDataTemplate } from "./TechnicsForm";
import { Title } from "@yamaha-admin-sb/title";
import { useAddNotification } from "@/shared/lib/react";
import { toDateFormat } from "@/shared/helpers/utils";
import { DataFilled } from "./DataFilled";
import { IHandleSuccess } from "../../model/interfaces";

const StyledError = styled.div`
  margin-top: 4px;
`;

const StyledLinkBtn = styled.div`
  width: 368px;
  display: grid;
  gap: 24px;
`;

interface IContractFormProps {
  lead: GetLeadQuery;
  showForm: boolean;
  isEnabled: boolean;
  enableGenerateContract: boolean;
  sendLink: () => void;
  handleSuccess: (value: IHandleSuccess) => void;
  isLinkSending: boolean;
}

export const ContractForm: React.FC<IContractFormProps> = (props) => {
  const {
    lead,
    showForm,
    isEnabled,
    enableGenerateContract,
    isLinkSending,
    sendLink,
    handleSuccess,
  } = props;
  const notify = useAddNotification();
  const [form, setForm] = useState<UpdateContactPublicPageInput>({
    name: lead.getLead.contact?.name || "",
    surname: lead.getLead.contact?.surname || "",
    phone: lead.getLead.contact?.phone || "",
    email: lead.getLead.contact?.email || "",
    city: lead.getLead.contact?.city || "",
    birthday: new Date(lead.getLead.contact?.birthday).toLocaleDateString() || "",
    gender: lead.getLead.contact?.gender || null,
    passportSeries: lead.getLead.contact?.passportSeries || "",
    passportNum: lead.getLead.contact?.passportNum || "",
    passportIssue: new Date(lead.getLead.contact?.passportIssue).toLocaleDateString() || "",
    passportDepartment: lead.getLead.contact?.passportDepartment || "",
    passportCode: lead.getLead.contact?.passportCode || "",
  });
  const [technics, setTechnics] = useState<ITechnicsDataTemplate[]>(
    lead.getLead.technics as ITechnicsDataTemplate[]
  );
  const isLinkSend = lead.getLead.leadAccess?.path;
  const { mutate: updateLead, isLoading } = useUpdateLeadDetailsMutation();
  const { mutate: sendContract, isLoading: isSendingContract } = useCreateLeadContractMutation();
  const handleChange = (key: keyof UpdateContactPublicPageInput, value: string) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    Object.keys(form).map((item) => setValue(item, form[item]));
  }, [form]);

  const handleUpdateLead = () => {
    updateLead(
      {
        leadId: +lead.getLead.id,
        input: {
          technics,
          contact: {
            ...form,
            birthday: toDateFormat(form.birthday),
            passportIssue: toDateFormat(form.passportIssue),
          },
        },
      },
      {
        onSuccess: () => {
          sendContract(
            { leadId: +lead.getLead.id },
            {
              onSuccess: () => {
                handleSuccess({
                  text: "Договор успешно создан",
                  disableCompleteStage: true,
                });
              },
              onError: (error) => notify((error as ErrorEvent)?.message, "error"),
            }
          );
        },
        onError: (error) => notify((error as ErrorEvent)?.message, "error"),
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(() => 1)}>
        <StyledFormItem>
          <div className="space-24">
            <Title level={4} weight={500} color="gray-700">
              Данные для договора
            </Title>
          </div>

          {!enableGenerateContract ? (
            <StyledLinkBtn className="space-24">
              {!isLinkSend ? (
                <Button isDisabled={!isEnabled} isLoading={isLinkSending} onClick={sendLink}>
                  Отправить ссылку на личный кабинет
                </Button>
              ) : (
                <DataFilled text="Ссылка на личный кабинет отправлена" />
              )}
              {isEnabled ? (
                <Paragraph size={12} color="gray-600">
                  После того, как клиент заполнит личные данные, вы сможете сформировать договор
                </Paragraph>
              ) : (
                <></>
              )}
            </StyledLinkBtn>
          ) : (
            <></>
          )}
          {showForm && (!isLinkSend || enableGenerateContract) ? (
            <>
              <StyledGridContainer>
                <StyledGridElement column="1 / span 2" color="gray-700">
                  <Paragraph size={14} weight={500}>
                    Личные данные
                  </Paragraph>
                </StyledGridElement>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="name"
                    label="Имя *"
                    placeholder="Введите имя"
                    control={control}
                    value={form.name}
                    error={errors?.name?.message}
                    isBordered={true}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("name", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="surname"
                    label="Фамилия *"
                    control={control}
                    error={errors?.surname?.message}
                    placeholder="Введите фамилию"
                    value={form.surname}
                    isBordered={true}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("surname", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="phone"
                    mask="+7 (999) 999-99-99"
                    label="Телефон *"
                    value={form.phone}
                    placeholder="Введите телефон"
                    control={control}
                    error={errors?.phone?.message}
                    isBordered={true}
                    rules={phoneValidationRule}
                    onChange={(value) => handleChange("phone", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="email"
                    label="E-mail *"
                    placeholder="Введите e-mail"
                    isBordered={true}
                    value={form.email}
                    control={control}
                    error={errors?.email?.message}
                    rules={emailValidationRule}
                    onChange={(value) => handleChange("email", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="city"
                    label="Город рождения *"
                    placeholder="Введите город рождения"
                    control={control}
                    value={form.city}
                    error={errors?.city?.message}
                    isBordered={true}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("city", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="birthday"
                    mask="99.99.9999"
                    label="Дата рождения *"
                    placeholder="дд.мм.гггг"
                    isBordered={true}
                    control={control}
                    value={form.birthday}
                    error={errors?.birthday?.message}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("birthday", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div className="space-32">
                  <Controller<{
                    onChange: (value: string) => void;
                  }>
                    control={control}
                    rules={requiredValidationRule}
                    name="gender"
                    onChange={(value: GenderEnum) => handleChange("gender", value)}
                    component={(inputProps: { onChange: (value: string) => void }) => (
                      <>
                        <div className="space-12">
                          <Paragraph
                            size={12}
                            color={errors?.gender?.message ? "red-400" : "gray-700"}
                            weight={500}
                          >
                            Пол *
                          </Paragraph>
                        </div>
                        <StyledFlags>
                          <Radio
                            isDisabled={!enableGenerateContract}
                            name="gender"
                            value={form.gender === GenderEnum.Male}
                            onChange={() => inputProps.onChange(GenderEnum.Male)}
                          >
                            Мужской
                          </Radio>
                          <Radio
                            isDisabled={!enableGenerateContract}
                            name="gender"
                            value={form.gender === GenderEnum.Female}
                            onChange={() => inputProps.onChange(GenderEnum.Female)}
                          >
                            Женский
                          </Radio>
                        </StyledFlags>
                        {errors?.gender?.message ? (
                          <StyledError>
                            <Paragraph size={12} color="red-400">
                              {errors?.gender?.message as string}
                            </Paragraph>
                          </StyledError>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  />
                </div>

                <StyledGridElement column="1 / span 2" color="gray-700">
                  <Paragraph size={14} weight={500}>
                    Паспортные данные
                  </Paragraph>
                </StyledGridElement>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="passportSeries"
                    mask="9999"
                    label="Серия паспорта *"
                    placeholder="xxxx"
                    value={form.passportSeries}
                    control={control}
                    error={errors?.passportSeries?.message}
                    isBordered={true}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("passportSeries", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="passportNum"
                    mask="999999"
                    label="Номер паспорта *"
                    control={control}
                    value={form.passportNum}
                    error={errors?.passportNum?.message}
                    placeholder="xxxxxx"
                    isBordered={true}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("passportNum", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="passportIssue"
                    label="Дата выдачи паспорта *"
                    placeholder="дд.мм.гггг"
                    mask="99.99.9999"
                    value={form.passportIssue}
                    isBordered={true}
                    control={control}
                    error={errors?.passportIssue?.message}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("passportIssue", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div>
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="passportDepartment"
                    label="Отдел выдачи *"
                    placeholder="Введите отдел выдачи"
                    control={control}
                    error={errors?.passportDepartment?.message}
                    value={form.passportDepartment}
                    isBordered={true}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("passportDepartment", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div className="space-32">
                  <Controller<IInput>
                    isDisabled={!enableGenerateContract}
                    name="passportCode"
                    mask="999-999"
                    label="Код подразделения *"
                    placeholder="xxx-xxx"
                    isBordered={true}
                    control={control}
                    error={errors?.passportCode?.message}
                    value={form.passportCode}
                    rules={inputValidationRule}
                    onChange={(value) => handleChange("passportCode", value)}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
              </StyledGridContainer>

              {technics.length ? (
                <div className="space-20">
                  <Paragraph size={12} weight={400} color="gray-600">
                    Детали автоподбора
                  </Paragraph>
                </div>
              ) : (
                <></>
              )}
              {technics?.map((item, index) => (
                <FormTemplate
                  key={index}
                  data={item as ITechnicsDataTemplate}
                  control={control}
                  setValue={setValue}
                  isDisabled={!enableGenerateContract}
                  index={index}
                  handelChange={(key, value) =>
                    setTechnics((prev) =>
                      prev.map((techItem, techIndex) =>
                        techIndex === index ? { ...techItem, [key]: value } : techItem
                      )
                    )
                  }
                  onCarDeleteClick={() =>
                    setTechnics((prev) => prev.filter((_, techIndex) => techIndex !== index))
                  }
                  // @ts-ignore
                  errors={errors}
                />
              ))}
              <Button
                type="submit"
                isDisabled={!enableGenerateContract}
                onClick={handleUpdateLead}
                isLoading={isLoading || isSendingContract}
              >
                Сформировать договор
              </Button>
            </>
          ) : (
            <></>
          )}
        </StyledFormItem>
      </form>
    </>
  );
};
