import { getFile } from "@/shared/api";

export const getDocument = async ({
  key,
}: {
  key: string;
}) => {

  try {
    const data = await getFile(key);
    console.log("getDocument data", data);
    // Преобразуем данные в Blob
    const blob = new Blob([data.Body as ArrayBuffer], { type: data.ContentType || 'application/octet-stream' });
    console.log("data.ContentType", data.ContentType);
    console.log("getDocument blob", blob);
    const url = URL.createObjectURL(blob);
    console.log("getDocument url", url);
    return url;

  } catch (err) {
    console.error('Error fetching file', err);
  }
};
