import { RoleEnum } from "@/shared/lib/graphql";

export const UNPROTECTED_ROUTES = ["/login", "/login/reset", "/reset", "/recovery"];

export const PROTECTED_ROUTES_BY_ROLES: { [key in RoleEnum]: string[] } = {
  DIRECTOR: [], // Директор
  ADMIN: [], // Администратор
  HEAD_OF_MN: ["/reviews", "/expenses", "/technics", "/bonus"], // Руководитель отдела менеджеров
  HEAD_OF_SP: ["/reviews", "/expenses", "/technics", "/clients", "/reports", "/bonus"], // Руководитель отдела специалистов
  MANAGER: [
    // Менеджер
    "/users",
    "/reviews",
    "/expenses",
    "/clients",
    "/reports",
    "/technics",
    "/bonus",
  ],
  SN_MANAGER: [
    // Старший менеджер
    "/users",
    "/reviews",
    "/expenses",
    "/clients",
    "/reports",
    "/technics",
    "/bonus",
  ],
  SPECIALIST: [
    // Специалист
    "/users",
    "/reviews",
    "/expenses",
    "/reports",
    "/clients",
    "/calls",
    "/technics",
    "/bonus",
  ],
  MARKETING: [
    // Маркетолог
    "/leads",
    "/expenses",
    "/users",
    "/clients",
    "/reviews",
    "/calls",
    "/technics",
    "/bonus",
  ],
  ACCOUNTANT: [
    // Бухгалтер
    "/leads",
    "/expenses",
    "/users",
    "/reviews",
    "/calls",
    "/technics",
    "/bonus",
  ],
};
