import styled from "styled-components";

export const StyledFlexRow = styled.div<{ isCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isCenter }) => (isCenter ? "center" : "flex-end")};
  gap: 12px;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: 1440px) {
    flex-wrap: nowrap;
  }
`;

export const StyledFlexRowItem = styled.div<{ col: number }>`
  width: 100%;
  input {
    height: 44px;
  }

  @media (min-width: 768px) {
    width: ${({ col }) => `calc(100% / 12 * ${col})`};
  }
`;
