import React, { useCallback, useContext, useEffect } from "react";
import { MockDaysOfWeek } from "@/shared/helpers/mocks";
import { ScheduleUserFieldsFragment, SetScheduleMutationVariables } from "@/shared/lib/graphql";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { ModalContext } from "@/shared/lib/react";
import { getSuffixedDigit } from "@/shared/helpers/utils";
import {
  StyledCalendarTable,
  StyledDaysRow,
  StyledHeadCell,
  StyledRow,
  StyledTableHeaderItem,
} from "./CalendarTable.style";
import CalendarTableRow from "./CalendarTableRow";
import ModalEditSchedule from "../ModalEditSchedule";
import { TEditingScheduleUser } from "../../model/interfaces";

interface ICalendarTable {
  calendar: ICalendarData;
  data: ScheduleUserFieldsFragment[];
  isScheduleEditing?: boolean;
  editingUser: TEditingScheduleUser | null;
  onEditingUserChange: (arg: TEditingScheduleUser | null) => void;
  onUserRemove: (userId: number) => void;
  onScheduleChange: (arg: SetScheduleMutationVariables) => void;
}

interface ICalendarData {
  currentYear: number;
  currentMonth: number;
}

const getDay = (date) => {
  let day = date.getDay();
  if (+day === 0) day = 7;
  return day - 1;
};

export interface ICalendarDay {
  day: string;
  dayOfWeek: number;
  humanReadableDay: number;
  date: string;
  isWeekend: boolean;
  isToday: boolean;
}
const weekendDOW = [5, 6];

const createCalendar = (year: number, month: number): ICalendarDay[] => {
  const currentDate = new Date();
  const d = new Date(year, month);
  const days: ICalendarDay[] = [];

  while (d.getMonth() === +month) {
    const date = `${year}-${getSuffixedDigit(month + 1)}-${getSuffixedDigit(d.getDate())}`;
    days.push({
      day: getSuffixedDigit(d.getDate()).toString(),
      dayOfWeek: getDay(d) % 7,
      humanReadableDay: d.getDate(),
      date,
      isWeekend: weekendDOW.includes(getDay(d) % 7),
      isToday:
        currentDate.getDate() === d.getDate() &&
        currentDate.getMonth() === d.getMonth() &&
        currentDate.getFullYear() === d.getFullYear(),
    });
    d.setDate(d.getDate() + 1);
  }

  return days;
};

export const CalendarTable: React.FC<ICalendarTable> = (props) => {
  const {
    calendar,
    data,
    isScheduleEditing,
    editingUser,
    onEditingUserChange,
    onUserRemove,
    onScheduleChange,
  } = props;
  const days = createCalendar(calendar.currentYear, calendar.currentMonth);
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const handleScheduleEdit = useCallback((item) => {
    onEditingUserChange(item);
  }, []);

  const handleModalClose = () => {
    onEditingUserChange(null);
    setModalContext((prevState) => ({
      ...prevState,
      modalEditSchedule: false,
    }));
  };

  useEffect(() => {
    if (!editingUser) return;

    setModalContext((prevState) => ({
      ...prevState,
      modalEditSchedule: true,
    }));
  }, [editingUser]);

  const handleModalSuccess = async (data: SetScheduleMutationVariables) => {
    if (!editingUser) return;

    onScheduleChange({
      input: data.input,
    });
  };

  return (
    <StyledCalendarTable>
      <table>
        <StyledRow>
          <StyledHeadCell>
            <Paragraph size={12} color="gray-500">
              Имя
            </Paragraph>
          </StyledHeadCell>
          <StyledHeadCell>
            <Paragraph size={12} color="gray-500">
              Должность
            </Paragraph>
          </StyledHeadCell>
          <StyledHeadCell>
            <StyledDaysRow>
              {days.map((item, index) => (
                <StyledTableHeaderItem key={index}>
                  <Paragraph
                    size={12}
                    color={item.isToday ? "blue-200" : item.isWeekend ? "red-200" : "gray-500"}
                  >
                    {item.day}
                    <br />
                    {MockDaysOfWeek[item.dayOfWeek]}
                  </Paragraph>
                </StyledTableHeaderItem>
              ))}
            </StyledDaysRow>
          </StyledHeadCell>
          <StyledHeadCell>
            <Paragraph size={12} color="gray-500">
              Количество часов
            </Paragraph>
          </StyledHeadCell>
        </StyledRow>
        {data.map((user, index) => (
          <CalendarTableRow
            key={user.id}
            user={user}
            monthDays={days}
            onEdit={handleScheduleEdit}
            onDelete={() => onUserRemove(user.id)}
          />
        ))}
      </table>
      {modalContext?.modalEditSchedule ? (
        <ModalEditSchedule
          name={`${editingUser?.name} ${editingUser?.surname}`}
          day={editingUser?.day}
          userId={editingUser?.id || 0}
          isLoading={isScheduleEditing}
          onSuccess={handleModalSuccess}
          onCancel={handleModalClose}
        />
      ) : (
        <></>
      )}
    </StyledCalendarTable>
  );
};
