import React from "react";
import {
  AutoDateDelivery,
  WrappedBlock,
  TechnicsBlock,
  SelectedEmployee,
  ClientBlock,
  RequestBlock,
  CommentBlock,
} from "@/entities/leads";
import styled, { css } from "styled-components";
import { colors } from "@/shared/helpers";
import {
  BaseUserOutput,
  ContactEntity,
  GetLeadQuery,
  TechnicOutput,
  RoleEnum,
  ClientEntity,
} from "@/shared/lib/graphql";
import { IModalContextValue } from "@/shared/lib/react";
import { Button } from "@yamaha-admin-sb/button";
import { useGetMe } from "@/entities/session";
import { ISelectedTechnic } from "@/features/leads";
import { LeadReminders } from "./LeadReminders";

const canEditManagerOfLead_Roles = [RoleEnum.Director, RoleEnum.HeadOfMn];
const CAR_SELECTION_SLUG = "car-selection";

const StyledReqInfo = styled.div<{ isDisabled?: boolean }>`
  flex: 0 0 376px;
  width: 340px;
  border-left: solid 1px ${colors.gray200};
  padding-left: 24px;
  position: relative;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 10;
        opacity: 0.7;
      }
    `}
`;

interface ISummaryFields {
  data: GetLeadQuery;
  currentStatus?: string | null;
  isOffsiteDiagnostics: boolean;
  setSelectedTechnic: React.Dispatch<React.SetStateAction<ISelectedTechnic | undefined>>;
  handleToggleModal: (modalName: keyof IModalContextValue, flag: boolean) => void;
  renderStatusBtn: () => JSX.Element;
  isLinkReSending: boolean;
  setMainContact: () => void;
  reSendEmailLink: () => void;
  isShowEmailSendButton: boolean;
}
export const SummaryFields: React.FC<ISummaryFields> = (props) => {
  const {
    data,
    currentStatus,
    isOffsiteDiagnostics,
    renderStatusBtn,
    handleToggleModal,
    setSelectedTechnic,
    isLinkReSending,
    setMainContact,
    reSendEmailLink,
    isShowEmailSendButton,
  } = props;

  const { me } = useGetMe();
  const userRole: any = me?.getMe.role?.slug;

  const currentlsType = data?.getLead?.lService?.slug || ""; // Тип заявки в данном случае

  const rolesCanEditSpecialist = [RoleEnum.Director, RoleEnum.HeadOfSp];

  return (
    <>
      <StyledReqInfo
        isDisabled={
          !!data?.getLead?.leadStatuses &&
          data?.getLead?.leadStatuses[0]?.status?.slug === "canceled"
        }
      >
        <RequestBlock
          enableEdit={["new", "prefatory", "to-be-agreed"].includes(currentStatus || "")}
          isOffsiteDiagnostics={isOffsiteDiagnostics}
          lead={data}
          onEditClick={() => handleToggleModal("modalEditLead", true)}
          renderStatusBtn={renderStatusBtn}
        />
        {data.getLead.contact && (
          <ClientBlock
            //enableEdit={["prefatory", "to-be-agreed"].includes(currentStatus || "")}
            enableEdit={true}
            contact={data.getLead.contact as ContactEntity}
            client={data.getLead.client as ClientEntity}
            onEditClick={() => handleToggleModal("modalEditContact", true)}
            isLinkReSending={isLinkReSending}
            setMainContact={setMainContact}
            reSendEmailLink={reSendEmailLink}
            isShowEmailSendButton={isShowEmailSendButton}
          />
        )}
        <CommentBlock
          comment={data.getLead.comment}
          onEditClick={() => handleToggleModal("modalLeadComment", true)}
        />
        <LeadReminders
          leadId={data.getLead.id}
          onEditClick={() => handleToggleModal("modalReminder", true)}
        />
        <AutoDateDelivery
          contact={data.getLead.contact as ContactEntity}
          onEditClick={() => handleToggleModal("modalAutoRecontactData", true)}
        />
        {data.getLead.technics?.length ? (
          data.getLead.technics
            .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
            .map((item, index) => (
              <TechnicsBlock
                enableEdit={["prefatory", "to-be-agreed"].includes(currentStatus || "")}
                key={index}
                index={index}
                params={item as TechnicOutput}
                onEditClick={(index, data) => {
                  setSelectedTechnic({ index, technic: data });
                  handleToggleModal("modalEditTechnic", true);
                }}
              />
            ))
        ) : (
          <></>
        )}
        <SelectedEmployee
          enableEdit={
            ["prefatory", "to-be-agreed"].includes(currentStatus || "") &&
            (currentlsType === CAR_SELECTION_SLUG
              ? canEditManagerOfLead_Roles.includes(userRole)
              : true)
          }
          role="Менеджер"
          person={data.getLead.manager as BaseUserOutput}
          onEditClick={() => handleToggleModal("modalManagerAppointment", true)}
        />
        <SelectedEmployee
          departure={data?.getLead.departure}
          inspectionPlace={data?.getLead.inspectionPlace || ""}
          inspectionTime={
            data.getLead.inspectionTime
              ? data.getLead.inspectionTime.split(":").splice(0, 2).join(":")
              : ""
          }
          enableEdit={
            rolesCanEditSpecialist.includes(userRole) &&
            ((isOffsiteDiagnostics &&
              [
                "to-be-agreed",
                "payment-made",
                "report-loaded",
                "lead-closed",
                "in-progress",
                "payment-waiting-client",
              ].includes(currentStatus || "")) ||
              [
                "prepayment-made",
                "in-progress",
                "payment-waiting-client",
                "payment-made",
                "report-loaded",
                "lead-closed",
              ].includes(currentStatus || ""))
          }
          role="Специалист"
          person={data?.getLead.specialist as BaseUserOutput}
          onEditClick={() => handleToggleModal("modalExpertAppointment", true)}
        />
        {data?.getLead?.leadStatuses &&
        data?.getLead?.leadStatuses[0].status?.slug !== "lead-closed" &&
        data?.getLead?.leadStatuses &&
        data?.getLead?.leadStatuses[0].status?.slug !== "successful" ? (
          <WrappedBlock>
            <Button
              isBlock={true}
              color="red"
              onClick={() => handleToggleModal("modalCancelLead", true)}
            >
              Отменить заявку
            </Button>
          </WrappedBlock>
        ) : (
          <></>
        )}
      </StyledReqInfo>
    </>
  );
};
