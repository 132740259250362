import { GetLeadQuery } from "@/shared/lib/graphql";
import { StatusToButtonText } from "@/shared/helpers/const";
import { Button } from "@yamaha-admin-sb/button";
import React from "react";
import { RequestStatus } from "../RequestStatus";

interface IRequestStatusButton {
  lead: GetLeadQuery;
  leadType: string;
  isOffsiteDiagnostics: boolean;
  onNextStatusClick: (statusId: number) => void;
}
const StatusHideBtn = ["agreed-by-client", "lead-closed", "successful"];

export const RequestStatusButton: React.FC<IRequestStatusButton> = (props) => {
  const { onNextStatusClick, lead, leadType, isOffsiteDiagnostics } = props;
  const currentStatus = lead?.getLead?.leadStatuses && lead?.getLead?.leadStatuses[0]?.status;
  const currentStage = lead?.getLead?.leadStages && lead?.getLead?.leadStages[0];
  const nextStatus = lead?.getLead?.leadStages && lead?.getLead?.leadStages[1]?.stage?.nextStatus;

  return (
    <>
      {lead?.getLead?.leadStatuses && lead?.getLead?.leadStatuses[0]?.status?.slug ? (
        <div className="space-12">
          <RequestStatus status={lead?.getLead?.leadStatuses[0]?.status?.slug} isSimple={true} />
        </div>
      ) : (
        <></>
      )}

      {currentStatus && !StatusHideBtn.includes(currentStatus?.slug) ? (
        currentStage &&
        currentStage?.isCompleted &&
        currentStage?.isFilled &&
        currentStage?.stage?.next === `${leadType}-not-specified` &&
        currentStatus?.slug === currentStage?.stage?.nextStatus?.slug ? (
          <Button
            isBlock={true}
            leftIcon={false}
            rightIcon={true}
            icon="arrow-right"
            onClick={() => onNextStatusClick(9)}
          >
            Закрыть заявку
          </Button>
        ) : (
          <>
            {currentStatus && currentStatus?.slug === nextStatus?.slug ? (
              <>
                {isOffsiteDiagnostics &&
                currentStage &&
                currentStage?.stage?.next === `${leadType}-not-specified` &&
                currentStatus?.slug !== currentStage?.stage?.nextStatus?.slug ? (
                  <Button
                    isBlock={true}
                    leftIcon={false}
                    rightIcon={true}
                    icon="arrow-right"
                    onClick={() => onNextStatusClick(currentStage.stage?.nextStatus?.id || 0)}
                  >
                    {StatusToButtonText[currentStage.stage?.nextStatus?.slug || ""]}
                  </Button>
                ) : (
                  ""
                )}
              </>
            ) : nextStatus && currentStatus?.slug !== "payment-waiting-client" ? (
              <Button
                isBlock={true}
                leftIcon={false}
                rightIcon={true}
                icon="arrow-right"
                onClick={() => onNextStatusClick(nextStatus?.id || 0)}
              >
                {StatusToButtonText[nextStatus?.slug || ""]}
              </Button>
            ) : (
              <></>
            )}
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};
