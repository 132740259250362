import React from "react";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Modal } from "./Modal";

import { ModalBody, ModalFooter } from "@/shared/ui";
import { IModalContextValue } from "@/shared/lib/react";

interface IModalConfirm {
  name?: keyof IModalContextValue;
  title: string;
  text: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  confirmButtonColor?: "red" | "gray" | "blue" | "black" | undefined;
  icon?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const StyledModalBody = styled(ModalBody)`
  p:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ModalConfirm: React.FC<IModalConfirm> = (props): JSX.Element => {
  const {
    title = "Вы действительно хотите заблокировать пользователя?",
    text = "Отменить это действие будет невозможно",
    cancelButtonText = "Отменить",
    confirmButtonText = "Да, заблокировать",
    icon,
    confirmButtonColor = "red",
    isLoading = false,
    onCancel,
    onConfirm,
    name = "confirmModal",
  } = props;

  return (
    <Modal name={name}>
      <StyledModalBody>
        {text ? (
          <>
            <Paragraph size={14} weight={500}>
              {title}
            </Paragraph>
            <Paragraph size={12} weight={400} color="gray-600">
              {text}
            </Paragraph>
          </>
        ) : (
          <>
            <Paragraph size={14} weight={500}>
              {title}
            </Paragraph>
          </>
        )}
      </StyledModalBody>
      <ModalFooter>
        <Button variant="secondary" color="gray" onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button
          onClick={onConfirm}
          color={confirmButtonColor}
          leftIcon={!!icon}
          icon={icon ? icon : undefined}
          isLoading={isLoading}
        >
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
