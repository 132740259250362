import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "@yamaha-admin-sb/input";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
// import { useForm } from "react-hook-form";
import { LoginQueryVariables, useLoginQuery } from "@/shared/lib/graphql";
import Cookies from "js-cookie";
import { useAddNotification } from "@/shared/lib/react";
// import { Controller } from "@/components/Controller";
// import { requiredValidationRule } from "@/shared/helpers/const";

interface ILoginForm {
  onLogin: () => void;
}

const StyledLoginForm = styled.div`
  width: 100%;
  max-width: 300px;
`;

const StyledFormTitle = styled.div`
  margin-bottom: 48px;
  text-align: center;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:last-child {
    marginb-bottom: 8px;
  }
`;

const StyledAction = styled.div`
  margin-top: 32px;
`;

export type TLoginQueryVariables = LoginQueryVariables["payload"];

export const LoginForm: React.FC<ILoginForm> = ({ onLogin }): JSX.Element => {
  const [form, setForm] = useState<TLoginQueryVariables>({
    password: "",
    username: "",
  });

  const notify = useAddNotification();

  const {
    data,
    refetch: login,
    isLoading,
    isError,
    error,
  } = useLoginQuery(
    { payload: form as TLoginQueryVariables },
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: false,
    }
  );

  useEffect(() => {
    if (isError) {
      notify((error as ErrorEvent)?.message, "error");
    }

    if (!data?.login) return;

    Cookies.set("accessToken", data.login.accessToken, {
      expires: data.login.expiresIn / 60 / 60 / 24,
    });

    onLogin();
  }, [isError, data, error]);

  const handleSubmit = () => {
    login();
  };
  // const {
  //   formState: { errors },
  //   handleSubmit,
  //   control,
  // } = useForm({
  //   mode: "onBlur",
  //   defaultValues: {
  //     ...form,
  //   },
  // });

  const handleChange = (name: keyof TLoginQueryVariables, value: string) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <StyledLoginForm>
      <StyledFormTitle>
        <Paragraph size={16} weight={500} color="black">
          Войдите в свой аккаунт
        </Paragraph>
      </StyledFormTitle>
      <div>
        <StyledFormGroup>
          <Input
            name="username"
            label="Имя пользователя"
            type="email"
            placeholder="Введите имя пользователя"
            isBordered={true}
            value={form.username}
            onChange={(value: string) => handleChange("username", value)}
          />
          {/*<Controller<IInput>*/}
          {/*  control={control}*/}
          {/*  rules={requiredValidationRule}*/}
          {/*  name="username"*/}
          {/*  error={errors?.username?.message}*/}
          {/*  component={(inputProps: IInput) => <Input {...inputProps} />}*/}
          {/*  label="E-mail"*/}
          {/*  type="email"*/}
          {/*  placeholder="Введите E-mail"*/}
          {/*  isBordered={true}*/}
          {/*  value={form.username}*/}
          {/*  onChange={(value: string) => handleChange("username", value)}*/}
          {/*/>*/}
        </StyledFormGroup>
        <StyledFormGroup>
          <Input
            name="password"
            label="Пароль"
            type="password"
            placeholder="Введите пароль"
            isBordered={true}
            value={form.password}
            onChange={(value: string) => handleChange("password", value)}
          />
          {/*<Controller<IInput>*/}
          {/*  control={control}*/}
          {/*  rules={requiredValidationRule}*/}
          {/*  name="password"*/}
          {/*  error={errors?.password?.message}*/}
          {/*  component={(inputProps: IInput) => <Input {...inputProps} />}*/}
          {/*  label="E-mail"*/}
          {/*  type="password"*/}
          {/*  placeholder="Введите E-mail"*/}
          {/*  isBordered={true}*/}
          {/*  value={form.password}*/}
          {/*  onChange={(value: string) => handleChange("password", value)}*/}
          {/*/>*/}
        </StyledFormGroup>
        {/*<TextButton>Забыли пароль?</TextButton>*/}
        <StyledAction>
          <Button isLoading={isLoading} isBlock={true} onClick={handleSubmit}>
            Войти
          </Button>
        </StyledAction>
      </div>
    </StyledLoginForm>
  );
};
