import {
  AnalyticsFileEntity,
  AnalyticsTypeEnum,
  SortEnum,
  useGetAnalyticsFilesQuery,
} from "@/shared/lib/graphql";
import { useEffect, useState } from "react";

export const useGetOneAnalyticsFiles = ({
  analyticsType,
  onSuccess,
}: {
  analyticsType: AnalyticsTypeEnum;
  onSuccess: (file: AnalyticsFileEntity) => void;
}) => {
  const [enableGetFile, setEnableGetFile] = useState(false);
  const { data: files, isLoading: isFilesLoading } = useGetAnalyticsFilesQuery(
    {
      input: {
        filter: { type: analyticsType },
        sort: { date: SortEnum.Desc },
        pagination: { take: 1, skip: 0 },
      },
    },
    { enabled: enableGetFile }
  );

  const getFile = () => {
    setEnableGetFile(true);
  };
  const setFalse = () => {
    setEnableGetFile(false);
  };

  useEffect(() => {
    if (files?.getAnalyticsFiles?.data?.length && enableGetFile) {
      setEnableGetFile(false);
      onSuccess(files?.getAnalyticsFiles?.data[0]);
    }
  }, [files]);

  return {
    isFilesLoading,
    getFile,
    setFalse,
  };
};
