import React, { useEffect, useState } from "react";
import { Input } from "@yamaha-admin-sb/input";
import styled from "styled-components";
import {
  useGetBonusesByContractsConfigQuery,
  useUpdateConfigBonusesByContractsMutation,
} from "@/shared/lib/graphql";
import { useForm } from "react-hook-form";
import { IInput } from "@yamaha-admin-sb/input";
import { Loader, Controller } from "@/shared/ui";
import { pluralWord } from "@/shared/helpers/utils";

const StyledFormLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #282a30;
  width: 100px;
  padding-top: 13px;
`;

const StyledFormInput = styled.div`
  max-width: 272px;
  width: 100%;
  min-width: 80px;
  position: relative;
  margin-top: 22px;

  input {
    height: 32px;
    min-height: auto;
    max-height: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    border-radius: 4px;
  }
`;

const StyledFormInputTopLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #282a30;
  position: absolute;
  top: -20px;
`;

const StyledFormBox = styled.div`
  padding: 24px;
  background: #f8f9fb;
  border-radius: 8px;
  max-width: 464px;
  min-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const WrappedLoader = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface DiagnosticsCountFieldEditBoxProps {
  label: string;
  inputName: string;
}

export const DiagnosticsCountFieldEditBox: React.FC<DiagnosticsCountFieldEditBoxProps> = (
  props
) => {
  const { label, inputName } = props;

  const {
    data: requestData,
    isSuccess: isDataSuccessLoaded,
    refetch,
  } = useGetBonusesByContractsConfigQuery({
    input: {
      name: inputName,
    },
  });

  const countOfDiagnostics = requestData?.getConfig?.value || "0";
  const configValueId = requestData?.getConfig?.id || -1;

  const { mutate: updateConfig, isSuccess: isSuccessUpdated } =
    useUpdateConfigBonusesByContractsMutation();
  const [form, setForm] = useState({});

  const { control } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  useEffect(() => {
    refetch();
  }, [isSuccessUpdated]);

  const saveValueRequest = () => {
    const newValue = form[configValueId];

    if (!newValue || newValue === countOfDiagnostics) return;

    updateConfig({
      id: configValueId,
      input: { name: inputName, value: newValue },
    });
  };

  if (!isDataSuccessLoaded) {
    return (
      <WrappedLoader>
        <Loader size="l" />
      </WrappedLoader>
    );
  }

  return (
    <StyledFormBox>
      <StyledFormLabel>{label} = </StyledFormLabel>
      <StyledFormInput>
        <StyledFormInputTopLabel>Количество выездных диагностик *</StyledFormInputTopLabel>

        <Controller<IInput>
          name="diagnostics count"
          control={control}
          value={countOfDiagnostics}
          placeholder="Введите количество"
          isBordered={true}
          type="number"
          onChange={(val) => {
            setForm((prev) => ({
              ...prev,
              [configValueId]: val,
            }));
          }}
          onBlur={() => saveValueRequest()}
          component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
        />
      </StyledFormInput>
    </StyledFormBox>
  );
};

const AlertBox = styled.div`
  padding: 16px;
  color: #707787;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: #f8f9fb;
  border-radius: 8px;
  text-align: left;
  width: 100%;
`;

const pluraledWords = ["выездная диагностика", "выездные диагностики", "выездных диагностик"];

const CONFIG_NAME = "coefLead";

export const DiagnosticsCountFieldGetAlert: React.FC = () => {
  const { data: requestData, isSuccess: isDataSuccessLoaded } = useGetBonusesByContractsConfigQuery(
    {
      input: {
        name: CONFIG_NAME,
      },
    }
  );

  const countOfDiagnostics = requestData?.getConfig?.value || "0";

  if (!isDataSuccessLoaded) {
    return (
      <WrappedLoader>
        <Loader size="l" />
      </WrappedLoader>
    );
  }

  return (
    <AlertBox>
      1 договор = {countOfDiagnostics} {pluralWord(parseInt(countOfDiagnostics), pluraledWords)}
    </AlertBox>
  );
};
