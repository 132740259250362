import React from "react";
import styled, { css } from "styled-components";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { colors } from "@/shared/helpers";
import { Icon } from "@yamaha-admin-sb/icon";
import { ScheduleDayTypeEnum } from "@/shared/lib/graphql";
import { Tooltip } from "@yamaha-admin-sb/tooltip";

interface ICalendarTableCell {
  type: ScheduleDayTypeEnum;
  hours?: number;
  onClick: () => void;
}

const StyledCalendarTableCell = styled.div<Pick<ICalendarTableCell, "type">>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.gray200};
  border-radius: 8px;
  overflow: hidden;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: 0.4s;

  ${({ type }) => {
    switch (type) {
      case ScheduleDayTypeEnum.Vacation:
        return css`
          background-color: ${colors.yellow100};
          border-color: ${colors.yellow100};

          &:hover {
            border-color: ${colors.yellow200};
          }
        `;
      case ScheduleDayTypeEnum.Sick:
        return css`
          background-color: ${colors.green100};
          border-color: ${colors.green100};

          &:hover {
            border-color: ${colors.green200};
          }
        `;
      case ScheduleDayTypeEnum.Work:
        return css`
          &:hover {
            background-color: ${colors.gray100};
            border-color: ${colors.gray300};
          }
        `;
      default:
        return css``;
    }
  }}
`;

const CalendarCellTooltipTemplate: {
  [key in ScheduleDayTypeEnum]: { iconColor?: string; icon?: string; message: string };
} = {
  Vacation: { iconColor: "yellow-200", icon: "vacation-16", message: "Отпуск" },
  Work: { message: "Рабочий день" },
  Sick: { icon: "sick-16", iconColor: "green-200", message: "Больничный" },
};

const CalendarTableCell: React.FC<ICalendarTableCell> = (props) => {
  const { type = ScheduleDayTypeEnum.Work, hours = 0, onClick } = props;

  return (
    <Tooltip
      content={
        <Paragraph size={10}>
          {type === ScheduleDayTypeEnum.Work && !hours
            ? "Выходной"
            : CalendarCellTooltipTemplate[type].message}
        </Paragraph>
      }
      config={{ delayShow: 400 }}
    >
      <StyledCalendarTableCell type={type} onClick={onClick}>
        {hours ? (
          <Paragraph size={12} color="gray-700">
            {hours}
          </Paragraph>
        ) : CalendarCellTooltipTemplate[type].icon ? (
          <Icon
            name={CalendarCellTooltipTemplate[type].icon || ""}
            color={CalendarCellTooltipTemplate[type].iconColor}
          />
        ) : (
          <></>
        )}
      </StyledCalendarTableCell>
    </Tooltip>
  );
};

export default CalendarTableCell;
