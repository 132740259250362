import React from "react";
import styled from "styled-components";
import { Checkbox } from "@yamaha-admin-sb/checkbox";
import { Radio } from "@yamaha-admin-sb/radio";
import { Paragraph } from "@yamaha-admin-sb/paragraph";

interface IControlGroup {
  isMultiple?: boolean;
  options: {
    label: string;
    value: string | boolean;
  }[];
  label: string;
  value: string | boolean | string[];
  onChange: (value: string | boolean | string[]) => void;
}

const StyledControlGroup = styled.div``;

export const ControlGroup: React.VFC<IControlGroup> = (props) => {
  const { options, label, value, isMultiple, onChange } = props;

  const handleCheckboxChange = (name: string) => {
    if (!(value as string[]).includes(name)) {
      onChange([...(value as string[]), name]);
    } else {
      onChange((value as string[]).filter((val) => val !== name));
    }
  };

  return (
    <StyledControlGroup>
      <div className="space-12">
        <Paragraph size={12}>{label}</Paragraph>
      </div>
      {options.map((option, index) => (
        <div className="space-12" key={index}>
          {isMultiple ? (
            <Checkbox
              name=""
              onInput={(value) => handleCheckboxChange(option.value as string)}
              value={Array.isArray(value) && value.includes(option.value.toString())}
            >
              {option.label}
            </Checkbox>
          ) : (
            <Radio name="" value={value === option.value} onChange={() => onChange(option.value)}>
              {option.label}
            </Radio>
          )}
        </div>
      ))}
    </StyledControlGroup>
  );
};
