import { ModalContext } from "@/shared/lib/react";
import { Modal, ModalFooter, ModalTop } from "@/shared/ui";
import { useContext, type FC } from "react";
import { Button } from "@yamaha-admin-sb/button";
import { useDeleteReminderMutation } from "@/shared/lib/graphql";

interface IModalDeleteReminderProps {
  reminderId: number;
  onSuccess?: () => void;
}

export const ModalDeleteReminder: FC<IModalDeleteReminderProps> = ({ reminderId, onSuccess }) => {
  const [, setModalContext = () => ({})] = useContext(ModalContext);
  const deleteReminder = useDeleteReminderMutation();

  const onClose = () => {
    setModalContext((prev) => ({ ...prev, modalDeleteReminder: false }));
  };

  const handleDeleteReminder = () => {
    deleteReminder.mutate(
      {
        id: reminderId,
      },
      {
        onSuccess: () => {
          onSuccess?.();
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      name="modalDeleteReminder"
      onClose={onClose}
      style={{ width: "auto" }}
      overlayStyle={{ opacity: 0 }}
    >
      <ModalTop title="Вы действительно хотите удалить напоминание?" />
      <ModalFooter>
        <Button color="red" onClick={handleDeleteReminder}>
          Удалить
        </Button>
        <Button onClick={onClose}>Закрыть</Button>
      </ModalFooter>
    </Modal>
  );
};
