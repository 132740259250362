import React from "react";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Title } from "@yamaha-admin-sb/title";
import { WrappedLeadTitle, WrappedBlock } from "../leads.styled";
import styled from "styled-components";
import { colors } from "@/shared/helpers";

const StyledComment = styled.p`
  color: ${colors.gray700};
  font-size: 12px;
`;

interface ICommunication {
  comment?: string | null;
  onEditClick: () => void;
}

export const CommentBlock: React.FC<ICommunication> = (props) => {
  const { comment, onEditClick } = props;

  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Комментарий
        </Title>
        <IconButton icon="edit" variant="tertiary" color="gray" onClick={onEditClick} />
      </WrappedLeadTitle>
      <StyledComment>{comment}</StyledComment>
    </WrappedBlock>
  );
};
