import React from "react";
import styled from "styled-components";
import { colors } from "@/shared/helpers";
import { Icon } from "@yamaha-admin-sb/icon";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { DotedNavigation } from "@yamaha-admin-sb/doted-navigation";
import { IHiddenNavigationItem } from "@yamaha-admin-sb/hidden-navigation";
import { IconButton } from "@yamaha-admin-sb/icon-button";

interface IDocumentPreview {
  name: string;
  size?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  onView?: () => void;
  onDownload?: () => void;
  hasDelete?: boolean;
}

const StyledFrame = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${colors.gray100};
  width: fit-content;
  gap: 18px;
  border-radius: 4px;
`;

type ActionType = "view" | "download" | "delete" | "edit";

export const DocumentPreview: React.FC<IDocumentPreview> = (props) => {
  const { hasDelete, name, size, onDelete, onEdit, onView, onDownload } = props;

  const menu: IHiddenNavigationItem[] = [
    {
      title: "Просмотреть",
      icon: "view",
      value: "view",
      type: "black",
      isDisabled: false,
    },
    {
      title: "Скачать",
      icon: "download",
      value: "download",
      type: "black",
      isDisabled: false,
    },
    {
      title: "Удалить",
      icon: "delete",
      value: "delete",
      type: "black",
      isDisabled: false,
    },
  ];

  if (onEdit)
    menu.unshift({
      title: "Изменить",
      icon: "edit",
      value: "edit",
      type: "black",
      isDisabled: false,
    });

  const menuActions: Record<ActionType, (() => void) | undefined> = {
    view: onView,
    edit: onEdit,
    download: onDownload,
    delete: onDelete,
  };

  const handleDottedMenuClick = (item: IHiddenNavigationItem) => {
    const func = menuActions[item.value as ActionType];

    if (func) func();
  };

  return (
    <StyledFrame>
      <Icon name="document" color="gray-600" size={24} />
      <div>
        <Paragraph size={12} color="black">
          {name || "Договор"}
        </Paragraph>
        {size ? (
          <Paragraph size={10} color="gray-600">
            {size}
          </Paragraph>
        ) : (
          <></>
        )}
      </div>
      {!hasDelete ? (
        <DotedNavigation
          items={menu}
          onSelected={(item) => handleDottedMenuClick(item)}
          isDisabled={false}
        />
      ) : (
        <IconButton variant="tertiary" color="gray" icon="delete" onClick={onDelete} />
      )}
    </StyledFrame>
  );
};
