import React, { useContext, useEffect, useState } from "react";
import { Input, IInput } from "@yamaha-admin-sb/input";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { useForm } from "react-hook-form";
import { requiredValidationRule, inputValidationRule, expensesTypes } from "@/shared/helpers/const";
import { toFormatPrice } from "@/shared/helpers/utils";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { ITextarea, Textarea } from "@yamaha-admin-sb/textarea";
import styled from "styled-components";
import {
  ContactTypeEnum,
  CostAccountingEnum,
  CreateCostAccountingInput,
  UpdateCostAccountingInput,
  useCreateCostAccountingMutation,
  useGetCostAccountingByIdQuery,
  useUpdateCostAccountingMutation,
} from "@/shared/lib/graphql";

export interface IModal {
  selectedExpenses?: number;
  onSuccess: () => void;
}

export interface ICreateLeadFrom {
  name: string;
  surname: string;
  phone: string;
  type: ContactTypeEnum;
  value: string;
  lServiceId: number;
}

const WrappedTextarea = styled.div`
  margin-top: 40px;
`;

export const ModalCreateExpenses: React.FC<IModal> = (props): JSX.Element => {
  const { selectedExpenses, onSuccess } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const notify = useAddNotification();
  const { mutate: createCostAccaunting, isLoading: isCreating } = useCreateCostAccountingMutation();

  const { data: currentExpenses, isLoading } = useGetCostAccountingByIdQuery(
    { id: selectedExpenses || 0 },
    { enabled: !!selectedExpenses }
  );

  const { mutate: updateCost, isLoading: isUpdating } = useUpdateCostAccountingMutation();
  const [form, setForm] = useState({
    amount: currentExpenses?.getCostAccounting.amount || "",
    type: currentExpenses?.getCostAccounting.type || "",
    comment: currentExpenses?.getCostAccounting.comment || "",
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (currentExpenses?.getCostAccounting) {
      Object.keys(currentExpenses?.getCostAccounting).forEach((item) =>
        setValue(item, currentExpenses?.getCostAccounting[item])
      );
      setForm({
        amount: currentExpenses?.getCostAccounting.amount || "",
        type: currentExpenses?.getCostAccounting.type || "",
        comment: currentExpenses?.getCostAccounting.comment || "",
      });
    }
  }, [currentExpenses]);

  const handleChange = (key: keyof CreateCostAccountingInput, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleCreate = () => {
    if (!selectedExpenses) {
      createCostAccaunting(
        { input: form as CreateCostAccountingInput },
        {
          onSuccess: async () => {
            onSuccess();
            handleButtonUndoClick();
            notify("Расход добавлен", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    } else {
      updateCost(
        { id: selectedExpenses, input: form as UpdateCostAccountingInput },
        {
          onSuccess: async () => {
            onSuccess();
            handleButtonUndoClick();
            notify("Расход обновлен", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    }
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalCreateExpenses: false,
    }));
  };

  return (
    <Modal name="modalCreateExpenses">
      <ModalTop
        title={selectedExpenses ? "Редактирование записи о расходах" : "Создание записи о расходах"}
        onClose={handleButtonUndoClick}
      />
      <form onSubmit={handleSubmit(handleCreate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IInput>
              name="amount"
              label="Сумма *"
              placeholder="Введите сумму"
              suffix="₽"
              value={form?.amount && !isNaN(+form?.amount) ? toFormatPrice(+form.amount) : ""}
              isBordered={true}
              onChange={(value) => {
                const digits = value.split(/\D/).join("");
                digits && handleChange("amount", +digits);
              }}
              control={control}
              error={errors?.amount?.message}
              rules={inputValidationRule}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>

          <Controller<IDropdown>
            portal={document.querySelector("body") as HTMLElement}
            name="type"
            label="Тип заявки *"
            placeholder="Выберите тип"
            isBordered={true}
            control={control}
            rules={requiredValidationRule}
            error={errors?.type?.message}
            value={[expensesTypes.find((option) => option.value === form.type) || []].flat()}
            isDisabled={isLoading}
            options={expensesTypes || []}
            onChange={(value: IDropdownItem) =>
              value[0].value && handleChange("type", value[0].value)
            }
            component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
          />
          {form.type === CostAccountingEnum.Other ? (
            <WrappedTextarea>
              <Controller<ITextarea>
                name="comment"
                label="Комментарий *"
                placeholder="Введите комментарий"
                control={control}
                error={errors?.comment?.message}
                value={form.comment}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value: string) => handleChange("comment", value)}
                component={(inputProps: ITextarea) => <Textarea {...inputProps} />}
              />
            </WrappedTextarea>
          ) : (
            <></>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isDisabled={isLoading} isLoading={isUpdating || isCreating} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
