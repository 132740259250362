import { AddSpecialistToLeadButton } from "./AddSpecialistToLeadButton";
import { ArrayElement, WithoutNull } from "@/shared/helpers/interfaces";
import { GetLeadsQuery } from "@/shared/lib/graphql";
import { AvatarWithBtn } from "@/shared/ui";
import type { FC } from "react";

interface ILeadSpecialistProps {
  lead: ArrayElement<WithoutNull<Required<GetLeadsQuery["getLeads"]>>["data"]>;
  hasSpecialistAppointment: boolean;
  isRowLoading: boolean;
  onLeadSelect: (leadId: number) => void;
  invalidateLeads?: () => Promise<unknown>;
  setIsLeadLoading: (leadId: number, isLoading: boolean) => void;
}

export const LeadSpecialist: FC<ILeadSpecialistProps> = ({
  lead,
  hasSpecialistAppointment,
  isRowLoading,
  setIsLeadLoading,
  invalidateLeads,
  onLeadSelect,
}) => {
  return (
    <AvatarWithBtn
      user={
        lead?.specialist?.name && lead?.specialist.surname
          ? {
              firstName: lead.specialist.name,
              lastName: lead.specialist.surname,
            }
          : undefined
      }
      renderProps={() => {
        if (!hasSpecialistAppointment) {
          return (
            <AddSpecialistToLeadButton
              leadId={lead.id}
              onLeadSelect={onLeadSelect}
              invalidateLeads={invalidateLeads}
              isLoading={isRowLoading}
              setIsLeadLoading={setIsLeadLoading}
            />
          );
        } else return <>-</>;
      }}
    />
  );
};
