import { IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { useEffect, useState } from "react";
import { RoleEnum, useFindOneRoleQuery } from "@/shared/lib/graphql";

export const useSpecialists = () => {
  const [isStale, setIsStale] = useState(false);

  const {
    data: specialists,
    isLoading: isLoadingSpecialist,
    isStale: isStaleSpecialist,
  } = useFindOneRoleQuery(
    {
      slug: RoleEnum.Specialist,
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: isStale,
    }
  );

  useEffect(() => {
    if (!isStale && isStaleSpecialist) setIsStale(true);
  }, [isStaleSpecialist]);

  const allSpecialists: IDropdownItem[] =
    specialists?.findOneRole?.users?.map((el) => ({
      value: el.id,
      name: `${el.name} ${el.surname}`,
    })) || [];

  return {
    allSpecialists,
    isLoading: isLoadingSpecialist,
  };
};
