import { ModalContext } from "@/shared/lib/react";
import { Button } from "@yamaha-admin-sb/button";
import { useContext, type FC } from "react";

interface ISelectLeadManagerButtonProps {
  leadId: number;
  onLeadSelect: (leadId: number) => void;
}

export const SelectLeadManagerButton: FC<ISelectLeadManagerButtonProps> = ({
  leadId,
  onLeadSelect,
}) => {
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const handleToggleModal = () => {
    setModalContext((prev) => ({ ...prev, modalManagerAppointment: true }));
  };

  return (
    <Button
      variant="secondary"
      color="blue"
      onClick={(event) => {
        event.stopPropagation();
        handleToggleModal();
        onLeadSelect(leadId);
      }}
    >
      Назначить менеджера
    </Button>
  );
};
