import React, { useState } from "react";
import { Controller } from "@/shared/ui";
import { Dropdown, IDropdownItem, IDropdown } from "@yamaha-admin-sb/dropdown";
import { requiredValidationRule } from "@/shared/helpers/const";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useUpdateLeadManagerMutation, RoleEnum } from "@/shared/lib/graphql";
import { useManagers } from "@/entities/user";
import { Title } from "@yamaha-admin-sb/title";
import { DataFilled } from "./DataFilled";
import { StyledFormItem, StyledGridContainer } from "@/entities/leads";
import { useGetMe } from "@/entities/session";
import { IHandleSuccess } from "../../model/interfaces";

const MANAGER_SLUGS = [RoleEnum.Manager, RoleEnum.SnManager];

const StyledActions = styled.div`
  display: flex;
  align-items: center;

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const Manager: React.FC<{
  hasData: boolean;
  isCompleted: boolean;
  leadId: number;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}> = ({ isCompleted, leadId, handleSuccess, handleError }) => {
  const { allManagers, isLoading: isMangersLoading } = useManagers();
  const { mutate: updateLeadmanager, isLoading: isManagerUpdating } =
    useUpdateLeadManagerMutation();
  const [manager, setManager] = useState<IDropdownItem[]>();
  const {
    formState: { errors },
    handleSubmit,
    control,
    trigger,
  } = useForm({ mode: "onBlur" });

  const submitCallback = () => {
    if (leadId && manager && manager[0].id) {
      updateLeadmanager(
        {
          leadId,
          input: {
            managerId: +manager[0].id,
          },
        },
        {
          onSuccess: () => handleSuccess({ text: "Менеджер назначен", blockSlug: "manager" }),
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    }
  };

  const leadToYourself = () => {
    const userId: number | undefined = me?.getMe.id;

    if (!userId) return;

    updateLeadmanager(
      {
        leadId,
        input: {
          managerId: +userId,
        },
      },
      {
        onSuccess: () =>
          handleSuccess({
            text: "Вы назначены менеджером",
            blockSlug: "manager",
          }),
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  const { me } = useGetMe();
  const userRole: any = me?.getMe.role?.slug;
  const userId: any = me?.getMe.id;
  const isManager = MANAGER_SLUGS.includes(userRole);

  const currentManager = allManagers.find((item) => item.id === userId) || false;

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Назначение менеджера на звонок
        </Title>
      </div>
      {!isCompleted ? (
        <>
          <StyledGridContainer className="space-20">
            <Controller<IDropdown>
              // portal={document.querySelector("body") as HTMLElement}
              name="managers"
              control={control}
              rules={requiredValidationRule}
              error={errors?.managers?.message}
              options={allManagers || []}
              value={currentManager || manager}
              onChange={setManager}
              label="Менеджер*"
              placeholder="Выберите менеджера"
              isBordered={true}
              isDisabled={isMangersLoading || isManager}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </StyledGridContainer>
          <StyledActions>
            {isManager ? (
              <Button
                isDisabled={isManagerUpdating}
                onClick={() => leadToYourself()}
                variant="secondary"
                color="blue"
              >
                Взять себе
              </Button>
            ) : (
              <Button
                isDisabled={isManagerUpdating}
                onClick={() => {
                  trigger();
                  handleSubmit(submitCallback)();
                }}
              >
                Назначить
              </Button>
            )}
          </StyledActions>
        </>
      ) : (
        <DataFilled text="Менеджер успешно назначен" />
      )}
    </StyledFormItem>
  );
};
