import {
  BodyConditionEnum,
  BrakeLiquidLevel,
  ElectronicsCondition,
  EPasportSend,
  LiquidLevel,
  TireSeasonEnum,
} from "../lib/graphql";

export const defaultValuesIfApproved = {
  isRecommended: true,
  hasOriginalPassport: true,
  hasEPassport: true,
  sendEPassport: EPasportSend.Send,
  owners: 1,
  freeFields: null,
  serviceConfirmation: "Да",
  hasRegRestrictions: false,
  hasAccidents: false,
  inFBS: false,
  inPledges: false,
  report: null,
  bodyCondition: BodyConditionEnum.Clear,
  roofPaintThickness: 120,
  roofDefects: null,
  hoodPaintThickness: 120,
  hoodDefects: null,
  frontLeftFenderPaintThickness: 120,
  frontLeftFenderDefects: null,
  frontLeftDoorPaintThickness: 120,
  frontLeftDoorDefects: null,
  rearLeftDoorPaintThickness: 120,
  rearLeftDoorDefects: null,
  rearLeftFenderPaintThickness: 120,
  rearLeftFenderDefects: null,
  leftThresholdPaintThickness: 120,
  leftThresholdDefects: null,
  trunkLidPaintThickness: 120,
  trunkLidDefects: null,
  frontRightFenderPaintThickness: 120,
  frontRightFenderDefects: null,
  frontRightDoorPaintThickness: 120,
  frontRightDoorDefects: null,
  rearRightDoorPaintThickness: 120,
  rearRightDoorDefects: null,
  rearRightFenderPaintThickness: 120,
  rearRightFenderDefects: null,
  rightThresholdPaintThickness: 120,
  rightThresholdDefects: null,
  aperturePaintThickness: 120,
  apertureDefects: null,
  interiorCondition: null,
  carBodyRemarks: "Указано среднее значение толщины ЛКП",
  isOriginalWindshield: true,
  windshieldManufactureYear: null,
  windshieldCondition: null,
  isOriginalSideGlasses: true,
  sideGlassesManufactureYear: null,
  sideGlassesCondition: null,
  isOriginalRearGlass: true,
  rearGlassManufactureYear: null,
  rearGlassCondition: null,
  isOriginalFrontRightHeadlight: true,
  frontRightHeadlightManufactureYear: null,
  frontRightHeadlightCondition: null,
  isOriginalFrontLeftHeadlight: true,
  frontLeftHeadlightManufactureYear: null,
  frontLeftHeadlightCondition: null,
  isOriginalRearLeftHeadlight: true,
  rearLeftHeadlightManufactureYear: null,
  rearLeftHeadlightCondition: null,
  isOriginalRearRightHeadlight: true,
  rearRightHeadlightManufactureYear: null,
  rearRightHeadlightCondition: null,
  glassAndHeadlightsRemarks: null,
  engineOilLevel: [LiquidLevel.Norm],
  brakeFluidLevel: BrakeLiquidLevel.Norm,
  coolantLevel: LiquidLevel.Norm,
  AHSLevel: LiquidLevel.Norm,
  driveBeltCondition: null,
  engineLeak: null,
  hasEngineExtraSounds: false,
  technicalRecommendations: null,
  frontPadWear: 50,
  frontDiscWear: 50,
  rearPadWear: 50,
  rearDiscWear: 50,
  brakesRecommendations: null,
  tireModel: null,
  tireSeason: TireSeasonEnum.Summer,
  tireManufactureYear: null,
  tireTread: null,
  tireRemark: null,
  powerWindows: ElectronicsCondition.Norm,
  mirrorAdjustment: ElectronicsCondition.Norm,
  heatedMirrors: ElectronicsCondition.Norm,
  foldingMirrors: ElectronicsCondition.Norm,
  seatsCondition: ElectronicsCondition.Norm,
  seatsAdjustment: ElectronicsCondition.Norm,
  seatsHeating: ElectronicsCondition.Norm,
  steeringWheelCondition: ElectronicsCondition.Norm,
  steeringWheelAdjustment: ElectronicsCondition.Norm,
  steeringWheelHeating: ElectronicsCondition.Norm,
  airConditioner: ElectronicsCondition.Norm,
  multimedia: ElectronicsCondition.Norm,
  speakers: ElectronicsCondition.Norm,
  antenna: ElectronicsCondition.Norm,
  headlights: ElectronicsCondition.Norm,
  lamps: ElectronicsCondition.Norm,
  backlight: ElectronicsCondition.Norm,
  windshieldWipers: ElectronicsCondition.Norm,
  headlightsWasher: ElectronicsCondition.Norm,
  computerDiagnostics: null,
  electronicsRecommendations: null,
  testRideRemarks: null,
  liftRemarks: null,
  endoscopeRemarks: null,
  optionalEquipmentRemarks: null,
};
