import React, { useEffect, useState } from "react";
import { Input } from "@yamaha-admin-sb/input";
import styled from "styled-components";
import {
  useGetBonusRateQuery,
  useFindManyRolesQuery,
  useUpdateBonusRateMutation,
  RoleEntity,
} from "@/shared/lib/graphql";
import { Controller, Loader } from "@/shared/ui";
import { useForm } from "react-hook-form";
import { IInput } from "@yamaha-admin-sb/input";
import { useAddNotification } from "@/shared/lib/react";

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledFormLabel = styled.div`
  max-width: 384px;
  width: 100%;
  min-width: 150px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

const StyledFormInput = styled.div`
  max-width: 272px;
  width: 100%;
  min-width: 80px;
  height: 32px;

  input {
    height: 32px;
    min-height: auto;
    max-height: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    border-radius: 4px;
  }
`;

const StyledFormTitle = styled.div`
  font-weight: 400;
  color: #a4abb5;
  margin-bottom: 13px;
  font-size: 12px;
`;

const BorderedRow = styled.div`
  padding: 16px 0;
  border-top: 1px #f3f5f9 solid;
`;

const WrappedLoader = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const typeOfServices = [
  { label: "Автоподбор", valueKey: "csRate" },
  { label: "Выездная диагностика", valueKey: "osRate" },
  { label: "Специалист на день", valueKey: "daySpecialistRate" },
  { label: "Отмена договора", valueKey: "leadCancelRate" },
];

const MANAGER_SLUG = "MANAGER";
const DEFAULT_VALUE = 0;

const initialStateValues = typeOfServices
  .map((item) => item.valueKey)
  .reduce((accumulator, value) => {
    return { ...accumulator, [value]: DEFAULT_VALUE };
  }, {});

export const BonusEployeeByRequests: React.FC = () => {
  const notify = useAddNotification();
  const { data: roles, isSuccess: isSuccessLoadedRoles } = useFindManyRolesQuery();

  const managerRole: RoleEntity | undefined = roles?.findManyRoles.find(
    (item) => item.slug === MANAGER_SLUG
  );

  const { mutate: updateBonusRate, isSuccess: isSuccessUpdated } = useUpdateBonusRateMutation();
  const [form, setForm] = useState({
    ...initialStateValues,
  });

  const { control } = useForm({
    mode: "onBlur",
    defaultValues: { ...initialStateValues },
  });

  const enableFetchBonusData = !!managerRole?.id;

  const {
    data: bonusData,
    isLoading,
    isSuccess: isSuccessLoadedBonusRate,
    isRefetching,
    refetch,
  } = useGetBonusRateQuery(
    {
      input: {
        roleId: managerRole?.id || 0,
      },
    },
    {
      enabled: enableFetchBonusData,
    }
  );

  const isDataSuccessLoaded = isSuccessLoadedRoles && isSuccessLoadedBonusRate && !isLoading;

  useEffect(() => {
    if (isSuccessUpdated) {
      refetch();
    }
  }, [isSuccessUpdated]);

  useEffect(() => {
    if (isSuccessLoadedBonusRate && !isRefetching) {
      const values = bonusData?.getBonusRate || {};
      setForm((prev) => ({ ...prev, ...values }));
    }
  }, [isSuccessLoadedBonusRate, isRefetching]);

  const saveValueRequest = (valueKey) => {
    const newValue = form[valueKey] ? parseInt(form[valueKey]) : 0;
    const prevValue = bonusData?.getBonusRate[valueKey];

    if (newValue === prevValue) {
      return;
    }

    updateBonusRate({
      input: {
        [valueKey]: newValue,
        roleId: managerRole?.id || 0,
      },
    });

    notify("Ставка бонуса обновлена", "success");
  };

  if (!isDataSuccessLoaded) {
    return (
      <WrappedLoader>
        <Loader size="l" />
      </WrappedLoader>
    );
  }

  return (
    <>
      <StyledRow>
        <StyledFormLabel>
          <StyledFormTitle>Тип услуги</StyledFormTitle>
        </StyledFormLabel>
        <StyledFormInput>
          <StyledFormTitle>Ставка бонуса</StyledFormTitle>
        </StyledFormInput>
      </StyledRow>

      {typeOfServices.map((item) => (
        <BorderedRow key={item.label}>
          <StyledRow>
            <StyledFormLabel>{item.label}</StyledFormLabel>
            <StyledFormInput>
              <Controller<IInput>
                name={item.valueKey}
                control={control}
                value={form[item.valueKey]}
                placeholder="Введите ставку"
                isBordered={true}
                type="number"
                suffix="₽"
                onChange={(val) => {
                  setForm((prev) => ({
                    ...prev,
                    [item.valueKey]: val,
                  }));
                }}
                onBlur={() => saveValueRequest(item.valueKey)}
                component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
              />
            </StyledFormInput>
          </StyledRow>
        </BorderedRow>
      ))}
    </>
  );
};
