import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown } from "@yamaha-admin-sb/dropdown";
import { ModalContext } from "@/shared/lib/react";
import { ModalBody, ModalFooter, Modal, ModalTop } from "@/shared/ui";
import { UpdateLeadSpecialistInput } from "@/shared/lib/graphql";
import { Controller, Datepicker, Timepicker } from "@/shared/ui";
import { inputValidationRule, requiredValidationRule } from "@/shared/helpers/const";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { useSpecialists } from "@/entities/user";
import { useForm } from "react-hook-form";
import moment from "moment";

interface IModalExpertAppointment {
  isOffsiteDiagnostics?: boolean;
  prevDeparture?: string;
  prevInspectionTime?: string | null;
  prevInspectionPlace?: string;
  specialistId?: number;
  title?: string;
  isLoading?: boolean;
  onCancel: () => void;
  onSubmit: (data: UpdateLeadSpecialistInput) => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ModalExpertAppointment: React.FC<IModalExpertAppointment> = (props): JSX.Element => {
  const {
    isOffsiteDiagnostics,
    specialistId,
    isLoading,
    prevDeparture,
    prevInspectionTime,
    prevInspectionPlace,
    title = "Назначение специалиста на заявку",
    onCancel,
    onSubmit,
  } = props;

  const {
    formState: { errors },
    control,
    setValue,
    trigger,
  } = useForm({ mode: "onBlur" });

  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const defaultFields = {
    specialistId: specialistId,
    departure: prevDeparture ? moment(prevDeparture, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
    inspectionPlace: prevInspectionPlace || "", // На беке required
  };

  const [form, setForm] = useState<UpdateLeadSpecialistInput>(
    isOffsiteDiagnostics
      ? {
          ...defaultFields,
          inspectionTime: prevInspectionTime
            ? `${prevInspectionTime.split(":")[0]}:${prevInspectionTime.split(":")[1]}`
            : "",
        }
      : {
          ...defaultFields,
        }
  );

  const { allSpecialists, isLoading: isSpecialistsLoading } = useSpecialists();

  useEffect(() => {
    if (form.specialistId) {
      const selectedSpecialist = allSpecialists.find((item) => item.value === specialistId);
      setValue("specialistId", selectedSpecialist?.value);
    }
  }, [allSpecialists]);

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalExpertAppointment: false,
    }));
  };

  const labelDatepicker = !isOffsiteDiagnostics ? "Дата контакта специалиста" : "Дата выезда";

  const getNormalizedDate = () => {
    if (form.departure) {
      return moment(form.departure, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
    return "";
  };

  return (
    <Modal name="modalExpertAppointment">
      <ModalTop title={title} onClose={handleButtonUndoClick} />
      <StyledModalBody disableOverflow={true}>
        <StyledFormGroup>
          <div className="space-20">
            <Datepicker
              value={getNormalizedDate()}
              label={labelDatepicker}
              placeholder="Выберите дату"
              isBordered={true}
              isBlock={true}
              hasTimeInput={false}
              minDate={new Date()}
              onInput={(val) => {
                setForm((prev) => ({
                  ...prev,
                  departure: moment(val, "DD.MM.YYYY").format("YYYY-MM-DD"),
                }));
              }}
            />
          </div>
          <div className="space-20">
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="specialistId"
              label="Специалист*"
              placeholder="Выберите специалиста"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={!form?.specialistId ? errors?.specialistId?.message : null}
              options={allSpecialists || []}
              value={[
                allSpecialists?.find((item) => item?.value === form?.specialistId) || [],
              ].flat()}
              isDisabled={isSpecialistsLoading}
              onChange={(value) =>
                value[0]?.value &&
                setForm((prev) => ({
                  ...prev,
                  specialistId: value[0].value,
                }))
              }
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
          {isOffsiteDiagnostics ? (
            <>
              <div className="space-20">
                <div>
                  <Timepicker
                    value={form.inspectionTime || ""}
                    label="Время выезда"
                    placeholder="Выберите время выезда"
                    isBordered={true}
                    isBlock={true}
                    onInput={(date) => {
                      setForm((prev) => ({ ...prev, inspectionTime: date }));
                    }}
                  />
                </div>
              </div>
              <div className="space-20">
                <Controller<IInput>
                  name="inspectionPlace"
                  control={control}
                  rules={inputValidationRule}
                  error={errors?.inspectionPlace?.message}
                  onChange={(value) => {}}
                  value={form.inspectionPlace}
                  label="Место осмотра*"
                  placeholder="Введите адрес"
                  isBordered={true}
                  onInput={(val) => {
                    setForm((prev) => ({ ...prev, inspectionPlace: val }));
                  }}
                  component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </StyledFormGroup>
      </StyledModalBody>
      <ModalFooter>
        <Button variant="secondary" color="gray" onClick={onCancel}>
          Отменить
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => {
            trigger();
            onSubmit({
              ...form,
            });
          }}
        >
          Назначить специалиста
        </Button>
      </ModalFooter>
    </Modal>
  );
};
