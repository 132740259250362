import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "@/shared/ui";
import { Dropdown, IDropdownItem, IDropdown } from "@yamaha-admin-sb/dropdown";
import { requiredValidationRule } from "@/shared/helpers/const";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";

import {
  useFindServicePricesQuery,
  useUpdateLeadPaymentDetailsMutation,
} from "@/shared/lib/graphql";
import { Title } from "@yamaha-admin-sb/title";
import { DataFilled } from "./DataFilled";
import { StyledFormItem, StyledGridContainer, StyledGridElement } from "@/entities/leads";
import { toFormatPrice } from "@/shared/helpers/utils";
import { IHandleSuccess } from "../../model/interfaces";

const DIAGNOSTIC_SLUG = "offsite-diagnostics";
interface IServicePrice {
  isCompleted: boolean;
  toPaid?: number | null;
  leadId: number;
  isEnabled: boolean;
  currentStatus: string;
  leadlSType: string;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}
export const ServicePrice: React.FC<IServicePrice> = (props) => {
  const {
    isCompleted,
    toPaid,
    leadId,
    isEnabled,
    handleSuccess,
    handleError,
    currentStatus,
    leadlSType,
  } = props;
  const [selectedPrice, setSelectedPrice] = useState<IDropdownItem[]>();
  const { data: servicePrice, isLoading } = useFindServicePricesQuery(
    {
      leadId,
    },
    {
      enabled: isEnabled,
    }
  );
  const { mutate: updateLeadPayment, isLoading: isUpdatingLead } =
    useUpdateLeadPaymentDetailsMutation();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    trigger,
  } = useForm({ mode: "onBlur" });

  const submitCallback = () => {
    const toBeAgreedStatusId = 3;
    const nextStatusId =
      leadlSType === DIAGNOSTIC_SLUG && currentStatus !== "to-be-agreed"
        ? toBeAgreedStatusId
        : null;

    if (selectedPrice && selectedPrice[0]?.value) {
      updateLeadPayment(
        { leadId, input: { toPaid: selectedPrice[0].value } },
        {
          onSuccess: () => {
            handleSuccess({
              text: "Стоимость услуги добавлена",
              disableCompleteStage: false,
              blockSlug: "servicePrice",
              nextStatusId,
            });
          },

          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    }
  };
  const servicePriceItems: IDropdownItem[] = useMemo(
    () =>
      servicePrice?.findServicePrices.map((item) => ({
        name: `${toFormatPrice(+item.price)} ₽`,
        value: +item.price,
        id: item.id,
      })) || [],
    [servicePrice]
  );

  useEffect(() => {
    if (toPaid) {
      setSelectedPrice([{ name: `${toFormatPrice(toPaid)} ₽`, value: toPaid }]);
      setValue("toPaid", toPaid);
    }
  }, [toPaid]);

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Стоимость услуги
        </Title>
      </div>

      {!isCompleted ? (
        <>
          <StyledGridContainer className="space-20">
            <StyledGridElement column="1">
              <Controller<IDropdown>
                // portal={document.querySelector("body") as HTMLElement}
                name="servicePrice"
                control={control}
                rules={requiredValidationRule}
                error={errors?.servicePrice?.message}
                options={servicePriceItems || []}
                value={selectedPrice || []}
                onChange={setSelectedPrice}
                label="Стоимость услуги *"
                placeholder="Выберите стоимость услуги *"
                isBordered={true}
                isDisabled={!isEnabled || isLoading}
                component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
              />
            </StyledGridElement>
          </StyledGridContainer>
          <Button
            isDisabled={!isEnabled}
            isLoading={isUpdatingLead}
            onClick={() => {
              trigger();
              handleSubmit(submitCallback)();
            }}
          >
            Сохранить
          </Button>
        </>
      ) : toPaid ? (
        <DataFilled text={`Стоимость услуги в размере ${toFormatPrice(toPaid)} ₽ назначена`} />
      ) : (
        <></>
      )}
    </StyledFormItem>
  );
};
