import React, { useMemo, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { WrappedTable, Container, RoleBlock, ModalConfirm, StyledTitle } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { Title } from "@yamaha-admin-sb/title";
import { Avatar } from "@yamaha-admin-sb/avatar";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import {
  IModalContextValue,
  ModalContext,
  useAddNotification,
  useQueryFilters,
} from "@/shared/lib/react";
import { CreateUserModal } from "@/features/user";
import { Default } from "@/widgets/layouts";
import {
  BaseUserOutput,
  CreateUserInput,
  FilterUserInput,
  GetUsersSortInfo,
  PaginationArgs,
  UpdateUserInput,
  useCreateUserMutation,
  useDeleteUserMutation,
  useFindManyRolesQuery,
  useFindManyUsersQuery,
  UserEntity,
  useRestoreOneUserMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
} from "@/shared/lib/graphql";
import { IHiddenNavigationItem } from "@yamaha-admin-sb/hidden-navigation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IChangePasswordForm, ModalChangePassword } from "@/features/auth";
import { getCapitalized } from "@/shared/helpers/utils";
import { PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { useDebounce } from "use-debounce";
import { MockUsersFilters } from "@/shared/helpers/mocks";
import { useGetMe } from "@/entities/session";

const breads = [
  {
    title: "Пользователи",
    clickable: false,
  },
];

const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
`;

const WrappedUserName = styled.div`
  margin-left: 10px;
`;
const headers = [
  {
    Header: "Имя",
    accessor: "name",
    isSortable: true,
  },
  {
    Header: "E-mail",
    accessor: "email",
    isSortable: true,
  },
  {
    Header: "Телефон",
    accessor: "phone",
    isSortable: true,
  },
  {
    Header: "Роль в системе",
    accessor: "role",
    isSortable: true,
  },
];

const UnProtectedTemplate = {
  DIRECTOR: {
    create: true,
    delete: true,
    percent: true,
    edit: [],
    view: [],
  },

  ADMIN: {
    create: true,
    delete: true,
    percent: true,
    edit: [],
    view: [],
  },
  HEAD_OF_SP: {
    create: false,
    delete: false,
    percent: false,
    edit: ["DIRECTOR", "ADMIN", "HEAD_OF_MN", "HEAD_OF_SP", "MANAGER", "SN_MANAGER", "ACCOUNTANT"],
    view: ["DIRECTOR", "ADMIN", "HEAD_OF_MN", "HEAD_OF_SP", "MANAGER", "SN_MANAGER", "ACCOUNTANT"],
  },
  HEAD_OF_MN: {
    create: false,
    delete: false,
    percent: false,
    edit: ["DIRECTOR", "ADMIN", "HEAD_OF_MN", "HEAD_OF_SP", "SPECIALIST", "ACCOUNTANT"],
    view: ["DIRECTOR", "ADMIN", "HEAD_OF_MN", "HEAD_OF_SP", "SPECIALIST", "ACCOUNTANT"],
  },
};

const Users: React.FC = () => {
  const { selectedFilters } = useQueryFilters();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { me } = useGetMe();
  const navigate = useNavigate();
  const notify = useAddNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMounted, setIsMounted] = useState(false);
  const [editingUserId, setEditingUserId] = useState<number | undefined>();
  const [pagination, setPagination] = useState<PaginationArgs>(PAGINATION_DEFAULT_OPTIONS);
  const [filter, setFilter] = useState<FilterUserInput>(selectedFilters.filter);
  const [sort, setSort] = useState<GetUsersSortInfo>({});
  const [searchDebouncedValue] = useDebounce(filter?.search, 500);
  const [isStale, setIsStale] = useState(false);

  const { data: roles, isStale: isRolesStale } = useFindManyRolesQuery(undefined, {
    staleTime: 5 * 60 * 1000,
    enabled: isStale,
  });

  useEffect(() => {
    if (!isStale && isRolesStale) setIsStale(true);
  }, [isRolesStale]);

  useEffect(() => {
    const page = searchParams.get("page");

    if (!page) return;

    handlePaginationClick(+page - 1);
  }, [setSearchParams]);

  const { data, isLoading, refetch } = useFindManyUsersQuery({
    filter: {
      ...filter,
      search: searchDebouncedValue,
    },
    pagination,
    sort,
  });

  useEffect(() => {
    if (!isMounted) return;

    const page = Math.floor((pagination?.skip || 1) / (pagination?.take || 1) + 1);

    // setSearchParams(
    //   page > 1
    //     ? {
    //         page: page.toString(),
    //       }
    //     : {},
    // );
  }, [isMounted, pagination]);

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, []);

  const { mutate: createUser, isLoading: isUserCreating } = useCreateUserMutation();
  const { mutate: updateUser, isLoading: isUserUpdating } = useUpdateUserMutation();
  const { mutate: deleteUser, isLoading: isUserDeleting } = useDeleteUserMutation();
  const { mutate: restoreUser, isLoading: isUserRestore } = useRestoreOneUserMutation();
  const { mutate: changePassword, isLoading: isPasswordChanging } = useUpdateUserPasswordMutation();
  const tablePagination = {
    page: Math.floor(
      (data?.findManyUsers?.meta?.skip || 1) / (data?.findManyUsers?.meta?.take || 1)
    ),
    count: Math.ceil((data?.findManyUsers?.meta?.count || 0) / (pagination.take || 1)),
  };
  const editingUser = editingUserId
    ? (data?.findManyUsers?.data?.find((user) => {
        return user.id === editingUserId;
      }) as BaseUserOutput)
    : undefined;

  const preparedTableData = useMemo(
    () =>
      data?.findManyUsers?.data?.map((item) => ({
        ...item,
        email: item.email,
        phone: item.phone,
        role: <RoleBlock role={item.role?.name || ""} />,
        name: (
          <StyledAvatar>
            <Avatar size="s" firstName={item.name} lastName={item.surname} />
            <WrappedUserName>
              <Paragraph size={12} weight={500}>
                {item.name} {item.surname}
              </Paragraph>
            </WrappedUserName>
          </StyledAvatar>
        ),
        menu: [
          {
            title: "Просмотреть пользователя",
            icon: "profile",
            value: "view",
            type: "black",
            isDisabled:
              !!UnProtectedTemplate[me?.getMe?.role?.slug || ""]?.view.includes(
                item?.role?.slug || ""
              ) || !!item.deletedAt,
          },
          {
            title: "Изменить пользователя",
            icon: "edit",
            value: "userEdit",
            type: "black",
            isDisabled: !!UnProtectedTemplate[me?.getMe?.role?.slug || ""]?.edit.includes(
              item?.role?.slug || ""
            ),
          },
          {
            title: "Изменить пароль",
            icon: "edit",
            value: "edit",
            type: "black",
            isDisabled: !UnProtectedTemplate[me?.getMe?.role?.slug || ""]?.delete,
          },
          {
            title: item.deletedAt ? "Разблокировать" : "Заблокировать",
            icon: "block-user",
            value: "delete",
            type: "red",
            isDisabled: !UnProtectedTemplate[me?.getMe?.role?.slug || ""]?.delete,
          },
        ],
      })) || [],
    [data, editingUserId, me]
  );

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (data?.findManyUsers?.meta?.take || pagination.take || 10),
    }));
  };

  const handleOpenModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: true }));
  };

  const handleCreateUser = (user: CreateUserInput) => {
    createUser(
      { input: user },
      {
        onSuccess: async () => {
          await refetch();
          setModalContext((prev) => ({ ...prev, createUserModal: false }));
          notify("Пользователь добавлен", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleUpdateUser = (user: UpdateUserInput) => {
    updateUser(
      { input: user },
      {
        onSuccess: async () => {
          // const newData = [...data?.findManyUsers?.data!];
          // const index =
          //   data?.findManyUsers?.data?.findIndex(
          //     (_user) => _user.id === user.id
          //   ) || -1;
          //
          // if (index === -1) return;
          //
          // newData[index] = user as UserEntity;
          //
          // queryClient.setQueryData<FindManyUsersQuery>(
          //   "findManyUsersQuery",
          //   (prevState) => ({
          //    f: {
          //       ...(prevState?.findManyUsers as FindManyUsersQuery["findManyUsers"]),
          //       data: newData,
          //     },
          //   })
          // );
          await refetch();
          setEditingUserId(undefined);
          setModalContext((prev) => ({ ...prev, createUserModal: false }));
          notify("Пользователь изменен", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleMenuItemClick = (cell: UserEntity, menuItem: IHiddenNavigationItem) => {
    if (menuItem.isDisabled) return;
    switch (menuItem.value) {
      case "view":
        navigate(`/users/${cell.id}`);
        break;
      case "delete":
        setEditingUserId(cell.id);
        setModalContext((prev) => ({ ...prev, confirmModal: true }));
        break;
      case "edit":
        setEditingUserId(cell.id);
        setModalContext((prev) => ({ ...prev, changePasswordModal: true }));
        break;
      case "userEdit":
        setEditingUserId(cell.id);
        setModalContext((prev) => ({ ...prev, createUserModal: true }));
        break;
    }
  };

  const handleUserDelete = () => {
    deleteUser(
      {
        id: editingUserId as number,
      },
      {
        onSuccess: () => {
          refetch();
          setModalContext((prev) => ({ ...prev, confirmModal: false }));
          notify("Пользователь заблокирован", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const handleChangePassword = (form: IChangePasswordForm) => {
    if (!editingUserId) return;

    changePassword(
      {
        id: editingUserId,
        password: form.newPassword,
      },
      {
        onSuccess: () => {
          setEditingUserId(undefined);
          setModalContext((prev) => ({ ...prev, changePasswordModal: false }));
          notify("Пароль изменен", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleRestoreUser = () => {
    restoreUser(
      {
        id: editingUserId as number,
      },
      {
        onSuccess: () => {
          refetch();
          setModalContext((prev) => ({ ...prev, confirmModal: false }));
          notify("Пользователь разблокирован", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const renderChangePasswordModal = (): JSX.Element => {
    const user = data?.findManyUsers?.data?.find((user) => user.id === editingUserId);

    if (!user) return <></>;

    const userName = `${user.name} ${user.surname}`;

    return (
      <ModalChangePassword
        isLoading={isPasswordChanging}
        userName={userName}
        onCancel={() => setModalContext((prev) => ({ ...prev, changePasswordModal: false }))}
        onSubmit={handleChangePassword}
      />
    );
  };

  return (
    <Default breads={breads}>
      <StyledTitle className="space-32">
        <Title level={2} color="gray-700">
          Пользователи
        </Title>
      </StyledTitle>
      <Container>
        <WrappedTable
          checkbox={{
            label: "Только заблокированные",
            value: false,
            onCheckClick: (value) =>
              setFilter((prevState) => ({ ...prevState, onlyDeleted: value })),
          }}
          isLoading={isLoading}
          filters={MockUsersFilters(roles?.findManyRoles || [])}
          showMenu={true}
          tableTitle="Все пользователи"
          headers={headers}
          data={preparedTableData}
          search={{
            placeholder: "Поиск по пользователям",
            onSearch: (value) => setFilter((prevState) => ({ ...prevState, search: value })),
          }}
          onMenuItemSelected={handleMenuItemClick}
          pagination={tablePagination}
          onPaginationClick={handlePaginationClick}
          onFiltersApply={(data) =>
            Object.keys(data).length !== 0
              ? setFilter((prevState) => ({ ...prevState, ...data }))
              : setFilter(() => data)
          }
          onSort={handleSort}
          rightBtn={() => {
            if (UnProtectedTemplate[me?.getMe?.role?.slug || ""]?.create) {
              return (
                <Button
                  variant="secondary"
                  color="gray"
                  leftIcon={true}
                  icon="plus"
                  onClick={() => handleOpenModal("createUserModal")}
                >
                  Добавить пользователя
                </Button>
              );
            } else return <></>;
          }}
        />
      </Container>
      <CreateUserModal
        data={editingUser}
        onSubmitCreate={handleCreateUser}
        onSubmitUpdate={handleUpdateUser}
        isLoading={isUserCreating || isUserUpdating}
      />
      {renderChangePasswordModal()}
      <ModalConfirm
        title={
          editingUser?.deletedAt
            ? "Вы действительно хотите разблокировать пользователя?"
            : "Вы действительно хотите заблокировать пользователя?"
        }
        text={editingUser?.deletedAt ? "" : "Отменить это действие будет невозможно"}
        cancelButtonText="Отменить"
        confirmButtonText={editingUser?.deletedAt ? "Да, разблокировать" : "Да, заблокировать"}
        icon="block-user"
        confirmButtonColor={editingUser?.deletedAt ? "blue" : "red"}
        isLoading={isUserDeleting || isUserRestore}
        onCancel={() => setModalContext((prev) => ({ ...prev, confirmModal: false }))}
        onConfirm={() => {
          editingUser?.deletedAt ? handleRestoreUser() : handleUserDelete();
        }}
      />
    </Default>
  );
};

export default Users;
