import { useManagers } from "@/entities/user";
import { colors, transformDateToISO } from "@/shared/helpers";
import { requiredValidationRule } from "@/shared/helpers/const";
import { RoleEnum, useGetLeadQuery, useGetLeadsQuery } from "@/shared/lib/graphql";
import { Datepicker, Timepicker } from "@/shared/ui";
import { Dropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { Input } from "@yamaha-admin-sb/input";
import { Textarea } from "@yamaha-admin-sb/textarea";
import { useEffect, useMemo, useState, type FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { remindNotificationOptions } from "../model/constants";
import { useGetMe } from "@/entities/session";

export interface IReminderFormValues {
  date: string;
  time: string;
  title: string;
  notifyTime: number | null;
  comment: string;
  leadId: number;
  userId: number;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledDateWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledDate = styled.div`
  flex-grow: 1;
`;

const StyledError = styled.div`
  font-size: 12px;
  color: ${colors.red};
`;

export const ReminderFormFileds: FC = () => {
  const [leadQuery, setLeadQuery] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const [debouncedLeadQuery] = useDebounce(leadQuery, 500);

  const {
    formState: { errors, defaultValues },
    control,
  } = useFormContext<IReminderFormValues>();

  const { data: leads } = useGetLeadsQuery({
    input: {
      pagination: {
        skip: 0,
        take: 10,
      },
      filter: {
        query: debouncedLeadQuery,
      },
    },
  });

  const { data: lead } = useGetLeadQuery(
    {
      id: defaultValues?.leadId as number,
    },
    { enabled: !!defaultValues?.leadId }
  );

  const { me } = useGetMe();

  const myRole = me?.getMe.role?.slug;

  const canSelectUser = myRole
    ? [RoleEnum.Admin, RoleEnum.HeadOfMn, RoleEnum.Director, RoleEnum.SnManager].includes(myRole)
    : false;

  useEffect(() => {
    if (lead) {
      setLeadQuery(lead.getLead.num);
    }
  }, [lead]);

  const { allManagers } = useManagers();

  const filteredManagers = allManagers?.filter((item) => item.name.includes(userQuery));

  const leadOptions = useMemo(() => {
    return (
      leads?.getLeads?.data?.map((item) => ({
        name: `${new Date(item.createdAt).toLocaleDateString("ru-RU")} №${item.num}`,
        value: item.id,
      })) || []
    );
  }, [leads]);

  return (
    <StyledContainer>
      <StyledDateWrapper>
        <StyledDate>
          <Controller
            name="date"
            control={control}
            rules={requiredValidationRule}
            render={({ field }) => (
              <Datepicker
                value={field.value}
                label="Дата события"
                placeholder="Дата"
                isBordered={true}
                isBlock={true}
                onInput={(val) => {
                  const formattedValue = val ? transformDateToISO(val) || "" : "";
                  field.onChange(formattedValue);
                }}
              />
            )}
          />
          {errors.date && <StyledError>{errors.date.message}</StyledError>}
        </StyledDate>
        <div>
          <Controller
            name="time"
            control={control}
            rules={requiredValidationRule}
            render={({ field }) => (
              <Timepicker
                label="Время *"
                placeholder="Время"
                isBordered={true}
                isBlock={true}
                onInput={field.onChange}
                {...field}
              />
            )}
          />
          {errors.time && <StyledError>{errors.time.message}</StyledError>}
        </div>
      </StyledDateWrapper>

      <Controller
        name="notifyTime"
        control={control}
        render={({ field }) => (
          <Dropdown
            label="Напоминание"
            isBordered={true}
            options={remindNotificationOptions}
            value={
              [
                remindNotificationOptions?.find((item) => item.value === field.value) || [],
              ].flat() || []
            }
            onChange={(value: IDropdownItem[]) => field.onChange(value[0]?.value)}
          />
        )}
      />

      <div>
        <Controller
          name="leadId"
          control={control}
          render={({ field }) => (
            <Dropdown
              label="Заявка"
              placeholder="Поиск..."
              isBordered={true}
              searchable
              onSearch={(value) => setLeadQuery(value)}
              options={leadOptions}
              value={[leadOptions?.find((item) => item.value === field.value) || []].flat() || []}
              onChange={(value: IDropdownItem[]) => field.onChange(value[0]?.value)}
            />
          )}
        />
        {errors.leadId && <StyledError>{errors.leadId.message}</StyledError>}
      </div>

      {canSelectUser && (
        <div>
          <Controller
            name="userId"
            control={control}
            //rules={requiredValidationRule}
            render={({ field }) => (
              <Dropdown
                label="Ответственный"
                placeholder="Выберите ответственного"
                searchable
                onSearch={(value) => setUserQuery(value)}
                isBordered={true}
                options={filteredManagers || []}
                value={[filteredManagers.find((item) => field?.value === item?.id) || []].flat()}
                onChange={(value: IDropdownItem[]) => field.onChange(value[0]?.id)}
              />
            )}
          />
          {errors.userId && <StyledError>{errors.userId.message}</StyledError>}
        </div>
      )}

      <div>
        <Controller
          name="title"
          control={control}
          rules={requiredValidationRule}
          render={({ field }) => (
            <Input
              {...field}
              isBordered={true}
              label="Заголовок события *"
              placeholder="Заголовок события"
            />
          )}
        />
        {errors.title && <StyledError>{errors.title.message}</StyledError>}
      </div>

      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <Textarea {...field} label="Комментарий" placeholder="Комментарий" />
        )}
      />
    </StyledContainer>
  );
};
