import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "@yamaha-admin-sb/input";
import { Button } from "@yamaha-admin-sb/button";
import { ModalTop, Modal, ModalBody, ModalFooter } from "@/shared/ui";
import { IChangePasswordForm } from "../model/interfaces";

interface IModalChangePassword {
  userName?: string;
  isLoading?: boolean;
  onCancel: () => void;
  onSubmit: (form: IChangePasswordForm) => void;
}

const StyledChangePasswordForm = styled.form``;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ModalChangePassword: React.FC<IModalChangePassword> = (props): JSX.Element => {
  const { userName, isLoading, onCancel, onSubmit } = props;
  const [form, setForm] = useState<IChangePasswordForm>({
    //oldPassword: "",
    newPasswordConfirmation: "",
    newPassword: "",
  });

  const handleChange = (name: string, value: string) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal name="changePasswordModal" hasClose={true}>
      <ModalTop title={`Новый пароль ${userName ? `для ${userName}` : ""}`} onClose={onCancel} />
      <ModalBody>
        <StyledChangePasswordForm>
          {/*<StyledFormGroup>*/}
          {/*  <Input*/}
          {/*    name="oldPassword"*/}
          {/*    label="Старый пароль *"*/}
          {/*    type="password"*/}
          {/*    placeholder="Введите старый пароль"*/}
          {/*    isBordered={true}*/}
          {/*    value={form.oldPassword}*/}
          {/*    onChange={(value) => handleChange("oldPassword", value)}*/}
          {/*  />*/}
          {/*</StyledFormGroup>*/}
          <StyledFormGroup>
            <Input
              name="newPassword"
              label="Новый пароль *"
              type="password"
              placeholder="Придумайте пароль"
              description="Не менее 8 символов (используйте заглавные и прописные латинские буквы и цифры)"
              isBordered={true}
              value={form.newPassword}
              onChange={(value) => handleChange("newPassword", value)}
            />
          </StyledFormGroup>
          {/*<StyledFormGroup>*/}
          {/*  <Input*/}
          {/*    name="repeatNewPassword"*/}
          {/*    type="password"*/}
          {/*    label="Продублируйте новый пароль*"*/}
          {/*    placeholder="Введите старый пароль"*/}
          {/*    isBordered={true}*/}
          {/*    value={form.newPasswordConfirmation}*/}
          {/*    onChange={(value) =>*/}
          {/*      handleChange("newPasswordConfirmation", value)*/}
          {/*    }*/}
          {/*  />*/}
          {/*</StyledFormGroup>*/}
        </StyledChangePasswordForm>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" color="gray" onClick={onCancel}>
          Отменить
        </Button>
        <Button isLoading={isLoading} type="submit" onClick={() => onSubmit(form)}>
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  );
};
