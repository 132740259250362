import React from "react";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import { useGetMe } from "@/entities/session";
import { EditServicesButton } from "@/features/leads";
import styled from "styled-components";
import { colors } from "@/shared/helpers";
import { RoleEnum } from "@/shared/lib/graphql";
import { LeadsTable } from "./ui/LeadsTable";

const StyledHead = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border-bottom: 1px solid ${colors.gray200};

  @media (min-width: 768px) {
    padding: 20px 32px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: 1024px) {
    padding: 20px 40px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const breads = [
  {
    title: "Заявки",
    clickable: false,
  },
];

const Leads: React.FC = () => {
  const { me } = useGetMe();
  const userRole: any = me?.getMe.role?.slug;

  const canChangePrice = ![RoleEnum.Specialist, RoleEnum.Manager].includes(userRole);

  return (
    <>
      <Default breads={breads}>
        <StyledHead>
          <StyledTitle>
            <Title level={2} color="gray-700">
              Заявки
            </Title>
          </StyledTitle>
          {canChangePrice && <EditServicesButton />}
        </StyledHead>
        <LeadsTable />
      </Default>
    </>
  );
};

export default Leads;
