import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Container, WrappedTable, StyledTitle } from "@/shared/ui";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import {
  RatingPagination,
  RatingsFilters,
  RatingsSortInfo,
  SortEnum,
  useGetReviewsQuery,
} from "@/shared/lib/graphql";
import { Link } from "react-router-dom";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { getCapitalized } from "@/shared/helpers/utils";
import { MockReviewsFilters } from "@/shared/helpers/mocks";
import { useQueryFilters } from "@/shared/lib/react";
import { useSpecialists, useManagers } from "@/entities/user";

const breads = [
  {
    title: "Отзывы о работе",
    clickable: false,
  },
];

const headers = [
  {
    Header: "Клиент",
    accessor: "client",
    isSortable: false,
  },
  {
    Header: "Заявка",
    accessor: "lead",
    isSortable: false,
  },
  {
    Header: "Оценка",
    accessor: "value",
    isSortable: true,
  },
  {
    Header: "Специалист",
    accessor: "specialist",
    isSortable: true,
  },
  {
    Header: "Менеджер",
    accessor: "manager",
    isSortable: true,
  },
  {
    Header: "Комментарий",
    accessor: "comment",
    isSortable: false,
  },
];

const StyledPerson = styled.div`
  white-space: nowrap;
`;
const Reviews: React.FC = () => {
  const { selectedFilters, setQueryFilters } = useQueryFilters();
  const [pagination, setPagination] = useState<RatingPagination>(PAGINATION_DEFAULT_OPTIONS);
  const [filter, setFilter] = useState<RatingsFilters>({
    ...selectedFilters.filter,
  });
  const [sort, setSort] = useState<RatingsSortInfo>({ date: SortEnum.Desc });
  const { data: reviews, isLoading } = useGetReviewsQuery({
    input: { sort, pagination, filter },
  });

  const tablePagination = {
    page: Math.floor(
      (reviews?.findManyRatings?.meta?.skip || 0) / (reviews?.findManyRatings?.meta?.take || 1)
    ),
    count: Math.ceil((reviews?.findManyRatings?.meta?.count || 0) / (pagination.take || 1)),
  };

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (reviews?.findManyRatings?.meta?.take || pagination.take || 10),
    }));
  };

  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const preparedData = useMemo(
    () =>
      reviews?.findManyRatings?.data?.map((item) => ({
        ...item,

        client: (
          <StyledPerson>{`${item.leadModel.contact?.surname || ""} ${
            item.leadModel.contact?.name || ""
          }`}</StyledPerson>
        ),

        manager: (
          <StyledPerson>{`${item.manager?.surname || ""} ${
            item.manager?.name || ""
          }`}</StyledPerson>
        ),
        specialist: (
          <StyledPerson>{`${item.specialist?.surname || ""} ${
            item.specialist?.name || ""
          }`}</StyledPerson>
        ),
        lead: (
          <Link to={`/leads/${item.leadModel.id}`}>
            <Paragraph color="blue-200" size={12} weight={500}>
              №{item.leadModel.num}
            </Paragraph>
          </Link>
        ),
      })) || [],
    [reviews]
  );

  const { allManagers } = useManagers();
  const { allSpecialists } = useSpecialists();

  return (
    <>
      <Default breads={breads}>
        <StyledTitle className="space-32">
          <Title level={2} color="gray-700">
            Отзывы о работе
          </Title>
        </StyledTitle>

        <Container>
          <WrappedTable
            tableTitle="Все отзывы"
            showMenu={false}
            headers={headers}
            search={{
              placeholder: "Поиск по заявкам",
              onSearch: (value) => setFilter((prev) => ({ ...prev, leadNum: value || "" })),
            }}
            filters={MockReviewsFilters(allManagers, allSpecialists)}
            onFiltersApply={(data) => {
              if (Object.keys(data).length) {
                setFilter((prevState) => ({ ...prevState, ...data }));
              } else {
                setFilter({});
              }
            }}
            data={preparedData || []}
            isLoading={isLoading}
            onSort={handleSort}
            onMenuItemSelected={() => ({})}
            pagination={tablePagination}
            onPaginationClick={handlePaginationClick}
          />
        </Container>
      </Default>
    </>
  );
};

export default Reviews;
