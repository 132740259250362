import { ModalContext } from "@/shared/lib/react";
import { Modal, ModalBody, ModalFooter, ModalTop } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useContext, type FC } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { IReminderFormValues, ReminderFormFileds } from "./ReminderFormFileds";
import { remindNotificationOptions } from "../model/constants";
import moment from "moment";
import { useCreateReminderMutation } from "@/shared/lib/graphql";

interface IModalCreateReminderProps {
  leadId?: number;
  userId?: number;
  date?: Date | null;
  transparentOverlay?: boolean;
  onSuccess?: () => void;
}

export const ModalCreateReminder: FC<IModalCreateReminderProps> = ({
  leadId,
  userId,
  date,
  onSuccess,
  transparentOverlay,
}) => {
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const createReminder = useCreateReminderMutation();

  const methods = useForm<IReminderFormValues>({
    defaultValues: {
      notifyTime: remindNotificationOptions[0].value,
      leadId: leadId,
      userId: userId,
      date: moment(date).format("YYYY-MM-DD"),
    },
  });

  const onClose = () => {
    setModalContext((prev) => ({ ...prev, modalCreateReminder: false }));
  };

  const onSubmit = (values: IReminderFormValues) => {
    createReminder.mutate(
      {
        input: {
          remindDate: `${values.date} ${values.time}`,
          remindNotificationDate: values.notifyTime,
          title: values.title,
          userId: values.userId,
          leadId: values.leadId,
          comment: values.comment,
        },
      },
      {
        onSuccess: () => {
          onSuccess?.();
          onClose();
        },
      }
    );
  };

  return (
    <Modal name="modalCreateReminder" {...(transparentOverlay && { overlayStyle: { opacity: 0 } })}>
      <ModalTop title="Новое событие" onClose={onClose} />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalBody>
          <FormProvider {...methods}>
            <ReminderFormFileds />
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={onClose}>
            Отменить
          </Button>
          <Button type="submit">Создать</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
