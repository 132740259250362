import React from "react";
import styled from "styled-components";
import { Default } from "@/widgets/layouts";
import { ProfileLayout } from "@/widgets/layouts";
import { useGetMe } from "@/entities/session";

const StyledProfile = styled.div``;

const breads = [
  {
    title: "Мой профиль",
    clickable: false,
  },
];

const Profile: React.VFC = () => {
  const { me } = useGetMe();

  return (
    <Default breads={breads}>
      <StyledProfile>
        {me?.getMe ? <ProfileLayout user={me?.getMe} isSelf={true} /> : <></>}
      </StyledProfile>
    </Default>
  );
};

export default Profile;
