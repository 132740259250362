import { Avatar } from "@yamaha-admin-sb/avatar";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import React from "react";
import styled from "styled-components";

interface IAvatarWithProps {
  user?: { firstName?: string; lastName?: string };
  renderProps: () => JSX.Element;
}
const StyledFrame = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AvatarWithBtn: React.FC<IAvatarWithProps> = (
  props,
): JSX.Element => {
  const { user, renderProps } = props;
  return (
    <StyledFrame>
      {user ? (
        <>
          <Avatar
            size="xs"
            isDisabled={true}
            firstName={user.firstName}
            lastName={user.lastName}
          />
          <Paragraph
            size={12}
            weight={500}
          >{`${user?.firstName} ${user?.lastName}`}</Paragraph>
        </>
      ) : (
        renderProps()
      )}
    </StyledFrame>
  );
};
