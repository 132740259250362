import React from "react";
import styled from "styled-components";
import { Input } from "@yamaha-admin-sb/input";
import { Button } from "@yamaha-admin-sb/button";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";

const StyledRecoveryPasswordForm = styled.form`
  width: 100%;
  max-width: 300px;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const StyledRecoveryPasswordFormTop = styled.div`
  margin-bottom: 48px;
  text-align: center;

  p:first-child {
    margin-bottom: 15px;
  }
`;

const StyledFormActions = styled.div`
  display: flex;
  justify-content: space-between;

  button:first-child {
    margin-right: 8px;
  }
`;

export const RecoveryPasswordForm: React.FC = (props): JSX.Element => {
  return (
    <StyledRecoveryPasswordForm>
      <StyledRecoveryPasswordFormTop>
        <Paragraph size={16} weight={500} color="black">
          Восстанвление пароля
        </Paragraph>
        <Paragraph size={12} weight={400} color="gray-600">
          Введите почту, на которую будут отправлены инструкции по смене пароля
        </Paragraph>
      </StyledRecoveryPasswordFormTop>
      <StyledFormGroup>
        <Input
          name="email"
          label="E-mail"
          type="email"
          placeholder="Введите E-mail"
          isBordered={true}
        />
      </StyledFormGroup>
      <StyledFormActions>
        <IconButton color="gray" icon="arrow-left" />
        <Button isBlock={true}>Отправить</Button>
      </StyledFormActions>
    </StyledRecoveryPasswordForm>
  );
};
