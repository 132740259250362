import { ModalContext } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import { ModalBody, ModalFooter, Modal, ModalTop, Datepicker } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import {
  ContactEntity,
  Maybe,
  UpdateContactDetailsInput,
  useUpdateContactDetailsMutation,
} from "@/shared/lib/graphql";
import { Radio } from "@yamaha-admin-sb/radio";
import moment from "moment";
import { Textarea } from "@yamaha-admin-sb/textarea";
import { StyledFlags } from "@/entities/leads";
import { IHandleSuccess } from "../../model/interfaces";

export interface IModal {
  leadId: number;
  contact?: Maybe<ContactEntity>;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

export const ModalRecontactData: React.FC<IModal> = (props): JSX.Element => {
  const { leadId, contact, handleSuccess, handleError } = props;
  const [_, setModalContext = () => ({})] = useContext(ModalContext);

  const [form, setForm] = useState<{
    recontact: string | null;
    clientAnswered: boolean | null | undefined;
    comment: string | null | undefined;
  }>({
    recontact: contact?.recontact ? contact?.recontact.split("T")[0] : null,
    clientAnswered: contact?.clientAnswered,
    comment: contact?.comment,
  });

  const { handleSubmit, setValue } = useForm({
    mode: "onBlur",
  });

  const { mutate: updateContact, isLoading } = useUpdateContactDetailsMutation();

  const handleChange = (key: keyof UpdateContactDetailsInput, value: string | null | boolean) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdate = () => {
    updateContact(
      {
        leadId,
        input: form,
      },
      {
        onSuccess: () => {
          handleButtonUndoClick();
          handleSuccess({
            text: "Дата следующего контакта изменена",
            disableCompleteStage: true,
          });
        },
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalRecontactData: false,
    }));
  };

  useEffect(() => {
    contact && Object.keys(contact).forEach((item) => setValue(item, contact[item]));
  }, [contact]);

  return (
    <Modal name="modalRecontactData">
      <ModalTop title="Коммуникация с клиентом" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <ModalBody disableOverflow={true}>
          <div className="space-20">
            <Datepicker
              minDate={new Date()}
              value={(form.recontact as string) || ""}
              label="Дата следующего контакта"
              placeholder="Выберите дату"
              isBordered={true}
              isBlock={true}
              onInput={(date) => {
                handleChange(
                  "recontact",
                  date ? moment(date, "DD.MM.YYYY").format("YYYY-MM-DD") : null
                );
              }}
            />
          </div>
          <div className="space-40">
            <StyledFlags>
              <Radio
                name="answer1"
                value={form.clientAnswered === true}
                onClick={(value) => {
                  handleChange("clientAnswered", form.clientAnswered === true ? null : true);
                }}
              >
                Дозвонился
              </Radio>
              <Radio
                name="answer2"
                value={form.clientAnswered === false}
                onClick={(value) =>
                  handleChange("clientAnswered", form.clientAnswered === false ? null : false)
                }
              >
                Не дозвонился
              </Radio>
            </StyledFlags>
          </div>
          <div>
            <Textarea
              label={"Комментарий"}
              placeholder={"Введите комментарий"}
              value={(form.comment as string) || ""}
              name={"answer3"}
              onInput={(comment) => {
                handleChange("comment", comment ? comment : null);
              }}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
