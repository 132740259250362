import React from "react";
import { TextButton } from "@yamaha-admin-sb/text-button";
import styled from "styled-components";
import { Paragraph } from "@yamaha-admin-sb/paragraph";

interface ICustomNotification {
  title: string;
  link?: { url: string; linkTitle: string };
}
const StyledContainer = styled.div``;

export const CustomNotification: React.FC<ICustomNotification> = (props): JSX.Element => {
  const { title, link } = props;
  return (
    <StyledContainer>
      <Paragraph size={14} weight={400} color={"neutral-white"}>
        {title}
      </Paragraph>
      {link ? <TextButton onClick={() => window.open(link.url, "_self")}>{link.linkTitle}</TextButton> : ""}
    </StyledContainer>
  );
};
