import {
  CallMessageOutput,
  // CallSubscriptionsDocument,
  GetNotificationsQuery,
  NotificationAppearedDocument,
  NotificationMessageOutput,
  SortEnum,
  Subscription,
  useGetNotificationsQuery,
} from "@/shared/lib/graphql";
import { SubscriptionClient } from "graphql-subscriptions-client";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

// TODO заменить из генерации
const CallSubscriptionsDocument = `
subscription CallSubscriptions {
callAppeared {
id
leadId
from
to
status
createdAt
manager {
  id
  name
  surname
}
}
}
`;

let GRAPHQL_ENDPOINT = process.env.REACT_APP_BASE_URL?.replace("https://", "wss://") || "";

GRAPHQL_ENDPOINT = GRAPHQL_ENDPOINT.replace("http://", "ws://");

export const useGetNotifications = () => {
  const queryClient = useQueryClient();
  const meCache = queryClient.getQueryData<GetNotificationsQuery>("notifications", {
    predicate: (query) => query.queryHash === `getMe-${Cookies.get("accessToken")}`,
  });
  const {
    data: notifications,
    isLoading,
    refetch,
  } = useGetNotificationsQuery(
    {
      input: {
        pagination: { take: 100, skip: 0 },
        sort: { date: SortEnum.Desc },
        filter: { isShown: false },
      },
    },
    {
      initialData: meCache,
      enabled: !meCache,
      queryKeyHashFn: (key) => `${key}-${Cookies.get("accessToken")}`,
    }
  );

  const updateNotifications = () => {
    refetch();
  };
  return { notifications, updateNotifications };
};

export const useWebScocket = ({
  onSuccess,
}: {
  onSuccess: (data: NotificationMessageOutput) => void;
}) => {
  const { updateNotifications } = useGetNotifications();
  const queryClient = useQueryClient();
  useEffect(() => {
    const client = new SubscriptionClient(GRAPHQL_ENDPOINT, {
      reconnect: true,
      reconnectionAttempts: 3,
      connectionParams: {
        token: Cookies.get("accessToken"),
      },
      connectionCallback: (error) => {
        error && console.error(error);
      },
    });

    client.request({ query: NotificationAppearedDocument });

    const subscription = client.request({ query: NotificationAppearedDocument }).subscribe({
      next({ data }: { data: Subscription }) {
        if (data) {
          onSuccess(data.notificationAppeared);
          updateNotifications();
          queryClient.setQueryData("notification", data.notificationAppeared);
        }
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {};
};

export const useCallWebsocket = ({
  onSuccess,
}: {
  onSuccess: (data: CallMessageOutput) => void;
}) => {
  useEffect(() => {
    const client = new SubscriptionClient(GRAPHQL_ENDPOINT, {
      reconnect: true,
      reconnectionAttempts: 3,
      connectionParams: {
        token: Cookies.get("accessToken"),
      },
      connectionCallback: (error) => {
        error && console.error(error);
      },
    });

    client.request({ query: CallSubscriptionsDocument });

    const subscription = client.request({ query: CallSubscriptionsDocument }).subscribe({
      next({ data }: { data: Subscription }) {
        if (data) {
          onSuccess(data.callAppeared);
        }
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {};
};
