import React, { useEffect, useState } from "react";
import { Button } from "@yamaha-admin-sb/button";
import { Title } from "@yamaha-admin-sb/title";
import { StyledFormItem } from "@/entities/leads";
import {
  LeadReportOutput,
  useGetReportQuery,
  useUpdateLeadReportMutation,
} from "@/shared/lib/graphql";
import styled from "@emotion/styled";
import { useAddNotification } from "@/shared/lib/react";
import { ReportPreview } from "./ReportPreview";
import { getDocument } from "../../model/getDocument";
import { downloadURL } from "@/shared/helpers";

interface IReport {
  enableImageLoad: boolean;
  leadId: number;
  isDisabled: boolean;
  reports?: any[];
  isOffsiteDiagnostics: boolean;
  handleReportCreate: () => void;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  updateLead: () => void;
}

const StyledInnerBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled.div`
  margin-top: 20px;
`;

export const Report: React.FC<IReport> = (props) => {

  const {
    enableImageLoad,
    leadId,
    isDisabled,
    reports,
    isOffsiteDiagnostics,
    handleReportCreate,
    onDelete,
    onEdit,
    updateLead,
  } = props;
  const notify = useAddNotification();
  const { mutate: updateLeadReport, isLoading: isUpdationdReport } = useUpdateLeadReportMutation();

  const [selectedReportId, setSelectedReportId] = useState(-1);
  const { data: reportData } = useGetReportQuery(
    {
      leadId: +leadId,
      input: {
        reportId: selectedReportId,
      },
    },
    {
      enabled: selectedReportId !== -1,
    }
  );

  useEffect(() => {
    if (reportData?.getReport) {
      updateLeadReport(
        {
          leadId,
          input: {
            id: selectedReportId,
            shouldGenerate: false,
            report: { ...reportData?.getReport, isPublic: true },
          },
        },
        {
          onSuccess: () => {
            setSelectedReportId(-1);
            updateLead();
            notify("Отчет отправлен клиенту", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    }
  }, [reportData]);

  const handleSendReportToClient = (report: LeadReportOutput) => {
    setSelectedReportId(report.id);
  };

  const handleDownloadClick = async (id: number) => {
    const link = reports && reports.find((rep) => rep.id === id)?.filePath;

    if (!link) return;
    const fileUrl = await getDocument({ key: link });
    const filename = link.split("/").pop() as string;
    if (!fileUrl) return;
    await downloadURL(fileUrl, filename);
  };

  const handleViewClick = async (id: number) => {

    const link = reports?.find((rep) => rep.id === id)?.filePath;
    if (!link) return;

    window.open(await getDocument({ key: link }), "_blank");
  };

  const sortedReports = reports?.sort(function (a: LeadReportOutput, b: LeadReportOutput) {
    return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
  });

  return (
    <>
      <StyledFormItem>
        <div className="space-24">
          <Title level={4} weight={500} color="gray-700">
            Создание отчета
          </Title>
        </div>
        <StyledInnerBlock>
          <div className="m-w-100">
            {reports?.length ? (
              <>
                {sortedReports?.map((report) => (
                  <ReportPreview
                    isOffsiteDiagnostics={isOffsiteDiagnostics}
                    leadId={+leadId}
                    report={report}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onView={handleViewClick}
                    onDownload={handleDownloadClick}
                    handleSendReportToClient={handleSendReportToClient}
                    isUpdationdReport={isUpdationdReport}
                    isDisabled={isDisabled}
                    enableImageLoad={enableImageLoad}
                  />
                ))}
                {!isDisabled ? (
                  <StyledButton>
                    <Button variant="secondary" color="gray" onClick={handleReportCreate}>
                      Создать еще отчет
                    </Button>
                  </StyledButton>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Button isDisabled={isDisabled} onClick={handleReportCreate}>
                Создать отчет
              </Button>
            )}
          </div>
        </StyledInnerBlock>
      </StyledFormItem>
    </>
  );
};
