import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { Container, WrappedTable, StyledTitle } from "@/shared/ui";
import { Title } from "@yamaha-admin-sb/title";
import { colors } from "@/shared/helpers";
import { Default } from "@/widgets/layouts";
import {
  AnalyticsFilesPaginationInfo,
  AnalyticsFilesSortInfo,
  AnalyticsTypeEnum,
  Query,
  useDeleteReportMutation,
  useGetAnalyticsFilesQuery,
  RoleEnum,
} from "@/shared/lib/graphql";
import { Tabs } from "@yamaha-admin-sb/tabs";
import { PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { downloadURL, getCapitalized } from "@/shared/helpers/utils";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Button } from "@yamaha-admin-sb/button";
import { IModalContextValue, ModalContext, useAddNotification } from "@/shared/lib/react";
import { useQueryClient } from "react-query";
import Cookies from "js-cookie";
import {
  EditReportNameModal,
  ModalReportEployee,
  ModalReportGeneralExpense,
} from "@/features/reports";

interface IReportInfo {
  id: number;
  createdAt: any;
  updatedAt: any;
  type?: AnalyticsTypeEnum | null;
  fileName?: string | null;
  fileSize?: number | null;
  fileUrl?: string | null;
  filePath?: string | null;
}

const breads = [
  {
    title: "Отчеты",
    clickable: false,
  },
];

const WrappedTabs = styled.div`
  padding: 0 20px;
  border-bottom: 1px solid ${colors.gray200};
`;

const StyledButtonCell = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledInnerBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const headers = [
  {
    Header: "Название отчёта",
    accessor: "date",
    isSortable: true,
  },
  {
    Header: "",
    accessor: "download",
    isSortable: false,
  },
];

const tabs = [
  {
    title: "Отчеты по трафику",
    value: AnalyticsTypeEnum.LeadTraffic,
  },
  {
    title: "Отчеты о поступлении ДС",
    value: AnalyticsTypeEnum.CashInflow,
  },
  {
    title: "Отчеты по ЗП",
    value: AnalyticsTypeEnum.Salary,
  },
  {
    title: "Отчеты по балансу",
    value: AnalyticsTypeEnum.CostAccounting,
    roles: [RoleEnum.Director, RoleEnum.Admin],
  },
];

const Reports: React.FC = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const queryClient = useQueryClient();
  const me = queryClient.getQueryData<Pick<Query, "getMe">>("getMe", {
    predicate: (query) => query.queryHash === `getMe-${Cookies.get("accessToken")}`,
  }) as Pick<Query, "getMe">;

  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const [pagination, setPagination] = useState<AnalyticsFilesPaginationInfo>(
    PAGINATION_DEFAULT_OPTIONS
  );
  const [sort, setSort] = useState<AnalyticsFilesSortInfo>({});
  const [selectedReport, setSelectedReport] = useState<IReportInfo>();
  const {
    data: files,
    isLoading,
    refetch,
  } = useGetAnalyticsFilesQuery({
    input: { pagination, sort, filter: { type: activeTab.value } },
  });

  const notify = useAddNotification();

  const { mutate: deleteReport } = useDeleteReportMutation();
  const handleDeleteReport = (id) => {
    deleteReport(
      { id },
      {
        onSuccess: async () => {
          notify("Отчёт удален", "success");
          refetch();
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleMenuItemClick = (cellId: number) => {
    if (files?.getAnalyticsFiles?.data) setSelectedReport(files?.getAnalyticsFiles?.data[cellId]);
  };
  const tablePagination = {
    page: Math.floor(
      (files?.getAnalyticsFiles?.meta?.skip || 0) / (files?.getAnalyticsFiles?.meta?.take || 1)
    ),
    count: Math.ceil((files?.getAnalyticsFiles?.meta?.count || 0) / (pagination.take || 1)),
  };

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (files?.getAnalyticsFiles?.meta?.take || pagination.take || 10),
    }));
  };

  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const handleDownloadClick = async (link: string, fileName: string) => {
    await downloadURL(link, fileName, "xlsx");
  };

  const handleOpenModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: true }));
  };

  const preparedData = useMemo(
    () =>
      files?.getAnalyticsFiles.data?.map((item) => ({
        date: item.fileName,
        download: (
          <StyledButtons>
            <StyledButtonCell>
              <IconButton
                onClick={() => handleDeleteReport(item?.id)}
                icon="delete"
                isSquared={true}
                color="gray"
              />
            </StyledButtonCell>
            <StyledButtonCell>
              <IconButton
                onClick={() => {
                  setModalContext((prevState) => ({
                    ...prevState,
                    editReportNameModal: true,
                  }));
                }}
                icon="edit"
                isSquared={true}
                color="gray"
              />
            </StyledButtonCell>
            <StyledButtonCell>
              <IconButton
                isDisabled={
                  !!["HEAD_OF_MN"].includes(me?.getMe.role?.slug || "") &&
                  activeTab.value !== AnalyticsTypeEnum.CashInflow
                }
                onClick={() =>
                  item.fileUrl && item.fileName && handleDownloadClick(item.fileUrl, item.fileName)
                }
                icon="download"
                isSquared={true}
                color="gray"
              />
            </StyledButtonCell>
          </StyledButtons>
        ),
      })) || [],
    [files?.getAnalyticsFiles, me, activeTab]
  );

  const modalType = {
    [AnalyticsTypeEnum.LeadTraffic]: "eployeeReportModal",
    [AnalyticsTypeEnum.CashInflow]: "eployeeReportModal",
    [AnalyticsTypeEnum.Salary]: "eployeeReportModal",
    [AnalyticsTypeEnum.CostAccounting]: "generalExpenseReportModal",
  };

  const userRole = me?.getMe.role?.slug;

  const filteredByRoleTabs = userRole
    ? tabs.filter((item) => !item?.roles || item?.roles?.includes(userRole))
    : [];

  return (
    <>
      <Default breads={breads}>
        <StyledTitle>
          <Title level={2} color="gray-700">
            Отчеты
          </Title>
        </StyledTitle>
        <WrappedTabs className="space-32">
          <Tabs
            tabs={filteredByRoleTabs}
            activeTab={activeTab}
            onClick={(value) => {
              setActiveTab(
                filteredByRoleTabs.find((item) => item.value === value.value) ||
                  filteredByRoleTabs[0]
              );
            }}
            onAddClick={() => ({})}
            defaultColor={"black"}
            fontWeight={500}
          />
        </WrappedTabs>
        <StyledInnerBlock>
          <div className="space-20">
            <Container>
              <Button
                variant="secondary"
                color="gray"
                leftIcon={true}
                icon="download"
                onClick={() => handleOpenModal(modalType[activeTab.value])}
              >
                Скачать отчет
              </Button>
            </Container>
          </div>
        </StyledInnerBlock>
        <Container>
          {activeTab.value !== AnalyticsTypeEnum.CostAccounting ? (
            <WrappedTable
              tableTitle={activeTab.title}
              showMenu={false}
              headers={headers}
              data={preparedData || []}
              isLoading={isLoading}
              onSort={handleSort}
              onCellClick={(data) => {
                handleMenuItemClick(data.rowIndex);
              }}
              pagination={tablePagination}
              onPaginationClick={handlePaginationClick}
            />
          ) : (
            <></>
          )}
        </Container>
        <EditReportNameModal data={selectedReport} isSuccess={refetch} />
      </Default>

      {modalContext?.eployeeReportModal ? (
        <ModalReportEployee reportType={activeTab.value} />
      ) : (
        <></>
      )}

      {modalContext?.generalExpenseReportModal ? <ModalReportGeneralExpense /> : <></>}
    </>
  );
};

export default Reports;
