import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalFooter, Datepicker, IDatepicker, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { colors } from "@/shared/helpers";
import { Radio } from "@yamaha-admin-sb/radio";
import { Dropdown } from "@yamaha-admin-sb/dropdown";
import { transformDateToISO } from "@/shared/helpers/utils";
import { requiredValidationRule } from "@/shared/helpers/const";
import { useForm } from "react-hook-form";

import {
  ScheduleDayOutput,
  ScheduleDayTypeEnum,
  SetScheduleMutationVariables,
} from "@/shared/lib/graphql";
import { MockRussianMonth } from "@/shared/helpers/mocks";
import { Modal, ModalTop } from "@/shared/ui";

interface IModalEditSchedule {
  name: string;
  day?: ScheduleDayOutput;
  userId: number;
  isLoading?: boolean;
  onSuccess: (arg: SetScheduleMutationVariables) => void;
  onCancel: () => void;
}

const StyledRow = styled.div`
  padding: 24px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray200};
  }
`;

const StyledDates = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 30fr 1fr 30fr;
  gap: 4px;
  width: 100%;
`;

const StyledSeparator = styled.div`
  width: 8px;
  height: 1px;
  background-color: ${colors.gray300};
  margin-bottom: 22px;
`;

const hoursOptions = Array.from(Array(24).keys()).map((_, index) => ({
  value: index + 1,
  name: (index + 1).toString(),
}));

const ModalEditSchedule: React.FC<IModalEditSchedule> = (props) => {
  const { name, day, userId, isLoading, onSuccess, onCancel } = props;
  const [dateStart, setDateStart] = useState(day?.date || "");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [hours, setHours] = useState<number>(day?.hours || 8);
  const [value, setValue] = useState<ScheduleDayTypeEnum | null>(
    (day?.type as unknown as ScheduleDayTypeEnum) || null
  );
  const formattedDate =
    day?.date &&
    `${new Date(day?.date).getDate()} ${MockRussianMonth[new Date(day?.date).getMonth() + 1]}`;

  const title = `${name}, ${formattedDate}`;
  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue: setFormValue,
  } = useForm({ mode: "onBlur", defaultValues: { dateStart } });

  useEffect(() => {
    if (!day) return;

    setValue(day.type as unknown as ScheduleDayTypeEnum);
    setDateStart(day?.date);
  }, [day]);

  useEffect(() => {
    setFormValue("dateStart", dateStart);
  }, [dateStart]);

  const handleClose = () => {
    setFormValue("dateStart", "");
    setDateStart("");
    setDateEnd("");
    onCancel();
  };

  const handleSuccess = () => {
    if (dateStart)
      onSuccess({
        input: {
          userId,
          firstDay: dateStart,
          hours,
          lastDay: dateEnd,
          type: value as unknown as ScheduleDayTypeEnum,
        },
      });
  };

  return (
    <Modal name="modalEditSchedule" onClose={handleClose}>
      <ModalTop title={title} onClose={onCancel} />
      <form onSubmit={handleSubmit(handleSuccess)}>
        <StyledRow>
          <StyledDates>
            <Controller<IDatepicker>
              maxDate={dateEnd ? new Date(dateEnd) : undefined}
              value={dateStart ? dateStart : ""}
              label="Дата *"
              placeholder="От"
              isBordered={true}
              isBlock={true}
              name="dateStart"
              onInput={(val) => {
                setDateStart(val ? transformDateToISO(val) || "" : "");
              }}
              rules={requiredValidationRule}
              control={control}
              error={errors?.dateStart?.message}
              component={(datepickerProps: IDatepicker) => <Datepicker {...datepickerProps} />}
            />
            <StyledSeparator />
            <Datepicker
              value={dateEnd || dateStart}
              label=""
              placeholder="До"
              isBordered={true}
              isBlock={true}
              minDate={dateStart ? new Date(dateStart) : undefined}
              onInput={(val) => {
                setDateEnd(val ? transformDateToISO(val) || "" : "");
              }}
            />
          </StyledDates>
        </StyledRow>
        <StyledRow>
          <div className="space-16">
            <Radio
              value={value === ScheduleDayTypeEnum.Work}
              onChange={() => setValue(ScheduleDayTypeEnum.Work)}
            >
              Рабочий день
            </Radio>
          </div>
          <Dropdown
            label="Количество рабочих часов *"
            placeholder="Выберите количество рабочих часов"
            isBordered={true}
            isDisabled={value !== ScheduleDayTypeEnum.Work}
            options={hoursOptions}
            value={[hoursOptions.find((item) => item?.value === hours) || []].flat()}
            onChange={(value) => value[0]?.value && setHours(value[0]?.value)}
          />
        </StyledRow>
        <StyledRow>
          <Radio value={value === null} onChange={() => setValue(null)}>
            Выходной
          </Radio>
        </StyledRow>
        <StyledRow>
          <Radio
            value={value === ScheduleDayTypeEnum.Sick}
            onChange={() => setValue(ScheduleDayTypeEnum.Sick)}
          >
            Больничный
          </Radio>
        </StyledRow>
        <StyledRow>
          <Radio
            value={value === ScheduleDayTypeEnum.Vacation}
            onChange={() => setValue(ScheduleDayTypeEnum.Vacation)}
          >
            Отпуск
          </Radio>
        </StyledRow>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={onCancel}>
            Отменить
          </Button>
          <Button isDisabled={!dateStart} isLoading={isLoading} type="submit">
            Создать
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalEditSchedule;
