import { ModalExpertAppointment } from "@/features/requests";
import { ArrayElement, WithoutNull } from "@/shared/helpers/interfaces";
import {
  GetLeadsQuery,
  useCompleteLeadStageMutation,
  useGetLeadStatusesQuery,
  useSetLeadStatusAutoMutation,
  useUpdateLeadSpecialistMutation,
} from "@/shared/lib/graphql";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { useContext, type FC } from "react";

interface IModalAddSpecialistProps {
  leadInfo:
    | ArrayElement<WithoutNull<Required<GetLeadsQuery["getLeads"]>>["data"]>
    | null
    | undefined;
  selectedLead: number | null | undefined;
  invalidateLeads?: () => Promise<unknown>;
}

export const ModalAddSpecialist: FC<IModalAddSpecialistProps> = ({
  leadInfo,
  selectedLead,
  invalidateLeads,
}) => {
  const { mutate: updateLeadSpecialist, isLoading: isAddingSpecialist } =
    useUpdateLeadSpecialistMutation();
  const { mutate: completeLeadStage } = useCompleteLeadStageMutation();
  const { data: leadStatuses } = useGetLeadStatusesQuery();
  const { mutate: setLeadStatusAuto } = useSetLeadStatusAutoMutation();
  const notify = useAddNotification();
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const isOffsiteDiagnostics = leadInfo?.lService?.slug === "offsite-diagnostics";

  const handleToggleModal = () => {
    setModalContext((prev) => ({ ...prev, modalExpertAppointment: false }));
  };

  const setLeadStatusToInProgress = async (leadId: number) => {
    const inProgressStatusId =
      leadStatuses && leadStatuses.getStatuses.find((el) => el.slug === "in-progress")?.id;
    const currentStatusOfLead = leadInfo && leadInfo?.leadStatuses?.[0]?.status?.slug;

    if (
      currentStatusOfLead === "prepayment-made" &&
      inProgressStatusId &&
      !isNaN(inProgressStatusId)
    ) {
      setLeadStatusAuto(
        {
          leadId: +leadId,
          input: {
            statusId: inProgressStatusId,
          },
        },
        {
          onSuccess: async () => {
            await invalidateLeads?.();
          },
          onError: () => notify("Ошибка", "error"),
        }
      );
    } else {
      await invalidateLeads?.();
    }
  };

  const handleAddSpecialistToLead = (specialistId: number, leadId: number, data: any) => {
    updateLeadSpecialist(
      {
        leadId,
        input: {
          specialistId,
          ...data,
        },
      },
      {
        onSuccess: async () => {
          if (!isOffsiteDiagnostics) {
            completeLeadStage(
              { leadId },
              {
                onSuccess: async () => {
                  await setLeadStatusToInProgress(leadId);
                },
                onError: () => notify("Ошибка", "error"),
              }
            );
          } else {
            await setLeadStatusToInProgress(leadId);
          }

          handleToggleModal();
          notify("Эксперт назначен", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  return (
    <ModalExpertAppointment
      isLoading={isAddingSpecialist}
      isOffsiteDiagnostics={isOffsiteDiagnostics}
      onCancel={() => handleToggleModal()}
      onSubmit={(data) =>
        selectedLead &&
        data.specialistId &&
        handleAddSpecialistToLead(data.specialistId, selectedLead, data)
      }
    />
  );
};
