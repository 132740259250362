import { useEffect, useState } from "react";
import { RoleEnum, useFindOneRoleQuery } from "@/shared/lib/graphql";

export const useManagers = (isEnabled = true) => {
  const [isStale, setIsStale] = useState(false);
  const {
    data: managersSN,
    isLoading: isLoadingManagersSn,
    isStale: isStaleManagersSn,
  } = useFindOneRoleQuery(
    { slug: RoleEnum.SnManager },
    {
      staleTime: 5 * 60 * 1000,
      enabled: isEnabled && isStale,
    }
  );
  const {
    data: managers,
    isLoading: isLoadingManagers,
    isStale: isStaleManagers,
  } = useFindOneRoleQuery(
    {
      slug: RoleEnum.Manager,
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: isEnabled && isStale,
    }
  );

  useEffect(() => {
    if (!isStale && (isStaleManagersSn || isStaleManagers)) setIsStale(true);
  }, [isStaleManagersSn, isStaleManagers]);

  const allManagers = [
    ...(managers?.findOneRole?.users?.map((el) => ({
      id: el.id,
      name: `${el.name} ${el.surname}`,
    })) || []),
    ...(managersSN?.findOneRole?.users?.map((el) => ({
      id: el.id,
      name: `${el.name} ${el.surname}`,
    })) || []),
  ];

  return {
    allManagers,
    isLoading: isLoadingManagers || isLoadingManagersSn,
    isLoadingManagers,
    isLoadingManagersSn,
  };
};
