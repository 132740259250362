import React, { useEffect, useState } from "react";
import { Input } from "@yamaha-admin-sb/input";
import styled from "styled-components";
import {
  useGetBonusesByAgreementsQuery,
  useUpdateBonusByAgreementsMutation,
  SortEnum,
} from "@/shared/lib/graphql";
import { useForm } from "react-hook-form";
import { IInput } from "@yamaha-admin-sb/input";
import { Loader, Controller } from "@/shared/ui";
import { useAddNotification } from "@/shared/lib/react";

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledFormLabel = styled.div`
  max-width: 384px;
  width: 100%;
  min-width: 150px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

const StyledFormInput = styled.div`
  max-width: 272px;
  width: 100%;
  min-width: 80px;
  height: 32px;

  input {
    height: 32px;
    min-height: auto;
    max-height: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    border-radius: 4px;
  }
`;

const StyledFormTitle = styled.div`
  font-weight: 400;
  color: #a4abb5;
  margin-bottom: 13px;
  font-size: 12px;
`;

const BorderedRow = styled.div`
  padding: 16px 0;
  border-top: 1px #f3f5f9 solid;
`;

const WrappedLoader = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BonusEployeeByAgreements: React.FC = () => {
  const notify = useAddNotification();
  const {
    data: requestData,
    isSuccess: isDataSuccessLoaded,
    refetch,
  } = useGetBonusesByAgreementsQuery({
    input: {
      sort: { price: SortEnum.Desc },
    },
  });

  const allServicePrices = requestData?.findAllServicePrice?.data || [];
  const { mutate: updateBonus, isSuccess: isSuccessUpdated } = useUpdateBonusByAgreementsMutation();
  const [form, setForm] = useState({});

  const { control } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  useEffect(() => {
    refetch();
  }, [isSuccessUpdated]);

  useEffect(() => {
    const formObj = {};
    for (let key in allServicePrices) {
      const el = allServicePrices[key];
      formObj[el.id] = el.premium;
    }

    setForm((prev) => ({
      ...prev,
      ...formObj,
    }));
  }, [isDataSuccessLoaded]);

  const saveValueRequest = (id: number) => {
    const newValue = form[id] || "0";
    const prevValue = allServicePrices.find((item) => item.id === id)?.premium;
    if (newValue === prevValue) {
      return;
    }

    updateBonus({
      id,
      input: {
        premium: parseInt(newValue),
      },
    });

    notify("Премия обновлена", "success");
  };

  if (!isDataSuccessLoaded) {
    return (
      <WrappedLoader>
        <Loader size="l" />
      </WrappedLoader>
    );
  }

  return (
    <>
      <StyledRow>
        <StyledFormLabel>
          <StyledFormTitle>Цена</StyledFormTitle>
        </StyledFormLabel>
        <StyledFormInput>
          <StyledFormTitle>Премия</StyledFormTitle>
        </StyledFormInput>
      </StyledRow>

      {allServicePrices.map((item) => (
        <BorderedRow key={item.id}>
          <StyledRow>
            <StyledFormLabel>{item.price} ₽</StyledFormLabel>
            <StyledFormInput>
              <Controller<IInput>
                name={item.price}
                control={control}
                value={form[item.id]}
                placeholder="Введите ставку"
                isBordered={true}
                type="number"
                suffix="₽"
                onChange={(val) => {
                  setForm((prev) => ({
                    ...prev,
                    [item.id]: val,
                  }));
                }}
                onBlur={() => saveValueRequest(item.id)}
                component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
              />
            </StyledFormInput>
          </StyledRow>
        </BorderedRow>
      ))}
    </>
  );
};
