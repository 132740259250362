import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import { StyledTitle, Loader, WrappedTable, Container } from "@/shared/ui";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@yamaha-admin-sb/icon";
import {
  FindManyRolesQuery,
  FindManyUsersQuery,
  FindManyUsersQueryVariables,
  ScheduleFilters,
  SetScheduleMutationVariables,
  useAddUsersToMonthMutation,
  useFindManyRolesQuery,
  useFindManyUsersQuery,
  useGetScheduleQuery,
  useRemoveUserFromMonthMutation,
  UserEntity,
  useSetScheduleMutation,
} from "@/shared/lib/graphql";
import { getSuffixedDigit } from "@/shared/helpers/utils";
import {
  IModalContextValue,
  ModalContext,
  useAddNotification,
  useQueryFilters,
  useCachedQuery,
  TCachedQuery,
} from "@/shared/lib/react";
import { MockScheduleFilters } from "@/shared/helpers/mocks";
import { Button } from "@yamaha-admin-sb/button";
import { ModalScheduleAddUser, CalendarTable, TEditingScheduleUser } from "@/features/schedule";
import { useQueryClient } from "react-query";
import { useDebounce } from "use-debounce";
import { useGetMe } from "@/entities/session";
import { EditRoleModal } from "@/features/user";

const monthTranslate = {
  january: { name: "Январь", index: 0 },
  february: { name: "Февраль", index: 1 },
  march: { name: "Март", index: 2 },
  april: { name: "Апрель", index: 3 },
  may: { name: "Май", index: 4 },
  june: { name: "Июнь", index: 5 },
  july: { name: "Июль", index: 6 },
  august: { name: "Август", index: 7 },
  september: { name: "Сентябрь", index: 8 },
  october: { name: "Октябрь", index: 9 },
  november: { name: "Ноябрь", index: 10 },
  december: { name: "Декабрь", index: 11 },
};

const StyledLeft = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CalendarContainer = styled.div`
  overflow: auto;
  display: flex;
  justify-content: center;
  margin: 0 -40px;
`;

const UnProtectedActions = {
  editRole: ["DIRECTOR", "ADMIN", "HEAD_OF_SP", "HEAD_OF_MN"],
  addUserToSchedule: ["DIRECTOR", "ADMIN", "HEAD_OF_SP", "HEAD_OF_MN"],
  removeUserFromSchedule: ["DIRECTOR", "ADMIN", "HEAD_OF_SP", "HEAD_OF_MN"],
  editDay: ["DIRECTOR", "ADMIN", "HEAD_OF_SP", "HEAD_OF_MN"],
};

const ScheduleDetail: React.FC = () => {
  const navigate = useNavigate();
  const { selectedFilters } = useQueryFilters();
  const queryClient = useQueryClient();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [editingUser, setEditingUser] = useState<TEditingScheduleUser | null>(null);
  const { slug } = useParams();
  const { me } = useGetMe();
  const notify = useAddNotification();
  const params = slug?.split("-");
  if (!slug && !params?.length) {
    navigate("/404");
  }
  const pageParams = {
    year: (params && params[1]) || "",
    month: (params && params[0]) || "",
  };
  const date = `${pageParams.year}-${getSuffixedDigit(monthTranslate[pageParams.month].index + 1)}`;
  const [filter, setFilter] = useState<ScheduleFilters>({
    date,
    ...selectedFilters.filter,
  });
  const [searchDebouncedValue] = useDebounce(filter?.search, 500);
  const {
    data: schedule,
    isLoading,
    refetch: refetchSchedule,
  } = useGetScheduleQuery(
    { input: { filter: { ...filter, search: searchDebouncedValue } } },
    {
      queryKey: ["getSchedule", filter],
    }
  );
  const { mutate: changeSchedule, isLoading: isChanging } = useSetScheduleMutation();
  const { mutate: addUsersToMonth, isLoading: isAddingUsersToMonth } = useAddUsersToMonthMutation();
  const { mutate: removeUserFromMonth, isLoading: isRemovingUserFromMonth } =
    useRemoveUserFromMonthMutation();
  const { data: roles, isLoading: isRolesLoading } = useCachedQuery(
    useFindManyRolesQuery as TCachedQuery<FindManyRolesQuery, undefined>
  );
  const { data: users, isLoading: isUsersLoading } = useCachedQuery(
    useFindManyUsersQuery as TCachedQuery<FindManyUsersQuery, FindManyUsersQueryVariables>,
    {
      filter: {},
      pagination: {},
      sort: {},
    }
  );
  const breads = [
    {
      title: "График работы",
      clickable: true,
      path: "/schedule",
    },
    {
      title: `${monthTranslate[pageParams.month].name} ${pageParams.year}`,
      clickable: false,
    },
  ];

  const handleScheduleChange = (vars: SetScheduleMutationVariables) => {
    changeSchedule(vars, {
      onSuccess: ({ setSchedule }) => {
        refetchSchedule();
        setEditingUser(null);
        setModalContext((prevState) => ({
          ...prevState,
          modalEditSchedule: false,
        }));
      },
      onError: (error) => notify((error as ErrorEvent)?.message, "error"),
    });
  };

  const handleOpenModal = (name: keyof IModalContextValue) =>
    setModalContext((prev) => ({ ...prev, [name]: true }));

  const handleCloseModal = (name: keyof IModalContextValue) =>
    setModalContext((prev: any) => ({ ...prev, [name]: false }));

  const handleAddUser = (users: number[]) => {
    addUsersToMonth(
      {
        input: {
          users,
          month: date,
        },
      },
      {
        onSuccess: async () => {
          handleCloseModal("modalScheduleAddUser");
          notify("Пользователи успешно добавлены", "success");
          await refetchSchedule();
        },
        onError: (error) => notify((error as ErrorEvent)?.message, "error"),
      }
    );
  };

  const handleDeleteUser = (user: number) => {
    removeUserFromMonth(
      {
        input: {
          user,
          month: date,
        },
      },
      {
        onSuccess: async () => {
          notify("Пользователь успешно удален", "success");
          await refetchSchedule();
        },
        onError: (error) => notify((error as ErrorEvent)?.message, "error"),
      }
    );
  };

  return (
    <>
      <Default breads={breads}>
        <StyledTitle className="space-32">
          <StyledLeft onClick={() => navigate(-1)}>
            <Icon name="arrow-left" color="gray-500" size={24} />
            <Title level={2} color="gray-700">
              {monthTranslate[pageParams.month].name} {pageParams.year}
            </Title>
          </StyledLeft>
          {UnProtectedActions.editRole.includes(me?.getMe.role?.slug || "") ? (
            <Button
              variant="secondary"
              color="gray"
              leftIcon={true}
              icon="edit"
              onClick={() => handleOpenModal("roleEditModal")}
            >
              Редактировать ставку в час
            </Button>
          ) : (
            <></>
          )}
        </StyledTitle>
        <Container>
          <WrappedTable
            tableTitle="График рабочих дней"
            isLoading={isLoading}
            isTable={false}
            search={{
              placeholder: "Поиск по пользователям",
              onSearch: (value) => setFilter((prevState) => ({ ...prevState, search: value })),
            }}
            filters={MockScheduleFilters(
              roles?.findManyRoles || [],
              (users?.findManyUsers?.data as UserEntity[]) || []
            )}
            rightBtn={() =>
              UnProtectedActions.addUserToSchedule.includes(me?.getMe.role?.slug || "") ? (
                <Button
                  variant="secondary"
                  color="gray"
                  leftIcon={true}
                  icon="plus"
                  onClick={() => handleOpenModal("modalScheduleAddUser")}
                >
                  Добавить пользователя
                </Button>
              ) : (
                <></>
              )
            }
            onFiltersApply={(data) => {
              if (Object.keys(data).length) {
                setFilter((prevState) => ({ ...prevState, ...data }));
              } else {
                setFilter({ date });
              }
            }}
          >
            <CalendarContainer>
              {isLoading ? (
                <Loader />
              ) : schedule?.getSchedule ? (
                <CalendarTable
                  data={schedule.getSchedule}
                  calendar={{
                    currentYear: +pageParams.year,
                    currentMonth: monthTranslate[pageParams.month].index,
                  }}
                  isScheduleEditing={isChanging}
                  editingUser={editingUser}
                  onEditingUserChange={setEditingUser}
                  onScheduleChange={handleScheduleChange}
                  onUserRemove={(userId) => handleDeleteUser(userId)}
                />
              ) : (
                <></>
              )}
            </CalendarContainer>
          </WrappedTable>
        </Container>
        {modalContext?.modalScheduleAddUser ? (
          <ModalScheduleAddUser
            date={date}
            isLoading={isAddingUsersToMonth}
            onSuccess={(userIds) => handleAddUser(userIds)}
          />
        ) : (
          <></>
        )}
        {modalContext?.roleEditModal ? <EditRoleModal onSuccess={() => null} /> : <></>}
      </Default>
    </>
  );
};

export default ScheduleDetail;
