import React, { useMemo, useState, useContext, useEffect } from "react";
import { WrappedTable, Container, Loader, StyledNoItem } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import {
  IModalContextValue,
  ModalContext,
  useQueryFilters,
  useQueryParams,
} from "@/shared/lib/react";
import {
  useGetClientsQuery,
  GetClientsPagination,
  GetClientsSortInfo,
  GetClientsFilters,
} from "@/shared/lib/graphql";
import { getCapitalized, parseQueryKey } from "@/shared/helpers";
import { PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useGetMe } from "@/entities/session";
import { ModalCreateClient, ModalCreateContact } from "@/features/clients";

const headers = [
  {
    Header: "ID клиента",
    accessor: "num",
  },
  {
    Header: "Клиент",
    accessor: "user",
  },
  {
    Header: "Город",
    accessor: "city",
  },
  {
    Header: `Контакты`,
    accessor: "contacts",
  },
  {
    Header: ``,
    accessor: "actions",
  },
];

const LeadsDisableTemplate = {
  HEAD_OF_MN: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  HEAD_OF_SP: {
    managerAppointment: true,
    specialistAppointment: false,
    createLead: true,
  },
  MANAGER: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  SN_MANAGER: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  SPECIALIST: {
    managerAppointment: true,
    specialistAppointment: false,
    createLead: true,
  },
};

export const ClientsTable: React.FC = () => {
  const { selectedFilters } = useQueryFilters();
  const navigate = useNavigate();
  const [sort, setSort] = useState<GetClientsSortInfo>({});
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [pagination, setPagination] = useState<GetClientsPagination>(PAGINATION_DEFAULT_OPTIONS);

  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState<GetClientsFilters>(selectedFilters.filter);
  const [isMounted, setIsMounted] = useState(false);
  const { me } = useGetMe();
  const params = useQueryParams();

  const currentTabParam: { key: string; value: string } = useMemo(() => {
    let selectedTab = { key: "responsible", value: "Union" };
    Object.keys(params).forEach((key) =>
      /\[ext\]/.test(key)
        ? (selectedTab = { key: parseQueryKey(key)[0], value: params[key] })
        : null
    );
    return selectedTab;
  }, [params]);

  const {
    data: clients,
    refetch: updateClients,
    isLoading,
  } = useGetClientsQuery({
    input: {
      pagination,
      sort,
      filter,
    },
  });

  const [selectedClient, setSelectedClient] = useState<null | number>();

  const handleToggleModal = (modalName: keyof IModalContextValue, flag: boolean) => {
    setModalContext((prev) => ({ ...prev, [modalName]: flag }));
  };

  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const tablePagination = {
    page: Math.floor(
      (clients?.getClients?.meta?.skip || 0) / (clients?.getClients?.meta?.take || 1)
    ),
    count: Math.ceil((clients?.getClients?.meta?.count || 0) / (pagination.take || 1)),
  };

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (clients?.getClients?.meta?.take || pagination.take || 10),
    }));
  };

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, []);

  useEffect(() => {
    setPagination(PAGINATION_DEFAULT_OPTIONS);
  }, [filter]);

  useEffect(() => {
    const page = searchParams.get("page");

    if (!page) return;

    handlePaginationClick(+page - 1);
  }, [searchParams]);

  const preparedTableData = useMemo(
    () =>
      clients?.getClients?.data?.map((item) => ({
        ...item,
        num: (
          <Paragraph size={12} weight={500}>
            {new Date(item.createdAt).toLocaleDateString("ru-RU")}
            <br />№{item.id}
          </Paragraph>
        ),
        user: (
          <Paragraph size={12}>
            {`${item.surname ?? ""} ${item.name ?? ""} ${item?.patronymic ?? ""}`}
          </Paragraph>
        ),
        city: <Paragraph size={12}>{item.city ? item.city : ""}</Paragraph>,
        contacts: (
          <Paragraph size={12}>
            {item.contacts && item.contacts.length > 0 ? <div>{item.contacts[0]?.value}</div> : ""}
            {item.contacts && item.contacts.length > 1 ? "Смотреть все" : ""}
          </Paragraph>
        ),
        actions: (
          <Paragraph size={12}>
            <Button
              variant="secondary"
              color="blue"
              onClick={(event) => {
                event.stopPropagation();
                handleToggleModal("modalCreateContact", true);
                setSelectedClient(item.id);
              }}
            >
              Добавить контакт
            </Button>
          </Paragraph>
        ),
      })),
    [clients?.getClients, me?.getMe, isLoading]
  );

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading) setIsPageLoaded(true);
  }, [isLoading]);

  return (
    <>
      {!isLoading || isPageLoaded ? (
        <>
          <Container>
            <WrappedTable
              additionalFilter={{
                [`${currentTabParam.key}[filter][ext]`]: currentTabParam.value,
              }}
              pagination={tablePagination}
              onSort={handleSort}
              onPaginationClick={handlePaginationClick}
              onRowClick={(value) => navigate(`/clients/${value.id}`)}
              showMenu={false}
              tableTitle="Все клиенты"
              headers={headers}
              isLoading={isLoading}
              data={preparedTableData || []}
              search={{
                placeholder: "Поиск по клиентам",
                onSearch: (value) => setFilter((prev) => ({ ...prev, query: value })),
              }}
              onFiltersApply={(data) => {
                if (Object.keys(data).length) {
                  setFilter((prevState) => ({ ...prevState, ...data }));
                } else {
                  setFilter({});
                }
              }}
              onMenuItemSelected={() => ({})}
              rightBtn={() => {
                if (!LeadsDisableTemplate[me?.getMe.role?.slug || ""]?.createLead) {
                  return (
                    <Button
                      variant="secondary"
                      color="gray"
                      leftIcon={true}
                      isBlock={true}
                      icon="plus"
                      onClick={() => handleToggleModal("modalCreateClient", true)}
                    >
                      Добавить клиента
                    </Button>
                  );
                } else return <></>;
              }}
            />
          </Container>
        </>
      ) : (
        <StyledNoItem>
          <Loader size="l" />
        </StyledNoItem>
      )}
      {modalContext?.modalCreateClient && <ModalCreateClient onSuccess={updateClients} />}
      {modalContext?.modalCreateContact && (
        <ModalCreateContact selectedClient={selectedClient} onSuccess={updateClients} />
      )}
    </>
  );
};
