import React from "react";
import { Container, StyledTitle } from "@/shared/ui";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import { CallsTable } from "./ui/CallsTable";

const breads = [
  {
    title: "Звонки",
    clickable: false,
  },
];

const Calls: React.FC = () => {
  return (
    <Default breads={breads}>
      <StyledTitle className="space-32">
        <Title level={2} color="gray-700">
          Звонки
        </Title>
      </StyledTitle>
      <Container>
        <CallsTable />
      </Container>
    </Default>
  );
};

export default Calls;
