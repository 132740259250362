import React, { useContext, useState } from "react";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import { StyledTitle, Container } from "@/shared/ui";
import { Dropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { useNavigate } from "react-router-dom";
import { useQueryParams, IModalContextValue, ModalContext } from "@/shared/lib/react";
import styled from "styled-components";
import { colors } from "@/shared/helpers/index";
import { Button } from "@yamaha-admin-sb/button";
import { ModalEployeeEfficiency } from "@/features/schedule";

const breads = [
  {
    title: "График работы",
    clickable: false,
  },
];

const monthTemplate = [
  {
    number: "01",
    name: "Январь",
    slug: "january",
  },
  {
    number: "02",
    name: "Февраль",
    slug: "february",
  },
  {
    number: "03",
    name: "Март",
    slug: "march",
  },
  {
    number: "04",
    name: "Апрель",
    slug: "april",
  },
  {
    number: "05",
    name: "Май",
    slug: "may",
  },
  {
    number: "06",
    name: "Июнь",
    slug: "june",
  },
  {
    number: "07",
    name: "Июль",
    slug: "july",
  },
  {
    number: "08",
    name: "Август",
    slug: "august",
  },
  {
    number: "09",
    name: "Сентябрь",
    slug: "september",
  },
  {
    number: "10",
    name: "Октябрь",
    slug: "october",
  },
  {
    number: "11",
    name: "Ноябрь",
    slug: "november",
  },
  {
    number: "12",
    name: "Декабрь",
    slug: "december",
  },
];

// January
// February
// March
// April
// May
// June
// July
// August
// September
// October
// November
// December

const template: IDropdownItem[] = Array.from(Array(10).keys()).map((_, index) => ({
  value: new Date().getFullYear() + index,
  name: `Месяцы ${new Date().getFullYear() + index}`,
}));

const WrappedSelect = styled.div`
  width: 155px;
`;

const StyledMonthList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 1140px;
`;

const StyledMonthCard = styled.div<{ isCurrent: boolean }>`
  cursor: pointer;

  background-color: ${colors.gray100};
  border-radius: 8px;
  width: 176px;
  height: 88px;
  padding: 20px;
  border: 1px solid ${({ isCurrent }) => (isCurrent ? colors.blue200 : colors.gray100)};

  &:hover {
    background-color: ${colors.blue200};
    p {
      color: ${colors.white};
    }
    p:first-child {
      color: ${colors.blue100};
    }
  }
  transition: 0.4s;
`;

const Schedule: React.FC = () => {
  const navigate = useNavigate();
  const search = useQueryParams();

  const [selectedYear, setSelectedYear] = useState<IDropdownItem[]>([
    template.find((item) => item?.value == search?.year) || template[0],
  ]);

  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const handleOpenModal = (name: keyof IModalContextValue) =>
    setModalContext((prev) => ({ ...prev, [name]: true }));
  return (
    <>
      <Default breads={breads}>
        <StyledTitle className="space-32">
          <Title level={2} color="gray-700">
            График работы {selectedYear[0].value}
          </Title>
          <Button
            variant="secondary"
            color="gray"
            onClick={() => handleOpenModal("eployeeEfficiencyModal")}
          >
            Отчет эффективности
          </Button>
        </StyledTitle>
        <Container>
          <WrappedSelect className="space-20">
            <Dropdown
              options={template}
              value={[template.find((item) => item?.value === selectedYear[0]?.value) || []].flat()}
              onChange={(value) => {
                setSelectedYear(value);
                navigate({
                  pathname: "/schedule",
                  search: `?year=${value[0].value}`,
                });
              }}
            />
          </WrappedSelect>

          <StyledMonthList>
            {monthTemplate.map((item, index) => (
              <StyledMonthCard
                isCurrent={
                  new Date().getMonth() === index &&
                  new Date().getFullYear() === selectedYear[0].value
                }
                key={index + selectedYear[0].value}
                onClick={() => navigate(`/schedule/${item.slug}-${selectedYear[0].value}`)}
              >
                <div className="space-4">
                  <Paragraph weight={500} size={16} color="gray-600">
                    {item.number}
                  </Paragraph>
                </div>
                <Paragraph weight={500} size={16} color="gray-700">
                  {item.name}
                </Paragraph>
              </StyledMonthCard>
            ))}
          </StyledMonthList>
        </Container>
      </Default>
      {modalContext?.eployeeEfficiencyModal ? <ModalEployeeEfficiency /> : <></>}
    </>
  );
};

export default Schedule;
