import styled from "styled-components";
import { colors } from "@/shared/helpers";

export const StyledCalendarTable = styled.div`
  overflow: auto;
  padding: 0 30px;
`;

export const StyledTableHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 32px;
  margin: 4px 2px;
  position: relative;
`;

export const StyledDaysRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCalendarItem = styled.div`
  padding: 4px 2px;
`;

export const StyledDataCell = styled.td`
  padding: 0 10px;

  &:not(:first-of-type) {
    border-left: 1px solid ${colors.gray200};
  }
  &:not(:last-of-type) {
    border-right: 1px solid ${colors.gray200};
  }
`;

export const StyledHeadCell = styled.th`
  padding: 0 10px;
  text-align: start;
  vertical-align: top;
  white-space: nowrap;
`;

export const StyledAvatar = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 200px;
`;

export const StyledRow = styled.tr`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray200};
  }
`;
