import { FC } from "react";
import styled from "styled-components";
import { colors } from "@/shared/helpers";
import moment from "moment";
import { Icon } from "@yamaha-admin-sb/icon";
import { ReminderEntity, ReminderTypeEnum } from "@/shared/lib/graphql";
import { Link } from "react-router-dom";

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 1px solid ${colors.gray200};
  height: 38px;
  font-family: var(--font-brand);
  cursor: pointer;
`;

const StatusIndicator = styled.div<{ status: ReminderTypeEnum }>`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === ReminderTypeEnum.Success
      ? colors.green200
      : status === ReminderTypeEnum.Pending
      ? colors.blue400
      : colors.gray400};
`;

const StyledTitle = styled.div`
  font-size: 14px;
  color: ${colors.gray700};
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: ${colors.gray700};
  white-space: nowrap;
  &:hover,
  &:focus {
    color: ${colors.blue400};
  }
  &:active {
    color: ${colors.blue400};
  }
`;

const StyledNotifyTime = styled.div`
  font-size: 12px;
  color: ${colors.gray600};
  white-space: nowrap;
`;

const StyledContent = styled.div`
  font-size: 12px;
  color: ${colors.gray400};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

interface IReminderProps {
  remindDate: ReminderEntity["remindDate"];
  remindNotificationDate: ReminderEntity["remindNotificationDate"];
  status: ReminderTypeEnum;
  title: ReminderEntity["title"];
  comment?: ReminderEntity["comment"];
  lead?: { id: number; num: string } | null;
  onClick?: () => void;
  fullDate?: boolean;
  style?: React.CSSProperties;
}

export const ReminderRow: FC<IReminderProps> = ({
  status,
  title,
  remindDate,
  comment,
  lead,
  remindNotificationDate,
  onClick,
  fullDate,
  style,
}) => {
  const formattedTime = moment(remindDate).format(fullDate ? "DD.MM.YY HH:mm" : "HH:mm");

  return (
    <StyledRow onClick={onClick} style={style}>
      <StatusIndicator status={status} />
      <StyledTitle>
        {formattedTime} {title}
      </StyledTitle>
      {lead && <StyledLink to={`/leads/${lead.id}`}>Заявка №{lead.num}</StyledLink>}
      {remindNotificationDate && (
        <StyledNotifyTime>
          <Icon name="bell" size={12} color={colors.gray600} /> за {remindNotificationDate} минут
        </StyledNotifyTime>
      )}
      {comment && <StyledContent>{comment}</StyledContent>}
    </StyledRow>
  );
};
