import React, { useEffect, useMemo, useState } from "react";
import { Controller, Loader } from "@/shared/ui";
import { Dropdown, IDropdown } from "@yamaha-admin-sb/dropdown";
import {
  diagnosticsExpenses,
  inputValidationRule,
  requiredValidationRule,
} from "@/shared/helpers/const";

import { useForm } from "react-hook-form";

import { Title } from "@yamaha-admin-sb/title";
import {
  StyledFormItem,
  StyledGridContainer,
  StyledGridElement,
  WrappedCheckboxes,
} from "@/entities/leads";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { toFormatPrice } from "@/shared/helpers/utils";
import { Button } from "@yamaha-admin-sb/button";
import { TextButton } from "@yamaha-admin-sb/text-button";
import {
  CostAccountingEnum,
  SortEnum,
  UpdateLeadCostAccountingInput,
  useCreateCostAccountingMutation,
  useGetCostAccountingsQuery,
  useUpdateLeadCostAccountingMutation,
} from "@/shared/lib/graphql";
import { useAddNotification } from "@/shared/lib/react";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Checkbox } from "@yamaha-admin-sb/checkbox";
import { IHandleSuccess } from "../../model/interfaces";

interface IExpensesProps {
  leadId: number;
  endoscopy: UpdateLeadCostAccountingInput;
  isEnabled?: boolean;
  isOffsiteDiagnostics: boolean;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

const TEMPLATE: {
  amount: number;
  type: null;
  isNew: boolean;
  comment: null | string;
} = { amount: 0, type: null, isNew: true, comment: null };

export const ExpensesForm: React.FC<IExpensesProps> = (props) => {
  const {
    leadId,
    isEnabled = false,
    endoscopy,
    isOffsiteDiagnostics,
    handleSuccess,
    handleError,
  } = props;
  const notify = useAddNotification();

  const { mutate: updateLeadCostAccounting } = useUpdateLeadCostAccountingMutation();
  const { mutate: createExpenses, isLoading: isCreating } = useCreateCostAccountingMutation();

  const {
    data: costs,
    isLoading,
    refetch,
  } = useGetCostAccountingsQuery({
    input: {
      filter: { leadId, withSpecialists: true },
      sort: { date: SortEnum.Asc },
      pagination: { take: 1000 },
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const preparedCosts = useMemo(
    () =>
      costs?.getCostAccountings.data?.map((item) => ({
        amount: item.amount,
        type: item.type,
        comment: item.comment || null,
        isNew: false,
      })),
    [costs]
  );

  useEffect(() => {
    preparedCosts?.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        setValue(`${key}-${index}`, item[key]);
      });
    });
  }, [preparedCosts]);

  const [fieldTemplate, setFieildTemplate] = useState(
    preparedCosts?.length ? preparedCosts : [TEMPLATE]
  );
  useEffect(() => {
    preparedCosts?.length && setFieildTemplate(preparedCosts);
  }, [preparedCosts]);

  const [endoscopeForm, setEndoscopeForm] = useState<UpdateLeadCostAccountingInput>(endoscopy);

  const handleEndoscopeChange = ({
    endoscopeInput,
  }: {
    endoscopeInput: UpdateLeadCostAccountingInput;
  }) => {
    updateLeadCostAccounting(
      { input: endoscopeInput, leadId },
      {
        onSuccess: () =>
          handleSuccess({
            text: "Статус обновлен",
            disableCompleteStage: true,
          }),
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  const handleSave = () => {
    fieldTemplate.forEach(
      (item) =>
        item.isNew &&
        createExpenses(
          {
            input: {
              amount: item.amount,
              type: item.type as CostAccountingEnum,
              leadId,
              comment: item.comment,
            },
          },
          {
            onSuccess: () => {
              refetch();
            },
            onError: (error) => {
              notify((error as ErrorEvent)?.message, "error");
            },
          }
        )
    );
  };
  const handleChange = (key: string, value: any, itemIndex: number) => {
    setFieildTemplate((prev) =>
      prev.map((item, index) => (itemIndex === index ? { ...item, [key]: value } : item))
    );
  };

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Затраты на выезд
        </Title>
      </div>
      <form onSubmit={handleSubmit(handleSave)}>
        {isOffsiteDiagnostics ? (
          <WrappedCheckboxes className="space-20">
            <Checkbox
              name="withClient"
              value={!!endoscopeForm.endoscopyWithClient}
              isDisabled={!isEnabled}
              onInput={() => {
                setEndoscopeForm({
                  endoscopyWithClient: true,
                  endoscopyWithoutClient: false,
                });
                handleEndoscopeChange({
                  endoscopeInput: {
                    endoscopyWithClient: true,
                    endoscopyWithoutClient: false,
                  },
                });
              }}
            >
              Эндоскопия с клиентом
            </Checkbox>
            <Checkbox
              name="withoutClient"
              value={!!endoscopeForm.endoscopyWithoutClient}
              isDisabled={!isEnabled}
              onInput={() => {
                handleEndoscopeChange({
                  endoscopeInput: {
                    endoscopyWithClient: false,
                    endoscopyWithoutClient: true,
                  },
                });
                setEndoscopeForm({
                  endoscopyWithClient: false,
                  endoscopyWithoutClient: true,
                });
              }}
            >
              Эндоскопия без клиента
            </Checkbox>
          </WrappedCheckboxes>
        ) : (
          <></>
        )}
        {!isLoading ? (
          isEnabled || (fieldTemplate.length >= 1 && !fieldTemplate[0].isNew) ? (
            <>
              {fieldTemplate.map((item, index) => (
                <StyledGridContainer key={index} className="space-20">
                  <StyledGridElement column="1">
                    <Controller<IInput>
                      name={`amount-${index}`}
                      control={control}
                      rules={inputValidationRule}
                      error={errors?.[`amount-${index}`]?.message}
                      isDisabled={!isEnabled || !item.isNew}
                      // type="number"
                      onChange={(value) => {
                        const digits = value.split(/\D/).join("");
                        digits && handleChange("amount", +digits, index);
                      }}
                      value={item.amount ? toFormatPrice(item.amount) : ""}
                      label="Сумма *"
                      suffix="₽"
                      placeholder="Введите сумму"
                      isBordered={true}
                      component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
                    />
                  </StyledGridElement>
                  <StyledGridElement column="2">
                    <Controller<IDropdown>
                      // portal={document.querySelector("body") as HTMLElement}
                      name={`type-${index}`}
                      isDisabled={!isEnabled || !item.isNew}
                      control={control}
                      rules={requiredValidationRule}
                      error={errors?.[`type-${index}`]?.message}
                      options={diagnosticsExpenses}
                      onChange={(value) => handleChange("type", value[0].value, index)}
                      value={diagnosticsExpenses.find((option) => option.value === item.type)}
                      label="Тип расходов *"
                      placeholder="Выберите тип расходов"
                      isBordered={true}
                      // isDisabled={}
                      component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
                    />
                  </StyledGridElement>
                  {item.type === CostAccountingEnum.Other ? (
                    <StyledGridElement column="1 / span 2">
                      <Controller<IDropdown>
                        name={`comment-${index}`}
                        control={control}
                        rules={inputValidationRule}
                        error={errors?.[`comment-${index}`]?.message}
                        isDisabled={!isEnabled || !item.isNew}
                        onChange={(value) => {
                          handleChange("comment", value, index);
                        }}
                        value={item?.comment || ""}
                        label="Комментарий *"
                        placeholder="Введите комментарий *"
                        isBordered={true}
                        component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
                      />
                    </StyledGridElement>
                  ) : (
                    <></>
                  )}
                </StyledGridContainer>
              ))}
            </>
          ) : (
            <Paragraph size={14} color="gray-600">
              Затраты на выезд еще не добавлены
            </Paragraph>
          )
        ) : (
          <>
            <Loader size="m" />
          </>
        )}
        {isEnabled ? (
          <>
            <div className="space-20">
              <TextButton
                leftIcon={true}
                icon="plus"
                isDisabled={!isEnabled}
                onClick={() => setFieildTemplate((prev) => [...prev, TEMPLATE])}
              >
                Добавить еще запись о расходах
              </TextButton>
            </div>
            <Button isDisabled={!isEnabled} type="submit" isLoading={isCreating}>
              Сохранить
            </Button>
          </>
        ) : (
          <></>
        )}
      </form>
    </StyledFormItem>
  );
};
