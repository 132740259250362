import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import {
  inputValidationRule,
  LeadServiceTypeToText,
  requiredValidationRule,
} from "@/shared/helpers/const";
import {
  UpdateServicePriceData,
  useGetLsTypesQuery,
  useUpdateServicePricesMutation,
} from "@/shared/lib/graphql";
import { Button } from "@yamaha-admin-sb/button";
import { IInput, Input } from "@yamaha-admin-sb/input";
import styled from "styled-components";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { TextButton } from "@yamaha-admin-sb/text-button";
import { useAddNotification } from "@/shared/lib/react";

interface IModal {
  onCancel: () => void;
  onSuccess: () => void;
}

const WrappedInput = styled.div`
  display: flex;

  width: 100%;
  gap: 16px;
`;

const StyledIcon = styled.div`
  height: 63px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const ModalEditServices: React.FC<IModal> = (props): JSX.Element => {
  const { onCancel } = props;
  const notify = useAddNotification();
  const [selectedService, setSelectedService] = useState();
  const [servicePrices, setServicePrices] = useState<UpdateServicePriceData[]>([]);
  const { mutate: updateServicePrices, isLoading: isUpdating } = useUpdateServicePricesMutation();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const { data: lsTypes, isLoading, refetch } = useGetLsTypesQuery();

  const handleEdit = () => {
    selectedService &&
      updateServicePrices(
        {
          input: {
            lSTypeId: selectedService,
            servicePrices: servicePrices.map((item) => ({
              price: +item.price,
              id: item.id,
            })),
          },
        },
        {
          onSuccess: () => {
            notify("Стоимость обновлена", "success");
            onCancel();
            refetch();
          },
          onError: (error) => notify((error as ErrorEvent)?.message, "error"),
        }
      );
  };

  const preparedServicePrices: IDropdownItem[] = useMemo(
    () =>
      lsTypes?.getLSTypes.map((item) => ({
        name: LeadServiceTypeToText[item.slug],
        id: item.id,
        value: item.servicePrices,
      })) || [],
    [lsTypes]
  );
  useEffect(() => {
    servicePrices.forEach((item, index) => setValue(`price-${index}`, item.price));
  }, [selectedService]);

  return (
    <Modal name="modalEditServices">
      <ModalTop title="Редиктировать стоимость услуг" onClose={onCancel} />
      <form onSubmit={handleSubmit(handleEdit)}>
        <ModalBody minHeight={260}>
          <div className="space-20">
            <Controller<IDropdown>
              // portal={document.querySelector("body") as HTMLElement}
              name="lSTypeId"
              label="Тип услуги *"
              placeholder="Выберите тип"
              isBordered={true}
              control={control}
              isDisabled={isLoading}
              rules={requiredValidationRule}
              error={errors?.lSTypeId?.message}
              options={preparedServicePrices || []}
              value={
                [preparedServicePrices?.find((item) => item.id === selectedService) || []].flat() ||
                []
              }
              onChange={(value: IDropdownItem) => {
                if (value[0].id) {
                  setSelectedService(value[0].id);
                  setServicePrices(value[0].value);
                }
              }}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
          {selectedService ? (
            <>
              {servicePrices?.map((item, index) => (
                <WrappedInput className="space-20" key={index}>
                  <div className="m-w-100">
                    <Controller<IInput>
                      name={`price-${index}`}
                      value={item.price}
                      label="Стоимость услуги *"
                      placeholder="Введите стоимость услуги"
                      control={control}
                      rules={inputValidationRule}
                      error={errors[`price-${index}`]?.message}
                      onChange={(value) => {
                        const digits = value.split(/\D/).join("");
                        setServicePrices((prev) => {
                          return prev.map((priceItem, priceIndex) =>
                            index === priceIndex ? { price: digits, id: item.id } : priceItem
                          );
                        });
                      }}
                      // value={item.price ? toFormatPrice(item.price) : ""}
                      type="number"
                      suffix="₽"
                      isBordered={true}
                      component={(inputProps: IInput) => <Input {...inputProps} />}
                    />
                  </div>
                  <StyledIcon>
                    <IconButton
                      icon="delete"
                      variant="tertiary"
                      color="gray"
                      onClick={() =>
                        setServicePrices((prev) =>
                          prev.filter((priceItem, priceIndex) => priceIndex !== index)
                        )
                      }
                    />
                  </StyledIcon>
                </WrappedInput>
              ))}
              <TextButton
                leftIcon={true}
                icon="plus"
                onClick={() => setServicePrices((prev) => [...prev, { price: 0, id: 0 }])}
              >
                Добавить
              </TextButton>
            </>
          ) : (
            <></>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={onCancel}>
            Отменить
          </Button>
          <Button isDisabled={isUpdating} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
