import styled, { css } from "styled-components";
import { colors } from "@/shared/helpers";

interface ICustomInput {
  isDisabled: boolean;
  isBordered: boolean;
}

const CustomInput = styled.input<ICustomInput>`
  padding: 11px 16px;
  width: 100%;
  border: 1px solid ${({ isBordered }) => (isBordered ? colors.gray400 : "transparent")};
  background-color: ${colors.white};
  border-radius: 8px;
  transition: 0.2s;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray700};

  &::placeholder {
    color: ${colors.gray600};
  }

  &:focus {
    border-color: ${colors.blue400};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${colors.gray300};
      color: ${colors.gray500};
      pointer-events: none;
    `}
`;

interface IStyledTimepicker {
  isOpen: boolean;
  isBlock: boolean;
  isDisabled: boolean;
}

const StyledTimepicker = styled.div<IStyledTimepicker>`
  width: ${({ isBlock }) => (isBlock ? "auto" : "172px")};
  position: relative;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}

  .react-datepicker {
    font-family: var(--font-brand);
    border-color: ${colors.gray300};
    background-color: ${colors.white};
    box-shadow: 0 0 12px rgba(29, 68, 168, 0.1);
    border-radius: 8px;
    border: none;

    &__time-container {
      border: none;
      right: -86px;
    }
    &-wrapper {
      width: 100%;
    }

    &__navigation {
      background: transparent;
      transform: rotate(45deg);
      border: 1px solid ${colors.gray800};
      border-bottom: 0;
      border-left: 0;
      width: 8px;
      height: 8px;
      top: 14px;

      &--previous {
        transform: rotate(-135deg);
      }
    }

    &__header {
      background-color: ${colors.white};
      padding-top: 9px;
      border-radius: 0;
      border-bottom: none;
    }

    &__triangle {
      display: none;
    }

    &__close-icon {
      display: none;
    }

    &__day {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      width: 24px;
      margin: 2px 6px;
      transition: 0.2s;
      border: 1px solid transparent;
      border-radius: 2px;
      color: ${colors.gray700};

      &:hover {
        border-color: ${colors.blue400};
        background-color: inherit;
      }

      &--outside-month {
        color: ${colors.gray500};
      }

      &--selected {
        border-color: ${colors.blue400};
        background-color: inherit;
      }

      &--keyboard-selected {
        border-color: transparent;
        background-color: inherit;
      }

      &-names {
        padding-top: 12px;
      }

      &-name {
        width: 24px;
        margin: 0 6px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: ${colors.gray700};
      }
      &--disabled {
        color: ${colors.gray300};
        cursor: not-allowed;
      }
    }

    &__month {
      margin: 0;
      padding: 0 14px 9px;
      background-color: ${colors.white};
    }

    &__today-button {
      padding: 9px 0;
      color: ${colors.blue400};
      background-color: ${colors.white};
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border: none;
      transition: 0.2s;

      &:hover {
        background-color: ${colors.gray100};
      }
    }

    &__input-container {
      display: flex;
      align-items: center;
      position: relative;

      ${({ isDisabled, isOpen }) =>
        !isDisabled &&
        css`
          &:hover {
            ${CustomInput}:not(:focus) {
              ${isOpen
                ? css`
                    border-color: ${colors.blue400};
                  `
                : css`
                    border-color: ${colors.blue300};
                  `}
            }
          }
        `}

      ${CustomInput} {
        ${({ isOpen }) =>
          isOpen &&
          css`
            border-color: ${colors.blue400};
          `}
      }
    }
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 700;
  color: ${colors.gray700};
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid ${colors.gray300};
  padding: 0 8px 9px;
  text-transform: capitalize;
`;

const StyledNavigationButtons = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNavigationButton = styled.div`
  padding: 0 2px;
`;

const StyledIcon = styled.div<{ isOpen: boolean; isDisabled: boolean }>`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;

      svg {
        fill: ${colors.gray500};
      }
    `}

  svg {
    transition: 0.2s;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          svg {
            fill: ${colors.blue400};
          }
        `
      : css`
          &:hover {
            svg {
              fill: ${colors.blue300};
            }
          }
        `}
`;

const StyledLabel = styled.div<{ isDisabled: boolean }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
  color: ${colors.gray700};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${colors.gray500};
    `}
`;

export {
  StyledTimepicker,
  StyledHeader,
  StyledNavigationButton,
  StyledNavigationButtons,
  CustomInput,
  StyledIcon,
  StyledLabel,
};
