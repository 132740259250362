import { Header } from "./Header";
import { useGetMe } from "@/entities/session";
import { IBreadcrumbsItem } from "@yamaha-admin-sb/breadcrumbs";
import React, { useState } from "react";
import styled from "styled-components";
import { PROTECTED_ROUTES_BY_ROLES } from "../model/constants";
import { Menu } from "./Menu";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  padding-bottom: 64px;
`;

const StyledDefault = styled.div`
  min-height: 100vh;
  display: flex;
`;

const MENU_ITEMS_MOCK = [
  {
    label: "Заявки",
    icon: "applications-16",
    path: "/leads",
  },
  {
    label: "Звонки",
    icon: "calls-16",
    path: "/calls",
  },
  {
    label: "Клиенты",
    icon: "users",
    path: "/clients",
  },
  {
    label: "Отчеты",
    icon: "file-16",
    path: "/reports",
  },
  {
    label: "Рабочий день",
    icon: "calendar-16",
    path: "/work-day",
  },
  {
    label: "Техника",
    icon: "car-16",
    path: "/technics",
  },
  {
    label: "График работы",
    icon: "calendar-16",
    path: "/schedule",
  },
  {
    label: "Пользователи",
    icon: "profile-16",
    path: "/users",
  },
  {
    label: "Учет расходов",
    icon: "coin-16",
    path: "/expenses",
  },
  {
    label: "Премии",
    icon: "bonus-16",
    path: "/bonus",
  },
  {
    label: "Отзывы о работе",
    icon: "review-16",
    path: "/reviews",
  },
];

const Default: React.FC<IDefault> = ({ children, breads }): JSX.Element => {
  const { me } = useGetMe();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <StyledDefault>
      <Menu
        onCloseClick={() => setIsMenuOpen(false)}
        isMenuOpen={isMenuOpen}
        items={
          MENU_ITEMS_MOCK.filter(
            (item) =>
              me?.getMe?.role?.slug &&
              !PROTECTED_ROUTES_BY_ROLES[me?.getMe?.role?.slug]?.includes(item.path)
          ) || []
        }
      />

      <StyledContent>
        <Header breads={breads} onOpenClick={() => setIsMenuOpen(true)} />
        {children}
      </StyledContent>
    </StyledDefault>
  );
};

interface IDefault {
  breads: IBreadcrumbsItem[];
  children: JSX.Element | JSX.Element[];
}

export default Default;
