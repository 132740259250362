import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { colors } from "@/shared/helpers/index";
import { Icon } from "@yamaha-admin-sb/icon";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { TextButton } from "@yamaha-admin-sb/text-button";
import { DocumentPreview } from "@/entities/document";

const StyledDropzone = styled.div<{ isDragActive: boolean }>`
  padding: 20px 0;
  border: 1px dashed;
  border-radius: 4px;
  border-color: ${({ isDragActive }) => (isDragActive ? colors.red100 : colors.blue200)};
  transition: border 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IDropzone {
  onChange: (file?: File) => void;
}

const StyledIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${colors.blue100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrappedText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;

export const Dropzone: React.FC<IDropzone> = ({ onChange }) => {
  const [selectedFile, setSelectedFile] = useState<File>();

  useEffect(() => {
    onChange(selectedFile);
  }, [selectedFile]);
  const { open, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      multiple: false,
      noClick: true,
      noKeyboard: true,
      accept: { "text/xml": [".xml", ".xls", ".xlsx", ".csv"] },
      onDrop: async (acceptedFiles) => {
        setSelectedFile(acceptedFiles[0] as File);
      },
    });

  return (
    <div>
      <Paragraph size={12}>XLSX файл *</Paragraph>
      <StyledDropzone
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        className="space-20"
      >
        <input multiple={false} accept="text/xml" {...getInputProps()} />

        <StyledIcon className="space-12">
          <Icon name="document" size={24} color="blue-200" />
        </StyledIcon>
        <WrappedText>
          <Paragraph size={12}>Перетащите файл или</Paragraph>{" "}
          <TextButton onClick={open}>выберите</TextButton>
        </WrappedText>
        <Paragraph size={10} color="gray-600">
          XLSX
        </Paragraph>
      </StyledDropzone>
      {selectedFile ? (
        <DocumentPreview
          name={selectedFile.name}
          size={`${+(selectedFile.size / 1048576).toFixed(2)} МБ` || ""}
          hasDelete={true}
          onDelete={() => setSelectedFile(undefined)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
