import { parseQueryKey } from "@/shared/helpers";
import { useQueryParams } from "@/shared/lib/react";
import { useMemo } from "react";

export function useGetCurrentTabParam() {
  const params = useQueryParams();

  const currentTabParam: { key: string; value: string } = useMemo(() => {
    let selectedTab = { key: "responsible", value: "Union" };
    Object.keys(params).forEach((key) =>
      /\[ext\]/.test(key)
        ? (selectedTab = { key: parseQueryKey(key)[0], value: params[key] })
        : null
    );
    return selectedTab;
  }, [params]);

  return currentTabParam;
}
