import React, { useState } from "react";
import { Controller, Datepicker, Timepicker } from "@/shared/ui";
import { Dropdown, IDropdown } from "@yamaha-admin-sb/dropdown";
import { inputValidationRule, requiredValidationRule } from "@/shared/helpers/const";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import { UpdateLeadSpecialistInput, useUpdateLeadSpecialistMutation } from "@/shared/lib/graphql";
import { Title } from "@yamaha-admin-sb/title";
import { DataFilled } from "./DataFilled";
import { StyledFormItem, StyledGridContainer } from "@/entities/leads";
import moment from "moment";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { useSpecialists } from "@/entities/user";
import { transformDateToISO } from "@/shared/helpers/utils";
import { RoleEnum } from "@/shared/lib/graphql";
import { useGetMe } from "@/entities/session";
import { IHandleSuccess } from "../../model/interfaces";

export const Expert: React.FC<{
  disableCompleteStage: boolean;
  isCompleted: boolean;
  isOffsiteDiagnostics: boolean;
  leadId: number;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}> = ({
  disableCompleteStage,
  isCompleted,
  leadId,
  handleSuccess,
  handleError,
  isOffsiteDiagnostics,
}) => {
  const [form, setForm] = useState<UpdateLeadSpecialistInput>({
    specialistId: null,
    inspectionPlace: "",
    departure: "",
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    trigger,
  } = useForm({ mode: "onBlur" });

  const { allSpecialists, isLoading: isSpecialistsLoading } = useSpecialists();

  const { mutate: updateLeadSpecialist, isLoading: isSpecialistUpdating } =
    useUpdateLeadSpecialistMutation();

  const submitCallback = () => {
    if (leadId) {
      updateLeadSpecialist(
        {
          leadId,
          input: {
            ...form,
            departure: moment(form.departure, "DD.MM.YYYY").format("YYYY-MM-DD"),
          },
        },
        {
          onSuccess: () =>
            handleSuccess({
              text: "Специалист назначен",
              blockSlug: "specialist",
              disableCompleteStage,
            }),
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    }
  };

  const { me } = useGetMe();
  const userRole = me?.getMe.role?.slug;

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Данные об осмотре
        </Title>
      </div>
      {!isCompleted ? (
        <>
          <StyledGridContainer className="space-20">
            <Datepicker
              value={form.departure ? transformDateToISO(form.departure) : ""}
              hasTimeInput={false}
              label="Дата выезда"
              placeholder="Выберите дату выезда"
              isBordered={true}
              isBlock={true}
              minDate={new Date()}
              onInput={(val) => {
                setForm((prev) => ({
                  ...prev,
                  departure: val,
                }));
              }}
            />
            {RoleEnum.Manager !== userRole && (
              <Controller<IDropdown>
                // portal={document.querySelector("body") as HTMLElement}
                name="specialist"
                label="Специалист*"
                placeholder="Выберите специалиста"
                isBordered={true}
                control={control}
                rules={requiredValidationRule}
                error={errors?.specialist?.message}
                options={allSpecialists || []}
                isLoading={isSpecialistsLoading}
                value={[
                  allSpecialists.find((item) => form?.specialistId === item?.value) || [],
                ].flat()}
                isDisabled={isSpecialistUpdating}
                onChange={(value) => {
                  value[0]?.value &&
                    setForm((prev) => ({
                      ...prev,
                      specialistId: value[0].value,
                    }));
                }}
                component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
              />
            )}
          </StyledGridContainer>
          {isOffsiteDiagnostics ? (
            <StyledGridContainer className="space-20">
              <div>
                <Timepicker
                  value={form.inspectionTime || ""}
                  label="Время выезда"
                  placeholder="Выберите время выезда"
                  isBordered={true}
                  isBlock={true}
                  onInput={(date) => {
                    setForm((prev) => ({ ...prev, inspectionTime: date }));
                  }}
                />
              </div>
              <div>
                <Controller<IInput>
                  name="inspectionPlace"
                  control={control}
                  rules={inputValidationRule}
                  error={errors?.inspectionPlace?.message}
                  value={""}
                  label="Место осмотра*"
                  placeholder="Введите адрес"
                  isBordered={true}
                  // onInput={(val) =>
                  //   setForm((prev) => ({ ...prev, inspectionPlace: val }))
                  // }
                  onChange={(val) => {
                    setForm((prev) => ({ ...prev, inspectionPlace: val }));
                  }}
                  component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
                />
              </div>
            </StyledGridContainer>
          ) : (
            <></>
          )}
          <Button
            isLoading={isSpecialistUpdating}
            onClick={() => {
              trigger();
              handleSubmit(submitCallback)();
            }}
          >
            Назначить
          </Button>
        </>
      ) : (
        <DataFilled text="Специалист успешно назначен" />
      )}
    </StyledFormItem>
  );
};
