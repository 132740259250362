import React, { useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { media } from "@/shared/helpers";

interface INotifications {
  children: JSX.Element;
}

const StyledPortal = styled.div`
  z-index: 1;
  padding: 0 16px;
  ${media.tablet} {
    padding: 0;
  }
`;

export const DottedMenu: React.FC<INotifications> = (props) => {
  const { children } = props;
  const [controlledVisible, setControlledVisible] = useState(false);
  const handleVisibleChange = (value) => {
    setControlledVisible(value);
  };

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: "click",
    delayHide: 500,
    // offset: "",
    placement: "bottom-end",
    interactive: true,
    visible: controlledVisible,
    closeOnOutsideClick: true,
    onVisibleChange: handleVisibleChange,
  });

  return (
    <>
      {React.cloneElement(
        <div onClick={() => setControlledVisible((prev) => !prev)}>
          <IconButton variant="secondary" color="blue" icon="dots" isSquared={true} size="l" />
        </div>,
        {
          ref: setTriggerRef,
        }
      )}
      {visible && (
        <>
          <StyledPortal
            id="portalMenu"
            ref={setTooltipRef}
            {...getTooltipProps({ className: "tooltip-container" })}
          />
          {createPortal(children, document.querySelector("#portalMenu") || document.body)}
        </>
      )}
    </>
  );
};
