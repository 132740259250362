import React, { Dispatch, SetStateAction } from "react";

export interface IModalContextValue {
  payload: any;
  roleEditModal: boolean;
  changePasswordModal: boolean;
  confirmModal: boolean;
  createUserModal: boolean;
  modalCreateClient: boolean;
  modalChangeLServiceTypeLead: boolean;
  modalCreateContact: boolean;
  modalCreateReport: boolean;
  modalManagerAppointment: boolean;
  modalExpertAppointment: boolean;
  modalCreateRequest: boolean;
  modalCancelLead: boolean;
  modalEditContact: boolean;
  modalEditLead: boolean;
  modalEditTechnic: boolean;
  modalLeadComment: boolean;
  modalRecontactData: boolean;
  modalAutoRecontactData: boolean;
  modalUploadTechnics: boolean;
  modalEditBonuses: boolean;
  modalCreateExpenses: boolean;
  modalPrepaymentReturn: boolean;
  modalPaymentReturn: boolean;
  modalEditSchedule: boolean;
  modalScheduleAddUser: boolean;
  modalEditServices: boolean;
  editReportNameModal: boolean;
  modalOtherExpenses: boolean;
  modalCopyLead: boolean;
  modalCreateReminder: boolean;
  modalEditReminder: boolean;
  modalDeleteReminder: boolean;
  modalReminderInfo: boolean;
  modalReminder: boolean;
  eployeeEfficiencyModal: boolean;
  eployeeReportModal: boolean;
  eployeeReportRateModal: boolean;
  generalExpenseReportModal: boolean;
  modalCreateBonusByContracts: boolean;
}

type TModalContext =
  | [userValue: IModalContextValue, setUserValue: Dispatch<SetStateAction<IModalContextValue>>]
  | [];

export const ModalContext = React.createContext<TModalContext>([]);
