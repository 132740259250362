import { ModalContext } from "@/shared/lib/react";
import { Modal, ModalBody, ModalTop } from "@/shared/ui";
import { FC, useContext } from "react";
import styled from "styled-components";
import { colors } from "@/shared/helpers";
import moment from "moment";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { ReminderEntity } from "@/shared/lib/graphql";

const SyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: var(--font-brand);
`;

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.gray700};
`;

const StyledNotifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  font-weight: 300;
`;

const StyledComment = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: ${colors.gray600};
`;

interface IModalReminderProps {
  onEdit: () => void;
  onDelete: () => void;
  remindDate: ReminderEntity["remindDate"];
  remindNotificationDate: ReminderEntity["remindNotificationDate"];
  title: ReminderEntity["title"];
  user?: ReminderEntity["user"];
  lead?: ReminderEntity["lead"];
  comment?: ReminderEntity["comment"];
  transparentOverlay?: boolean;
}

export const ModalReminderInfo: FC<IModalReminderProps> = ({
  title,
  remindDate,
  remindNotificationDate,
  comment,
  lead,
  user,
  onDelete,
  onEdit,
  transparentOverlay,
}) => {
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const notifyDateString = moment(remindDate).format("dddd, DD MMMM, HH:mm");

  const onClose = () => {
    setModalContext((prev) => ({ ...prev, modalReminderInfo: false }));
  };

  return (
    <Modal
      name="modalReminderInfo"
      style={{ width: "382px" }}
      {...(transparentOverlay && { overlayStyle: { opacity: 0 } })}
    >
      <ModalTop
        title=""
        onClose={onClose}
        style={{ height: "50px", padding: "10px 24px" }}
        actions={
          <>
            <IconButton icon="edit" size="m" color="gray" variant="tertiary" onClick={onEdit} />
            <IconButton icon="delete" size="m" color="gray" variant="tertiary" onClick={onDelete} />
          </>
        }
      />
      <ModalBody style={{ padding: "10px 24px 24px 24px" }}>
        <SyledContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledNotifyContainer>
            <span>{notifyDateString}</span>
            <span>Напоминание: за {remindNotificationDate} минут</span>
            {lead && <span>Заявка: №{lead.num}</span>}
            {user && (
              <span>
                Пользователь: {user.name} {user.surname}
              </span>
            )}
          </StyledNotifyContainer>
          {comment && <StyledComment>{comment}</StyledComment>}
        </SyledContainer>
      </ModalBody>
    </Modal>
  );
};
