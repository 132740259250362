import React, { useContext, useEffect, useMemo, useState } from "react";
import { ModalContext } from "@/shared/lib/react";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import { LeadServiceTypeToText, requiredValidationRule } from "@/shared/helpers/const";
import {
  GetLeadQuery,
  LsTypeEnum,
  UpdateLeadPreAgreedDetailsInput,
  useGetLsTypesQuery,
  useUpdateLeadPreAgreedDetailsMutation,
} from "@/shared/lib/graphql";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { toFormatPrice } from "@/shared/helpers/utils";
import { IHandleSuccess } from "../../model/interfaces";

export interface IModal {
  lead: GetLeadQuery;
  isOffsiteDiagnostics: boolean;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

const serviceOptions = {
  "car-selection": {
    name: "Автоподбор",
    id: "car-selection",
    value: LsTypeEnum.CarSelection,
  },
  "day-specialist": {
    name: "Специалист на день",
    id: "day-specialist",
    value: LsTypeEnum.DaySpecialist,
  },
  "one-car": {
    name: "1 автомобиль",
    id: "one-car",
    value: LsTypeEnum.OneCar,
  },
};

export const ModalEditLead: React.FC<IModal> = (props): JSX.Element => {
  const { lead, isOffsiteDiagnostics, handleSuccess, handleError } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: updateLead, isLoading: isUpdating } = useUpdateLeadPreAgreedDetailsMutation();

  const { data: serviceTypes, isLoading } = useGetLsTypesQuery();

  const [currentLsType, setCurrentLsType] = useState(
    serviceTypes?.getLSTypes.find((item) => item.slug === lead.getLead.lSType?.slug)
  );
  useEffect(() => {
    setCurrentLsType(
      serviceTypes?.getLSTypes.find((item) => item.slug === lead.getLead.lSType?.slug)
    );
  }, [serviceTypes]);

  const [form, setForm] = useState<UpdateLeadPreAgreedDetailsInput>();

  useEffect(() => {
    setForm(
      currentLsType?.slug
        ? {
            toPaid: lead.getLead.toPaid,
            lSType: serviceOptions[currentLsType.slug].value,
          }
        : {
            toPaid: lead.getLead.toPaid,
          }
    );

    setValue("toPaid", lead.getLead.toPaid);
    currentLsType?.slug && setValue("lSType", serviceOptions[currentLsType.slug].value);
  }, [lead, currentLsType]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const handleChange = (key: keyof UpdateLeadPreAgreedDetailsInput, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdate = () => {
    form &&
      updateLead(
        {
          leadId: lead.getLead.id,
          input: form,
        },
        {
          onSuccess: () => {
            handleButtonUndoClick();
            handleSuccess({
              text: "Данные заявки изменены",
              disableCompleteStage: true,
            });
          },
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalEditLead: false,
    }));
  };

  const preparedServiceTypes: IDropdownItem[] = useMemo(
    () =>
      serviceTypes?.getLSTypes
        .filter((item) =>
          isOffsiteDiagnostics ? item.slug !== "car-selection" : item.slug === "car-selection"
        )
        .map((item) => ({
          name: LeadServiceTypeToText[item?.slug],
          id: item?.slug,
          value: serviceOptions[item?.slug]?.value,
        })) || [],
    [serviceTypes]
  );

  const mappedServicePrices: IDropdownItem[] = useMemo(
    () =>
      [
        currentLsType?.servicePrices?.map((item) => ({
          name: `${toFormatPrice(+item.price)} ₽`,
          value: +item.price,
        })) || [],
      ].flat(),
    [currentLsType]
  );

  return (
    <Modal name="modalEditLead">
      <ModalTop title="Редактировать информацию о заявке" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="lSType"
              label="Тип услуги *"
              placeholder="Выберите тип"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={errors?.lSType?.message}
              options={preparedServiceTypes}
              value={[
                preparedServiceTypes.find((item) => item.value === form?.lSType) || [],
              ].flat()}
              onChange={(value: IDropdownItem) => {
                if (value[0].value) {
                  handleChange("lSType", value[0].value);
                  setCurrentLsType(
                    serviceTypes?.getLSTypes.find((item) => item.slug === value[0].id)
                  );
                }
              }}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>

          <div className="space-20">
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="toPaid"
              control={control}
              rules={requiredValidationRule}
              error={errors?.toPaid?.message}
              options={mappedServicePrices}
              value={[
                mappedServicePrices?.find((item) => item?.value === form?.toPaid) || [],
              ].flat()}
              onChange={(value) => value[0]?.value && handleChange("toPaid", +value[0]?.value)}
              label="Стоимость услуги *"
              placeholder="Выберите стоимость услуги *"
              isBordered={true}
              // isDisabled={!form.lSType || isLoading}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isUpdating} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
