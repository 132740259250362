import { ModalManagerAppointment } from "@/features/user";
import { useCompleteLeadStageMutation, useUpdateLeadManagerMutation } from "@/shared/lib/graphql";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { useContext, type FC } from "react";

interface IModalAddManagerToLeadProps {
  selectedLead: number | null;
  invalidateLeads: () => Promise<unknown>;
}

export const ModalAddManagerToLead: FC<IModalAddManagerToLeadProps> = ({
  selectedLead,
  invalidateLeads,
}) => {
  const { mutate: updateLeadManager, isLoading: isAddingManager } = useUpdateLeadManagerMutation();
  const { mutate: completeLeadStage } = useCompleteLeadStageMutation();
  const [, setModalContext = () => ({})] = useContext(ModalContext);
  const notify = useAddNotification();

  const handleToggleModal = () => {
    setModalContext((prev) => ({ ...prev, modalManagerAppointment: false }));
  };

  const handleAddManagerToLead = (managerId: number, leadId: number) => {
    updateLeadManager(
      {
        leadId,
        input: {
          managerId,
        },
      },
      {
        onSuccess: () => {
          completeLeadStage(
            { leadId },
            {
              onSuccess: async () => {
                handleToggleModal();
                notify("Менеджер назначен", "success");
                // await updateLeads();
                await invalidateLeads;
              },
              onError: () => notify("Ошибка", "error"),
            }
          );
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  return (
    <ModalManagerAppointment
      title="Назначение менеджера на заявку"
      onCancel={() => handleToggleModal()}
      onSubmit={(value) => selectedLead && handleAddManagerToLead(value, selectedLead)}
      isLoading={isAddingManager}
    />
  );
};
