import React from "react";
import { Default } from "@/widgets/layouts";
import { TechnicOutput, useGetClientQuery } from "@/shared/lib/graphql";
import { useNavigate, useParams } from "react-router-dom";
import ClientLayout from "./ui/ClientLayout";

export type TBlockSlug = "manager" | "client" | "technics" | "specialist" | "servicePrice";

export interface IHandleSuccess {
  text: string;
  disableCompleteStage?: boolean;
  blockSlug?: TBlockSlug;
  nextStatusId?: number | null;
}

export interface ISelectedTechnic {
  index: number;
  technic: TechnicOutput;
}

const ClientEdit: React.FC = () => {
  const { clientId = 0 } = useParams();
  const navigate = useNavigate();

  if (!clientId || isNaN(+clientId)) {
    navigate("/404");
  }

  const { data, refetch } = useGetClientQuery({ input: +clientId });

  const breads = [
    {
      title: "Клиенты",
      clickable: true,
      path: "/clients",
    },
    {
      title: `Клиент  ${data?.getClient ? data?.getClient.id : ""}`,
      clickable: false,
    },
  ];

  return (
    <Default breads={breads}>
      {data?.getClient ? <ClientLayout user={data} refetchClient={refetch} /> : <></>}
    </Default>
  );
};

export default ClientEdit;
