import { useGetMe } from "@/entities/session";
import { RoleEnum, useSetLeadToSpecialistMutation } from "@/shared/lib/graphql";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { Button } from "@yamaha-admin-sb/button";
import { useContext, type FC } from "react";

interface IAddSpecialistToLeadButtonProps {
  isLoading: boolean;
  setIsLeadLoading: (leadId: number, isLoading: boolean) => void;
  leadId: number;
  onLeadSelect: (leadId: number) => void;
  invalidateLeads?: () => Promise<unknown>;
}

export const AddSpecialistToLeadButton: FC<IAddSpecialistToLeadButtonProps> = ({
  leadId,
  isLoading,
  setIsLeadLoading,
  onLeadSelect,
  invalidateLeads,
}) => {
  const { me } = useGetMe();
  const notify = useAddNotification();
  const { mutate: setLeadToSpecialist } = useSetLeadToSpecialistMutation();
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const handleToggleModal = () => {
    setModalContext((prev) => ({ ...prev, modalExpertAppointment: true }));
  };

  const handleSetSpecialistToLead = (leadId: number) => {
    setIsLeadLoading(leadId, true);

    setLeadToSpecialist(
      {
        leadId,
      },
      {
        onSuccess: async () => {
          await invalidateLeads?.();
          notify("Вы назначили заявку на себя", "success");
          setIsLeadLoading(leadId, false);
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
          setIsLeadLoading(leadId, false);
        },
      }
    );
  };

  return (
    <Button
      variant="secondary"
      color="blue"
      isLoading={isLoading}
      onClick={(event) => {
        event.stopPropagation();
        if (RoleEnum.Specialist === me?.getMe.role?.slug) {
          handleSetSpecialistToLead(leadId);
          return;
        }

        handleToggleModal();
        onLeadSelect(leadId);
      }}
    >
      {RoleEnum.Specialist === me?.getMe.role?.slug ? "Взять себе" : "Назначить специалиста"}
    </Button>
  );
};
