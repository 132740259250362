import React, { useMemo } from "react";
import { GetLeadQuery, useGetLServicesQuery } from "@/shared/lib/graphql";
import {
  LeadServiceSlugToText,
  LeadServiceTypeToText,
  ResourceEnumToText,
} from "@/shared/helpers/const";
import { toFormatPrice } from "@/shared/helpers/utils";

import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";

import {
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
  WrappedLeadTitle,
  WrappedBlock,
  StyledLaptopReverseHidden,
} from "../leads.styled";

interface IRequsetBlock {
  enableEdit: boolean;
  isOffsiteDiagnostics: boolean;
  lead: GetLeadQuery;
  onEditClick: () => void;
  renderStatusBtn: () => JSX.Element;
}

export const RequestBlock: React.FC<IRequsetBlock> = (props) => {
  const { enableEdit, lead, isOffsiteDiagnostics, onEditClick, renderStatusBtn } = props;
  const { data: lServices } = useGetLServicesQuery();
  const selectedType = useMemo(
    () =>
      lServices?.getLServices
        .find((item) => item.slug === "offsite-diagnostics")
        ?.lSTypes.find((serviceType) => serviceType.id === lead.getLead.lSTypeId),
    [lServices, lead]
  );

  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Заявка
        </Title>
        {enableEdit ? (
          <IconButton icon="edit" variant="tertiary" color="gray" onClick={onEditClick} />
        ) : (
          <></>
        )}
      </WrappedLeadTitle>
      <StyledLaptopReverseHidden>
        <div className="space-16">{renderStatusBtn()}</div>
      </StyledLaptopReverseHidden>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Номер
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph size={12} color="gray-700" weight={400}>
            №{lead.getLead.num}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Тип
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph size={12} color="gray-700" weight={400}>
            {LeadServiceSlugToText[lead?.getLead?.lService?.slug || ""]}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
      {isOffsiteDiagnostics ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Услуга
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color={selectedType?.id ? "gray-700" : "gray-500"} weight={400}>
              {selectedType?.id ? LeadServiceTypeToText[selectedType.slug] : "Не выбрана"}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Источник
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph size={12} color="gray-700" weight={400}>
            {ResourceEnumToText[lead.getLead.resource]}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Сумма
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph size={12} color={lead.getLead?.toPaid ? "gray-700" : "gray-500"} weight={400}>
            {lead?.getLead?.toPaid ? `${toFormatPrice(lead.getLead.toPaid)} ₽` : "Не указана"}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
    </WrappedBlock>
  );
};
