import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalFooter, AvatarWithName, Loader, ModalTop, Modal } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { ModalContext, TCachedQuery, useCachedQuery } from "@/shared/lib/react";
import { colors } from "@/shared/helpers";
import {
  FindManyUsersQuery,
  FindManyUsersQueryVariables,
  useFindManyUsersQuery,
  useGetMonthUsersQuery,
} from "@/shared/lib/graphql";
import { Input } from "@yamaha-admin-sb/input";
import { Checkbox } from "@yamaha-admin-sb/checkbox";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { useDebounce } from "use-debounce";

interface IModalScheduleAddUser {
  date: string;
  isLoading?: boolean;
  onSuccess: (userIds: number[]) => void;
}

const StyledHeader = styled.div`
  background-color: ${colors.gray100};
  padding: 12px 24px;
  width: 100%;
`;

const StyledModalTop = styled(ModalTop)`
  border-bottom: none;
  padding-bottom: 0;
`;

const StyledSearch = styled.div`
  padding: 16px 24px;
`;

const StyledList = styled.div`
  overflow: auto;
  max-height: 50vh;
  min-height: 400px;
`;

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

const StyledRow = styled.div`
  display: grid;
  padding: 12px 24px;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  border-bottom: 1px solid ${colors.gray200};
`;

export const ModalScheduleAddUser: React.VFC<IModalScheduleAddUser> = (props) => {
  const { date, isLoading, onSuccess } = props;
  const [users, setUsers] = useState<FindManyUsersQuery["findManyUsers"]["data"] | []>([]);
  const [filter, setFilter] = useState<any>({
    filter: {},
    pagination: {},
    sort: {},
  });
  const [usersIds, setUsersIds] = useState<number[]>([]);
  const [searchDebouncedValue] = useDebounce(filter?.filter.search, 500);
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const {
    data: monthUsers,
    isLoading: isMonthUsersLoading,
    refetch: refetchMonthUsers,
  } = useGetMonthUsersQuery({
    date,
  });
  const {
    data: allUsers,
    isLoading: isAllUsersLoading,
    refetch: refetchAllUsers,
  } = useCachedQuery(
    useFindManyUsersQuery as TCachedQuery<FindManyUsersQuery, FindManyUsersQueryVariables>,
    {
      ...filter,
      filter: {
        search: searchDebouncedValue,
      },
    }
  );
  const isAllChecked = users?.length === usersIds.length;

  useEffect(() => {
    refetchMonthUsers();
  }, []);

  useEffect(() => {
    if (isMonthUsersLoading && isAllUsersLoading) return;

    const filteredUsers: FindManyUsersQuery["findManyUsers"]["data"] | undefined =
      allUsers?.findManyUsers?.data?.filter((user) => {
        const elem = monthUsers?.getMonthUsers.users.find((el) => el.id === user.id);
        if (!elem) return user;
      });

    setUsers(filteredUsers);
  }, [monthUsers, allUsers]);

  const handleClose = () => {
    setUsersIds([]);
    setModalContext((prevValue) => ({
      ...prevValue,
      modalScheduleAddUser: false,
    }));
  };

  const handleAllClick = (value: boolean) => {
    if (value) setUsersIds(users?.map((user) => user.id) || []);
    else setUsersIds([]);
  };

  const handleCheck = (value: boolean, id: number) => {
    if (value) setUsersIds((prevState) => [...prevState, id]);
    else setUsersIds((prevState) => prevState.filter((_id) => _id !== id));
  };

  return (
    <Modal name="modalScheduleAddUser" onClose={handleClose}>
      <StyledModalTop title="Добавление пользователей" onClose={handleClose} />
      <StyledSearch>
        <Input
          isBordered={true}
          name="search"
          withIcon={true}
          icon="search"
          isClearable={true}
          value=""
          onChange={(value) =>
            setFilter((prevState) => ({
              ...prevState,
              filter: { search: value },
            }))
          }
          placeholder="Поиск"
        />
      </StyledSearch>
      <StyledHeader>
        <Checkbox name="all" onInput={handleAllClick} value={isAllChecked}>
          <Paragraph size={12} color="gray-700">
            Выбрать всех{" "}
            <Paragraph size={12} tag="span" color="gray-600">
              {users?.length || ""}
            </Paragraph>
          </Paragraph>
        </Checkbox>
      </StyledHeader>
      <StyledList>
        {isLoading ? (
          <StyledLoader>
            <Loader size="l" />
          </StyledLoader>
        ) : (
          users?.map((user) => (
            <StyledRow key={user.id}>
              <Checkbox
                name=""
                value={usersIds.includes(user.id)}
                onInput={(value) => handleCheck(value, user.id)}
              >
                <AvatarWithName user={user} />
              </Checkbox>
              <Paragraph size={12} color="gray-600">
                {user.email}
              </Paragraph>
            </StyledRow>
          ))
        )}
      </StyledList>
      <ModalFooter>
        <Button variant="secondary" color="gray" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          isDisabled={!usersIds.length}
          isLoading={isLoading}
          onClick={() => usersIds.length && onSuccess(usersIds)}
        >
          Создать
        </Button>
      </ModalFooter>
    </Modal>
  );
};
