import { ICreateLeadFrom, ModalCreateRequest } from "@/features/requests";
import { ContactTypeEnum, LeadResourceEnum, useCreateLeadMutation } from "@/shared/lib/graphql";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { CustomNotification } from "@/shared/ui";
import { useContext, type FC } from "react";

interface IModalCreateLeadProps {
  invalidateLeads: () => Promise<unknown>;
}

export const ModalCreateLead: FC<IModalCreateLeadProps> = ({ invalidateLeads }) => {
  const { mutate: createLead, isLoading: isLeadCreating } = useCreateLeadMutation();
  const [, setModalContext = () => ({})] = useContext(ModalContext);
  const notify = useAddNotification();

  const handleToggleModal = () => {
    setModalContext((prev) => ({ ...prev, modalCreateRequest: false }));
  };

  const handleCreateLead = (data: ICreateLeadFrom) => {
    createLead(
      {
        input: data.managerId
          ? {
              contact: {
                name: data.name,
                surname: data.surname,
                value: data.phone,
                type: ContactTypeEnum.Phone,
              },
              lServiceId: data.lServiceId,
              resource: LeadResourceEnum.Site,
              managerId: data.managerId,
            }
          : {
              contact: {
                name: data.name,
                surname: data.surname,
                value: data.phone,
                type: ContactTypeEnum.Phone,
              },
              lServiceId: data.lServiceId,
              resource: LeadResourceEnum.Site,
            },
      },
      {
        onSuccess: (resp) => {
          handleToggleModal();
          notify(
            <CustomNotification
              title="Заявка создана"
              link={{
                url: `/leads/${resp.createLead.id}`,
                linkTitle: "Перейти",
              }}
            />,
            "default"
          );
          //   updateLeads();
          invalidateLeads();
        },
      }
    );
  };

  return (
    <ModalCreateRequest
      isLoading={isLeadCreating}
      onSuccess={handleCreateLead}
      onCancel={handleToggleModal}
    />
  );
};
