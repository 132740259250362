import React, { useContext, useEffect, useState } from "react";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import {
  ModalBody,
  ModalFooter,
  Modal,
  ModalTop,
  CustomNotification,
  Controller,
} from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import {
  LeadServiceSlugToText,
  requiredValidationRule,
  ResourceEnumToText,
} from "@/shared/helpers/const";
import {
  CopyLeadInput,
  GetLeadQuery,
  LeadResourceEnum,
  useCopyLeadMutation,
  useGetLServicesQuery,
} from "@/shared/lib/graphql";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";

export interface IModal {
  lead: GetLeadQuery;
  handleError: (value: ErrorEvent) => void;
}

const resourceOptions: IDropdownItem[] = [
  {
    name: ResourceEnumToText[LeadResourceEnum.Call],
    value: LeadResourceEnum.Call,
  },
  {
    name: ResourceEnumToText[LeadResourceEnum.Site],
    value: LeadResourceEnum.Site,
  },
  {
    name: ResourceEnumToText[LeadResourceEnum.Social],
    value: LeadResourceEnum.Social,
  },
];
export const ModalCopyLead: React.FC<IModal> = (props): JSX.Element => {
  const { lead, handleError } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { data: leadServices } = useGetLServicesQuery();
  const notify = useAddNotification();
  const [form, setForm] = useState({
    lServiceId: lead.getLead.lServiceId || -1,
    resource: lead.getLead.resource,
  });

  const options = [
    ...(leadServices?.getLServices?.map((el) => ({
      id: el.id,
      name: LeadServiceSlugToText[el.slug],
    })) || []),
  ];

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const { mutate: copyLead, isLoading } = useCopyLeadMutation();

  const handleChange = (key: keyof CopyLeadInput, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalCopyLead: false,
    }));
  };

  const handleCopy = () => {
    copyLead(
      {
        leadId: lead.getLead.id,
        input: form,
      },
      {
        onSuccess: (resp) => {
          handleButtonUndoClick();
          notify(
            <CustomNotification
              title="Заявка скопирована"
              link={{
                url: `/leads/${resp.copyLead.id}`,
                linkTitle: "Перейти",
              }}
            />,
            "default"
          );
        },
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  useEffect(() => {
    Object.keys(form).forEach((item) => setValue(item, form[item]));
  }, [form]);

  return (
    <Modal name="modalCopyLead">
      <ModalTop title="Скопировать заявку" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleCopy)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="lServiceId"
              label="Тип заявки *"
              placeholder="Выберите тип"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={errors?.lServiceId?.message}
              options={options || []}
              value={[options?.find((item) => item.id === form?.lServiceId) || []].flat() || []}
              onChange={(value: IDropdownItem) =>
                value[0].id && handleChange("lServiceId", value[0].id)
              }
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="resource"
              label="Источник *"
              placeholder="Выберите источник"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={errors?.resource?.message}
              options={resourceOptions || []}
              value={
                [resourceOptions?.find((item) => item.value === form?.resource) || []].flat() || []
              }
              onChange={(value: IDropdownItem) => {
                value[0].value && handleChange("resource", value[0].value);
              }}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Скопировать
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
