import React, { useContext, useMemo, useState } from "react";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import {
  CostAccountingEntity,
  CostAccountingFilters,
  CostAccountingPaginationInfo,
  CostAccountingSortInfo,
  SortEnum,
  useDeleteCostAccountingMutation,
  useGetCostAccountingsQuery,
} from "@/shared/lib/graphql";
import {
  ExpensesTypeEnumToText,
  expensesTypes,
  PAGINATION_DEFAULT_OPTIONS,
} from "@/shared/helpers/const";
import { getCapitalized, toFormatPrice, parseQueryKey } from "@/shared/helpers/utils";
import { MockExpensesFilters } from "@/shared/helpers/mocks";
import { StyledTitle, WrappedTable, Container } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import {
  IModalContextValue,
  ModalContext,
  useAddNotification,
  useQueryFilters,
  useQueryParams,
} from "@/shared/lib/react";
import { ModalCreateExpenses } from "@/features/expenses";
import { IHiddenNavigationItem } from "@yamaha-admin-sb/hidden-navigation";
import { Dropdown } from "@yamaha-admin-sb/dropdown";
import { useSearchParams } from "react-router-dom";
import { ModalReportRateEployee } from "@/features/reports";

const breads = [
  {
    title: "Учет расходов",
    clickable: false,
  },
];

const headers = [
  {
    Header: "Тип расходов",
    accessor: "type",
    isSortable: false,
  },
  {
    Header: "Сумма",
    accessor: "amount",
    isSortable: true,
  },
  {
    Header: "Дата внесения записи",
    accessor: "date",
    isSortable: true,
  },
];

const Expenses: React.FC = () => {
  const { selectedFilters, setQueryFilters } = useQueryFilters();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [pagination, setPagination] = useState<CostAccountingPaginationInfo>(
    PAGINATION_DEFAULT_OPTIONS
  );
  const params = useQueryParams();

  const currentTypeFilter: CostAccountingFilters = useMemo(() => {
    let selected = {};
    Object.keys(params).forEach((key) =>
      /\[ext\]/.test(key) ? (selected = { [parseQueryKey(key)[0]]: params[key] }) : null
    );
    return selected;
  }, [params]);

  const notify = useAddNotification();
  const { mutate: deleteCostAccounting } = useDeleteCostAccountingMutation();

  const [sort, setSort] = useState<CostAccountingSortInfo>({
    date: SortEnum.Desc,
  });
  const [filter, setFilter] = useState<CostAccountingFilters>({
    ...selectedFilters.filter,
    ...currentTypeFilter,
  });
  const [selectedExpenses, setSelectedExpenses] = useState<number>();

  const {
    data: expenses,
    isLoading,
    refetch,
  } = useGetCostAccountingsQuery({
    input: { pagination, sort, filter },
  });

  const handleToggleModal = (modalName: keyof IModalContextValue, flag: boolean) => {
    setModalContext((prev) => ({ ...prev, [modalName]: flag }));
  };

  const tablePagination = {
    page: Math.floor(
      (expenses?.getCostAccountings.meta?.skip || 0) /
        (expenses?.getCostAccountings?.meta?.take || 1)
    ),
    count: Math.ceil((expenses?.getCostAccountings?.meta?.count || 0) / (pagination.take || 1)),
  };

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (expenses?.getCostAccountings?.meta?.take || pagination.take || 10),
    }));
  };

  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const preparedData = useMemo(
    () =>
      expenses?.getCostAccountings.data?.map((item) => ({
        id: item.id,
        date: new Date(item.createdAt).toLocaleString(),
        type: ExpensesTypeEnumToText[item.type || ""],
        amount: toFormatPrice(item?.amount || 0) + " ₽",
        menu: [
          {
            title: "Редактировать",
            icon: "edit",
            value: "edit",
            type: "black",
            isDisabled: false,
          },
          {
            title: "Удалить",
            icon: "delete",
            value: "delete",
            type: "black",
            isDisabled: false,
          },
        ],
      })) || [],
    [expenses]
  );

  const handleMenuItemClick = (cell: CostAccountingEntity, menuItem: IHiddenNavigationItem) => {
    switch (menuItem.value) {
      case "edit":
        setSelectedExpenses(cell.id);
        handleToggleModal("modalCreateExpenses", true);
        break;
      case "delete":
        deleteCostAccounting(
          { id: cell.id },
          {
            onSuccess: async () => {
              notify("Расход удален", "success");
              refetch();
            },
            onError: (error) => {
              notify((error as ErrorEvent)?.message, "error");
            },
          }
        );
        break;
    }
  };

  const handleOpenModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: true }));
  };

  return (
    <>
      <Default breads={breads}>
        <StyledTitle className="space-64">
          <Title level={2} color="gray-700">
            Учет расходов
          </Title>
          <Button
            variant="secondary"
            color="gray"
            leftIcon={true}
            icon="download"
            onClick={() => handleOpenModal("eployeeReportRateModal")}
          >
            Скачать отчет
          </Button>
        </StyledTitle>

        <Container>
          <WrappedTable
            isAdditionalClerable={true}
            showMenu={true}
            headers={headers}
            data={preparedData || []}
            isLoading={isLoading}
            onSort={handleSort}
            onFiltersApply={(data) => {
              if (Object.keys(data).length) {
                setFilter((prevState) => ({ ...data, ...prevState }));
              } else {
                setFilter(data);
              }
            }}
            rightBtn={() => (
              <Button
                variant="secondary"
                color="gray"
                leftIcon={true}
                icon="plus"
                onClick={() => {
                  setSelectedExpenses(undefined);
                  handleToggleModal("modalCreateExpenses", true);
                }}
              >
                Добавить запись о расходах
              </Button>
            )}
            additionalFilter={
              filter.type
                ? {
                    [`type[filter][ext]`]: filter.type,
                  }
                : undefined
            }
            renderLeftElement={() => (
              <Dropdown
                isBordered={true}
                label="Тип"
                placeholder="Выберите тип"
                isMultiple={false}
                options={expensesTypes}
                value={[
                  expensesTypes.find((item) => item?.value === currentTypeFilter?.type) || [],
                ].flat()}
                onChange={(value) => {
                  if (value[0]?.value) {
                    setSearchParams({ "type[filter][ext]": value[0]?.value });
                    setFilter((prevState) => ({
                      ...prevState,
                      type: value[0]?.value,
                    }));
                  }
                }}
              />
            )}
            filters={MockExpensesFilters()}
            onMenuItemSelected={handleMenuItemClick}
            pagination={tablePagination}
            onPaginationClick={handlePaginationClick}
          />
        </Container>
      </Default>
      {/* type: "dropdown",
      label: "Тип",
      placeholder: "Выберите тип",
      name: "type",
      isMultiple: false,
      options: expensesTypes, */}
      {modalContext?.modalCreateExpenses ? (
        <ModalCreateExpenses
          selectedExpenses={selectedExpenses}
          onSuccess={() => {
            refetch();
            setSelectedExpenses(undefined);
          }}
        />
      ) : (
        <></>
      )}

      {modalContext?.eployeeReportRateModal ? <ModalReportRateEployee /> : <></>}
    </>
  );
};

export default Expenses;
