import { GetNotificationsQuery } from "@/shared/lib/graphql";
import { NotificationTypeToText } from "@/shared/helpers/const";
import { colors } from "@/shared/helpers";
import { Icon } from "@yamaha-admin-sb/icon";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { TextButton } from "@yamaha-admin-sb/text-button";
import React, { useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
interface INotifications {
  allNotifications?: GetNotificationsQuery;
  onVisibleChange?: (arg: boolean) => void;
}

const StyledBell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    svg {
      fill: ${colors.gray700};
    }
  }
`;

const StyledCount = styled.div`
  position: absolute;
  top: -6px;
  right: 0;
  transform: translateX(50%);
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  min-width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  text-align: center;

  border-radius: 20px;
  background-color: ${colors.primaryBlue};
  color: ${colors.white};
`;

const StyledNotificationContainer = styled.div`
  width: 240px;
  max-height: 300px;
  border-radius: 8px;
  background-color: ${colors.white};
  overflow: scroll;
  box-shadow: 0px 6px 40px rgba(22, 35, 70, 0.1);
  border-radius: 8px;
`;

const StyledNotificationItem = styled.div`
  padding: 12px 16px;
  display: flex;
  border-bottom: 1px solid ${colors.gray200};
`;

const StyledTitle = styled.div`
  padding: 16px;
`;

const StyledtextButton = styled.span`
  button {
    display: contents;
  }
`;

const StyledIcon = styled.div`
  min-width: 16px;
  width: 16px;
  margin-right: 10px;
`;

export const Notifications: React.FC<INotifications> = (props) => {
  const { onVisibleChange, allNotifications } = props;
  const navigate = useNavigate();
  const [controlledVisible, setControlledVisible] = useState(false);

  const handleVisibleChange = (value) => {
    setControlledVisible(value);
    if (onVisibleChange) onVisibleChange(value);
  };

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: "click",
    delayHide: 500,
    // offset: "",
    placement: "bottom-start",
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: handleVisibleChange,
  });

  return (
    <>
      {React.cloneElement(
        <StyledBell>
          {allNotifications?.getNotifications.meta.count ? (
            <StyledCount>{allNotifications?.getNotifications.meta.count}</StyledCount>
          ) : (
            <></>
          )}
          <Icon name="bell" size={16} color="gray-600" />
        </StyledBell>,
        {
          ref: setTriggerRef,
        }
      )}
      {visible && (
        <StyledNotificationContainer
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <StyledTitle>
            <Paragraph weight={500} size={10} color="gray700">
              {allNotifications?.getNotifications.meta.count
                ? `Новые уведомления ${allNotifications?.getNotifications.meta.count}`
                : "У Вас пока нет уведомлений"}
            </Paragraph>
          </StyledTitle>
          {allNotifications?.getNotifications?.data?.map((item) => (
            <>
              <StyledNotificationItem key={item.id}>
                <StyledIcon>
                  <Icon name="applications-16" color="gray-600" size={16} />
                </StyledIcon>

                <div>
                  <div className="space-4">
                    <Paragraph size={12}>
                      {`${NotificationTypeToText[item.type]} `}{" "}
                      <StyledtextButton>
                        <TextButton
                          onClick={() => navigate(`/leads/${item.leadId}`)}
                        >{`№${item.leadNum}`}</TextButton>
                      </StyledtextButton>
                    </Paragraph>
                  </div>
                  <Paragraph size={10} color="gray-600">
                    {moment(item.createdAt).fromNow()}
                  </Paragraph>
                </div>
              </StyledNotificationItem>
            </>
          ))}
        </StyledNotificationContainer>
      )}
    </>
  );
};
