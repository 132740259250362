import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { ModalContext } from "@/shared/lib/react";
import { ModalBody, ModalFooter, ModalTop, Modal, Controller } from "@/shared/ui";
import { useForm } from "react-hook-form";
import { ITextarea, Textarea } from "@yamaha-admin-sb/textarea";
import { inputValidationRule } from "@/shared/helpers/const";
import { CancelActionEnum, useCancelLeadMutation } from "@/shared/lib/graphql";
import { IHandleSuccess } from "../../model/interfaces";

interface IModalCancelLead {
  leadId: number;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ModalCancelLead: React.FC<IModalCancelLead> = (props): JSX.Element => {
  const { leadId, handleSuccess, handleError } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: cancelLead, isLoading } = useCancelLeadMutation();
  const [comment, setComment] = useState("");
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalCancelLead: false,
    }));
  };

  const submitCallback = () => {
    cancelLead(
      {
        leadId,
        input: {
          comment,
          action: CancelActionEnum.Cancel,
        },
      },
      {
        onSuccess: () => {
          handleButtonUndoClick();
          handleSuccess({
            text: "Заявка отменена",
            disableCompleteStage: true,
          });
          window.location.assign("/leads");
        },
        onError: (error) => {
          handleError(error as ErrorEvent);
          handleButtonUndoClick();
        },
      }
    );
  };

  return (
    <Modal name="modalCancelLead">
      <ModalTop title="Отмена заявки" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(submitCallback)}>
        <StyledModalBody>
          <StyledFormGroup>
            <Controller<ITextarea>
              name="comment"
              label="Комментарий *"
              placeholder="Введите комментарий"
              control={control}
              error={errors?.comment?.message}
              value={comment}
              isBordered={true}
              rules={inputValidationRule}
              onChange={(value: string) => setComment(value)}
              component={(inputProps: ITextarea) => <Textarea {...inputProps} />}
            />
          </StyledFormGroup>
        </StyledModalBody>
        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
