import React, { useContext, useState, useMemo, useEffect } from "react";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import styled from "styled-components";
import { ModalTop, Modal, Loader, Controller, ModalBody, ModalFooter } from "@/shared/ui";

import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { IInput, Input } from "@yamaha-admin-sb/input";

import { colors, media } from "@/shared/helpers";
import { Button } from "@yamaha-admin-sb/button";
import {
  UpdatePaymentBonusData,
  useCreatePaymentBonusesMutation,
  useDeletePaymentBonusesMutation,
  useFindManyRolesQuery,
  useGetPaymentBonusesQuery,
  useUpdatePaymentBonusesMutation,
} from "@/shared/lib/graphql";
import { useForm } from "react-hook-form";
import { inputValidationRule } from "@/shared/helpers/const";
import { toFormatPrice } from "@/shared/helpers/utils";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { TextButton } from "@yamaha-admin-sb/text-button";

export interface IModal {
  onSuccess: () => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray200};
    margin-bottom: 0;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    margin-top: 10px;
  }
`;

const WrappedLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 16px 20px;
`;

export const ModalEditBonuses: React.FC<IModal> = (props): JSX.Element => {
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { data: roles, isLoading: isRolesLoading } = useFindManyRolesQuery();
  const {
    data: bonuses,
    refetch: update,
    isLoading: isBonusesLoading,
  } = useGetPaymentBonusesQuery();
  const isLoading = isRolesLoading || isBonusesLoading;
  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalEditBonuses: false,
    }));
  };

  const preparedRoles = useMemo(
    () =>
      roles?.findManyRoles.map((item) => ({
        roleName: item.name,
        roleId: item.id,
        paymentBonuses: [],
        ...bonuses?.getPaymentBonuses.groups?.find((bonuses) => bonuses.role === item.slug),
      })),
    [roles, bonuses]
  );

  return (
    <Modal name="modalEditBonuses">
      <ModalTop title="Редактирование премий" onClose={handleButtonUndoClick} />

      <StyledModalBody>
        {!isLoading ? (
          preparedRoles ? (
            preparedRoles?.map((item, index) => (
              <div className="space-24" key={item.roleId}>
                <div className="space-16">
                  <Paragraph size={14} weight={500} color="gray-700">
                    {item.roleName}
                  </Paragraph>
                </div>
                <BonusTemplate roleId={item.roleId} bonuses={item.paymentBonuses} update={update} />
              </div>
            ))
          ) : (
            <></>
          )
        ) : (
          <WrappedLoader>
            <Loader size="m" />
          </WrappedLoader>
        )}
      </StyledModalBody>

      <ModalFooter>
        <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
          Отменить
        </Button>
        <Button onClick={handleButtonUndoClick}>Закрыть</Button>
      </ModalFooter>
    </Modal>
  );
};

interface IBonusTemplate {
  update: () => void;
  roleId: number;
  bonuses: {
    id: number;
    roleId: number;
    border: number;
    bonus: number;
  }[];
}

interface IBonusData {
  id?: number;
  roleId: number;
  border: number | null;
  bonus: number | null;
  isEdit?: boolean;
}

const BonusTemplate: React.FC<IBonusTemplate> = (props) => {
  const { bonuses, update, roleId } = props;
  const [isOnChange, setIsOnChange] = useState(false);
  const [bonusesTemplate, setBonusesTemplate] = useState<IBonusData[]>(bonuses);

  useEffect(() => setBonusesTemplate(bonuses), [bonuses]);

  const { mutate: updateBonuses } = useUpdatePaymentBonusesMutation();
  const { mutate: createBonuses } = useCreatePaymentBonusesMutation();
  const { mutate: deleteBonuses } = useDeletePaymentBonusesMutation();
  const notify = useAddNotification();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() =>
    bonusesTemplate.forEach((item, bonusIndex) => {
      setValue(`bonus-${bonusIndex}`, item.bonus);
      setValue(`border-${bonusIndex}`, item.border);
    })
  );

  const handleChange = (key: string, value: number, index: number) => {
    setBonusesTemplate((prev) =>
      prev.map((item, templateNum) =>
        templateNum === index ? { ...item, [key]: value, isEdit: true } : item
      )
    );

    !isOnChange && setIsOnChange(true);
  };

  const submitCallback = () => {
    const newFields = bonusesTemplate.filter((item) => !item.id);
    const editedBonuse = bonusesTemplate.filter((item) => item.id && !!item?.isEdit);
    if (newFields.length) {
      createBonuses(
        {
          input: {
            pBonuses: newFields.map((item) => ({
              roleId: item.roleId,
              border: item.border || 0,
              bonus: item.bonus || 0,
            })),
          },
        },
        {
          onSuccess: async () => {
            await update();
            setIsOnChange(false);
            notify("Премии добавлены", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    }
    if (editedBonuse.length) {
      updateBonuses(
        {
          input: {
            pBonuses: editedBonuse.map((item) => ({
              id: item.id,

              border: item.border,
              bonus: item.bonus,
            })) as UpdatePaymentBonusData[],
          },
        },
        {
          onSuccess: async () => {
            await update();
            setIsOnChange(false);
            notify("Премии обновлены", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    }
  };

  const handleDelete = (index: number, id?: number) => {
    if (!!id) {
      deleteBonuses(
        { input: { ids: [id] } },
        {
          onSuccess: async () => {
            await update();
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    }
    setBonusesTemplate((prev) => prev.filter((_, bonusIndex) => bonusIndex !== index));
  };

  return (
    <form onSubmit={handleSubmit(submitCallback)}>
      {bonusesTemplate.map((bonus, index) => (
        <StyledFlex>
          <StyledGridContainer className="m-w-100">
            <div className="space-12">
              <Controller<IInput>
                isBordered={true}
                name={`border-${index}`}
                label="Цель *"
                placeholder="Цель"
                suffix="₽"
                onChange={(value) => {
                  const digits = value.split(/\D/).join("");
                  digits && handleChange("border", +digits, index);
                }}
                control={control}
                error={errors?.[`border-${index}`]?.message}
                value={bonus.border && !isNaN(+bonus.border) ? toFormatPrice(+bonus.border) : ""}
                rules={inputValidationRule}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                isBordered={true}
                name={`bonus-${index}`}
                label="Премия *"
                placeholder="Премия"
                suffix="₽"
                onChange={(value) => {
                  const digits = value.split(/\D/).join("");
                  digits && handleChange("bonus", +digits, index);
                }}
                control={control}
                error={errors?.[`bonus-${index}`]?.message}
                value={bonus?.bonus && !isNaN(+bonus?.bonus) ? toFormatPrice(+bonus?.bonus) : ""}
                rules={inputValidationRule}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
          </StyledGridContainer>
          <IconButton
            icon="delete"
            variant="tertiary"
            color="gray"
            onClick={() => handleDelete(index, bonus.id)}
          />
        </StyledFlex>
      ))}
      <div className="display-flex ai-c jc-sb">
        <TextButton
          leftIcon={true}
          icon="plus"
          onClick={() => {
            setBonusesTemplate((prev) => [...prev, { roleId, border: null, bonus: null }]);
          }}
        >
          Добавить еще
        </TextButton>
        {isOnChange ? (
          <Button type="submit" onClick={() => trigger()}>
            Сохранить
          </Button>
        ) : (
          <></>
        )}
      </div>
    </form>
  );
};
