import React from "react";
import styled from "styled-components";
import { colors } from "@/shared/helpers";
import { CallStatusEnum } from "../lib/graphql";

interface IStatus {
  status: CallStatusEnum;
}

type TColors = {
  [key in CallStatusEnum]: {
    backColor: string;
    text: string;
  };
};

type TStatuses = {
  [key in CallStatusEnum]: string;
};

const STATUSES: TStatuses = {
  [CallStatusEnum.Missed]: "Пропущен",
  [CallStatusEnum.Received]: "Принят",
};

const COLORS: TColors = {
  [CallStatusEnum.Missed]: {
    backColor: "red100",
    text: "red200",
  },
  [CallStatusEnum.Received]: {
    backColor: "gray200",
    text: "gray500",
  },
};

const StyledStatus = styled.div<{ backColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95px;
  padding: 4px 8px 4px 4px;
  border-radius: 4px;
  background-color: ${({ backColor }) => colors[backColor]};
`;

const StyledCircle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 50px;
  background-color: ${({ color }) => colors[color]};
`;

const StyledStatusText = styled.span<{ color: string }>`
  flex-basis: 70%;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  color: ${({ color }) => colors[color]};
`;

export const Status: React.FC<IStatus> = (props): JSX.Element => {
  const { status = "skipped" } = props;

  return (
    <StyledStatus backColor={COLORS[status].backColor}>
      <div>
        <StyledCircle color={COLORS[status].text} />
      </div>
      <StyledStatusText color={COLORS[status].text}>{STATUSES[status]}</StyledStatusText>
    </StyledStatus>
  );
};
