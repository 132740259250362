import { Paragraph } from "@yamaha-admin-sb/paragraph";
import React from "react";
import styled from "styled-components";
import { colors } from "@/shared/helpers";

interface IRoleProps {
  role: string;
}

const StyledFrame = styled.div<{ color: string }>`
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
  white-space: nowrap;
  background-color: ${({ color }) => colors[color]};
`;

export const RoleBlock: React.FC<IRoleProps> = (props) => {
  const { role } = props;

  return (
    <StyledFrame color="gray200">
      <Paragraph size={12}>{role}</Paragraph>
    </StyledFrame>
  );
};
