import { colors } from "@/shared/helpers";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import React from "react";
import styled from "styled-components";

interface IModalTop {
  title: string;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
  onBackClick?: () => void;
  onClose?: () => void;
  actions?: React.ReactNode;
}

const StyledModalTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: 20px 24px;
  border-bottom: 1px solid ${colors.gray200};
`;
const StyledCloseButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledText = styled.div`
  margin-top: 12px;
`;

export const ModalTop: React.FC<IModalTop> = (props) => {
  const { title, text, className, onBackClick, onClose, actions, style } = props;
  return (
    <StyledModalTop className={className} style={style}>
      <div>
        <StyledTitleWrapper>
          {onBackClick && (
            <IconButton
              size="m"
              icon="arrow-left"
              color="gray"
              variant="tertiary"
              onClick={onBackClick}
            />
          )}
          <Paragraph size={14}>{title}</Paragraph>
        </StyledTitleWrapper>

        {text && (
          <StyledText>
            <Paragraph size={12} color="gray-700">
              {text}
            </Paragraph>
          </StyledText>
        )}
      </div>
      {onClose && (
        <StyledCloseButtonWrap>
          {actions}
          <IconButton size="m" icon="cross" color="gray" variant="tertiary" onClick={onClose} />
        </StyledCloseButtonWrap>
      )}
    </StyledModalTop>
  );
};
