import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Container, WrappedTable, Loader, StyledNoItem } from "@/shared/ui";
import { ModalContext } from "@/shared/lib/react";
import { useNavigate } from "react-router-dom";
import { ContactTypeEnum, RoleEnum } from "@/shared/lib/graphql";
import { LeadServiceSlugToText } from "@/shared/helpers/const";
import { useGetMe } from "@/entities/session";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import styled from "styled-components";
import { Title } from "@yamaha-admin-sb/title";
import { RequestStatus } from "@/entities/leads";
import { ModalAddSpecialist } from "@/widgets/leads";
import { LeadManager, LeadSpecialist } from "@/features/leads";

interface IClientLeadsProps {
  isLoading: boolean;
  isPageLoaded: boolean;
  leads?: Array<{
    id: number;
    num: string;
    lServiceId?: number | null;
    createdAt: any;
    contact?: {
      name?: string | null;
      surname?: string | null;
      phone?: string | null;
      value?: string | null;
      type?: ContactTypeEnum | null;
      comment?: string | null;
    } | null;
    lService?: { id: number; slug: string } | null;
    manager?: {
      id: number;
      email: string;
      name: string;
      username: string;
      surname: string;
      patronymic?: string | null;
      phone: string;
    } | null;
    leadStatuses?: Array<{
      id: number;
      comment?: string | null;
      status?: { slug: string } | null;
    }> | null;
    specialist?: {
      id: number;
      name: string;
      username: string;
      surname: string;
      patronymic?: string | null;
      phone: string;
    } | null;
    technics?: Array<{
      id: number;
      make?: string | null;
      model?: string | null;
      maxPrice?: number | null;
      minPrice?: number | null;
      color?: string | null;
      issueYear?: number | null;
      parameters?: string | null;
      body?: Array<string> | null;
      driveUnit?: Array<string> | null;
      mileage?: number | null;
      equipmentOptions?: string | null;
      engineVolume?: number | null;
    }> | null;
  }> | null;
}

const StyledCommentWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const LeadsDisableTemplate = {
  HEAD_OF_MN: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  HEAD_OF_SP: {
    managerAppointment: true,
    specialistAppointment: false,
    createLead: true,
  },
  MANAGER: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  SN_MANAGER: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  SPECIALIST: {
    managerAppointment: true,
    specialistAppointment: false,
    createLead: true,
  },
};

const headers = [
  {
    Header: "Номер заявки",
    accessor: "num",
    isSortable: true,
  },
  {
    Header: "Тип заявки",
    accessor: "type",
  },
  /*   {
          Header: "Имя и номер клиента",
          accessor: "date",
          isSortable: true,
      }, */
  {
    Header: `Марка\\модель`,
    accessor: "client",
  },
  {
    Header: "Менеджер",
    accessor: "manager",
  },
  {
    Header: "Специалист",
    accessor: "specialist",
  },
  {
    Header: "Комментарий",
    accessor: "comment",
  },
  {
    Header: "Статус",
    accessor: "status",
  },
];

export const ClientLeads: FC<IClientLeadsProps> = ({ leads, isPageLoaded }) => {
  const navigate = useNavigate();
  const [modalContext] = useContext(ModalContext);
  const [isMounted, setIsMounted] = useState(false);
  const { me } = useGetMe();

  const [isUpdatingLeadRow, setIsUpdatindLeadRow] = useState({});

  const [selectedLead, setSelectedLead] = useState<null | number>();

  const getLeadInfo = leads?.find((item) => item.id === selectedLead);

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, []);

  const userRole: any = me?.getMe.role?.slug;
  const canChangeManager = [RoleEnum.Director, RoleEnum.HeadOfMn].includes(userRole);
  const preparedTableData = useMemo(
    () =>
      leads?.map((item) => ({
        ...item,
        manager: (
          <LeadManager
            lead={item}
            canChangeManager={canChangeManager}
            hasManagerAppointment={
              LeadsDisableTemplate[me?.getMe.role?.slug || ""]?.managerAppointment
            }
            onLeadSelect={setSelectedLead}
          />
        ),
        specialist: (
          <LeadSpecialist
            lead={item}
            isRowLoading={isUpdatingLeadRow[item.id]}
            setIsLeadLoading={(leadId: number, isLoading: boolean) => {
              setIsUpdatindLeadRow((prev) => ({ ...prev, [leadId]: isLoading }));
            }}
            hasSpecialistAppointment={
              LeadsDisableTemplate[me?.getMe.role?.slug || ""]?.specialistAppointment
            }
            onLeadSelect={setSelectedLead}
          />
        ),
        num: (
          <Paragraph size={12} weight={500}>
            {new Date(item.createdAt).toLocaleDateString("ru-RU")}
            <br />№{item.num}
          </Paragraph>
        ),
        type: (
          <Paragraph size={12} color="gray-600">
            {item.lService?.slug ? LeadServiceSlugToText[item.lService?.slug] : ""}
          </Paragraph>
        ),
        client: (
          <Paragraph size={12} color="gray-600">
            {item.technics && item.technics[0]
              ? `${item.technics[0].make || ""} \\ ${item.technics[0].model || ""}`
              : "Не указаны"}
          </Paragraph>
        ),
        comment: (
          <Paragraph size={12} color="gray-600">
            <StyledCommentWrapper>
              {item.contact?.comment ? `${item.contact?.comment || ""}` : "Не указан"}
            </StyledCommentWrapper>
          </Paragraph>
        ),
        status: (
          <RequestStatus
            status={(item?.leadStatuses && item?.leadStatuses[0]?.status?.slug) || "new"}
          />
        ),
      })),
    [leads, me?.getMe]
  );

  return isPageLoaded ? (
    <>
      <Container>
        <Title level={3} weight={500} color="gray-600">
          Заявки
        </Title>
        <WrappedTable
          onRowClick={(value) => {
            navigate(`/leads/${value.id}`);
          }}
          showMenu={false}
          tableTitle="Все заявки"
          headers={headers}
          data={preparedTableData || []}
          onMenuItemSelected={() => ({})}
        />
      </Container>
      {modalContext?.modalExpertAppointment && (
        <ModalAddSpecialist selectedLead={selectedLead} leadInfo={getLeadInfo} />
      )}
    </>
  ) : (
    <StyledNoItem>
      <Loader size="l" />
    </StyledNoItem>
  );
};
