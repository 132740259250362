export const callsTableHeaders = [
  {
    Header: "Номер",
    accessor: "phone",
    isSortable: false,
  },
  {
    Header: "Дата",
    accessor: "date",
    isSortable: true,
  },
  {
    Header: "Статус",
    accessor: "status",
    isSortable: false,
  },
  {
    Header: "Менеджер",
    accessor: "manager",
    isSortable: false,
  },
  {
    Header: "",
    accessor: "action",
    isSortable: false,
  },
];
