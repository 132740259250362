import { colors } from "@/shared/helpers";
import { useGetCurrentReminderQuery } from "@/shared/lib/graphql";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ReminderNotification } from "../ui/ReminderNotification";

export function useReminderNotification() {
  const { data: reminders, refetch } = useGetCurrentReminderQuery();

  const reminderNotify = (reminder) => {
    const handleDeleteNotification = () => {
      toast.dismiss(reminder.id);
    };

    return toast(
      <ReminderNotification
        id={reminder.id}
        title={reminder.title}
        remindDate={reminder.remindDate}
        comment={reminder.comment}
        leadId={reminder.leadId}
        onDismiss={handleDeleteNotification}
      />,
      {
        toastId: reminder.id,
        autoClose: false,
        closeOnClick: false,
        style: {
          boxShadow: "4px 4px 4px 0px #00000040",
          backgroundColor: colors.white,
          borderLeft: `2px solid ${colors.brandBlue}`,
        },
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (reminders?.getCurrentReminder && reminders?.getCurrentReminder?.length > 0) {
      reminders?.getCurrentReminder.forEach((el) => reminderNotify(el));
    }
  }, [reminders?.getCurrentReminder]);
}
