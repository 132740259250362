import React, { useEffect, useMemo, useState } from "react";
import {
  LsTypeEnum,
  TechnicsData,
  useCreateLeadTechnicsMutation,
  useGetLServicesQuery,
  useGetVehicleMarksQuery,
  useGetVehicleModelsQuery,
  useUpdateLeadTechnicMutation,
} from "@/shared/lib/graphql";
import { Title } from "@yamaha-admin-sb/title";
import { StyledFormItem, StyledGridContainer, StyledGridElement } from "@/entities/leads";
import { Button } from "@yamaha-admin-sb/button";
import { Controller } from "@/shared/ui";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { Control, useForm } from "react-hook-form";
import {
  BODY_OPTIONS,
  DRIVE_UNIT_OPTIONS,
  gearboxOptions,
  inputValidationRule,
  LeadServiceTypeToText,
  requiredValidationRule,
} from "@/shared/helpers/const";
import { Textarea, ITextarea } from "@yamaha-admin-sb/textarea";
import { TextButton } from "@yamaha-admin-sb/text-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { DataFilled } from "./DataFilled";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { toFormatPrice } from "@/shared/helpers/utils";
import { IHandleSuccess } from "../../model/interfaces";

export interface ITechnicsDataTemplate extends TechnicsData {
  id?: number;
}

interface ITechnicsForm {
  technicsData?: ITechnicsDataTemplate[];
  leadId: number;
  isFilled: boolean;
  isOffsiteDiagnostics: boolean;
  lsType?: { slug: string; id: number } | null;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

interface IFromTemplate {
  isDisabled?: boolean;
  data: ITechnicsDataTemplate;
  index: number;
  control: Control<any>;
  errors: Record<string, { message?: string }>;
  setValue: (name: string, value: unknown, config?: Object) => void;
  onCarDeleteClick: () => void;
  handelChange: (key: keyof ITechnicsDataTemplate, value: any) => void;
}

const DEFAULT_TECHNICS_DATA: ITechnicsDataTemplate = {
  id: 0,
  parameters: "",
  make: "",
  color: null,
  issueYear: null,
  maxPrice: null,
  minPrice: null,
  model: null,
  body: null,
  gearbox: null,
  driveUnit: null,
  mileage: null,
  equipmentOptions: null,
  engineVolume: null,
};

const LServiceSlugToEnum = {
  "one-car": LsTypeEnum.OneCar,
  "day-specialist": LsTypeEnum.DaySpecialist,
};

// export const driveUnitOptions: IDropdownItem[] = [
//   {
//     name: "Передний привод",
//     value: DriveUnitEnum.FrontWheel,
//   },
//   {
//     name: "Задний привод",
//     value: DriveUnitEnum.RearWheel,
//   },
// ];

export const FormTemplate: React.FC<IFromTemplate> = (props) => {
  const { data, index, setValue, control, errors, isDisabled, onCarDeleteClick, handelChange } =
    props;

  const [selectedMarks, setSelectedMarks] = useState<IDropdownItem[]>([]);
  const [selectedModels, setSelectedModels] = useState<IDropdownItem[]>([]);
  const [selectedFilters, setSelectedFilters] = useState({
    model: {
      name: "",
      id: 0,
    },
    mark: {
      name: "",
    },
  });

  useEffect(() => {
    Object.keys(data).forEach((item) => setValue(`${item}-${index}`, data[item]));
  }, [data]);

  const { data: models } = useGetVehicleModelsQuery(
    {
      filter: selectedMarks[0]?.id
        ? selectedFilters?.model?.name
          ? {
              markId: selectedMarks[0]?.id.toString(),
              name: selectedFilters.model.name,
            }
          : { markId: selectedMarks[0]?.id.toString() }
        : {},
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!selectedMarks[0]?.id,
    }
  );
  const { data: marks, isLoading: isMarksLoading } = useGetVehicleMarksQuery({
    filter: selectedFilters?.mark?.name
      ? {
          name: selectedFilters.mark.name,
        }
      : {},
  });

  useEffect(() => {
    const prevMarks = marks?.getVehicleMarks.find((item) => item.name === data.make);

    prevMarks && setSelectedMarks([prevMarks]);
  }, [marks, data]);

  useEffect(() => {
    const prevModels = models?.getVehicleModels.filter(
      (item) => !!data.model?.split("; ").includes(item.name)
    );

    prevModels && setSelectedModels(prevModels);
  }, [models, selectedMarks]);

  return (
    <StyledGridContainer>
      <StyledGridElement className="display-flex jc-sb ai-c" column="1 / span 2">
        <Paragraph size={12} color="gray-600">
          Автомобиль №{index + 1}
        </Paragraph>
        {index ? (
          <IconButton color="gray" variant="tertiary" icon="trash" onClick={onCarDeleteClick} />
        ) : (
          <></>
        )}
      </StyledGridElement>
      <StyledGridElement column="1">
        <Controller<IDropdown>
          // portal={document.querySelector("body") as HTMLElement}
          name={`make-${index}`}
          label="Марка *"
          searchable={true}
          onSearch={(value) => setSelectedFilters((prev) => ({ ...prev, mark: { name: value } }))}
          placeholder="Выберите марку"
          control={control}
          error={errors[`make-${index}`]?.message}
          options={marks?.getVehicleMarks || []}
          isDisabled={isMarksLoading || isDisabled}
          isBordered={true}
          value={selectedMarks}
          rules={requiredValidationRule}
          onChange={(value: IDropdownItem[]) => {
            setSelectedMarks(value);
            value[0]?.name && handelChange("make", value[0].name);
            // handelChange("model", "");
            // setSelectedModels([]);
          }}
          component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Controller<IDropdown>
          // portal={document.querySelector("body") as HTMLElement}
          name={`model-${index}`}
          label="Модель"
          placeholder="Выберите модель"
          control={control}
          rules={requiredValidationRule}
          error={errors[`model-${index}`]?.message}
          searchable={true}
          options={models?.getVehicleModels || []}
          isBordered={true}
          value={selectedModels}
          isMultiple={true}
          isDisabled={!selectedMarks[0]?.id || isMarksLoading || isDisabled}
          withTags={true}
          onSearch={(value) =>
            setSelectedFilters((prev) => ({
              ...prev,
              model: { ...prev.model, name: value },
            }))
          }
          isVerticalTags={true}
          onChange={(value: IDropdownItem[]) => {
            setSelectedModels(value);
            handelChange("model", value?.map((item) => item.name).join("; ") || null);
          }}
          component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Dropdown
          label="Коробка передач"
          placeholder="Выберите коробку передач"
          isBordered={true}
          isMultiple={true}
          options={gearboxOptions}
          isDisabled={isDisabled}
          withTags={true}
          value={gearboxOptions.filter((item) => data.gearbox?.includes(item.value)) || []}
          onChange={(value) => handelChange("gearbox", value?.map((el) => el?.value) || [])}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Dropdown
          label="Привод"
          placeholder="Выберите привод"
          isDisabled={isDisabled}
          isBordered={true}
          isMultiple={true}
          withTags={true}
          options={DRIVE_UNIT_OPTIONS}
          value={DRIVE_UNIT_OPTIONS.filter((item) => data.driveUnit?.includes(item.name)) || []}
          onChange={(value) => handelChange("driveUnit", value?.map((el) => el?.name) || [])}
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Input
          name={`issueYear-${index}`}
          label="Год от"
          placeholder="Выберите год от"
          isDisabled={isDisabled}
          value={data.issueYear || ""}
          type="number"
          isBordered={true}
          onChange={(value) => handelChange("issueYear", +value)}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Input
          name={`color-${index}`}
          label="Цвет"
          placeholder="Введите цвет"
          isDisabled={isDisabled}
          value={data.color || ""}
          isBordered={true}
          onChange={(value) => handelChange("color", value)}
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Input
          name={`engineVolume-${index}`}
          label="Объем двигателя"
          placeholder="Введите объем двигателя"
          suffix="л"
          type="number"
          value={data.engineVolume || ""}
          isDisabled={isDisabled}
          isBordered={true}
          onChange={(value) => {
            handelChange("engineVolume", +value);
          }}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Dropdown
          label="Kyзов"
          placeholder="Выберите тип кузова"
          isBordered={true}
          isMultiple={true}
          withTags={true}
          isDisabled={isDisabled}
          options={BODY_OPTIONS}
          value={BODY_OPTIONS.filter((item) => data.body?.includes(item.name)) || []}
          onChange={(value) => handelChange("body", value?.map((el) => el?.name) || [])}
        />
      </StyledGridElement>
      {/*  <StyledGridElement column="1">
        <Input
          name={`minPrice-${index}`}
          label="Цена"
          placeholder="От"
          isDisabled={isDisabled}
          suffix="₽"
          value={
            data?.minPrice && !isNaN(+data?.minPrice)
              ? toFormatPrice(+data.minPrice)
              : ""
          }
          isBordered={true}
          onChange={(value) => {
            const digits = value.split(/\D/).join("");
            digits && handelChange("minPrice", +digits);
          }}
        />
      </StyledGridElement> */}
      <StyledGridElement column="1">
        <Input
          name={`maxPrice-${index}`}
          label="Цена"
          isDisabled={isDisabled}
          placeholder="Максимальный бюджет"
          suffix="₽"
          value={data?.maxPrice && !isNaN(+data?.maxPrice) ? toFormatPrice(+data.maxPrice) : ""}
          isBordered={true}
          rules={inputValidationRule}
          onChange={(value) => {
            const digits = value.split(/\D/).join("");
            digits && handelChange("maxPrice", +digits);
          }}
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Input
          name={`mileage-${index}`}
          label="Пробег"
          isDisabled={isDisabled}
          placeholder="До"
          suffix="км"
          value={data?.mileage && !isNaN(+data?.mileage) ? toFormatPrice(+data.mileage) : ""}
          isBordered={true}
          rules={inputValidationRule}
          onChange={(value) => {
            const digits = value.split(/\D/).join("");
            digits && handelChange("mileage", +digits);
          }}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Controller<IInput>
          name={`parameters-${index}`}
          label="Ссылка на авто"
          isDisabled={isDisabled}
          placeholder="Введите сслыку на авто"
          value={data?.parameters || ""}
          isBordered={true}
          // rules={inputValidationRule}
          onChange={(value) => handelChange("parameters", value)}
          control={control}
          // error={errors[`parameters-${index}`]?.message}
          component={(inputProps: IInput) => <Input {...inputProps} />}
        />
      </StyledGridElement>
      <StyledGridElement column="1 / span 2">
        <Controller<ITextarea>
          name={`equipmentOptions-${index}`}
          label="Комплектация. Необходимые опции"
          placeholder="Введите текст"
          control={control}
          isDisabled={isDisabled}
          // error={errors[`equipmentOptions-${index}`]?.message}
          value={data?.equipmentOptions || ""}
          isBordered={true}
          // rules={inputValidationRule}
          onChange={(value) => handelChange("equipmentOptions", value)}
          component={(inputProps: ITextarea) => <Textarea {...inputProps} />}
        />
      </StyledGridElement>
    </StyledGridContainer>
  );
};
export const TechnicsForm: React.FC<ITechnicsForm> = (props) => {
  const {
    technicsData,
    lsType,
    leadId,
    isFilled,
    isOffsiteDiagnostics,
    handleSuccess,
    handleError,
  } = props;
  const [selectedType, setSelectedType] = useState<IDropdownItem>();
  const { data: LService, isLoading } = useGetLServicesQuery({}, { enabled: isOffsiteDiagnostics });
  useEffect(() => {
    lsType &&
      setSelectedType({
        name: LeadServiceTypeToText[lsType.slug],
        value: lsType.slug,
      });
  }, [lsType]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    trigger,
    setValue,
  } = useForm({ mode: "onBlur" });
  const { mutate: updateTechnic } = useUpdateLeadTechnicMutation();

  const [technics, setTechnics] = useState<ITechnicsDataTemplate[]>(
    technicsData && technicsData[0]
      ? [{ ...DEFAULT_TECHNICS_DATA, ...technicsData[0] }]
      : [DEFAULT_TECHNICS_DATA]
  );
  const { mutate: createLeadTechnics, isLoading: isTechnicsCreating } =
    useCreateLeadTechnicsMutation();

  const handleCreate = () => {
    if (technics) {
      const oldTechnic = technics.find((item) => !!item.id && item?.id !== 0);
      const newTechnics: ITechnicsDataTemplate[] = technics
        .filter((item) => !item?.id && item?.id === 0)
        .map((item) => {
          delete item.id;
          return item;
        });
      if (oldTechnic) {
        const oldId = oldTechnic.id;
        delete oldTechnic.id;
        updateTechnic(
          {
            leadId,
            input: {
              id: oldId || 0,
              technic: {
                ...oldTechnic,
              },
            },
          },
          {
            onSuccess: () => {
              !newTechnics.length &&
                handleSuccess({
                  text: "Техника добавлена",
                  blockSlug: "technics",
                });
            },
            onError: (error) => handleError(error as ErrorEvent),
          }
        );
      }
      if (newTechnics.length) {
        createLeadTechnics(
          {
            leadId,
            input: {
              lSType: selectedType?.value
                ? LServiceSlugToEnum[selectedType?.value]
                : LsTypeEnum.CarSelection,
              technics: newTechnics,
            },
          },
          {
            onSuccess: () =>
              handleSuccess({
                text: "Техника добавлена",
                blockSlug: "technics",
              }),
            onError: (error) => handleError(error as ErrorEvent),
          }
        );
      }
    }
  };

  const handleChange = (key: keyof ITechnicsDataTemplate, value: any, carIndex: number) => {
    setTechnics((prev) =>
      prev.map((item, index) => (carIndex === index ? { ...item, [key]: value } : item))
    );
  };

  const preparedData: IDropdownItem[] = useMemo(
    () =>
      LService?.getLServices
        .find((item) => item.slug === "offsite-diagnostics")
        ?.lSTypes.map((item) => ({
          name: LeadServiceTypeToText[item.slug],
          value: item.slug,
        })) || [],
    [LService]
  );

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Детали автоподбора
        </Title>
      </div>
      {!isFilled ? (
        <>
          {isOffsiteDiagnostics ? (
            <StyledGridContainer className="space-20">
              <Dropdown
                // portal={document.querySelector("body") as HTMLElement}
                options={preparedData || []}
                onChange={(value) => setSelectedType(value[0])}
                value={selectedType}
                label="Услуга*"
                placeholder="Выберите услугу"
                isBordered={true}
                isDisabled={isLoading}
              />
            </StyledGridContainer>
          ) : (
            <></>
          )}
          {selectedType || !isOffsiteDiagnostics ? (
            <>
              {technics
                .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
                .map((item, index) => (
                  <FormTemplate
                    key={index}
                    data={
                      technicsData && technicsData[index]
                        ? { ...technicsData[index], ...item }
                        : item
                    }
                    index={index}
                    control={control}
                    // @ts-ignore
                    errors={errors}
                    setValue={setValue}
                    onCarDeleteClick={() =>
                      setTechnics((prev) => prev.filter((_, carIndex) => carIndex !== index))
                    }
                    handelChange={(key, value) => handleChange(key, value, index)}
                  />
                ))}
              {technics.length < 5 ? (
                <div className="space-20">
                  <TextButton
                    leftIcon={true}
                    isDisabled={isTechnicsCreating}
                    icon="plus"
                    onClick={() => setTechnics((prev) => [...prev, DEFAULT_TECHNICS_DATA])}
                  >
                    Добавить еще автомобиль
                  </TextButton>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <Button
            isLoading={isTechnicsCreating}
            onClick={() => {
              trigger();
              handleSubmit(handleCreate)();
            }}
          >
            Сохранить
          </Button>
        </>
      ) : (
        <DataFilled text="Детали автоподбора успешно сохранены" />
      )}
    </StyledFormItem>
  );
};
