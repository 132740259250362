import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ModalFooter, Datepicker, IDatepicker, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { colors } from "@/shared/helpers";
import { downloadURL, getFormattedDate, transformDateToISO } from "@/shared/helpers/utils";
import { useForm } from "react-hook-form";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import {
  AnalyticsTypeEnum,
  useGenerateLeadTraficAnalyticsMutation,
  useGenerateSalaryAnalyticsMutation,
  useGenerateCashInflowAnalyticsMutation,
  GenerateLeadTraficAnalyticsMutation,
  GenerateCashInflowAnalyticsMutation,
  GenerateSalaryAnalyticsMutation,
} from "@/shared/lib/graphql";
import { Modal, ModalTop } from "@/shared/ui";

interface IModal {
  reportType: string;
}

type GenerateReportResponse =
  | GenerateLeadTraficAnalyticsMutation
  | GenerateCashInflowAnalyticsMutation
  | GenerateSalaryAnalyticsMutation;

const StyledRow = styled.div`
  padding: 24px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray200};
  }
`;

const StyledDates = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 30fr 1fr 30fr;
  gap: 4px;
  width: 100%;
`;

const StyledSeparator = styled.div`
  width: 8px;
  height: 1px;
  background-color: ${colors.gray300};
  margin-bottom: 22px;
`;

const reportTitle = {
  [AnalyticsTypeEnum.LeadTraffic]: "по трафику",
  [AnalyticsTypeEnum.CashInflow]: "о поступлении ДС",
  [AnalyticsTypeEnum.Salary]: "по ЗП",
};

export const ModalReportEployee: React.FC<IModal> = (props) => {
  const notify = useAddNotification();
  const { reportType } = props;
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isFilesLoading, setIsFilesLoading] = useState<boolean>(false);
  const [_, setModalContext = () => ({})] = useContext(ModalContext);
  const { handleSubmit, control } = useForm({ mode: "onBlur" });

  const useGeneratedMutation = {
    [AnalyticsTypeEnum.LeadTraffic]: useGenerateLeadTraficAnalyticsMutation(),
    [AnalyticsTypeEnum.Salary]: useGenerateSalaryAnalyticsMutation(),
    [AnalyticsTypeEnum.CashInflow]: useGenerateCashInflowAnalyticsMutation(),
  };

  const { mutate: createReport, isLoading } = useGeneratedMutation[reportType];

  const handleSuccess = () => {
    setIsFilesLoading(true);
    createReport(
      { input: { endDate, startDate } },
      {
        onSuccess: (resp: GenerateReportResponse) => {
          const { fileUrl } = Object.values(resp)?.[0];

          downloadURL(fileUrl).then(() => {
            notify("Файл загружен", "success");
            setIsFilesLoading(false);
            handleButtonUndoClick();
          });
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleButtonUndoClick = () => {
    setStartDate("");
    setEndDate("");
    setModalContext((prevState) => ({
      ...prevState,
      eployeeReportModal: false,
    }));
  };

  return (
    <Modal name="eployeeReportModal" onClose={handleButtonUndoClick}>
      <ModalTop
        title={`Создать отчеты ${reportTitle[reportType]}`}
        onClose={handleButtonUndoClick}
      />
      <form onSubmit={handleSubmit(handleSuccess)}>
        <StyledRow>
          <StyledDates>
            <Controller<IDatepicker>
              maxDate={new Date()}
              value={startDate ? new Date(startDate) : ""}
              label="Дата *"
              placeholder="От"
              isBordered={true}
              isBlock={true}
              name="startDate"
              onInput={(val) => {
                setStartDate(val ? transformDateToISO(val) || "" : "");
              }}
              onChange={(val) => setStartDate(getFormattedDate(val) || "")}
              // rules={requiredValidationRule}
              control={control}
              // error={errors?.startDate?.message}
              component={(datepickerProps: IDatepicker) => <Datepicker {...datepickerProps} />}
            />
            <StyledSeparator />

            <Controller<IDatepicker>
              label=""
              name="dateEnd"
              placeholder="До"
              isBordered={true}
              isBlock={true}
              minDate={startDate ? new Date(startDate) : undefined}
              onInput={(val) => {
                setEndDate(val ? transformDateToISO(val) || "" : "");
              }}
              // rules={requiredValidationRule}
              control={control}
              // error={errors?.dateEnd?.message}
              component={(datepickerProps: IDatepicker) => <Datepicker {...datepickerProps} />}
            />
          </StyledDates>
        </StyledRow>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button
            isDisabled={!startDate || !endDate}
            isLoading={isLoading || isFilesLoading}
            type="submit"
          >
            Создать
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
