import { ICreateLeadFrom, ModalCreateRequest } from "@/features/requests";
import {
  ContactTypeEnum,
  LeadResourceEnum,
  useCreateLeadMutation,
  useUpdateCallMutation,
} from "@/shared/lib/graphql";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { CustomNotification } from "@/shared/ui";
import { useContext, type FC } from "react";

interface IModalCreateLeadFromCallProps {
  selectedCall: {
    callId: number;
    phone: string;
    managerId: number;
  };
  invalidateCalls: () => Promise<unknown>;
}

export const ModalCreateLeadFromCall: FC<IModalCreateLeadFromCallProps> = ({
  selectedCall,
  invalidateCalls,
}) => {
  const { mutate: createLead, isLoading: isLeadCreating } = useCreateLeadMutation();
  const { mutate: updateCall } = useUpdateCallMutation();
  const notify = useAddNotification();
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const handleCloseModal = () => {
    setModalContext((prev) => ({ ...prev, modalCreateRequest: false }));
  };

  const handleCreateLead = (data: ICreateLeadFrom) => {
    createLead(
      {
        input: selectedCall.managerId
          ? {
              contact: {
                name: data.name,
                surname: data.surname,
                value: data.phone,
                type: ContactTypeEnum.Phone,
              },
              lServiceId: data.lServiceId,
              clientId: data.clientId,
              managerId: selectedCall.managerId,
              resource: LeadResourceEnum.Call,
            }
          : {
              contact: {
                name: data.name,
                surname: data.surname,
                value: data.phone,
                type: ContactTypeEnum.Phone,
              },
              clientId: data.clientId,
              lServiceId: data.lServiceId,
              resource: LeadResourceEnum.Call,
            },
      },
      {
        onSuccess: (resp) => {
          handleCloseModal();
          updateCall(
            {
              id: selectedCall.callId,
              input: { leadId: resp.createLead.id },
            },
            {
              onSuccess: () => invalidateCalls(),
              onError: (error) => {
                notify((error as ErrorEvent)?.message, "error");
              },
            }
          );
          invalidateCalls();
          notify(
            <CustomNotification
              title="Заявка создана"
              link={{
                url: `/leads/${resp.createLead.id}`,
                linkTitle: "Перейти",
              }}
            />,
            "default"
          );
        },
      }
    );
  };

  return (
    <ModalCreateRequest
      isLoading={isLeadCreating}
      phone={selectedCall.phone}
      onSuccess={handleCreateLead}
      onCancel={() => handleCloseModal()}
    />
  );
};
