import { StatusToText } from "@/shared/helpers/const";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import React from "react";
import styled from "styled-components";
interface IRequestStatus {
  status: string;
  isSimple?: boolean;
}

const WrappedStatus = styled.div<{ color: string }>`
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: var(--color-${({ color }) => color});
  white-space: nowrap;
  width: fit-content;
`;
const Dot = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--color-${({ color }) => color});
`;

const STATUS = {
  new: { bgColor: "blue-100", textColor: "blue-200" },
  prefatory: {
    bgColor: "orange-100",
    textColor: "orange-200",
  },
  "to-be-agreed": {
    bgColor: "orange-100",
    textColor: "orange-200",
  },
  "agreed-by-client": {
    bgColor: "green-100",
    textColor: "green-200",
  },
  "prepayment-made": {
    bgColor: "green-100",
    textColor: "green-200",
  },
  "in-progress": {
    bgColor: "green-100",
    textColor: "green-200",
  },
  "report-loaded": {
    bgColor: "gray-200",
    textColor: "gray-500",
  },
  "payment-made": {
    bgColor: "green-100",
    textColor: "green-200",
  },
  "lead-closed": {
    bgColor: "gray-200",
    textColor: "gray-500",
  },
  successful: {
    bgColor: "green-100",
    textColor: "green-200",
  },
  agreed: {
    bgColor: "green-100",
    textColor: "green-200",
  },
  canceled: {
    bgColor: "red-100",
    textColor: "red-200",
  },
  "payment-waiting-client": { bgColor: "orange-100", textColor: "orange-200" },
  "payment-decline": { bgColor: "red-100", textColor: "red-200" },
};
export const RequestStatus: React.FC<IRequestStatus> = (props) => {
  const { isSimple = false, status } = props;
  return (
    <WrappedStatus color={isSimple ? "" : STATUS[status]?.bgColor}>
      <Dot color={STATUS[status]?.textColor} />
      <Paragraph size={12} color={isSimple ? "gray-700" : STATUS[status]?.textColor}>
        {StatusToText[status]}
      </Paragraph>
    </WrappedStatus>
  );
};
