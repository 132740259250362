import { useEffect, useState } from "react";
import { UseQueryOptions } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";

export type TCachedQuery<TReturnType, TVariablesType> = (
  variables: TVariablesType,
  options: UseQueryOptions
) => UseQueryResult<TReturnType>;

export const useCachedQuery = <TReturnType, TVariablesType = undefined>(
  query: TCachedQuery<TReturnType, TVariablesType>,
  variables?: TVariablesType
): UseQueryResult<TReturnType> => {
  const [isStale, setIsStale] = useState(false);

  const { isStale: isQueryStale, ...queryParams } = query.call(
    null,
    variables as TVariablesType,
    {
      staleTime: 5 * 60 * 1000,
      enabled: isStale,
    }
  );

  useEffect(() => {
    if (!isStale && isQueryStale) setIsStale(true);
  }, [isQueryStale]);

  return { ...queryParams, isStale: isQueryStale };
};
