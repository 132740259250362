import React from "react";
import styled from "styled-components";
import { CreateReportInput } from "@/shared/lib/graphql";
import { TReportField } from "@/shared/helpers/mocks";
import { Input } from "@yamaha-admin-sb/input";
import { getPlaceholderFromLabel } from "@/shared/helpers/utils";
import { Textarea } from "@yamaha-admin-sb/textarea";
import { ControlGroup } from "@/shared/ui";

interface IModalCreateReportSteps {
  data?: Partial<CreateReportInput>;
  fields: TReportField[];
  onChange: (
    name: keyof CreateReportInput,
    value: string | number | boolean | string[],
    variant?: "text" | "number"
  ) => void;
  withDisclaimer?: boolean;
}

const StyledModalCreateReportSteps = styled.div``;

const StyledTextarea = styled.div`
  margin-bottom: -6px;
  margin-top: 46px;
`;

const StyledDisclaimer = styled.div`
  font-family: var(--font-brand), sans-serif;
  font-weight: 400;
  color: var(--color-red);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
  margin-top: -10px;
`;

const ModalCreateReportSteps: React.VFC<IModalCreateReportSteps> = (props) => {
  const { data, fields, onChange, withDisclaimer } = props;

  const renderField = (field: TReportField): JSX.Element => {
    switch (field.type) {
      case "input":
        return (
          <Input
            name={field.valueField}
            label={field.label}
            placeholder={field.placeholder || getPlaceholderFromLabel(field.label)}
            type={field.variant}
            suffix={field.measurement}
            isBordered={true}
            key={field.valueField}
            value={(data?.[field.valueField] || null) as string}
            onChange={(value) => {
              onChange(field.valueField, value, field.variant || "text");
            }}
          />
        );
      case "text":
        return (
          <StyledTextarea>
            <Textarea
              name={field.valueField}
              label={field.label}
              placeholder={field.placeholder || "Введите текст"}
              value={data?.[field.valueField] as string}
              key={field.valueField}
              onChange={(value) => onChange(field.valueField, value)}
            />
          </StyledTextarea>
        );
      case "checkbox":
        return (
          <ControlGroup
            options={field.values}
            label={field.label}
            value={(data?.[field.valueField] as string[]) || []}
            onChange={(value) => onChange(field.valueField, value)}
            isMultiple={true}
            key={field.valueField}
          />
        );
      case "radio":
        return (
          <ControlGroup
            options={field.values}
            label={field.label}
            key={field.valueField}
            value={data?.[field.valueField] as string[]}
            onChange={(value) => onChange(field.valueField, value)}
          />
        );
    }

    return <></>;
  };

  return (
    <StyledModalCreateReportSteps>
      {fields.map((field, index) => (
        <div className="space-20" key={index}>
          {withDisclaimer && (
            <StyledDisclaimer>
              При продолженни с выбранным ответом "Да" все поля будут заполены средними значениями
            </StyledDisclaimer>
          )}
          {renderField(field)}
        </div>
      ))}
    </StyledModalCreateReportSteps>
  );
};

export default ModalCreateReportSteps;
