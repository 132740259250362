import React from "react";
import { Default } from "@/widgets/layouts";
import { Reminder } from "@/features/reminder";

const breads = [
  {
    title: "Рабочий день",
    clickable: false,
  },
];

const WorkDay: React.FC = () => {
  return (
    <Default breads={breads}>
      <Reminder />
    </Default>
  );
};

export default WorkDay;
