import React from "react";
import { Default } from "@/widgets/layouts";
import styled from "styled-components";
import { Logo } from "@/shared/ui";

interface IMain {
  path?: string | string[];
}

const StyledCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Main: React.FC<IMain> = () => {
  return (
    <Default breads={[]}>
      <StyledCenter>
        <Logo width={400} height={50} />
      </StyledCenter>
    </Default>
  );
};

export default Main;
