import React, { useEffect, useState } from "react";
import { ru } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import {
  StyledDatepicker,
  StyledHeader,
  StyledNavigationButton,
  StyledNavigationButtons,
  CustomInput,
  StyledIcon,
  StyledLabel,
} from "./Datepicker.style";
import { Icon } from "@yamaha-admin-sb/icon";

registerLocale("ru", ru);

export interface IDatepicker {
  label?: string;
  placeholder?: string;
  value?: string;
  isBlock?: boolean;
  isDisabled?: boolean;
  isBordered?: boolean;
  minDate?: Date;
  maxDate?: Date;
  hasTimeInput?: boolean;
  onInput?: (arg: string) => void;
  onInputOriginal?: (date: Date | null) => void;
  onTimeInput?: (time) => void;
}

const Datepicker: React.FC<IDatepicker> = (props): JSX.Element => {
  const {
    hasTimeInput,
    label,
    placeholder,
    value,
    isBlock = false,
    isDisabled = false,
    isBordered = false,
    minDate,
    maxDate,
    onTimeInput,
    onInput,
    onInputOriginal,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);

  useEffect(() => {
    if (!value) {
      setDate(null);
      return;
    }
    setDate(new Date(value));
  }, [value]);

  const handleCalendarOpen = (state: boolean) => {
    setIsOpen(state);
  };

  const handleDatePick = (newDate: Date) => {
    if (newDate) {
      setDate(newDate);
      if (!isNaN(newDate.getTime())) {
        if (onInput) {
          onInput(newDate.toLocaleDateString("ru-RU"));
          hasTimeInput &&
            onTimeInput &&
            onTimeInput(
              newDate.toLocaleTimeString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
              })
            );
        }
        if (onInputOriginal) onInputOriginal(newDate);
      }
    } else {
      setDate(null);
      if (onInput) onInput("");
      if (onInputOriginal) onInputOriginal(null);
    }
  };

  const ExampleCustomInput = (subProps: any) => {
    return (
      <>
        <InputMask
          name="date"
          mask="99.99.9999"
          {...subProps}
          onClick={() => ({})}
          onFocus={() => ({})}
        >
          {(inputProps: Record<string, unknown>) => (
            <CustomInput
              {...inputProps}
              isDisabled={isDisabled}
              isBordered={isBordered}
              value={date?.toLocaleDateString("ru-RU")}
            />
          )}
        </InputMask>
        <StyledIcon onClick={() => ({})} isOpen={isOpen} isDisabled={isDisabled}>
          <div onClick={subProps.onClick}>
            <Icon name="calendar" />
          </div>
        </StyledIcon>
      </>
    );
  };

  return (
    <StyledDatepicker isOpen={isOpen} isBlock={isBlock} isDisabled={isDisabled}>
      {label && <StyledLabel isDisabled={isDisabled}>{label}</StyledLabel>}
      <DatePicker
        selected={date}
        onChange={handleDatePick}
        placeholderText={placeholder}
        locale="ru"
        // todayButton="Сегодня"
        dateFormat="dd.MM.yyyy"
        timeInputLabel="Время:"
        isClearable={true}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={hasTimeInput}
        value={date?.toLocaleDateString("ru-RU")}
        customInput={<ExampleCustomInput />}
        onCalendarClose={handleCalendarOpen.bind(null, false)}
        onCalendarOpen={handleCalendarOpen.bind(null, true)}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          nextYearButtonDisabled,
          prevYearButtonDisabled,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <StyledHeader>
            <StyledNavigationButtons>
              <StyledNavigationButton>
                <button type="button" onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                  <Icon name="double-chevron-left" size={16} color="gray-700" />
                </button>
              </StyledNavigationButton>
              <StyledNavigationButton>
                <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  <Icon name="chevron-left" size={16} color="gray-700" />
                </button>
              </StyledNavigationButton>
            </StyledNavigationButtons>
            <span>
              {date.toLocaleString("ru-RU", { month: "long" })} {date.getFullYear()}
            </span>
            <StyledNavigationButtons>
              <StyledNavigationButton>
                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <Icon name="chevron-right" size={16} color="gray-700" />
                </button>
              </StyledNavigationButton>
              <StyledNavigationButton>
                <button type="button" onClick={increaseYear} disabled={nextYearButtonDisabled}>
                  <Icon name="double-chevron-right" size={16} color="gray-700" />
                </button>
              </StyledNavigationButton>
            </StyledNavigationButtons>
          </StyledHeader>
        )}
      />
    </StyledDatepicker>
  );
};

export { Datepicker };
