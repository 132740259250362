import { useGetMe } from "@/entities/session";
import { useCompleteLeadStageMutation, useUpdateLeadManagerMutation } from "@/shared/lib/graphql";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { Button } from "@yamaha-admin-sb/button";
import { useContext, type FC } from "react";

interface IAddManagerToLeadButtonProps {
  leadId: number;
  onLeadSelect: (leadId: number) => void;
  invalidateLeads?: () => Promise<unknown>;
}

export const AddManagerToLeadButton: FC<IAddManagerToLeadButtonProps> = ({
  leadId,
  onLeadSelect,
  invalidateLeads,
}) => {
  const { me } = useGetMe();
  const notify = useAddNotification();
  const [, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: updateLeadManager } = useUpdateLeadManagerMutation();
  const { mutate: completeLeadStage } = useCompleteLeadStageMutation();

  const handleToggleModal = () => {
    setModalContext((prev) => ({ ...prev, modalManagerAppointment: false }));
  };

  const handleAddManagerToLead = (managerId: number, leadId: number) => {
    updateLeadManager(
      {
        leadId,
        input: {
          managerId,
        },
      },
      {
        onSuccess: () => {
          completeLeadStage(
            { leadId },
            {
              onSuccess: async () => {
                handleToggleModal();
                notify("Менеджер назначен", "success");
                await invalidateLeads?.();
              },
              onError: () => notify("Ошибка", "error"),
            }
          );
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  return (
    <Button
      onClick={(event) => {
        if (me?.getMe.id) {
          event.stopPropagation();
          onLeadSelect(leadId);
          handleAddManagerToLead(me?.getMe.id, leadId);
        }
      }}
    >
      Взять себе
    </Button>
  );
};
