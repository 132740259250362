import React from "react";
import styled from "styled-components";

interface IContainer {
  children: JSX.Element | JSX.Element[];
}

const StyledContainer = styled.div`
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 32px;
  }

  @media (min-width: 1024px) {
    padding: 0 40px;
  }
`;

export const Container: React.VFC<IContainer> = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};
