import React, { useContext, useMemo, useState } from "react";
import { Container, WrappedTable, StyledTitle } from "@/shared/ui";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import {
  useGetVehicleModelsListQuery,
  VehicleModelsFilters,
  VehicleModelsPaginationInfo,
  VehicleModelsSortInfo,
} from "@/shared/lib/graphql";
import { PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { getCapitalized } from "@/shared/helpers/utils";
import { Button } from "@yamaha-admin-sb/button";
import { ModalContext } from "@/shared/lib/react";
import { ModalUploadTechnics } from "@/features/technics";

const breads = [
  {
    title: "Техника",
    clickable: false,
  },
];

const headers = [
  {
    Header: "Марка",
    accessor: "mark",
    isSortable: true,
  },
  {
    Header: "Модель",
    accessor: "model",
    isSortable: true,
  },
];

const Technics: React.FC = () => {
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const [pagination, setPagination] = useState<VehicleModelsPaginationInfo>(
    PAGINATION_DEFAULT_OPTIONS
  );

  const [sort, setSort] = useState<VehicleModelsSortInfo>({});
  const [filters, setFilters] = useState<VehicleModelsFilters>({});
  const prepareFilter = (filter: VehicleModelsFilters): VehicleModelsFilters => {
    const newFilter: VehicleModelsFilters = { ...filter };

    Object.keys(newFilter).forEach((item) => {
      if (!newFilter[item]) {
        delete newFilter[item];
      }
    });
    return newFilter;
  };

  const {
    data: models,
    isLoading,
    refetch,
  } = useGetVehicleModelsListQuery({
    input: { pagination, sort, filter: prepareFilter(filters) },
  });

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (models?.getVehicleModelsList?.meta?.take || pagination.take || 10),
    }));
  };
  const tablePagination = {
    page: Math.floor(
      (models?.getVehicleModelsList?.meta?.skip || 0) /
        (models?.getVehicleModelsList?.meta?.take || 1)
    ),
    count: Math.ceil((models?.getVehicleModelsList?.meta?.count || 0) / (pagination.take || 1)),
  };
  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const preparedTableData = useMemo(
    () =>
      models?.getVehicleModelsList?.data?.map((item) => ({
        mark: item.mark.name,
        model: item.name,
      })) || [],
    [models?.getVehicleModelsList]
  );

  return (
    <>
      <Default breads={breads}>
        <StyledTitle className="space-32">
          <Title level={2} color="gray-700">
            Техника
          </Title>
        </StyledTitle>
        <Container>
          <WrappedTable
            tableTitle="Вся техника"
            showMenu={false}
            headers={headers}
            data={preparedTableData || []}
            isLoading={isLoading}
            onSort={handleSort}
            onMenuItemSelected={() => ({})}
            search={[
              {
                placeholder: "Поиск по марке",
                onSearch: (value) => {
                  setFilters((prev) => ({ ...prev, markName: value }));
                },
              },
              {
                placeholder: "Поиск по модели",
                onSearch: (value) => {
                  setFilters((prev) => ({ ...prev, modelName: value }));
                },
              },
            ]}
            pagination={tablePagination}
            onPaginationClick={handlePaginationClick}
            rightBtn={() => (
              <Button
                variant="secondary"
                color="gray"
                leftIcon={true}
                icon="plus"
                onClick={() => {
                  setModalContext((prev) => ({
                    ...prev,
                    modalUploadTechnics: true,
                  }));
                }}
              >
                Добавить технику
              </Button>
            )}
          />
        </Container>
      </Default>
      {modalContext?.modalUploadTechnics ? <ModalUploadTechnics onSuccess={refetch} /> : <></>}
    </>
  );
};

export default Technics;
