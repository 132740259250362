import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Container, WrappedTable, StyledNoItem, Loader, Status } from "@/shared/ui";
import {
  GetCallsPagination,
  GetCallsSortInfo,
  RoleEnum,
  useGetCallsQuery,
  useUpdateCallMutation,
  Query,
  GetCallsOutputData,
} from "@/shared/lib/graphql";
import {
  IModalContextValue,
  ModalContext,
  useAddNotification,
  useQueryFilters,
} from "@/shared/lib/react";
import { useQueryClient } from "react-query";
import Cookies from "js-cookie";
import { PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { Title } from "@yamaha-admin-sb/title";
import { CallActionButton, CallManager } from "@/features/calls";
import { ModalCreateLeadFromCall } from "@/widgets/calls";

interface IClientCallsProps {
  clientId: number;
}

const headers = [
  {
    Header: "Номер",
    accessor: "phone",
    isSortable: false,
  },
  {
    Header: "Дата",
    accessor: "date",
    isSortable: true,
  },
  {
    Header: "Статус",
    accessor: "status",
    isSortable: false,
  },
  {
    Header: "Менеджер",
    accessor: "manager",
    isSortable: false,
  },
  {
    Header: "",
    accessor: "action",
    isSortable: false,
  },
];

export const ClientCalls: FC<IClientCallsProps> = ({ clientId }) => {
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [pagination] = useState<GetCallsPagination>(PAGINATION_DEFAULT_OPTIONS);
  const { selectedFilters } = useQueryFilters();
  const [sort] = useState<GetCallsSortInfo>({});

  const {
    data: calls,
    isLoading: isPageLoaded,
    refetch,
  } = useGetCallsQuery({
    input: {
      filter: {
        ...selectedFilters.filter,
        clientId,
      },
      pagination,
      sort,
    },
  });

  const handleCloseModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: false }));
  };
  const queryClient = useQueryClient();
  const { mutate: updateCall } = useUpdateCallMutation();
  const notify = useAddNotification();

  const [isMounted, setIsMounted] = useState(false);
  const me = queryClient.getQueryData<Pick<Query, "getMe">>("getMe", {
    predicate: (query) => query.queryHash === `getMe-${Cookies.get("accessToken")}`,
  }) as Pick<Query, "getMe">;

  const [editingCallId, setEditingCallId] = useState<number | undefined>();
  const [callUpdating, setCallUpdating] = useState<"self" | "other" | null>(null);

  const [selectedCall, setSelectedCall] = useState({
    callId: 0,
    phone: "",
    managerId: 0,
  });

  const handleOpenModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: true }));
  };

  const handleUpdateCall = (managerId: number, editingId?: number) => {
    if (!editingCallId && !editingId) return;

    updateCall(
      { id: (editingCallId || editingId) as number, input: { managerId } },
      {
        onSuccess: () => {
          setEditingCallId(undefined);
          handleCloseModal("modalManagerAppointment");
          notify("Менеджер назначен", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleAppointManager = (callId: number, isSelf: boolean = false) => {
    if (!isSelf) {
      setEditingCallId(callId);
      setCallUpdating("other");
      handleOpenModal("modalManagerAppointment");
    } else {
      setCallUpdating("self");
      handleUpdateCall(me?.getMe?.id, callId);
    }
  };

  const handleCreateRequest = (call: GetCallsOutputData) => {
    setSelectedCall({
      phone: call.from || "",
      managerId: call?.manager?.id || 0,
      callId: call.id,
    });
    handleOpenModal("modalCreateRequest");
  };

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, []);

  const userRole: any = me?.getMe.role?.slug;
  const canChangeManager = [RoleEnum.Director, RoleEnum.HeadOfMn].includes(userRole);

  const preparedTableData = useMemo(
    () =>
      calls?.getCalls?.data?.map((item) => ({
        ...item,
        phone: item?.from,
        date: new Date(item.createdAt).toLocaleString("ru-RU"),
        status: <Status status={item.status} />,
        manager: (
          <CallManager
            call={item as GetCallsOutputData}
            canChangeManager={canChangeManager}
            onAppointManager={handleAppointManager}
            isLoading={item.id === editingCallId && callUpdating === "self"}
          />
        ),
        action: (
          <CallActionButton
            call={item as GetCallsOutputData}
            onCreateRequestClick={handleCreateRequest}
          />
        ),
      })),
    [calls, me?.getMe]
  );

  return !isPageLoaded ? (
    <>
      <Container>
        <Title level={3} weight={500} color="gray-600">
          Звонки
        </Title>
        <WrappedTable
          showMenu={false}
          tableTitle="Все звонки"
          headers={headers}
          data={preparedTableData || []}
          onMenuItemSelected={() => ({})}
        />
      </Container>
      {modalContext?.modalCreateRequest && (
        <ModalCreateLeadFromCall selectedCall={selectedCall} invalidateCalls={refetch} />
      )}
    </>
  ) : (
    <StyledNoItem>
      <Loader size="l" />
    </StyledNoItem>
  );
};
