import React from "react";
import {
  ScheduleDayOutput,
  ScheduleDayTypeEnum,
  ScheduleUserFieldsFragment,
} from "@/shared/lib/graphql";
import { AvatarWithName, RoleBlock } from "@/shared/ui";
import { DotedNavigation } from "@yamaha-admin-sb/doted-navigation";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import {
  StyledAvatar,
  StyledCalendarItem,
  StyledDataCell,
  StyledDaysRow,
  StyledRow,
} from "./CalendarTable.style";
import { IHiddenNavigationItem } from "@yamaha-admin-sb/hidden-navigation";
import { useGetMe } from "@/entities/session";
import { ICalendarDay } from "./CalendarTable";
import CalendarTableCell from "./CalendarTableCell";
import { TEditingScheduleUser } from "../../model/interfaces";

interface ICalendarTableRow {
  user: ScheduleUserFieldsFragment;
  monthDays: ICalendarDay[];
  onEdit: (user: TEditingScheduleUser) => void;
  onDelete?: (user: ScheduleUserFieldsFragment) => void;
}

const generateDaysRow = (
  days: ScheduleDayOutput[],
  monthDays: ICalendarDay[]
): ScheduleDayOutput[] => {
  return monthDays.map((monthDay) => {
    const currentDay = days.find((day) => day.date === monthDay.date);

    return currentDay
      ? currentDay
      : {
          date: monthDay.date,
          hours: 0,
          type: ScheduleDayTypeEnum.Work,
        };
  });
};

const MENU_ITEMS: IHiddenNavigationItem[] = [
  {
    icon: "cross",
    title: "Убрать из графика",
    type: "gray",
    value: "remove",
  },
];

const UnProtectedActions = {
  removeUserFromSchedule: ["DIRECTOR", "ADMIN", "HEAD_OF_SP", "HEAD_OF_MN"],
  editDay: ["DIRECTOR", "ADMIN", "HEAD_OF_SP", "HEAD_OF_MN"],
};

const CalendarTableRow: React.VFC<ICalendarTableRow> = (props) => {
  const { user, monthDays, onEdit, onDelete } = props;
  const normalizedDays = generateDaysRow(user.days, monthDays);
  const overall = user.days.reduce((sum, current) => sum + (current?.hours || 0), 0);
  const { me } = useGetMe();

  return (
    <StyledRow>
      <StyledDataCell>
        <StyledAvatar>
          <AvatarWithName user={user} />
          {UnProtectedActions.removeUserFromSchedule.includes(me?.getMe.role?.slug || "") ? (
            <DotedNavigation
              items={MENU_ITEMS}
              isDisabled={false}
              isSquare={true}
              onSelected={() => (onDelete ? onDelete(user) : undefined)}
            />
          ) : (
            <></>
          )}
        </StyledAvatar>
      </StyledDataCell>
      <StyledDataCell>
        <RoleBlock role={user.role?.name || ""} />
      </StyledDataCell>
      <StyledDataCell>
        <StyledDaysRow>
          {normalizedDays.map((day, index) => (
            <StyledCalendarItem key={index}>
              <CalendarTableCell
                type={day.type}
                hours={day.hours || 0}
                onClick={() => {
                  if (UnProtectedActions.editDay.includes(me?.getMe.role?.slug || ""))
                    onEdit({ ...user, day });
                }}
              />
            </StyledCalendarItem>
          ))}
        </StyledDaysRow>
      </StyledDataCell>
      <StyledDataCell>
        <Paragraph size={12} color="gray-500">
          {overall}
        </Paragraph>
      </StyledDataCell>
    </StyledRow>
  );
};

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default React.memo(CalendarTableRow, areEqual);
