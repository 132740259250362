import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Title } from "@yamaha-admin-sb/title";
import { colors, media } from "@/shared/helpers";
import { Default } from "@/widgets/layouts";
import {
  CreateReportInput,
  UpdateReportData,
  PaymentTypeEnum,
  useCompleteLeadStageMutation,
  useGenerateLeadPageMutation,
  useGetLeadQuery,
  useGetReportQuery,
  useSetLeadStatusMutation,
  useSetLeadStatusSuccessMutation,
  useCreateLeadReportMutation,
  useDeleteLeadReportMutation,
  useUpdateLeadReportMutation,
  PaymentStatusEnum,
  useUpdateLeadLServiceMutation,
  useReturnCancelledLeadMutation,
  useSendLeadPageEmailMutation,
  ClientEntity,
  useGetRemindersQuery,
} from "@/shared/lib/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { useAddNotification, IModalContextValue, ModalContext } from "@/shared/lib/react";
import {
  Manager,
  ClientData,
  Payment,
  ServicePrice,
  Document,
  Expert,
  Report,
  TechnicsForm,
  ITechnicsDataTemplate,
  ModalLeadComment,
} from "@/features/leads";
import { LeadModals } from "./ui/LeadModals";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { ModalCreateReport } from "@/features/requests";
import { downloadURL } from "@/shared/helpers/utils";
import { StyledTitle, StyledNoItem, Loader, DottedMenu } from "@/shared/ui";
import { PaymentTypeToText } from "@/shared/helpers/const";
import { RequestStatusButton, StyledLaptopReverseHidden } from "@/entities/leads";
import { useGetMe } from "@/entities/session";
import { TextButton } from "@yamaha-admin-sb/text-button";
import {
  IHandleSuccess,
  ISelectedTechnic,
  ModalChangeLServiceTypeLead,
  TBlockSlug,
  ContractForm,
  ExpensesForm,
} from "@/features/leads";
import { SummaryFields } from "./ui/SummaryFields";
import { ModalReminder } from "@/features/reminder";

const PREFACTORY_STATUS = "prefatory";
const TO_BE_AGREED_STATUS = "to-be-agreed";
const CAR_SELECTION_SLUG = "car-selection";
const DIAGNOSTIC_SLUG = "offsite-diagnostics";

const StyledMainFrom = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1 1 100%;
`;

const WrappedButtons = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(2, 200px);
  margin-top: 32px;
  padding: 0 40px;
`;
const StyledTopStatus = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  align-items: flex-end;
  border-bottom: 1px solid ${colors.gray200};
  ${media.tablet} {
    align-items: center;
    padding: 12px 32px;
  }
  ${media.laptop} {
    display: none;
  }
`;

const StyledOrderBlock = styled.div<{ order?: number }>`
  ${({ order }) => `
    order: ${order};
  `}
`;

const StyledStatusButtons = styled.div`
  width: 100%;
  ${media.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      margin-bottom: 0;
    }
  }
`;

const StyledMenuItem = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 6px 40px rgba(22, 35, 70, 0.1);
  border-radius: 4px;
`;

const LeadAccessTemplate = {
  HEAD_OF_MN: {
    copyLead: true,
    expenses: false,
    prepayment: true,
    canComplete: true,
  },
  HEAD_OF_SP: {
    copyLead: false,
    expenses: false,
    prepayment: true,
    canComplete: true,
  },
  DIRECTOR: { canComplete: true },
  MANAGER: { copyLead: true, expenses: false, prepayment: true },
  SN_MANAGER: { copyLead: true, expenses: false, prepayment: true },
  SPECIALIST: { copyLead: false, expenses: true, prepayment: false },
};

const blockEnums = {
  Manager: "manager", // Назначение менеджера на звонок
  ClientData: "clientdata", // Данные клиента
  TechnicsForm: "technicsform", // Детали автоподбора
  ServicePrice: "serviceprice", // Стоимость услуги
  ContractForm: "contractform", // Данные для договора
  Document: "document", // Данные для договора
  Prepayment: "prepayment", // Предоплата
  Expert: "expert", // Данные об осмотре
  Payment: "payment", // Оплата
  Report: "report", // Создание отчета
  ExpensesForm: "expensesform", // Затраты на выезд
};

const defaultSort = [
  blockEnums.Manager,
  blockEnums.ClientData,
  blockEnums.TechnicsForm,
  blockEnums.ServicePrice,
  blockEnums.ContractForm,
  blockEnums.Document,
  blockEnums.Prepayment,
  blockEnums.Expert,
  blockEnums.Report,
  blockEnums.ExpensesForm,
  blockEnums.Payment,
];

const sortParams = {
  [DIAGNOSTIC_SLUG]: [
    blockEnums.Manager,
    blockEnums.ClientData,
    blockEnums.TechnicsForm,
    blockEnums.Expert,
    blockEnums.ServicePrice,
    blockEnums.ContractForm,
    blockEnums.Document,
    blockEnums.Prepayment,
    blockEnums.Payment,
    blockEnums.Report,
    blockEnums.ExpensesForm,
  ],
  [CAR_SELECTION_SLUG]: defaultSort,
  default: defaultSort,
};

const LeadEdit: React.FC = () => {
  const { leadId = 0 } = useParams();
  const navigate = useNavigate();
  if (!leadId || isNaN(+leadId)) {
    navigate("/404");
    // return <></>;
  }

  const [isCompletedBlock, setIsCompletedBlock] = useState<{
    [key in TBlockSlug]: Boolean;
  }>({
    manager: false,
    client: false,
    technics: false,
    specialist: false,
    servicePrice: false,
  });
  const [selectedTechnic, setSelectedTechnic] = useState<ISelectedTechnic>();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [selectedReportId, setSelectedReportId] = useState(-1);
  const { data: report } = useGetReportQuery(
    {
      leadId: +leadId,
      input: {
        reportId: selectedReportId,
      },
    },
    {
      enabled: selectedReportId !== -1,
    }
  );

  useEffect(() => {
    if (!report) return;

    setModalContext((prev) => ({ ...prev, modalCreateReport: true }));
  }, [report]);

  const notify = useAddNotification();
  const { data, isLoading, refetch: updateLead } = useGetLeadQuery({ id: +leadId });
  const { mutate: setLeadStatusSuccess, isLoading: issStLeadStatusSuccessLoading } =
    useSetLeadStatusSuccessMutation();

  const { mutate: completeLeadStage } = useCompleteLeadStageMutation();
  const { mutate: setLeadStatus } = useSetLeadStatusMutation();
  const { mutate: setReturnCanceledLead } = useReturnCancelledLeadMutation();

  const { mutate: generateLeadPage, isLoading: isLinkSending } = useGenerateLeadPageMutation();

  const { mutate: sendLeadPageEmail, isLoading: isLinkReSending } = useSendLeadPageEmailMutation();
  const { mutate: createReport, isLoading: isReportCreating } = useCreateLeadReportMutation();
  const { mutate: updateReport, isLoading: isReportUpdating } = useUpdateLeadReportMutation();
  const { mutate: updateLServiceType, isLoading: isLeadUpdating } = useUpdateLeadLServiceMutation();

  const { mutate: deleteReport } = useDeleteLeadReportMutation();
  const { me } = useGetMe();

  const isHaveSpecialist = !!data?.getLead?.specialist?.id;
  const leadlSType = data?.getLead?.lService?.slug || ""; // Тип заявки в данном случае

  const breads = [
    {
      title: "Заявки",
      clickable: true,
      path: "/leads",
    },
    {
      title: `Заявка  ${data?.getLead ? data?.getLead.num : ""}`,
      clickable: false,
    },
  ];

  const handleError = (error: ErrorEvent) => {
    notify(error?.message, "error");
  };

  const getOrderOfBlock = (blockName: string) => {
    const serviceType: string = leadlSType || "default";
    if (!blockName || !sortParams[serviceType]) {
      return 0;
    }

    return Object.values(sortParams[serviceType]).findIndex((key, i) => {
      return sortParams[serviceType][i] === blockName;
    });
  };

  const handleReturnCanceledLead = () => {
    setReturnCanceledLead(
      { leadId: +leadId },
      {
        onSuccess: () => {
          updateLead();
        },
      }
    );
  };

  const handleSuccess = ({
    text,
    disableCompleteStage,
    blockSlug,
    nextStatusId = null,
  }: IHandleSuccess) => {
    notify(text, "success");
    if (blockSlug) {
      setIsCompletedBlock((prev) => ({ ...prev, [blockSlug]: true }));
    }

    if (!disableCompleteStage) {
      completeLeadStage(
        { leadId: +leadId },

        {
          onSuccess: () => {
            if (!nextStatusId) {
              updateLead();
              return;
            }

            setLeadStatus(
              {
                leadId: +leadId,
                input: {
                  statusId: nextStatusId,
                },
              },
              {
                onSuccess: () => {
                  updateLead();
                },
                onError: (error) => {
                  handleError(error as ErrorEvent);
                },
              }
            );
          },
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    } else {
      updateLead();
    }
  };

  const sendCredsOnEmail = () => {
    sendLeadPageEmail(
      { leadId: +leadId },
      {
        onSuccess: () => {
          notify("Доступы отправлены", "success");
        },
        onError: (error) => {
          handleError(error as ErrorEvent);
        },
      }
    );
  };

  const handleNextStatus = (nextStatusId: number) => {
    setLeadStatus(
      {
        leadId: +leadId,
        input: {
          statusId: nextStatusId,
        },
      },
      {
        onSuccess: () => {
          notify("Статус обновлен", "success");
          updateLead();
        },
        onError: (error) => {
          handleError(error as ErrorEvent);
          updateLead();
        },
      }
    );
  };

  const handleToggleModal = (modalName: keyof IModalContextValue, flag: boolean) => {
    setModalContext((prev) => ({ ...prev, [modalName]: flag }));
  };

  const handleSetLeadStatusSuccess = () => {
    setLeadStatusSuccess(
      {
        leadId: +leadId,
        input: {
          statusId: 10,
        },
      },
      {
        onSuccess: () => {
          notify("Заявка успешно завершена", "success");
          updateLead();
        },
        onError: (error) => {
          handleError(error as ErrorEvent);
          updateLead();
        },
      }
    );
  };

  const handleGenerageLeadPage = () => {
    if (leadlSType === DIAGNOSTIC_SLUG) {
      sendLinkToProfile();
      return;
    }

    if (currentStatus === PREFACTORY_STATUS) {
      const contractNextStatus = data?.getLead?.leadStages?.find(
        (item) => item?.stage?.next === `${leadType}-contract`
      )?.stage?.nextStatus;

      if (contractNextStatus?.slug === TO_BE_AGREED_STATUS) {
        setLeadStatus(
          {
            leadId: +leadId,
            input: {
              statusId: contractNextStatus?.id,
            },
          },
          {
            onSuccess: () => {
              sendLinkToProfile();
            },
            onError: (error) => {
              handleError(error as ErrorEvent);
            },
          }
        );
      }
      return;
    }
    sendLinkToProfile();
  };

  const sendLinkToProfile = () => {
    generateLeadPage(
      {
        leadId: +leadId,
      },
      {
        onSuccess: () => {
          notify("Ссылка на личный кабинет отправлена", "success");
          updateLead();
        },
        onError: (error) => {
          handleError(error as ErrorEvent);
          updateLead();
        },
      }
    );
  };

  const handleReportCreate = (
    reportData: CreateReportInput | UpdateReportData,
    disableCompleteStage: boolean
  ) => {
    if (selectedReportId !== -1)
      updateReport(
        {
          leadId: Number(leadId),
          input: {
            shouldGenerate: true,
            id: Number(selectedReportId),
            report: reportData as UpdateReportData,
          },
        },
        {
          onSuccess: () => {
            setSelectedReportId(-1);
            setModalContext((prev) => ({ ...prev, modalCreateReport: false }));
            handleSuccess({
              text: "Отчет изменен",
              disableCompleteStage: true,
            });
          },
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    else
      createReport(
        {
          leadId: Number(leadId),
          input: reportData as CreateReportInput,
        },

        {
          onSuccess: (createdReportInfo) => {
            updateLead();
            setModalContext((prev) => ({ ...prev, modalCreateReport: false }));
            handleSuccess({
              text: "Отчет создан",
              disableCompleteStage: !!data?.getLead?.reports?.length,
            });

            if (leadlSType === CAR_SELECTION_SLUG) {
              sendReportToClient(createdReportInfo?.createLeadReport?.id);
            }
          },
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
  };

  const sendReportToClient = (reportId: number) => {
    updateReport(
      {
        leadId: +leadId,
        input: {
          id: reportId,
          shouldGenerate: false,
          report: { isPublic: true },
        },
      },
      {
        onSuccess: () => {
          setSelectedReportId(-1);
          updateLead();
          notify("Отчет отправлен клиенту", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleDownloadClick = async (id: number) => {
    const link = data?.getLead?.reports?.find((rep) => rep.id === id)?.fileUrl;

    if (!link) return;

    const filename = link.split("/").pop() as string;

    await downloadURL(link, filename);
  };

  const handleDeleteReportClick = async (id: number) => {
    await deleteReport(
      {
        leadId: +leadId,
        input: {
          reportId: id,
        },
      },
      {
        onSuccess: () => {
          notify("Отчет удален", "success");
          updateLead();
        },
      }
    );
  };

  const isOffsiteDiagnostics = data?.getLead.lService?.slug === "offsite-diagnostics";
  const leadType = data?.getLead.lService?.slug === "offsite-diagnostics" ? "os" : "cs";

  const isClientCompleted = useMemo(
    () =>
      data?.getLead?.leadStages?.find((item) => item?.stage?.slug === `${leadType}-contact-details`)
        ?.isCompleted,
    [data?.getLead, leadType]
  );

  const currentStatus: any =
    data?.getLead?.leadStatuses && data?.getLead?.leadStatuses[0].status?.slug;

  const requestStatusButtons = () =>
    data ? (
      <RequestStatusButton
        lead={data}
        leadType={leadType}
        isOffsiteDiagnostics={isOffsiteDiagnostics}
        onNextStatusClick={handleNextStatus}
      />
    ) : (
      <></>
    );

  const contactDetailsNextStatus = useMemo(
    () =>
      data?.getLead?.leadStages?.find((item) => item?.stage?.next === `${leadType}-contact-details`)
        ?.stage?.nextStatus,
    [data?.getLead, leadType]
  );

  const isShowEmailSendButton =
    currentStatus !== "new" &&
    currentStatus !== "prefatory" &&
    currentStatus !== "successful" &&
    currentStatus !== "canceled" &&
    currentStatus !== "payment-decline";

  const renderSummary = () =>
    data ? (
      <SummaryFields
        data={data}
        currentStatus={currentStatus}
        isOffsiteDiagnostics={isOffsiteDiagnostics}
        setSelectedTechnic={setSelectedTechnic}
        handleToggleModal={handleToggleModal}
        setMainContact={updateLead}
        renderStatusBtn={requestStatusButtons}
        reSendEmailLink={sendCredsOnEmail}
        isLinkReSending={isLinkReSending}
        isShowEmailSendButton={isShowEmailSendButton}
      />
    ) : (
      <></>
    );
  const statusesBeforeAgreed = ["new", "prefatory", "to-be-agreed", "agreed-by-client"];

  const isShowExpertBlock = leadlSType !== CAR_SELECTION_SLUG;
  const isEnabledGeneralPaymentBlock =
    leadlSType === CAR_SELECTION_SLUG ? !statusesBeforeAgreed.includes(currentStatus) : true;

  const isPaymentStatus = data?.getLead.leadStages?.some(
    (stage) => stage.stage?.slug === `${leadType}-payment-details`
  );

  const { refetch: updateLeadReminders } = useGetRemindersQuery({
    input: {
      filter: {
        leadId: +leadId,
      },
    },
  });

  return (
    <>
      <Default breads={breads}>
        {!isLoading ? (
          data?.getLead && !isLoading ? (
            <>
              <StyledTitle>
                <Title level={2} weight={500} color="gray-700">
                  Заявка №{data?.getLead.num}
                </Title>
                <TextButton
                  leftIcon={true}
                  onClick={() => handleToggleModal("modalChangeLServiceTypeLead", true)}
                  icon="arrow-reload"
                  isDisabled={isPaymentStatus}
                >
                  Изменить тип заявки
                </TextButton>
                {!!LeadAccessTemplate[me?.getMe.role?.slug || ""]?.copyLead ? (
                  <Button onClick={() => handleToggleModal("modalCopyLead", true)}>
                    Скопировать заявку
                  </Button>
                ) : (
                  <></>
                )}
              </StyledTitle>
              <StyledTopStatus>
                <StyledStatusButtons>{requestStatusButtons()}</StyledStatusButtons>

                <DottedMenu>
                  <StyledMenuItem>{renderSummary()}</StyledMenuItem>
                </DottedMenu>
              </StyledTopStatus>
              <div className="display-flex">
                {data.getLead.leadStatuses &&
                data?.getLead?.leadStatuses[0]?.status?.slug !== "canceled" ? (
                  <StyledMainFrom>
                    {!data.getLead?.manager?.id || isCompletedBlock.manager ? (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.Manager)}>
                        <Manager
                          isCompleted={!!data.getLead?.manager?.id}
                          leadId={data.getLead.id}
                          hasData={!!data?.getLead.manager}
                          handleError={handleError}
                          handleSuccess={handleSuccess}
                        />
                      </StyledOrderBlock>
                    ) : (
                      <></>
                    )}
                    {!isClientCompleted || isCompletedBlock.client ? (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.ClientData)}>
                        <ClientData
                          isCompleted={!!isClientCompleted}
                          handleError={handleError}
                          handleSuccess={handleSuccess}
                          leadId={data.getLead.id}
                          nextStatus={contactDetailsNextStatus}
                          currentStatus={currentStatus || ""}
                          clientContact={data.getLead.client as ClientEntity}
                        />
                      </StyledOrderBlock>
                    ) : (
                      <></>
                    )}
                    {!data.getLead.leadStages?.find(
                      (item) =>
                        !item.isCompleted &&
                        item.stage?.slug !== "cs-car-selection-details" &&
                        item.stage?.slug !== "os-offsite-diagnostic-details"
                    ) || isCompletedBlock.technics ? (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.TechnicsForm)}>
                        <TechnicsForm
                          isOffsiteDiagnostics={isOffsiteDiagnostics}
                          lsType={data.getLead.lSType}
                          leadId={+leadId}
                          technicsData={data?.getLead?.technics as ITechnicsDataTemplate[]}
                          isFilled={
                            !!data.getLead.leadStages?.find(
                              (item) =>
                                item.isCompleted &&
                                (item.stage?.slug === "cs-car-selection-details" ||
                                  item.stage?.slug === "os-offsite-diagnostic-details")
                            )
                          }
                          handleError={handleError}
                          handleSuccess={handleSuccess}
                        />
                      </StyledOrderBlock>
                    ) : (
                      <></>
                    )}
                    {!data.getLead.leadStages?.find(
                      (item) => item.stage?.slug === `${leadType}-payment-details`
                    )?.isCompleted || isCompletedBlock.servicePrice ? (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.ServicePrice)}>
                        <ServicePrice
                          toPaid={data.getLead.toPaid}
                          leadId={+leadId}
                          isCompleted={
                            !!data.getLead.leadStages?.find(
                              (item) => item.stage?.slug === `${leadType}-payment-details`
                            )?.isCompleted
                          }
                          leadlSType={leadlSType}
                          currentStatus={currentStatus || ""}
                          handleError={handleError}
                          handleSuccess={handleSuccess}
                          isEnabled={
                            !!data?.getLead?.leadStages &&
                            !!data.getLead.leadStages.find(
                              (item) => item.stage?.slug === `${leadType}-payment-details`
                            )
                          }
                        />
                      </StyledOrderBlock>
                    ) : (
                      <></>
                    )}
                    {!data.getLead.contracts?.length ? (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.ContractForm)}>
                        <ContractForm
                          isLinkSending={isLinkSending}
                          handleSuccess={handleSuccess}
                          sendLink={handleGenerageLeadPage}
                          lead={data}
                          isEnabled={
                            (leadlSType !== CAR_SELECTION_SLUG ? isHaveSpecialist : true) &&
                            !!data?.getLead?.leadStages &&
                            !!data.getLead.leadStages.find(
                              (item) => item.stage?.slug === `${leadType}-contract`
                            )
                          }
                          showForm={
                            currentStatus === "agreed-by-client" ||
                            !!data.getLead.contact?.passportCode
                          }
                          enableGenerateContract={currentStatus === "agreed-by-client"}
                        />
                      </StyledOrderBlock>
                    ) : (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.Document)}>
                        <Document
                          isCompleted={
                            !!data.getLead.leadStages?.find(
                              (item) =>
                                item.stage?.slug === `${leadType}-contract` &&
                                item.isCompleted &&
                                item.isFilled
                            )
                          }
                          isAgreed={currentStatus === "agreed"}
                          contracts={data.getLead.contracts}
                          leadId={+leadId}
                          handleError={handleError}
                          handleSuccess={handleSuccess}
                        />
                      </StyledOrderBlock>
                    )}
                    {!isOffsiteDiagnostics &&
                    !!LeadAccessTemplate[me?.getMe.role?.slug || ""]?.prepayment ? (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.Prepayment)}>
                        <Payment
                          onReturnClick={() => handleToggleModal("modalPrepaymentReturn", true)}
                          totalAmount={10000}
                          currentAmount={data.getLead.payments?.reduce(
                            (acc, item) =>
                              item.type === PaymentTypeEnum.Prepayment ? acc + item.amount : acc,
                            0
                          )}
                          leadId={+leadId}
                          paymentType={PaymentTypeEnum.Prepayment}
                          handleError={handleError}
                          handleSuccess={handleSuccess}
                          manager={`${data.getLead.manager?.name} ${data.getLead.manager?.surname}`}
                          payments={data.getLead.payments?.find(
                            (item) => item.type === PaymentTypeEnum.Prepayment
                          )}
                          isEnabled={
                            !!data?.getLead?.leadStages &&
                            !!data.getLead.leadStages.find(
                              (item) => item.stage?.slug === `${leadType}-prepayment`
                            )
                          }
                        />
                      </StyledOrderBlock>
                    ) : (
                      <></>
                    )}
                    {isShowExpertBlock &&
                    (!data.getLead.specialist?.id || isCompletedBlock.specialist) ? (
                      <StyledOrderBlock order={getOrderOfBlock(blockEnums.Expert)}>
                        <Expert
                          isCompleted={!!data.getLead.specialist?.id || !!data.getLead?.departure}
                          leadId={+leadId}
                          handleError={handleError}
                          handleSuccess={handleSuccess}
                          isOffsiteDiagnostics={isOffsiteDiagnostics}
                          disableCompleteStage={
                            !!data?.getLead?.leadStages &&
                            ![
                              `${leadType}-specialist-appointment`,
                              `${leadType}-report-loaded `,
                            ].includes(data?.getLead?.leadStages[0].stage?.slug || "")
                          }
                        />
                      </StyledOrderBlock>
                    ) : (
                      <></>
                    )}
                    <StyledOrderBlock order={getOrderOfBlock(blockEnums.Payment)}>
                      <Payment
                        isOffsiteDiagnostics={isOffsiteDiagnostics}
                        totalAmount={data.getLead.toPaid || 0}
                        currentAmount={data.getLead.payments?.reduce(
                          (acc, item) => acc + item.amount,
                          0
                        )}
                        leadId={+leadId}
                        paymentType={PaymentTypeEnum.Payment}
                        payments={data.getLead.payments?.find(
                          (item) => item.type === PaymentTypeEnum.Payment
                        )}
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                        manager={`${data.getLead.specialist?.name} ${data.getLead.specialist?.surname}`}
                        onReturnClick={() => {
                          handleToggleModal("modalPaymentReturn", true);
                        }}
                        isEnabled={isEnabledGeneralPaymentBlock}
                      />
                    </StyledOrderBlock>

                    <StyledOrderBlock order={getOrderOfBlock(blockEnums.Report)}>
                      <Report
                        isOffsiteDiagnostics={isOffsiteDiagnostics}
                        enableImageLoad={[
                          "in-progress",
                          "report-loaded",
                          "payment-made",
                          "successful",
                        ].includes(currentStatus || "")}
                        leadId={+leadId}
                        reports={data?.getLead?.reports || undefined}
                        onDelete={(id) => handleDeleteReportClick(id)}
                        onEdit={setSelectedReportId}
                        handleReportCreate={() => {
                          setSelectedReportId(-1);
                          setModalContext((prevState) => ({
                            ...prevState,
                            modalCreateReport: true,
                          }));
                        }}
                        updateLead={updateLead}
                        isDisabled={
                          !!data.getLead.leadStatuses[0].status &&
                          !["in-progress"].includes(data.getLead.leadStatuses[0].status?.slug)
                        }
                      />
                    </StyledOrderBlock>

                    <StyledOrderBlock order={getOrderOfBlock(blockEnums.ExpensesForm)}>
                      <ExpensesForm
                        endoscopy={{
                          endoscopyWithClient: data.getLead.endoscopyWithClient,
                          endoscopyWithoutClient: data.getLead.endoscopyWithoutClient,
                        }}
                        leadId={+leadId}
                        isEnabled={!!LeadAccessTemplate[me?.getMe.role?.slug || ""]?.expenses}
                        isOffsiteDiagnostics={isOffsiteDiagnostics}
                        handleSuccess={handleSuccess}
                        handleError={handleError}
                      />
                    </StyledOrderBlock>
                    {data.getLead.leadStatuses[0].status?.slug === "lead-closed" ? (
                      <WrappedButtons>
                        {!!LeadAccessTemplate[me?.getMe.role?.slug || ""]?.canComplete && (
                          <Button
                            onClick={handleSetLeadStatusSuccess}
                            isBlock={true}
                            isLoading={issStLeadStatusSuccessLoading}
                          >
                            Завершить
                          </Button>
                        )}

                        <Button
                          color="red"
                          onClick={() => handleToggleModal("modalCancelLead", true)}
                          isBlock={true}
                        >
                          Отменить заявку
                        </Button>
                      </WrappedButtons>
                    ) : (
                      <></>
                    )}
                  </StyledMainFrom>
                ) : (
                  <StyledNoItem>
                    <div className="display-flex fld-c ta-c">
                      <div className="space-16">
                        <Paragraph size={16} color="gray-500">
                          Заявка была отменена
                        </Paragraph>
                      </div>
                      <div className="space-16">
                        <Paragraph size={16} color="gray-500">
                          Комментарий отмены:
                        </Paragraph>
                        <Paragraph size={16} color="gray-500">
                          {`${data.getLead?.leadStatuses?.[0]?.comment}`}
                        </Paragraph>
                      </div>
                      <div className="space-16">
                        <Button color="blue" onClick={handleReturnCanceledLead} isBlock={true}>
                          Вернуть в работу
                        </Button>
                      </div>
                      {data.getLead.payments?.map((item) =>
                        item.status === PaymentStatusEnum.Refunded ? (
                          <>
                            <Paragraph size={16} color="gray-500">
                              {`${PaymentTypeToText[item.type]}а возвращена`}
                            </Paragraph>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </StyledNoItem>
                )}
                <StyledLaptopReverseHidden>{renderSummary()}</StyledLaptopReverseHidden>
              </div>
            </>
          ) : (
            <></>
          )
        ) : (
          <StyledNoItem>
            <Loader size="l" />
          </StyledNoItem>
        )}
      </Default>
      {data?.getLead ? (
        <LeadModals
          lead={data}
          selectedTechnic={selectedTechnic}
          isOffsiteDiagnostics={isOffsiteDiagnostics}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        <></>
      )}
      {modalContext?.modalChangeLServiceTypeLead && data?.getLead && (
        <ModalChangeLServiceTypeLead lead={data} onSuccess={updateLead} />
      )}
      {modalContext?.modalReminder && (
        <ModalReminder leadId={+leadId} onSuccess={updateLeadReminders} />
      )}
      {modalContext?.modalLeadComment && (
        <ModalLeadComment
          leadId={+leadId}
          comment={data?.getLead?.comment}
          handleSuccess={updateLead}
        />
      )}
      {modalContext?.modalCreateReport ? (
        <ModalCreateReport
          data={report?.getReport}
          isLoading={isReportCreating || isReportUpdating}
          onSuccess={handleReportCreate}
          onCancel={() =>
            setModalContext((prevState) => ({
              ...prevState,
              modalCreateReport: false,
            }))
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LeadEdit;
