import { ModalContext } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import { ModalBody, ModalFooter, Modal, ModalTop } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import {
  CreateReportInput,
  ReportOutputFieldsFragment,
  UpdateReportData,
} from "@/shared/lib/graphql";
import { WithoutNull } from "@/shared/helpers/interfaces";
import { MockReportCreateSteps } from "@/shared/helpers/mocks";
import { ModalConfirm } from "@/shared/ui";
import { defaultValuesIfApproved } from "@/shared/helpers/defaultValues";
import { ICreateReportInputRequired } from "../../model/interfaces";
import ModalCreateReportFirstStep from "./components/ModalCreateReportFirstStep";
import ModalCreateReportSteps from "./components/ModalCreateReportSteps";

export interface IModal {
  data?: ReportOutputFieldsFragment;
  isLoading?: boolean;
  onSuccess: (data: CreateReportInput | UpdateReportData, disableCompleteStage: boolean) => void;
  onCancel: () => void;
}

export const ModalCreateReport: React.FC<IModal> = (props): JSX.Element => {
  const { data, isLoading, onSuccess } = props;
  const [_, setModalContext = () => ({})] = useContext(ModalContext);
  const [step, setStep] = useState(1);
  const [formRequired, setFormRequired] = useState<ICreateReportInputRequired>({
    declaredPrice: data?.declaredPrice || null,
    engine: data?.engine || "",
    engineVolume: data?.engineVolume || null,
    finalPrice: data?.finalPrice || null,
    gearbox: data?.gearbox || null,
    manufactureYear: data?.manufactureYear || null,
    mark: data?.mark || "",
    mileage: data?.mileage || null,
    model: data?.model || "",
    vin: data?.vin || "",
  });
  const [form, setForm] = useState<Partial<CreateReportInput>>(data as Partial<CreateReportInput>);

  useEffect(() => {
    setForm(data as Partial<CreateReportInput>);
    setFormRequired({
      declaredPrice: data?.declaredPrice || null,
      engine: data?.engine || "",
      engineVolume: data?.engineVolume || 0,
      finalPrice: data?.finalPrice || null,
      gearbox: data?.gearbox || null,
      manufactureYear: data?.manufactureYear || null,
      mark: data?.mark || "",
      mileage: data?.mileage || null,
      model: data?.model || "",
      vin: data?.vin || "",
    });
  }, [data]);
  const stepLength = MockReportCreateSteps.length + 1;
  const stepTitle = `Шаг ${step} из ${stepLength}: ${
    step === 1 ? "Информация об автомобиле" : MockReportCreateSteps[step - 2]?.title
  }`;

  const handleChangeRequired = (
    key: string,
    value: string | number,
    variant?: "text" | "number"
  ) => {
    setFormRequired((prev) => ({
      ...prev,
      [key]:
        variant === "number"
          ? // eslint-disable-next-line no-new-wrappers
            value === ""
            ? null
            : Number(value)
          : typeof value === "string" && !isNaN(+value) && variant !== "text" && value !== ""
          ? +value
          : value,
    }));
  };

  const handleChange = (
    key: string,
    value: string | number | boolean | string[],
    variant?: "text" | "number"
  ) => {
    if (key === "isRecommended" && value) {
      setForm(defaultValuesIfApproved as any);
      return;
    }
    setForm((prev) => ({
      ...prev,
      [key]:
        variant === "number"
          ? // eslint-disable-next-line no-new-wrappers
            value === ""
            ? null
            : Number(value)
          : typeof value === "string" && !isNaN(+value) && variant !== "text" && value !== ""
          ? +value
          : value,
    }));
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      confirmModal: true,
    }));
    // setModalContext((prevState) => ({
    //   ...prevState,
    //   modalCreateReport: false,
    // }));
  };

  const handleBackClick =
    step === 1
      ? undefined
      : () => {
          setStep((prevState) => prevState - 1);
        };

  const handleContinueClick = () => {
    if (step < stepLength) setStep((prevState) => prevState + 1);
    else
      onSuccess(
        {
          ...form,
          ...(formRequired as WithoutNull<ICreateReportInputRequired>),
        },
        !!data
      );
  };

  return (
    <Modal name="modalCreateReport" onClose={handleButtonUndoClick}>
      <ModalTop
        title="Отчет специалиста"
        text={stepTitle}
        onBackClick={handleBackClick}
        onClose={handleButtonUndoClick}
      />
      {step === 1 ? (
        <ModalCreateReportFirstStep
          data={formRequired}
          onCancel={handleButtonUndoClick}
          onSubmit={handleContinueClick}
          onChange={handleChangeRequired}
        />
      ) : (
        <>
          <ModalBody>
            <ModalCreateReportSteps
              data={form}
              fields={MockReportCreateSteps[step - 2].fields}
              withDisclaimer={MockReportCreateSteps[step - 2].withDisclaimer}
              onChange={handleChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
              Отменить
            </Button>
            <Button isLoading={isLoading} onClick={handleContinueClick}>
              {step === 13 ? "Сохранить" : "Далее"}
            </Button>
          </ModalFooter>
        </>
      )}
      <ModalConfirm
        title="Вы действительно хотите завершить редактирование?"
        text="Все несохраненные данные будут "
        cancelButtonText="Отменить"
        confirmButtonText="Да, завершить"
        icon={undefined}
        confirmButtonColor="blue"
        onCancel={() => setModalContext((prev) => ({ ...prev, confirmModal: false }))}
        onConfirm={() => {
          setModalContext((prev) => ({ ...prev, modalCreateReport: false }));
          setModalContext((prev) => ({ ...prev, confirmModal: false }));
        }}
      />
    </Modal>
  );
};
