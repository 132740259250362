import React, { useContext, useEffect, useState } from "react";
import { Input, IInput } from "@yamaha-admin-sb/input";
import { ModalBody, ModalFooter, Controller, ModalTop, Modal } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import { positiveNumberPriceFormatValidationRule } from "@/shared/helpers/const";
import { toFormatPrice } from "@/shared/helpers/utils";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import {
  CreatePremiumBcInput,
  UpdatePremiumBcInput,
  useCreateBonusByContractsMutation,
  useUpdateBonusByContractsMutation,
} from "@/shared/lib/graphql";
import { DiagnosticsCountFieldGetAlert } from "./DiagnosticsCountField";

interface BonusType {
  premium?: number;
  contracts?: number;
  id?: number;
}

export interface IModal {
  selectedBonus?: BonusType;
  onSuccess: () => void;
}

export const ModalCreateBonusByContracts: React.FC<IModal> = (props): JSX.Element => {
  const { selectedBonus, onSuccess } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const notify = useAddNotification();
  const { mutate: createBonusByContracts, isLoading: isCreating } =
    useCreateBonusByContractsMutation();

  const { mutate: updateBonusByContracts, isLoading: isUpdating } =
    useUpdateBonusByContractsMutation();
  const [form, setForm] = useState({
    premium: selectedBonus?.premium || "",
    contracts: selectedBonus?.contracts || "",
  });

  const isOpenedCreateBonusMode = selectedBonus === undefined;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  useEffect(() => {
    if (!isOpenedCreateBonusMode) {
      setValue("premium", selectedBonus?.premium);
      setValue("contracts", selectedBonus?.contracts);

      setForm({
        premium: selectedBonus?.premium || "",
        contracts: selectedBonus?.contracts || "",
      });
    }
  }, [selectedBonus]);

  const handleChange = (key: keyof CreatePremiumBcInput, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleCreate = () => {
    if (isOpenedCreateBonusMode) {
      createBonusByContracts(
        { input: form as CreatePremiumBcInput },
        {
          onSuccess: async () => {
            onSuccess();
            handleButtonUndoClick();
            notify("Расход добавлен", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    } else {
      if (!selectedBonus?.id) {
        return;
      }

      updateBonusByContracts(
        { id: selectedBonus.id, input: form as UpdatePremiumBcInput },
        {
          onSuccess: async () => {
            onSuccess();
            handleButtonUndoClick();
            notify("Расход обновлен", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    }
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalCreateBonusByContracts: false,
    }));
  };

  const isLoading = isCreating || isUpdating;
  const isHaveErrors = errors?.premium?.message && errors?.contracts?.message;

  return (
    <Modal name="modalCreateBonusByContracts">
      <ModalTop
        title={isOpenedCreateBonusMode ? "Создание записи" : "Редактирование записи"}
        onClose={handleButtonUndoClick}
      />
      <form onSubmit={handleSubmit(handleCreate)}>
        <ModalBody>
          <div className="space-20">
            <DiagnosticsCountFieldGetAlert />
          </div>
          <div className="space-20">
            <Controller<IInput>
              name="contracts"
              label="Договора *"
              placeholder="Укажите количество"
              value={toFormatPrice(+form?.contracts) || undefined}
              isBordered={true}
              onChange={(value) => {
                const digits = value.split(/\D/).join("");
                digits && handleChange("contracts", +digits);
              }}
              control={control}
              error={errors?.contracts?.message}
              rules={positiveNumberPriceFormatValidationRule}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div>
            <Controller<IInput>
              name="premium"
              label="Премия *"
              placeholder="Введите сумму"
              suffix="₽"
              value={toFormatPrice(+form?.premium) || undefined}
              isBordered={true}
              onChange={(value) => {
                const digits = value.split(/\D/).join("");
                digits && handleChange("premium", +digits);
              }}
              control={control}
              error={errors?.premium?.message}
              rules={positiveNumberPriceFormatValidationRule}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button
            // @ts-ignore
            isDisabled={isHaveErrors}
            isLoading={isLoading}
            type="submit"
          >
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
