import { ModalContext, useAddNotification } from "@/shared/lib/react";
import React, { useContext, useState } from "react";
import { Input } from "@yamaha-admin-sb/input";
import { ModalBody, ModalFooter, Modal, ModalTop } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useUpdateReportMutation } from "@/shared/lib/graphql";

export interface IModal {
  data?: any;
  isSuccess?: () => void;
}

export const EditReportNameModal: React.FC<IModal> = (props): JSX.Element => {
  const { data, isSuccess } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const [form, setForm] = useState({ title: "" });

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      editReportNameModal: false,
    }));
  };

  const { mutate: updateReport } = useUpdateReportMutation();

  const notify = useAddNotification();

  const handleUpdate = () => {
    updateReport(
      { id: data.id, input: { fileName: form.title } },
      {
        onSuccess: async () => {
          handleButtonUndoClick();
          notify("Название отчёта изменени", "success");
          isSuccess && isSuccess();
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  return (
    <Modal name="editReportNameModal">
      <ModalTop title="Редактировать название файла отчёта" onClose={handleButtonUndoClick} />
      <form>
        <ModalBody>
          <div className="space-20">
            <Input
              name="title"
              label="Название файла"
              placeholder="Введите название файла"
              isBordered={true}
              value={""}
              onChange={(value) => setForm({ title: value })}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button onClick={handleUpdate}>Сохранить</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
