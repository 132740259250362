import React from "react";
import { Default, ProfileLayout } from "@/widgets/layouts";
import { useFindOneUserQuery } from "@/shared/lib/graphql";
import { useParams } from "react-router-dom";

const UserProfile: React.FC = (): JSX.Element => {
  const { userId = -1 } = useParams();
  const { data: user } = useFindOneUserQuery({
    userId: +userId,
  });

  const breads = [
    {
      title: "Пользователи",
      clickable: true,
      path: "/users",
    },
    {
      title: `${user?.findOneUser?.name} ${user?.findOneUser?.surname}`,
      clickable: false,
    },
  ];

  return (
    <Default breads={breads}>
      {user?.findOneUser ? <ProfileLayout user={user?.findOneUser} /> : <></>}
    </Default>
  );
};

export default UserProfile;
