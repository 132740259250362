import React from "react";
import styled from "styled-components";
import { IAvatar } from "@yamaha-admin-sb/avatar";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { UserFieldsFragment } from "@/shared/lib/graphql";
import { colors } from "@/shared/helpers";

interface IAvatarWithName {
  user: Pick<UserFieldsFragment, "name" | "surname">;
  size?: IAvatar["size"];
}

const StyledAvatarWithName = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: auto;
  background-color: ${colors.red200};
  text-transform: uppercase;
  white-space: nowrap;
`;

export const AvatarWithName: React.VFC<IAvatarWithName> = (props) => {
  const { user } = props;

  return (
    <StyledAvatarWithName>
      <StyledAvatar>
        <StyledInitials>
          <Paragraph size={10} color="neutral-white">
            {user.name[0]}
            {user.surname[0]}
          </Paragraph>
        </StyledInitials>
        <Paragraph size={12} color="gray-700">
          {user.name} {user.surname}
        </Paragraph>
      </StyledAvatar>
    </StyledAvatarWithName>
  );
};
