import { s3Client } from "../lib/s3";


export const getFile = async (key: string) => {

    const params: AWS.S3.GetObjectRequest = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`, // Замените на название вашего бакета
        Key: key, // Ваш ключ файла
    };
    const data = await s3Client.getObject(params).promise();
    return data;
}
