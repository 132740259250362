import { useState, type FC } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ru } from "date-fns/locale";
import styles from "./Calendar.module.scss";
import { Icon } from "@yamaha-admin-sb/icon";
import styled from "styled-components";
import { useGetRemindersByMonthQuery } from "@/shared/lib/graphql";
import moment from "moment";

registerLocale("ru", ru);

const StyledHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 8px 8px;
`;

const StyledMonth = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray-800);
  text-transform: capitalize;
  font-family: var(--font-brand), sans-serif;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const StyledButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledDotContainer = styled.div`
  display: flex;
  gap: 2px;
  position: absolute;
  left: 0;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
`;

interface ICalendarProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  userId?: number;
}

const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const Calendar: FC<ICalendarProps> = ({ selected, onChange, userId }) => {
  const [currentMonthDate, setCurrentMonthDate] = useState(selected);
  const { data: calendarData } = useGetRemindersByMonthQuery({
    input: {
      filter: {
        remindDate: moment(currentMonthDate).format("YYYY-MM-DD"),
        userId,
      },
    },
  });

  const applyCustomDayClass = (date: Date) => {
    if (!selected) return styles.day;

    return isSameDay(date, selected) ? `${styles.day} ${styles["selected-day"]}` : styles.day;
  };

  const renderDayContents = (day, date) => {
    const monthInfo = calendarData?.getRemindersByMonth;
    if (monthInfo && monthInfo.length > 0) {
      const notificationsCount = monthInfo.find(
        (item) => moment(+item.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
      )?.count;

      if (notificationsCount && notificationsCount > 0) {
        return (
          <StyledDay className={styles["day-content"]}>
            <span>{day}</span>
            <StyledDotContainer className={styles["dot-container"]}>
              {new Array(notificationsCount)
                .fill(0)
                .slice(0, 3)
                .map((_, index) => (
                  <div className={styles.dot} key={index} />
                ))}
            </StyledDotContainer>
          </StyledDay>
        );
      }
    }
    return day;
  };

  return (
    <DatePicker
      inline
      locale="ru"
      selected={selected}
      onChange={onChange}
      dayClassName={applyCustomDayClass}
      calendarClassName={styles.calendar}
      weekDayClassName={() => styles["week-day"]}
      disabledKeyboardNavigation
      onMonthChange={(date) => setCurrentMonthDate(date)}
      renderDayContents={renderDayContents}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <StyledHeader>
          <StyledMonth>
            {date.toLocaleString("ru-RU", { month: "long" })} {date.getFullYear()}
          </StyledMonth>
          <StyledButtonsContainer>
            <StyledButton type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <Icon name="chevron-left" size={16} color="gray-700" />
            </StyledButton>
            <StyledButton type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <Icon name="chevron-right" size={16} color="gray-700" />
            </StyledButton>
          </StyledButtonsContainer>
        </StyledHeader>
      )}
    />
  );
};
