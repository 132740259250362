import React, { useEffect, useState } from "react";
import { Controller } from "@/shared/ui";
import { Dropdown, IDropdownItem, IDropdown } from "@yamaha-admin-sb/dropdown";
import {
  PaymentMethodToText,
  PaymentTypeToText,
  requiredValidationRule,
} from "@/shared/helpers/const";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";

import {
  PaymentEntity,
  PaymentMethodEnum,
  PaymentTypeEnum,
  useAddPaymentMutation,
} from "@/shared/lib/graphql";
import { Title } from "@yamaha-admin-sb/title";
import { StyledFormItem, StyledGridContainer, StyledGridElement } from "@/entities/leads";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { toFormatPrice } from "@/shared/helpers/utils";
import { IHandleSuccess } from "../../model/interfaces";

interface IPayment {
  isOffsiteDiagnostics?: boolean;
  leadId: number;
  payments?: Pick<PaymentEntity, "amount" | "createdAt" | "type" | "method" | "status">;
  manager?: string;
  totalAmount?: number;
  currentAmount?: number;
  isEnabled?: boolean;
  paymentType: PaymentTypeEnum;
  onReturnClick?: (paymentType: PaymentTypeEnum) => void;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

export const Payment: React.FC<IPayment> = (props) => {
  const {
    isOffsiteDiagnostics = false,
    isEnabled = false,
    manager,
    payments,
    leadId,
    paymentType,
    totalAmount = 0,
    currentAmount = 0,
    handleSuccess,
    handleError,
    onReturnClick,
  } = props;

  const PaymentMethod: IDropdownItem[] = [
    {
      value: PaymentMethodEnum.Cash,
      name: PaymentMethodToText[PaymentMethodEnum.Cash],
      disabled: paymentType === PaymentTypeEnum.Prepayment,
    },
    {
      value: PaymentMethodEnum.Cashless,
      name: PaymentMethodToText[PaymentMethodEnum.Cashless],
    },
  ];

  const isPrepayment = paymentType === PaymentTypeEnum.Prepayment;

  const { mutate: addPayment, isLoading: isPaymentUpdating } = useAddPaymentMutation();
  const [paymentData, setPaymentData] = useState({
    amount: totalAmount - currentAmount,
    method: isPrepayment ? PaymentMethodEnum.Cashless : null,
    type: paymentType,
  });
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      cost: 0,
      paymentMethod: isPrepayment ? PaymentMethodEnum.Cashless : null,
    },
  });

  const submitCallback = () => {
    if (paymentData.amount && paymentData?.method) {
      addPayment(
        {
          leadId,
          input: {
            method: paymentData.method,
            type: paymentData.type,
            amount: paymentData.amount,
          },
        },
        {
          onSuccess: () =>
            handleSuccess({
              text: "Стоимость услуги добавлена",
              disableCompleteStage: true,
            }),
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    }
  };

  useEffect(() => {
    setValue("cost", totalAmount - currentAmount);
    setPaymentData((prev) => ({
      ...prev,
      amount: totalAmount - currentAmount,
    }));
  }, [totalAmount, currentAmount]);

  return (
    <StyledFormItem>
      <div className="space-24 display-flex ai-c jc-sb">
        <Title level={4} weight={500} color="gray-700">
          {`${PaymentTypeToText[paymentType]}а`}
        </Title>
        {totalAmount && totalAmount !== currentAmount ? (
          <Paragraph size={14} weight={500} color="gray-500">
            {`Внесено: ${toFormatPrice(currentAmount || 0)} / ${toFormatPrice(totalAmount)} ₽`}
          </Paragraph>
        ) : (
          <></>
        )}
      </div>

      {totalAmount !== currentAmount || (totalAmount === 0 && currentAmount === 0) ? (
        <>
          <StyledGridContainer className="space-20">
            <StyledGridElement column="1">
              <Controller<IInput>
                name="cost"
                control={control}
                rules={{
                  required: "Это поле обязательное",
                  validate: (value: string) => {
                    const digits = isNaN(+value) ? value?.split(/\D/)?.join("") : value;
                    return (
                      +digits === totalAmount - currentAmount ||
                      `Сумма должна быть равна ${toFormatPrice(totalAmount - currentAmount)} ₽`
                    );
                  },
                }}
                error={errors?.cost?.message}
                // type="number"
                onChange={(value) => {
                  const digits = value.split(/\D/).join("");
                  digits && setPaymentData((prev) => ({ ...prev, amount: +digits }));
                }}
                value={
                  totalAmount - currentAmount ? toFormatPrice(totalAmount - currentAmount) : ""
                }
                label="Сумма *"
                suffix="₽"
                placeholder="Введите сумму"
                isBordered={true}
                isDisabled={!isEnabled || (totalAmount === 0 && currentAmount === 0)}
                component={(dropdownProps: IInput) => <Input {...dropdownProps} />}
              />
            </StyledGridElement>
            <StyledGridElement column="2">
              <Controller<IDropdown>
                // portal={document.querySelector("body") as HTMLElement}
                name="paymentMethod"
                control={control}
                rules={requiredValidationRule}
                error={errors?.paymentMethod?.message}
                options={PaymentMethod}
                value={PaymentMethod.find(({ value }) => value === paymentData?.method)}
                isDisabled={!isEnabled || (totalAmount === 0 && currentAmount === 0)}
                onChange={(value) =>
                  value[0].value &&
                  setPaymentData((prev) => ({
                    ...prev,
                    method: value[0].value,
                  }))
                }
                label="Способ оплаты *"
                placeholder="Выберите способ оплаты"
                isBordered={true}
                // isDisabled={}
                component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
              />
            </StyledGridElement>
          </StyledGridContainer>
          <Button
            isDisabled={!isEnabled || (totalAmount === 0 && currentAmount === 0)}
            isLoading={isPaymentUpdating}
            onClick={() => handleSubmit(submitCallback)()}
          >
            Добавить оплату
          </Button>
        </>
      ) : payments ? (
        <>
          <div className={payments.status === "Processed" ? "space-20" : ""}>
            <Paragraph size={10} color="gray-600">
              {`${new Date(payments.createdAt).toLocaleString("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}, ${PaymentMethodToText[payments.method]}, ${manager}`}
            </Paragraph>
          </div>

          {payments.status === "Processed" ? (
            <Button
              variant="primary"
              color="red"
              onClick={() => onReturnClick && onReturnClick(paymentType)}
            >
              {`Вернуть ${PaymentTypeToText[paymentType].toLowerCase()}у`}
            </Button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </StyledFormItem>
  );
};
