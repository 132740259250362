import React, { useContext } from "react";
import {
  GetLeadQuery,
  useUpdateLeadManagerMutation,
  useUpdateLeadSpecialistMutation,
  PaymentTypeEnum,
  ContactEntity,
  ClientEntity,
} from "@/shared/lib/graphql";
import { IModalContextValue, ModalContext } from "@/shared/lib/react";
import { ModalManagerAppointment } from "@/features/user";
import { ModalExpertAppointment } from "@/features/requests";
import {
  ModalCancelLead,
  ModalEditContact,
  ModalEditLead,
  ModalAutoRecontactData,
  ModalPrepaymentReturn,
  ModalPaymentReturn,
  ModalCopyLead,
  ModalRecontactData,
  ModalEditTechnic,
  IHandleSuccess,
  ISelectedTechnic,
} from "@/features/leads";

interface ILeadModals {
  lead: GetLeadQuery;
  isOffsiteDiagnostics: boolean;
  selectedTechnic?: ISelectedTechnic;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

export const LeadModals: React.FC<ILeadModals> = (props) => {
  const { lead, selectedTechnic, isOffsiteDiagnostics, handleSuccess, handleError } = props;
  const { mutate: updateLeadManager, isLoading: isAddingManager } = useUpdateLeadManagerMutation();
  const { mutate: updateLeadSpecialist, isLoading: isAddingSpecialist } =
    useUpdateLeadSpecialistMutation();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const handleToggleModal = (modalName: keyof IModalContextValue, flag: boolean) => {
    setModalContext((prev) => ({ ...prev, [modalName]: flag }));
  };

  const leadId = +lead.getLead.id;
  const RequestNotify = (modalName: keyof IModalContextValue, successText: string) => ({
    onSuccess: () => {
      handleToggleModal(modalName, false);
      handleSuccess({ text: successText, disableCompleteStage: true });
    },
    onError: (error) => {
      handleToggleModal(modalName, false);
      handleError(error as ErrorEvent);
    },
  });

  const currentlService = lead?.getLead?.lService?.slug || "";

  return (
    <>
      {modalContext?.modalManagerAppointment ? (
        <ModalManagerAppointment
          title="Редактировать менеджера"
          managerId={lead.getLead.manager?.id}
          onCancel={() => handleToggleModal("modalManagerAppointment", false)}
          onSubmit={(value) =>
            updateLeadManager(
              {
                leadId,
                input: {
                  managerId: value,
                },
              },
              RequestNotify("modalManagerAppointment", "Менеджер успешно изменен")
            )
          }
          isLoading={isAddingManager}
        />
      ) : (
        ""
      )}
      {modalContext?.modalExpertAppointment ? (
        <ModalExpertAppointment
          prevInspectionTime={lead.getLead.inspectionTime || null}
          prevInspectionPlace={lead.getLead.inspectionPlace || ""}
          prevDeparture={lead.getLead.departure}
          isOffsiteDiagnostics={isOffsiteDiagnostics}
          isLoading={isAddingSpecialist}
          title="Редактировать специалиста"
          specialistId={lead.getLead.specialist?.id}
          onCancel={() => handleToggleModal("modalExpertAppointment", false)}
          onSubmit={(data) =>
            updateLeadSpecialist(
              {
                leadId,
                input: {
                  ...data,
                },
              },
              RequestNotify("modalExpertAppointment", "Специалист успешно изменен")
            )
          }
        />
      ) : (
        ""
      )}

      {modalContext?.modalCancelLead ? (
        <ModalCancelLead leadId={leadId} handleError={handleError} handleSuccess={handleSuccess} />
      ) : (
        ""
      )}

      {modalContext?.modalEditContact ? (
        <ModalEditContact
          leadId={leadId}
          contact={lead.getLead.contact as ContactEntity}
          client={lead.getLead.client as ClientEntity}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        ""
      )}

      {modalContext?.modalEditLead ? (
        <ModalEditContact
          leadId={leadId}
          contact={lead.getLead.contact as ContactEntity}
          client={lead.getLead.client as ClientEntity}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        ""
      )}
      {modalContext?.modalRecontactData ? (
        <ModalRecontactData
          leadId={leadId}
          contact={lead.getLead.contact as ContactEntity}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        ""
      )}

      {/*  {modalContext?.modalAutoRecontactData ? (
        <ModalAutoRecontactData
          leadId={leadId}
          currentlService={currentlService}
          contact={lead.getLead.contact}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        ""
      )} */}

      {modalContext?.modalEditTechnic && selectedTechnic ? (
        <ModalEditTechnic
          leadId={leadId}
          selectedTechnic={selectedTechnic}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        ""
      )}
      {modalContext?.modalEditLead ? (
        <ModalEditLead
          lead={lead}
          isOffsiteDiagnostics={isOffsiteDiagnostics}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        ""
      )}
      {modalContext?.modalPrepaymentReturn ? (
        <ModalPrepaymentReturn
          paymentId={
            lead.getLead.payments?.find((item) => item.type === PaymentTypeEnum.Prepayment)?.id ||
            ""
          }
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        ""
      )}
      {modalContext?.modalPaymentReturn ? (
        <ModalPaymentReturn
          payments={lead.getLead.payments || []}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      ) : (
        <></>
      )}
      {modalContext?.modalCopyLead ? (
        <ModalCopyLead lead={lead} handleError={handleError} />
      ) : (
        <></>
      )}
    </>
  );
};
