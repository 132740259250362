import { ModalContext, useAddNotification } from "@/shared/lib/react";
import React, { useContext, useState } from "react";
import {
  ModalTop,
  Controller,
  Modal,
  ModalBody,
  ModalFooter,
  CustomNotification,
} from "@/shared/ui";
import { Input, IInput } from "@yamaha-admin-sb/input";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import {
  ContactTypeEnum,
  useAttachContactToClientMutation,
  useCreateContactMutation,
} from "@/shared/lib/graphql";
import { useForm } from "react-hook-form";
import { inputValidationRule } from "@/shared/helpers/const";

export interface ICreateContactFrom {
  name: string;
  surname: string;
  value: string;
  type: ContactTypeEnum;
}

export interface IModal {
  // refetch clients
  onSuccess: () => Promise<unknown>;
  selectedClient: null | number | undefined;
}

const contactTypes = [
  {
    name: "Телефон",
    id: ContactTypeEnum.Phone,
  },
  {
    name: "Email",
    id: ContactTypeEnum.Email,
  },
];

export const ModalCreateContact: React.FC<IModal> = ({
  selectedClient,
  onSuccess,
}): JSX.Element => {
  const [contactType, setContactType] = useState<IDropdownItem | []>(contactTypes[0]);
  const notify = useAddNotification();
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const { mutate: createContact, isLoading: isContactCreating } = useCreateContactMutation();
  const { mutate: attachContact, isLoading: isContactAttaching } =
    useAttachContactToClientMutation();

  const [form, setForm] = useState<ICreateContactFrom>({
    name: "",
    surname: "",
    value: "",
    type: ContactTypeEnum.Phone,
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ mode: "onBlur" });

  const handleChange = (key: string, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleCloseModal = () => {
    setModalContext((prev) => ({ ...prev, modalCreateContact: false }));
  };

  const handleCreateContact = (data: ICreateContactFrom) => {
    if (!selectedClient) {
      notify(<CustomNotification title="Клиент не выбран" />, "default");
      return;
    }
    createContact(
      {
        input: {
          name: data.name,
          surname: data.surname,
          value: data.value,
          type: data.type,
        },
      },
      {
        onSuccess: (resp) => {
          attachContact(
            {
              contactId: resp.createContact.id,
              input: {
                clientId: selectedClient,
              },
            },
            {
              onSuccess: () => {
                handleCloseModal();
                onSuccess();
                notify(<CustomNotification title="Контакт добавлен для клиента" />, "default");
              },
            }
          );
        },
      }
    );
  };

  const handleCreate = () => {
    handleCreateContact(form);
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalCreateContact: false,
    }));
  };

  return (
    <Modal name="modalCreateContact">
      <ModalTop title={`Новый контакт`} onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleCreate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IDropdown>
              name="type"
              label="Тип контакта *"
              placeholder="Выберите тип"
              isBordered={true}
              control={control}
              error={errors?.type?.message}
              options={contactTypes}
              value={contactType}
              onChange={(value: IDropdownItem) => {
                handleChange("type", value[0].id);
                value[0].id &&
                  setContactType(contactTypes.find((item) => item.id === value[0].id) ?? []);
              }}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
          {form.type === ContactTypeEnum.Phone && (
            <div className="space-20">
              <Controller<IInput>
                name="value"
                label="Телефон *"
                placeholder="Введите телефон"
                control={control}
                error={errors?.name?.message}
                value={form.name}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("value", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
          )}
          {form.type === ContactTypeEnum.Email && (
            <div className="space-20">
              <Controller<IInput>
                name="value"
                label="Email *"
                placeholder="Введите email"
                control={control}
                error={errors?.name?.message}
                value={form.name}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("value", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleCloseModal}>
            Отменить
          </Button>
          <Button isLoading={isContactCreating || isContactAttaching} type="submit">
            Создать
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
