import { Table, ITable } from "@yamaha-admin-sb/table";
import React from "react";
import { StyledTablePagination, StyledTableWrap } from "./styled";
import { ICheckboxFilter, ITableSearch, TableFilters } from "./TableFilters";
import { Pagination } from "@yamaha-admin-sb/pagination";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { IFilter } from "@/shared/helpers/mocks";
import styled from "@emotion/styled";
import { Loader } from "./Loader";

interface IWrappedTable extends Partial<ITable> {
  tableTitle?: string;
  filters?: IFilter[];
  checkbox?: ICheckboxFilter;
  search?: ITableSearch | ITableSearch[];
  isAdditionalClerable?: boolean;
  rightBtn?: () => JSX.Element;
  isTable?: boolean;
  children?: React.ReactNode;
  onPaginationClick?: (page: number) => void;
  onSearch?: (value: string) => void;
  renderLeftElement?: () => JSX.Element;
  onFiltersApply?: (arg: { [key: string]: any }) => void;
  pagination?: {
    count: number;
    page: number;
  };
  isLoading?: boolean;
  additionalFilter?: { [key: string]: string };
}

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
`;

const StyledNoTableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`;

export const WrappedTable: React.FC<IWrappedTable> = (props) => {
  const {
    isLoading,
    filters,
    tableTitle,
    headers,
    data,
    search,
    showMenu,
    isAdditionalClerable,
    onMenuItemSelected,
    rightBtn,
    pagination,
    isTable = true,
    children,
    onPaginationClick,
    onFiltersApply,
    renderLeftElement,
    checkbox,
    additionalFilter,
    ...rest
  } = props;

  return (
    <StyledTableWrap>
      {tableTitle ? (
        <Paragraph size={12} color="gray-600">
          {tableTitle}
        </Paragraph>
      ) : (
        <></>
      )}

      {filters || search || rightBtn ? (
        <TableFilters
          additionalFilter={additionalFilter}
          checkbox={checkbox}
          filters={filters}
          search={search}
          rightBtn={rightBtn}
          onFiltersApply={onFiltersApply}
          renderLeftElement={renderLeftElement}
          isAdditionalClerable={isAdditionalClerable}
        />
      ) : (
        <></>
      )}
      {isTable ? (
        Array.isArray(data) && data.length && headers ? (
          <>
            <Table
              showMenu={showMenu}
              headers={headers}
              data={data}
              onMenuItemSelected={(dataItem, item) =>
                onMenuItemSelected && onMenuItemSelected(dataItem, item)
              }
              {...rest}
            />

            {pagination && pagination?.count > 1 ? (
              <StyledTablePagination>
                <Pagination
                  pageCount={pagination?.count || 0}
                  gotoPage={(value) => onPaginationClick && onPaginationClick(value)}
                  pageIndex={pagination?.page || 0}
                  type="buttons"
                />
              </StyledTablePagination>
            ) : (
              <></>
            )}
          </>
        ) : !isLoading ? (
          <StyledNoTableItem>
            <Paragraph size={16} color="gray-500">
              Нет данных
            </Paragraph>
          </StyledNoTableItem>
        ) : (
          <StyledLoader>
            <Loader size="l" />
          </StyledLoader>
        )
      ) : (
        <>{children}</>
      )}
    </StyledTableWrap>
  );
};
