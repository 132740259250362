import { colors, media } from "@/shared/helpers";
import styled from "styled-components";

const StyledTitle = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  ${media.tablet} {
    flex-direction: row;

    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
  }
  ${media.laptop} {
    padding: 20px 40px;
  }
  border-bottom: 1px solid ${colors.gray200};
`;

const StyledNoItem = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding-top: 160px;
  flex: 1 1 100%;
  justify-content: center;
`;

export { StyledTitle, StyledNoItem };
