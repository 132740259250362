export const getCapitalized = (order: string): string => {
  return `${order[0].toUpperCase()}${order.slice(1)}`;
};

export const getSuffixedDigit = (digit: number) => {
  return digit > 9 ? digit : `0${digit}`;
};

export const getFormattedDate = (date: Date): string | undefined => {
  return date
    ? `${date.getFullYear()}-${getSuffixedDigit(date.getMonth() + 1)}-${getSuffixedDigit(
      date.getDate()
    )}`
    : undefined;
};

export const getDottedDate = (date: string) => {
  if (!date) return undefined;

  const dateArr = date.replace("T", "-").split("-");

  return `${+dateArr[1]}.${+dateArr[2]}.${dateArr[0]}`;
};

export const toFormatPrice = (price?: number) => {
  if (!price) return 0;
  if (price % 1 !== 0) {
    const splittedPrice = price.toString().split(".");
    return `${splittedPrice[0].replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}.${splittedPrice[1]}`;
  } else {
    return price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
  }
};

export const getPlaceholderFromLabel = (label: string) => {
  return `Укажите ${label.toLowerCase()}`;
};

export const downloadURL = async (url: string, name?: string, format?: string) => {
  const toDataURL = (url: string) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  const a = document.createElement("a");
  a.href = await toDataURL(`${url}`);

  if (name) {
    a.download = format ? `${name}.${format}` : name;
  } else {
    a.download = url.substring(url.lastIndexOf("/") + 1);
  }

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const toDateFormat = (date: string) => {
  const dateArr = date.split(".");

  if (dateArr?.length > 2) {
    return new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);
  }
  return date;
};

const doubleDigitsFormat = (count: number) => {
  return count < 10 ? "0" + count : count;
};
export const getDateServerFormat = (date: Date) => {
  return `${date.getFullYear()}-${doubleDigitsFormat(date.getMonth() + 1)}-${doubleDigitsFormat(
    date.getDate()
  )}`;
};

// DD.MM.YYYY  -> YYYY-MM-DD
export const transformDateToISO = (date: string) => {
  const dateArr = date.split(".");
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
};

// YYYY-MM-DD -> DD.MM.YYYY
export const transformISOtoDate = (date: string) => {
  const dateArr = date.split("-");
  return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
};

export const pluralWord = (value: number, words: Array<String>) => {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value === 0) return words[2];
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

export const parseQueryKey = (key: string) =>
  key.replace(/\[|]/g, " ").replace(/\s+/g, " ").trim().split(" ");
