import { ModalContext } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import {
  BaseUserOutput,
  CreateUserInput,
  RoleEnum,
  UpdateUserInput,
  useFindManyRolesQuery,
  useGetCompetencesQuery,
} from "@/shared/lib/graphql";
import {
  emailValidationRule,
  inputValidationRule,
  nickNameValidationRule,
  phoneValidationRule,
  requiredValidationRule,
} from "@/shared/helpers/const";
import { useForm } from "react-hook-form";

export interface IModal {
  data?: BaseUserOutput;
  isLoading?: boolean;
  onSubmitCreate: (form: CreateUserInput) => void;
  onSubmitUpdate: (form: UpdateUserInput) => void;
}

const rolesForSIP: RoleEnum[] = [
  RoleEnum.Director,
  RoleEnum.HeadOfMn,
  RoleEnum.SnManager,
  RoleEnum.Manager,
];
const rolesForCompetence: RoleEnum[] = [RoleEnum.Specialist, RoleEnum.HeadOfSp];
const FORM_BASE_DATA = {
  email: "",
  name: "",
  password: "",
  phone: "",
  roleId: 0,
  surname: "",
  username: "",
  sip: undefined,
  extension: undefined,
  patronymic: undefined,
};

export const CreateUserModal: React.FC<IModal> = (props): JSX.Element => {
  const { data, onSubmitCreate, onSubmitUpdate, isLoading = false } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [isStale, setIsStale] = useState(false);
  const [form, setForm] = useState<CreateUserInput>(FORM_BASE_DATA);
  const {
    data: roles,
    isLoading: isRolesLoading,
    isStale: isRolesStale,
  } = useFindManyRolesQuery(undefined, {
    staleTime: 5 * 60 * 1000,
    enabled: !!modalContext?.createUserModal && isStale,
  });
  const {
    data: competences,
    isLoading: isCompetencesLoading,
    isStale: isCompetencesStale,
  } = useGetCompetencesQuery(undefined, {
    staleTime: 5 * 60 * 1000,
    enabled: !!modalContext?.createUserModal && isStale,
  });
  const isSIPShow = rolesForSIP.includes(
    roles?.findManyRoles?.find((role) => role.id === form.roleId)?.slug || ("" as RoleEnum)
  );
  const isCompetenceShow = rolesForCompetence.includes(
    roles?.findManyRoles?.find((role) => role.id === form.roleId)?.slug || ("" as RoleEnum)
  );
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onChange", defaultValues: { ...form } });

  useEffect(() => {
    if (!isStale && (isRolesStale || isCompetencesStale)) setIsStale(true);
  }, [isRolesStale, isCompetencesStale]);

  useEffect(() => {
    if (modalContext?.createUserModal) setForm(FORM_BASE_DATA);
  }, [modalContext?.createUserModal]);

  useEffect(() => {
    if (data) {
      //@ts-ignore
      Object.keys(data).forEach((item) => setValue(item, data[item]));
    }
  }, [data]);

  useEffect(() => {
    if (data)
      setForm({
        email: data.email,
        extension: isSIPShow ? data.extension : undefined,
        name: data.name,
        password: "",
        patronymic: data.patronymic,
        phone: data.phone,
        roleId: data.role?.id || 0,
        sip: isSIPShow ? data.sip : undefined,
        surname: data.surname,
        username: data.username,
        competences: isCompetenceShow
          ? data.competences?.map((competence) => competence.id) || []
          : undefined,
      });
  }, [data, competences?.getCompetences]);

  useEffect(() => {
    if (!isSIPShow)
      setForm((prevState) => ({
        ...prevState,
        sip: undefined,
        extension: undefined,
      }));
  }, [isSIPShow]);

  useEffect(() => {
    if (!isCompetenceShow)
      setForm((prevState) => ({
        ...prevState,
        competences: undefined,
      }));
  }, [isCompetenceShow]);

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      createUserModal: false,
    }));
  };

  const handleChange = (name: keyof CreateUserInput, value: string | number | number[]) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getEditData = () => {
    const { password, ...rest } = form;
    return { ...rest, id: data?.id || -1 };
  };

  return (
    <Modal name="createUserModal">
      <ModalTop title="Добавление пользователя" onClose={handleButtonUndoClick} />
      <form
        onSubmit={handleSubmit(() =>
          !data ? onSubmitCreate(form) : onSubmitUpdate(getEditData())
        )}
      >
        <ModalBody>
          <div className="space-20">
            <Controller<IInput>
              name="username"
              label="Никнейм*"
              placeholder="Введите никнейм"
              isBordered={true}
              value={form.username}
              onChange={(value) => handleChange("username", value)}
              rules={nickNameValidationRule}
              control={control}
              error={errors?.username?.message}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              isBordered={true}
              name="name"
              label="Имя пользователя*"
              placeholder="Введите имя"
              value={form.name}
              onChange={(value) => handleChange("name", value)}
              rules={inputValidationRule}
              control={control}
              error={errors?.name?.message}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Input
              isBordered={true}
              name="surname"
              label="Фамилия пользователя*"
              placeholder="Введите фамилию"
              value={form.surname}
              onChange={(value) => handleChange("surname", value)}
            />
          </div>
          <div className="space-20">
            <Input
              isBordered={true}
              name="surname"
              label="Отчество пользователя"
              placeholder="Введите отчество"
              value={form.patronymic || ""}
              onChange={(value) => handleChange("patronymic", value)}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              isBordered={true}
              name="email"
              label="E-mail*"
              placeholder="Введите E-mail"
              value={form.email}
              onChange={(value) => handleChange("email", value)}
              rules={emailValidationRule}
              control={control}
              error={errors?.email?.message}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              mask="+79999999999"
              isBordered={true}
              name="phone"
              label="Телефон*"
              placeholder="Введите телефон"
              value={form.phone}
              onChange={(value) => handleChange("phone", value)}
              control={control}
              error={errors?.phone?.message}
              rules={phoneValidationRule}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          {!data ? (
            <div className="space-20">
              <Controller<IInput>
                isBordered={true}
                name="password"
                label="Пароль*"
                type="password"
                placeholder="Введите пароль"
                description="Не менее 8 символов (используйте заглавные и прописные латинские буквы и цифры)"
                value={form.password}
                onChange={(value) => handleChange("password", value)}
                rules={inputValidationRule}
                control={control}
                error={errors?.password?.message}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="space-20">
            <Controller<IDropdown>
              name="roleId"
              isBordered={true}
              label="Роль*"
              placeholder="Выберите роль"
              value={[roles?.findManyRoles?.find((role) => role.id === form.roleId) || []].flat()}
              options={(roles?.findManyRoles || []) as IDropdownItem[]}
              isDisabled={isRolesLoading}
              onChange={(value) => value[0] && handleChange("roleId", value[0].id || 0)}
              rules={requiredValidationRule}
              control={control}
              error={errors?.roleId?.message}
              component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
            />
          </div>

          {isSIPShow ? (
            <>
              <div className="space-20">
                {form.roleId !== 1 ? (
                  <Controller<IInput>
                    isBordered={true}
                    name="sip"
                    label="SIP-адрес*"
                    placeholder="Введите SIP-адрес"
                    value={form.sip || ""}
                    onChange={(value) => handleChange("sip", value)}
                    rules={inputValidationRule}
                    control={control}
                    error={errors?.sip?.message}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                ) : (
                  <Input
                    isBordered={true}
                    name="sip"
                    label="SIP-адрес"
                    placeholder="Введите SIP-адрес"
                    value={form.sip || ""}
                    onChange={(value) => handleChange("sip", value)}
                  />
                )}
              </div>
              <div className="space-20">
                <Input
                  isBordered={true}
                  name="idMango"
                  label="Добавочный номер"
                  placeholder="Введите добавочный номер"
                  value={form.extension || ""}
                  onChange={(value) => handleChange("extension", value)}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {isCompetenceShow ? (
            <>
              <div className="space-20">
                <Controller<IDropdown>
                  name="competences"
                  isBordered={true}
                  label="Компетенции*"
                  placeholder="Выберите компетенции"
                  isMultiple={true}
                  isDisabled={isCompetencesLoading}
                  value={
                    competences?.getCompetences?.filter((competence) =>
                      form?.competences?.includes(competence.id)
                    ) || []
                  }
                  options={competences?.getCompetences || []}
                  onChange={(value) =>
                    handleChange(
                      "competences",
                      value.map((value) => Number(value?.id))
                    )
                  }
                  rules={requiredValidationRule}
                  control={control}
                  error={
                    Array.isArray(errors?.competences)
                      ? errors?.competences[0].message
                      : errors?.competences?.message
                  }
                  component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
