import { AddManagerToLeadButton, SelectLeadManagerButton } from "@/features/leads";
import { ArrayElement, WithoutNull } from "@/shared/helpers/interfaces";
import { GetLeadsQuery } from "@/shared/lib/graphql";
import { AvatarWithBtn } from "@/shared/ui";
import type { FC } from "react";

interface ILeadManagerProps {
  lead: ArrayElement<WithoutNull<Required<GetLeadsQuery["getLeads"]>>["data"]>;
  canChangeManager: boolean;
  hasManagerAppointment: boolean;
  onLeadSelect: (leadId: number) => void;
  invalidateLeads?: () => Promise<unknown>;
}

export const LeadManager: FC<ILeadManagerProps> = ({
  lead,
  canChangeManager,
  hasManagerAppointment,
  invalidateLeads,
  onLeadSelect,
}) => {
  return (
    <AvatarWithBtn
      user={
        lead?.manager?.name && lead?.manager.surname
          ? {
              firstName: lead.manager.name,
              lastName: lead.manager.surname,
            }
          : undefined
      }
      renderProps={() => {
        if (!hasManagerAppointment) {
          return (
            <>
              {canChangeManager && (
                <SelectLeadManagerButton leadId={lead.id} onLeadSelect={onLeadSelect} />
              )}

              <AddManagerToLeadButton
                leadId={lead.id}
                onLeadSelect={onLeadSelect}
                invalidateLeads={invalidateLeads}
              />
            </>
          );
        } else return <>-</>;
      }}
    />
  );
};
