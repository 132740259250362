import { colors } from "@/shared/helpers";
import {
  ReminderEntity,
  ReminderTypeEnum,
  useGetLeadQuery,
  useSetAsideReminderNotificationMutation,
  useUpdateReminderMutation,
} from "@/shared/lib/graphql";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { TextButton } from "@yamaha-admin-sb/text-button";
import moment from "moment";
import { useState, type FC } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  font-weight: 300;
  color: ${colors.gray700};
  padding: 24px;
  border-radius: 10px;
`;

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.gray700};
`;

const StyledLeadContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledComment = styled.p`
  color: ${colors.gray600};
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  marigin-top: 14px;
`;

export const remindNotificationOptions = [
  { name: "5 минут", value: 5 },
  { name: "10 минут", value: 10 },
  { name: "15 минут", value: 15 },
  { name: "30 минут", value: 30 },
];

interface IReminderNotificationProps {
  id: ReminderEntity["id"];
  title: ReminderEntity["title"];
  remindDate: ReminderEntity["remindDate"];
  leadId: ReminderEntity["leadId"];
  comment: ReminderEntity["comment"];
  onDismiss: () => void;
}

export const ReminderNotification: FC<IReminderNotificationProps> = ({
  id,
  title,
  comment,
  remindDate,
  leadId,
  onDismiss,
}) => {
  const { data: lead } = useGetLeadQuery({ id: leadId as number }, { enabled: !!leadId });
  const [puttOff, setPuttOff] = useState(false);
  const [notifyTime, setNotifyTime] = useState(remindNotificationOptions[0].value);
  const puttOffNotification = useSetAsideReminderNotificationMutation();
  const updateReminder = useUpdateReminderMutation();

  const notifyDateString = moment(remindDate).format("dddd, DD MMMM, HH:mm");
  const leadString = lead?.getLead ? `Заявка №${lead.getLead.num}` : "";

  const handlePuttOffNotification = () => {
    puttOffNotification.mutate(
      {
        id: id,
        remindVia: notifyTime,
      },
      {
        onSuccess: onDismiss,
      }
    );
  };

  const handleCancelReminder = () => {
    updateReminder.mutate(
      {
        input: {
          id,
          status: ReminderTypeEnum.Cancelled,
        },
      },
      {
        onSuccess: onDismiss,
      }
    );
  };

  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      <div>{notifyDateString}</div>
      {leadString && (
        <StyledLeadContainer>
          {leadString}{" "}
          <TextButton onClick={() => window.open(`/leads/${leadId}`, "_self")}>Перейти</TextButton>
        </StyledLeadContainer>
      )}
      {comment && <StyledComment>{comment}</StyledComment>}
      {puttOff && (
        <Dropdown
          label="Напомнить через"
          isBordered={true}
          options={remindNotificationOptions}
          value={
            [remindNotificationOptions?.find((item) => item.value === notifyTime) || []].flat() ||
            []
          }
          onChange={(value: IDropdownItem[]) => setNotifyTime(value[0]?.value)}
        />
      )}
      <StyledButtonContainer>
        {puttOff ? (
          <TextButton onClick={() => setPuttOff(false)}>Назад</TextButton>
        ) : (
          <TextButton onClick={() => setPuttOff(true)}>Отложить</TextButton>
        )}
        {puttOff ? (
          <Button onClick={handlePuttOffNotification}>Сохранить</Button>
        ) : (
          <Button onClick={handleCancelReminder}>Закрыть</Button>
        )}
      </StyledButtonContainer>
    </StyledContainer>
  );
};
