import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { NotificationContainer } from "@yamaha-admin-sb/notification";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import { ThemeProvider } from "@emotion/react";
import { theme } from "@/shared/theme";
import "./app/assets/css/styles.css";
import Clients from "@/pages/clients";
import Users from "@/pages/users";
import Calls from "@/pages/calls";
import Login from "@/pages/login";
import UserProfile from "@/pages/user";
import { Redirect, useLocation } from "@reach/router";
import Leads from "@/pages/leads";
import LeadsEdit from "@/pages/lead";
import Reports from "@/pages/reports";
import Technics from "@/pages/technics";
import Profile from "@/pages/profile";
import Schedule from "@/pages/schedule";
import Bonus from "@/pages/bonus";
import ScheduleDetail from "@/pages/schedule-detail";
import Main from "@/pages/home";
import Expenses from "@/pages/expenses";
import Reviews from "@/pages/reviews";
import WorkDay from "@/pages/work-day";
import { useGetMeQuery, useWebScocket } from "@/shared/lib/graphql";
import { CustomNotification } from "@/shared/ui";
import { NotificationTypeToText } from "@/shared/helpers/const";
import ClientEdit from "@/pages/client";
import { ModalContext, useAddNotification, ThemeContext } from "@/shared/lib/react";
import { PROTECTED_ROUTES_BY_ROLES, UNPROTECTED_ROUTES } from "@/widgets/layouts";
import { useReminderNotification } from "@/features/reminder";

const App: React.FC = (): JSX.Element => {
  const [modalValue, setModalValue] = useState({
    payload: null,
    roleEditModal: false,
    changePasswordModal: false,
    confirmModal: false,
    createUserModal: false,
    modalCreateReport: false,
    modalCreateClient: false,
    modalChangeLServiceTypeLead: false,
    modalCreateContact: false,
    modalManagerAppointment: false,
    modalExpertAppointment: false,
    modalCreateRequest: false,
    modalCancelLead: false,
    modalEditContact: false,
    modalEditLead: false,
    modalEditTechnic: false,
    modalLeadComment: false,
    modalRecontactData: false,
    modalAutoRecontactData: false,
    modalUploadTechnics: false,
    modalEditBonuses: false,
    modalCreateExpenses: false,
    modalPrepaymentReturn: false,
    modalPaymentReturn: false,
    modalEditSchedule: false,
    modalScheduleAddUser: false,
    modalEditServices: false,
    editReportNameModal: false,
    modalOtherExpenses: false,
    modalCopyLead: false,
    modalCreateReminder: false,
    modalEditReminder: false,
    modalDeleteReminder: false,
    modalReminderInfo: false,
    modalReminder: false,
    eployeeEfficiencyModal: false,
    eployeeReportModal: false,
    eployeeReportRateModal: false,
    generalExpenseReportModal: false,
    modalCreateBonusByContracts: false,
  });
  const isAuthenticated = !!Cookies.get("accessToken");
  const { data: me } = useGetMeQuery(undefined, {
    staleTime: Infinity,
    enabled: isAuthenticated,
    queryKeyHashFn: (key) => `${key}-${Cookies.get("accessToken")}`,
  });
  useReminderNotification();

  const notify = useAddNotification();

  useWebScocket({
    onSuccess: (data) =>
      notify(
        <CustomNotification
          title={`${NotificationTypeToText[data.type]} №${data.leadNum}`}
          link={{
            url: `/leads/${data.leadId}`,
            linkTitle: "Перейти",
          }}
        />,
        "default"
      ),
  });

  const [themeValue, setThemeValue] = useState<"light" | "dark">("light");
  const thisTheme = theme(themeValue);
  const { pathname } = useLocation();

  if (!isAuthenticated && !UNPROTECTED_ROUTES.includes(pathname)) {
    return <Redirect noThrow={true} to="/login" />;
  } else if (
    me?.getMe.role?.slug &&
    PROTECTED_ROUTES_BY_ROLES[me?.getMe.role?.slug]?.includes(window.location.pathname)
  ) {
    return <Redirect noThrow={true} to="/" />;
  }

  // PROTECTED_ROUTES_BYROLES[me?.getMe.role?.slug]?.find((url) =>
  //       new RegExp(window.location.pathname).test(url),
  //     ),

  return (
    <ModalContext.Provider value={[modalValue, setModalValue]}>
      <ThemeContext.Provider value={[themeValue, setThemeValue]}>
        <ThemeProvider theme={thisTheme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/work-day" element={<WorkDay />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/:userId" element={<UserProfile />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/:clientId" element={<ClientEdit />} />
              <Route path="/calls" element={<Calls />} />
              <Route path="/leads" element={<Leads />} />
              <Route path="/leads/:leadId" element={<LeadsEdit />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/technics" element={<Technics />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/bonus" element={<Bonus />} />
              <Route path="/schedule/:slug" element={<ScheduleDetail />} />
              <Route path="/expenses" element={<Expenses />} />
              <Route path="/reviews" element={<Reviews />} />
            </Routes>
          </BrowserRouter>
          {/*<Users path="/users" />*/}
          {/*<Login path="/login" />*/}
          {/*<RecoveryPassword path="/recovery-password" />*/}

          <NotificationContainer
            position="bottom-right"
            closeButton={false}
            hideProgressBar={true}
            newestOnTop={true}
            draggable={false}
            pauseOnHover={true}
            stacked
          />
        </ThemeProvider>
      </ThemeContext.Provider>
    </ModalContext.Provider>
  );
};

export default App;
