import React, { useEffect, useState } from "react";
import { ru } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";

import { StyledTimepicker, CustomInput, StyledLabel } from "./Timepicker.style";

registerLocale("ru", ru);

export interface ITimepicker {
  label: string;
  placeholder?: string;
  value?: string;
  isBlock?: boolean;
  isDisabled?: boolean;
  isBordered?: boolean;
  onInput?: (arg: string) => void;
  onInputOriginal?: (date: Date | null) => void;
}

const getNormalizedTimeFormat = (newDate: Date | null) => {
  if (!newDate) {
    return "";
  }
  return newDate.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const Timepicker: React.FC<ITimepicker> = (props): JSX.Element => {
  const {
    label,
    placeholder,
    value,
    isBlock = false,
    isDisabled = false,
    isBordered = false,
    onInput,
    onInputOriginal,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!value) {
      setDate(null);
      return;
    }

    const newDate = new Date();
    newDate.setHours(parseInt(value.split(":")[0]), parseInt(value.split(":")[1]));

    setDate(newDate);
  }, [value]);

  const handleDatePick = (newDate: Date) => {
    const time = getNormalizedTimeFormat(newDate);
    if (newDate) {
      setDate(newDate);
      if (!isNaN(newDate.getTime())) {
        if (onInput) {
          onInput(time);
        }
        if (onInputOriginal) onInputOriginal(newDate);
      }
    } else {
      setDate(null);
      if (onInput) onInput("");
      if (onInputOriginal) onInputOriginal(null);
    }
  };

  const ExampleCustomInput = (subProps: any) => {
    return (
      <>
        <InputMask name="time" mask={"99:99"} {...subProps} onFocus={() => ({})}>
          {(inputProps: Record<string, unknown>) => (
            <CustomInput
              {...inputProps}
              isDisabled={isDisabled}
              isBordered={isBordered}
              onChange={subProps.onChange}
            />
          )}
        </InputMask>
      </>
    );
  };

  return (
    <StyledTimepicker isOpen={isOpen} isBlock={isBlock} isDisabled={isDisabled}>
      <StyledLabel isDisabled={isDisabled}>{label}</StyledLabel>
      <DatePicker
        selected={date}
        onChange={handleDatePick}
        placeholderText={placeholder}
        locale="ru"
        timeCaption="Время"
        isClearable={true}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="HH:mm"
        value={date}
        customInput={<ExampleCustomInput />}
      />
    </StyledTimepicker>
  );
};

export { Timepicker };
