import { GetCallsOutputData } from "@/shared/lib/graphql";
import { Button } from "@yamaha-admin-sb/button";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

interface ICallActionButtonProps {
  call: GetCallsOutputData;
  onCreateRequestClick: (call: GetCallsOutputData) => void;
}

export const CallActionButton: FC<ICallActionButtonProps> = ({ call, onCreateRequestClick }) => {
  const navigate = useNavigate();

  return (
    <>
      {!!call?.leadId && !isNaN(+call?.leadId) && +call.leadId ? (
        <Button onClick={() => navigate(`/leads/${call.leadId}`)} variant="secondary" color="gray">
          К заявке
        </Button>
      ) : (
        <Button onClick={() => onCreateRequestClick(call)} color="gray">
          Создать заявку
        </Button>
      )}
    </>
  );
};
