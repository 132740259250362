import { ModalContext, useAddNotification } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import {
  ModalTop,
  Modal,
  ModalBody,
  ModalFooter,
  Controller,
  CustomNotification,
} from "@/shared/ui";
import { Input, IInput } from "@yamaha-admin-sb/input";
import { Button } from "@yamaha-admin-sb/button";
import { useCreateClientMutation } from "@/shared/lib/graphql";
import { useForm } from "react-hook-form";
import { inputValidationRule } from "@/shared/helpers/const";

export interface IModal {
  // refetch clients
  onSuccess: () => Promise<unknown>;
}

export interface ICreateClientFrom {
  name: string;
  surname: string;
  patronymic?: string;
}

export const ModalCreateClient: React.FC<IModal> = ({ onSuccess }): JSX.Element => {
  const { mutate: createClient, isLoading } = useCreateClientMutation();
  const notify = useAddNotification();
  const [, setModalContext = () => ({})] = useContext(ModalContext);

  const [form, setForm] = useState<ICreateClientFrom>({
    name: "",
    surname: "",
    patronymic: "",
  });

  useEffect(() => {
    form.name && setValue("name", form.name);
  }, [form.name]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const handleChange = (key: string, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleCloseModal = () => {
    setModalContext((prev) => ({ ...prev, modalCreateClient: false }));
  };

  const handleCreateClient = (data: ICreateClientFrom) => {
    createClient(
      {
        input: {
          name: data.name,
          surname: data.surname,
          patronymic: data.patronymic,
        },
      },
      {
        onSuccess: () => {
          handleCloseModal();
          onSuccess();
          notify(<CustomNotification title="Новый клиент добавлен" />, "default");
        },
      }
    );
  };

  const handleCreate = () => {
    handleCreateClient(form);
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalCreateClient: false,
    }));
  };

  return (
    <Modal name="modalCreateClient">
      <ModalTop title="Новый клиент" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleCreate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IInput>
              name="name"
              label="Имя клиента *"
              placeholder="Введите имя"
              control={control}
              error={errors?.name?.message}
              value={form.name}
              isBordered={true}
              rules={inputValidationRule}
              onChange={(value) => handleChange("name", value)}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              isBordered={true}
              name="surname"
              label="Фамилия клиента"
              placeholder="Введите фамилию"
              onChange={(value) => handleChange("surname", value)}
              control={control}
              value={form.surname}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              isBordered={true}
              name="patronymic"
              label="Отчество клиента"
              placeholder="Введите отчество (если есть)"
              onChange={(value) => handleChange("patronymic", value)}
              control={control}
              value={form.patronymic}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleCloseModal}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Создать
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
