import React from "react";
import { ContactEntity } from "@/shared/lib/graphql";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import {
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
  WrappedLeadTitle,
  WrappedBlock,
} from "../leads.styled";

interface IAutoDateDelivery {
  contact?: ContactEntity | null;
  onEditClick: () => void;
}

export const AutoDateDelivery: React.FC<IAutoDateDelivery> = (props) => {
  const { contact, onEditClick } = props;
  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Выдача автомобиля
        </Title>
        <IconButton icon="edit" variant="tertiary" color="gray" onClick={onEditClick} />
      </WrappedLeadTitle>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Дата выдачи автомобиля
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          {contact?.carDeliveryDate ? (
            <Paragraph size={12} color="gray-700" weight={400}>
              {new Date(contact.carDeliveryDate).toLocaleString("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </Paragraph>
          ) : (
            <Paragraph size={12} color="gray-500" weight={400}>
              Не указана
            </Paragraph>
          )}
        </StyledValueItem>
      </StyledInfoItem>
    </WrappedBlock>
  );
};
