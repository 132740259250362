import { useMutation } from "react-query";
import { DocumentService, FilesType, IDocumentResponse } from "./DocumentService";

export const useSendDocuments = () => {
  const { isSuccess, isError, mutate, isLoading } = useMutation(
    ({
      data,
      filesType,
      onSuccess,
      onError,
    }: {
      data: FormData;
      filesType: FilesType;
      onSuccess?: (resp: IDocumentResponse[]) => void;
      onError?: () => void;
    }) => DocumentService.sendDocuments(data, filesType),
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess(resp);
        }
      },
      onError: (resp, { onError }) => {
        if (onError) {
          onError();
        }
      },
    }
  );
  return {
    isSuccess,
    isError,
    mutate,
    isLoading,
  };
};
