import React, { useState } from "react";
import { DocumentPreview } from "@/entities/document";
import { ContractEntity, useDeleteLeadContractMutation } from "@/shared/lib/graphql";
import { downloadURL } from "@/shared/helpers/utils";
import { Title } from "@yamaha-admin-sb/title";
import { StyledFormItem } from "@/entities/leads";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { DataFilled } from "./DataFilled";
import { IHandleSuccess } from "../../model/interfaces";
import { getDocument } from "../../model/getDocument";


interface IDocument {
  isAgreed: boolean;
  isCompleted: boolean;
  contracts: ContractEntity[];
  leadId: number;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

export const Document: React.FC<IDocument> = (props) => {
  const { leadId, contracts, isCompleted, isAgreed, handleSuccess, handleError } = props;

  const { mutate: deleteContract } = useDeleteLeadContractMutation();



  const fetchFile = async (key: string) => {
    return await getDocument({ key });
  };

  const handleDownloadClick = async (id: number) => {
    const link = contracts && contracts.find((rep) => rep.id === id)?.filePath;

    if (!link) return;
    const fileUrl = await fetchFile(link);
    const filename = link.split("/").pop() as string;
    if (!fileUrl) return;
    await downloadURL(fileUrl, filename);
  };

  const handleViewClick = async (id: number) => {

    const link = contracts && contracts.find((rep) => rep.id === id)?.filePath;
    if (link) {
      const fileUrl = await fetchFile(link);
      if (!fileUrl) return;
      window.open(fileUrl, "_blank");
    }
  };

  const handleDeleteContract = (contractId: number) => {
    deleteContract(
      {
        leadId,
        input: {
          id: contractId,
        },
      },
      {
        onSuccess: () =>
          handleSuccess({
            text: "Документ удален",
            disableCompleteStage: true,
          }),
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Договор
        </Title>
      </div>
      {!isCompleted ? (
        <div className="space-20">
          <Paragraph size={12} color="gray-600">
            Клиент еще не подписал договор
          </Paragraph>
        </div>
      ) : (
        <></>
      )}

      {isAgreed ? (
        <div className="space-20">
          <DataFilled text="Договор согласован и подписан клиентом" />
        </div>
      ) : (
        <></>
      )}

      {contracts.map((item) => (
        <DocumentPreview
          key={item.id}
          name={item.fileName || ""}
          size={`${item.fileSize} МБ`}
          onDelete={() => handleDeleteContract(item.id)}
          onView={() => handleViewClick(item.id)}
          onDownload={() => handleDownloadClick(item.id)}
        />
      ))}
    </StyledFormItem>
  );
};
