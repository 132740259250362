import { GetMeQuery, useGetMeQuery } from "@/shared/lib/graphql";
import Cookies from "js-cookie";
import { useQueryClient } from "react-query";

export const useGetMe = () => {
  const queryClient = useQueryClient();
  const meCache = queryClient.getQueryData<GetMeQuery>("getMe", {
    predicate: (query) => query.queryHash === `getMe-${Cookies.get("accessToken")}`,
  });
  const { data: me, isLoading } = useGetMeQuery(undefined, {
    initialData: meCache,
    enabled: !meCache,
    queryKeyHashFn: (key) => `${key}-${Cookies.get("accessToken")}`,
  });

  return { me, isLoading };
};
