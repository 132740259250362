import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "@/shared/helpers/index";
import { ITab, Tabs } from "@yamaha-admin-sb/tabs";
import {
  FilterResponsibleEnum,
  LeadsFilters,
  StatusEnum,
  useGetLeadsQuery,
} from "@/shared/lib/graphql";
import { getDateServerFormat } from "@/shared/helpers/utils";

export type ITabItem = Omit<ITab, "onClick" | "onAddClick">;

export interface ILeadsTabs {
  selectedTab: { key: string; value: string };
  onTabClick: (filter: LeadsFilters) => void;
  profileId: number;
}

export const FreeStatuses = [
  StatusEnum.Agreed,
  StatusEnum.AgreedByClient,
  StatusEnum.ToBeAgreed,
  StatusEnum.New,
  StatusEnum.Closed,
  StatusEnum.InProgress,
  StatusEnum.Payment,
  StatusEnum.PaymentWaitingClient,
  StatusEnum.Prefatory,
  StatusEnum.PrePayment,
  StatusEnum.Report,
  StatusEnum.Successful,
];

const StyledWrapper = styled.div`
  border-bottom: 1px solid ${colors.gray200};

  @media (min-width: 768px) {
    padding: 0 20px;
  }
`;

export const LeadsTabs: React.FC<ILeadsTabs> = (props) => {
  const { onTabClick, profileId, selectedTab } = props;

  const { data: allLeads } = useGetLeadsQuery({
    input: {
      pagination: { take: 1, skip: 0 },
      filter: { responsible: FilterResponsibleEnum.Union },
    },
  });

  const { data: myLeads } = useGetLeadsQuery({
    input: {
      pagination: { take: 1, skip: 0 },
      filter: { responsible: FilterResponsibleEnum.Specified },
    },
  });
  const { data: freeLeads } = useGetLeadsQuery({
    input: {
      pagination: { take: 1, skip: 0 },
      filter: {
        responsible: FilterResponsibleEnum.Free,
        statuses: FreeStatuses,
      },
    },
  });
  const { data: recontact } = useGetLeadsQuery({
    input: {
      pagination: { take: 1, skip: 0 },
      filter: {
        recontact: getDateServerFormat(new Date()),
      },
    },
  });

  const tabs: ITabItem[] = useMemo(
    () => [
      {
        title: "Все заявки",
        count: allLeads?.getLeads.meta.count,
        value: FilterResponsibleEnum.Union,
        additional: { responsible: FilterResponsibleEnum.Union },
      },
      {
        title: "Мои заявки",
        count: myLeads?.getLeads.meta.count || 0,
        value: FilterResponsibleEnum.Specified,
        additional: { responsible: FilterResponsibleEnum.Specified },
      },
      {
        title: "Свободные заявки",
        count: freeLeads?.getLeads.meta.count || 0,
        value: FilterResponsibleEnum.Free,
        additional: {
          responsible: FilterResponsibleEnum.Free,
          statuses: FreeStatuses,
        },
      },
      {
        title: "Связаться сегодня",
        count: recontact?.getLeads.meta.count || 0,
        value: getDateServerFormat(new Date()),
        additional: {
          recontact: getDateServerFormat(new Date()),
        },
      },
    ],
    [myLeads, profileId, freeLeads, recontact, allLeads]
  );

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  useEffect(() => {
    setCurrentTab((prev) => tabs.find((item) => item.value === selectedTab.value) || prev);
  }, [selectedTab]);

  return (
    <StyledWrapper className="space-32">
      <Tabs
        tabs={tabs}
        activeTab={currentTab}
        onClick={(value) => {
          onTabClick(value.additional as LeadsFilters);
          setCurrentTab(value);
        }}
        onAddClick={() => ({})}
        defaultColor={"black"}
        fontWeight={500}
      />
    </StyledWrapper>
  );
};
