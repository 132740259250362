import { ModalContext } from "@/shared/lib/react";
import { Button } from "@yamaha-admin-sb/button";
import { useContext, type FC } from "react";
import styled from "styled-components";
import { ModalEditServices } from "./modals/ModalEditServices";

const StyledButton = styled.div`
  white-space: nowrap;
`;

export const EditServicesButton: FC = () => {
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const handleToggleModal = (flag: boolean) => {
    setModalContext((prev) => ({ ...prev, modalEditServices: flag }));
  };

  return (
    <>
      <StyledButton>
        <Button
          isBlock={true}
          variant="secondary"
          color="gray"
          leftIcon={true}
          icon="edit"
          onClick={() => handleToggleModal(true)}
        >
          Редактировать стоимость услуг
        </Button>
      </StyledButton>

      {modalContext?.modalEditServices && (
        <ModalEditServices onCancel={() => handleToggleModal(false)} onSuccess={() => ({})} />
      )}
    </>
  );
};
