import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Container } from "@/shared/ui";
import { Title } from "@yamaha-admin-sb/title";
import { colors } from "@/shared/helpers";
import { Default } from "@/widgets/layouts";
import { AnalyticsTypeEnum } from "@/shared/lib/graphql";
import { Tabs } from "@yamaha-admin-sb/tabs";
import { StyledTitle } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { IModalContextValue, ModalContext } from "@/shared/lib/react";
import {
  BonusEployeeByRequests,
  BonusEployeeByAgreements,
  BonusEployeeByContracts,
} from "@/entities/bonus";
import { ModalEditBonuses } from "@/features/bonus";
import { EditRoleModal } from "@/features/user";

const breads = [
  {
    title: "Премии",
    clickable: false,
  },
];

const WrappedTabs = styled.div`
  padding: 0 20px;
  border-bottom: 1px solid ${colors.gray200};
`;

const WrappedButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;

  button {
    margin: 0 15px;
  }
`;

const tabs = [
  {
    title: "Премия менеджера по типам заявок",
    value: AnalyticsTypeEnum.LeadTraffic,
    content: <BonusEployeeByRequests />,
  },
  {
    title: "Премия специалистов по числу договоров",
    value: AnalyticsTypeEnum.CashInflow,
    content: <BonusEployeeByContracts />,
  },
  {
    title: "Премия специалистов от стоимости договора",
    value: AnalyticsTypeEnum.Salary,
    content: <BonusEployeeByAgreements />,
  },
];

const Bonus: React.FC = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const handleOpenModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: true }));
  };

  return (
    <>
      <Default breads={breads}>
        <StyledTitle>
          <Title level={2} color="gray-700">
            Премии
          </Title>
          <WrappedButtons>
            <Button
              variant="secondary"
              color="gray"
              leftIcon={true}
              icon="edit"
              onClick={() => handleOpenModal("roleEditModal")}
            >
              Редактировать ставку в час
            </Button>

            <Button
              variant="secondary"
              color="gray"
              leftIcon={true}
              icon="edit"
              onClick={() => handleOpenModal("modalEditBonuses")}
            >
              Редактировать премии
            </Button>
          </WrappedButtons>
        </StyledTitle>
        <WrappedTabs className="space-32">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onClick={(value) => {
              setActiveTab(tabs.find((item) => item.value === value.value) || tabs[0]);
            }}
            onAddClick={() => ({})}
            defaultColor={"black"}
            fontWeight={500}
          />
        </WrappedTabs>
        <Container>{activeTab.content}</Container>
      </Default>

      {modalContext?.modalEditBonuses ? <ModalEditBonuses onSuccess={() => 1} /> : <></>}

      {modalContext?.roleEditModal ? <EditRoleModal onSuccess={() => null} /> : <></>}
    </>
  );
};

export default Bonus;
