import React, { useContext } from "react";
import styled from "styled-components";
import { Title } from "@yamaha-admin-sb/title";
import { Button } from "@yamaha-admin-sb/button";
import {
  FindOneUserQuery,
  useChangeMyPasswordMutation,
  useDeleteUserMutation,
  useRestoreOneUserMutation,
  useUpdateUserPasswordMutation,
} from "@/shared/lib/graphql";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Icon } from "@yamaha-admin-sb/icon";
import { useNavigate } from "react-router-dom";
import { DotedNavigation } from "@yamaha-admin-sb/doted-navigation";
import { IHiddenNavigationItem } from "@yamaha-admin-sb/hidden-navigation";
import { ModalChangePassword } from "@/features/auth";
import { IModalContextValue, ModalContext, useAddNotification } from "@/shared/lib/react";
import { ModalConfirm, StyledTitle } from "@/shared/ui";
import { IChangePasswordForm } from "@/features/auth";

interface IProfileLayout {
  user?: FindOneUserQuery["findOneUser"];
  isSelf?: boolean;
}

const StyledContent = styled.div`
  padding: 32px 40px;
`;

const StyledAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--color-cian);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const StyledAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
`;

const StyledItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 175px 1fr;
  gap: 24px;
`;

const StyledRole = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--color-gray-200);
  width: max-content;
`;

const StyledTitleBack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const info: { title: string; key: keyof FindOneUserQuery["findOneUser"] }[] = [
  {
    title: "Телефон",
    key: "phone",
  },
  {
    title: "E-mail",
    key: "email",
  },
  {
    title: "Роль",
    key: "role",
  },
];

const ProfileLayout: React.FC<IProfileLayout> = (props) => {
  const { user, isSelf } = props;
  const MENU_ITEMS: IHiddenNavigationItem[] = [
    {
      icon: "edit",
      title: "Изменить пароль",
      type: "black",
      value: "edit",
    },
    {
      icon: "block-user",
      title: user?.deletedAt ? "Разблокировать" : "Заблокировать",
      type: "red",
      value: "delete",
    },
  ];
  const navigate = useNavigate();
  const notify = useAddNotification();
  const [_, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: deleteUser, isLoading: isUserDeleting } = useDeleteUserMutation();
  const { mutate: restoreUser, isLoading: isUserRestore } = useRestoreOneUserMutation();
  const { mutate: changeMyPassword, isLoading: isMyPasswordChanging } =
    useChangeMyPasswordMutation();
  const { mutate: changePassword, isLoading: isPasswordChanging } = useUpdateUserPasswordMutation();

  const title = isSelf ? "Мой профиль" : `${user?.name} ${user?.surname}`;
  const initials = user ? `${user?.name[0]} ${user?.surname[0]}` : undefined;

  const handleMenuSelect = (item: IHiddenNavigationItem) => {
    switch (item.value) {
      case "edit":
        handleOpenModal("changePasswordModal");
        break;
      case "delete":
        handleOpenModal("confirmModal");
        break;
    }
  };

  const handleOpenModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev: any) => ({ ...prev, [modalName]: true }));
  };

  const handleCloseModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev: any) => ({ ...prev, [modalName]: false }));
  };

  const handleUserDelete = () => {
    deleteUser(
      {
        id: user?.id as number,
      },
      {
        onSuccess: () => {
          handleCloseModal("confirmModal");
          notify("Пользователь заблокирован", "success");
          navigate(-1);
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleChangePassword = (form: IChangePasswordForm) => {
    if (isSelf)
      changeMyPassword(
        {
          newPassword: form.newPassword,
          // oldPassword: form.oldPassword,
        },
        {
          onSuccess: () => {
            handleCloseModal("changePasswordModal");
            notify("Пароль изменен", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    else if (user)
      changePassword(
        {
          id: user?.id,
          password: form.newPassword,
        },
        {
          onSuccess: () => {
            handleCloseModal("changePasswordModal");
            notify("Пароль изменен", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
  };

  const handleRestoreUser = () => {
    restoreUser(
      {
        id: user?.id as number,
      },
      {
        onSuccess: () => {
          handleCloseModal("confirmModal");
          notify("Пользователь разблокирован", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  return (
    <>
      <StyledTitle className="space-32">
        <StyledTitleBack onClick={() => navigate(-1)}>
          <Icon name="arrow-left" color="gray-500" size={24} />
          <Title level={2} color="gray-700">
            {title}
          </Title>
        </StyledTitleBack>
        {isSelf ? (
          <Button
            variant="secondary"
            color="gray"
            leftIcon={true}
            icon="edit"
            onClick={() => handleOpenModal("changePasswordModal")}
          >
            Изменить пароль
          </Button>
        ) : (
          <DotedNavigation
            items={MENU_ITEMS}
            isDisabled={false}
            isSquare={true}
            onSelected={handleMenuSelect}
          />
        )}
      </StyledTitle>
      {user ? (
        <StyledContent>
          <StyledAvatarWrapper>
            <StyledAvatar>
              <Title level={1} color="gray-100" weight={500}>
                {initials || ""}
              </Title>
            </StyledAvatar>
            <Title level={2}>
              {user?.name} {user?.surname}
            </Title>
          </StyledAvatarWrapper>
          <StyledItemList>
            {info.map(({ title, key }) => (
              <StyledItem key={key}>
                <Paragraph size={14} weight={600} color="gray-600">
                  {title}
                </Paragraph>
                {key === "role" ? (
                  <StyledRole>
                    <Paragraph size={12} color="gray-700">
                      {user[key]?.name as string}
                    </Paragraph>
                  </StyledRole>
                ) : (
                  <Paragraph size={12} color="gray-700">
                    {user[key] as string}
                  </Paragraph>
                )}
              </StyledItem>
            ))}
          </StyledItemList>
          <ModalChangePassword
            isLoading={isPasswordChanging || isMyPasswordChanging}
            userName={isSelf ? undefined : `${user.name} ${user.surname}`}
            onCancel={() => handleCloseModal("changePasswordModal")}
            onSubmit={handleChangePassword}
          />
          <ModalConfirm
            title={
              user?.deletedAt
                ? "Вы действительно хотите разблокировать пользователя?"
                : "Вы действительно хотите заблокировать пользователя?"
            }
            text={user?.deletedAt ? "" : "Отменить это действие будет невозможно"}
            cancelButtonText="Отменить"
            confirmButtonText={user?.deletedAt ? "Да, разблокировать" : "Да, заблокировать"}
            icon="block-user"
            confirmButtonColor={user?.deletedAt ? "blue" : "red"}
            isLoading={isUserDeleting || isUserRestore}
            onCancel={() => handleCloseModal("confirmModal")}
            onConfirm={() => (user.deletedAt ? handleRestoreUser() : handleUserDelete())}
          />
        </StyledContent>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileLayout;
