import { ModalContext, useAddNotification } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { colors } from "@/shared/helpers";
import { Button } from "@yamaha-admin-sb/button";
import {
  UpdateRoleInput,
  useFindManyRolesQuery,
  useUpdateRolesMutation,
} from "@/shared/lib/graphql";
import { useForm } from "react-hook-form";
import { inputValidationRule } from "@/shared/helpers/const";

export interface IModal {
  onSuccess: () => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray200};
    margin-bottom: 0;
  }
`;

const StyledScroll = styled.div`
  max-height: 70vh;
  overflow: auto;
`;

type TCommisionFrom = {
  [key: number]: { commission?: number; rate?: number | null };
};

export const EditRoleModal: React.FC<IModal> = (props): JSX.Element => {
  const [form, setFrom] = useState<TCommisionFrom>();
  const notify = useAddNotification();
  const [_, setModalContext = () => ({})] = useContext(ModalContext);
  const { data: roles, refetch } = useFindManyRolesQuery();
  const { mutate: updateRoles } = useUpdateRolesMutation();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setFrom(() =>
      roles?.findManyRoles.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: {
            commission: item.commission,
            rate: item?.rate || 0,
          },
        }),
        {} as TCommisionFrom
      )
    );
    roles?.findManyRoles.forEach((item) => {
      setValue(`commission-${item.id}`, item.commission || 0);
      setValue(`rate-${item.id}`, item.rate || 0);
    });
  }, [roles]);

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      roleEditModal: false,
    }));
  };

  const handleChange = (key: number, slug: string, value: number) => {
    setFrom((prev) => ({
      ...prev,
      [key]: prev
        ? { ...prev[key], [slug]: !isNaN(+value) ? +value : 0 }
        : { [slug]: !isNaN(+value) ? +value : 0 },
    }));
  };

  const submitCallback = () => {
    const rolesIds: UpdateRoleInput[] = [];
    for (const key in form) {
      rolesIds.push({
        id: +key,
        commission: form[key].commission,
        rate: form[key].rate,
      });
    }
    updateRoles(
      {
        input: { roles: rolesIds },
      },
      {
        onSuccess: async () => {
          notify("Роли успешно обновлены", "success");
          handleButtonUndoClick();
          await refetch();
        },
      }
    );
  };
  return (
    <Modal name="roleEditModal">
      <ModalTop title="Редактирование ставки в час" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(submitCallback)}>
        <StyledScroll>
          {roles?.findManyRoles?.map((item, index) => {
            return (
              <StyledModalBody key={index}>
                <div className="space-16">
                  <Paragraph size={12} weight={500} color="gray-600">
                    {item.name}
                  </Paragraph>
                </div>
                {/* <div className="space-16">
                  <Controller<IInput>
                    isBordered={true}
                    name={`commission-${item.id}`}
                    label="Проценты с продаж *"
                    placeholder="Проценты с продаж *"
                    suffix="%"
                    type="number"
                    onChange={(value) =>
                      handleChange(item.id, "commission", value)
                    }
                    control={control}
                    error={errors[`commission-${item.id}`]?.message}
                    value={form && form[item.id].commission}
                    rules={{
                      required: "Это поле обязательное",
                      validate: (value: string) => {
                        const number = !isNaN(+value) ? +value : "";
                        if (
                          (typeof number === "number" && number) ||
                          number === 0
                        ) {
                          return (
                            number <= 100 || "Значение должно быть меньше 100%"
                          );
                        } else {
                          return (
                            typeof number === "number" ||
                            "Значение должнобыть числом"
                          );
                        }
                      },
                    }}
                    component={(inputProps: IInput) => (
                      <Input {...inputProps} />
                    )}
                  />
                </div> */}
                <div>
                  <Controller<IInput>
                    isBordered={true}
                    name={`rate-${item.id}`}
                    label="Ставка *"
                    placeholder="Ставка"
                    suffix="₽/ч"
                    onChange={(value) => {
                      const digits = value.split(/\D/).join("");
                      digits && handleChange(item.id, "rate", +digits);
                    }}
                    control={control}
                    error={errors?.[`rate-${item.id}`]?.message}
                    value={(form && form[item?.id].rate) || ""}
                    rules={inputValidationRule}
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
              </StyledModalBody>
            );
          })}
        </StyledScroll>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button type="submit">Сохранить</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
