import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdownItem, IDropdown } from "@yamaha-admin-sb/dropdown";
import { ModalContext } from "@/shared/lib/react";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { useManagers } from "@/entities/user";
import { useForm } from "react-hook-form";
import { requiredValidationRule } from "@/shared/helpers/const";

interface IModalManagerAppointment {
  title: string;
  managerId?: number;
  isLoading?: boolean;
  onCancel: () => void;
  onSubmit: (id: number) => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ModalManagerAppointment: React.FC<IModalManagerAppointment> = (props): JSX.Element => {
  const { isLoading, title, managerId, onCancel, onSubmit } = props;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [manager, setManager] = useState<IDropdownItem[]>([]);
  const { allManagers, isLoading: isMangersLoading } = useManagers();

  useEffect(() => {
    if (!modalContext?.modalManagerAppointment) setManager([]);
  }, [modalContext?.modalManagerAppointment]);

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalManagerAppointment: false,
    }));
  };

  useEffect(() => {
    if (managerId) {
      const selectedManager = allManagers.find((item) => item.id === managerId);
      if (selectedManager) {
        setValue("managers", selectedManager);
      }
    }
  }, [allManagers]);

  return (
    <Modal name="modalManagerAppointment">
      <ModalTop title={title} onClose={handleButtonUndoClick} />
      <form
        onSubmit={handleSubmit(() => {
          manager[0]?.id && onSubmit(manager[0].id as number);
        })}
      >
        <StyledModalBody>
          <StyledFormGroup>
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="managers"
              control={control}
              rules={requiredValidationRule}
              error={errors?.managers?.message}
              options={allManagers || []}
              value={manager.length ? manager : allManagers.find((item) => item.id === managerId)}
              onChange={setManager}
              label="Менеджер*"
              placeholder="Выберите менеджера"
              isBordered={true}
              isDisabled={isMangersLoading}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </StyledFormGroup>
        </StyledModalBody>
        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={onCancel}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Назначить менеджера
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
