import { GetCallsOutputData } from "@/shared/lib/graphql";
import { Avatar } from "@yamaha-admin-sb/avatar";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import type { FC } from "react";
import styled from "styled-components";

const StyledManagerActions = styled.div`
  display: flex;
  align-items: center;

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
`;

const WrappedUserName = styled.div`
  margin-left: 10px;
`;

interface ICallManagerProps {
  call: GetCallsOutputData;
  canChangeManager: boolean;
  isLoading: boolean;
  onAppointManager: (callId: number, isSelf?: boolean) => void;
}

export const CallManager: FC<ICallManagerProps> = ({
  call,
  canChangeManager,
  isLoading,
  onAppointManager,
}) => {
  return (
    <>
      {!call.manager ? (
        <StyledManagerActions>
          {canChangeManager && (
            <Button variant="secondary" onClick={() => onAppointManager(call.id)}>
              Назначить менеджера
            </Button>
          )}

          <Button isLoading={isLoading} onClick={() => onAppointManager(call.id, true)}>
            Взять себе
          </Button>
        </StyledManagerActions>
      ) : (
        <StyledAvatar>
          <Avatar size="s" firstName={call.manager?.name} lastName={call.manager?.surname} />
          <WrappedUserName>
            <Paragraph size={12} weight={500}>
              {call.manager?.name} {call.manager?.surname}
            </Paragraph>
          </WrappedUserName>
        </StyledAvatar>
      )}
    </>
  );
};
