import React, { useContext, useEffect, useState } from "react";
import { ModalTop, Modal, ModalBody, ModalFooter, Controller } from "@/shared/ui";
import { ModalContext } from "@/shared/lib/react";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import { requiredValidationRule } from "@/shared/helpers/const";
import {
  GetLeadQuery,
  UpdateLeadLServiceInput,
  useUpdateLeadLServiceMutation,
} from "@/shared/lib/graphql";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";

export interface IModal {
  lead: GetLeadQuery;
  onSuccess: () => void;
}

const serviceOptions = [
  {
    name: "Автоподбор",
    id: "car-selection",
    value: 1,
  },
  {
    name: "Выездная диагностика",
    id: "diagnose",
    value: 2,
  },
];

export const ModalChangeLServiceTypeLead: React.FC<IModal> = (props) => {
  const { lead, onSuccess } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: updateLead, isLoading: isUpdating } = useUpdateLeadLServiceMutation();
  const [currentLService, setCurrentLService] = useState<IDropdownItem>();

  const [form, setForm] = useState<UpdateLeadLServiceInput>();
  useEffect(() => {
    const current = serviceOptions.find((item) => item.value === lead.getLead.lServiceId);
    console.log("lead.getLead.lServiceId", lead.getLead.lServiceId);
    setCurrentLService(current);
    setForm({
      lServiceId: lead.getLead.lServiceId ?? 1,
    });
  }, [lead]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  /*   useEffect(() => {
      setValue("lServiceId", currentLService?.value);
      console.log("form", form);
  
    }, [currentLService]); */

  const handleUpdate = () => {
    form &&
      updateLead(
        {
          leadId: lead.getLead.id,
          input: form,
        },
        {
          onSuccess: () => {
            onSuccess();
            handleButtonUndoClick();
          },
        }
      );
  };
  console.log("lServiceId111", form?.lServiceId);
  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalChangeLServiceTypeLead: false,
    }));
  };

  return (
    <Modal name="modalChangeLServiceTypeLead">
      <ModalTop title="Изменить тип заявки" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="lServiceId"
              label="Тип услуги *"
              placeholder="Выберите тип"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={errors?.lServiceId?.message}
              options={serviceOptions}
              value={serviceOptions.find((item) => item.value === currentLService?.value)}
              onChange={(value: IDropdownItem) => {
                if (value[0]?.value) {
                  console.log("set", value[0].value);
                  setForm({
                    lServiceId: value[0].value,
                  });
                  console.log("form", form);
                  /*                  setCurrentLService(
                                     serviceOptions.find(
                                       (item) => item.value === value[0].id
                                     )
                                   ); */
                }
              }}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isUpdating} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
