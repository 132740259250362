import React, { useContext, useMemo, useState } from "react";
import {
  useGetBonusesByContractsQuery,
  useDeleteBonusByContractsMutation,
  SortEnum,
} from "@/shared/lib/graphql";
import { WrappedTable } from "@/shared/ui";
import { toFormatPrice } from "@/shared/helpers/utils";
import { Button } from "@yamaha-admin-sb/button";
import { IModalContextValue, ModalContext, useAddNotification } from "@/shared/lib/react";
import { IHiddenNavigationItem } from "@yamaha-admin-sb/hidden-navigation";
import { ModalCreateBonusByContracts } from "@/entities/bonus/ui/ModalCreateBonusByContracts";
import { DiagnosticsCountFieldEditBox } from "./DiagnosticsCountField";

interface BonusType {
  premium: number;
  contracts: number;
  id: number;
  itemInfo?: BonusType;
}

const headers = [
  {
    Header: "Договора",
    accessor: "contracts",
  },
  {
    Header: "Премия",
    accessor: "premium",
  },
];

export const BonusEployeeByContracts: React.FC = () => {
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const notify = useAddNotification();
  const { mutate: deleteBonusByContracts } = useDeleteBonusByContractsMutation();
  const [selectedBonus, setSelectedBonus] = useState<BonusType>();

  const {
    data: requestData,
    isLoading,
    refetch,
  } = useGetBonusesByContractsQuery({
    input: { sort: { contracts: SortEnum.Desc } },
  });

  const handleToggleModal = (modalName: keyof IModalContextValue, flag: boolean) => {
    setModalContext((prev) => ({ ...prev, [modalName]: flag }));
  };

  const data = requestData?.getPremiumByContracts?.data || [];
  const preparedData = useMemo(
    () =>
      data.map((item) => ({
        itemInfo: {
          contracts: item?.contracts,
          premium: item?.premium,
          id: item?.id,
        },
        id: item?.id,
        contracts: item?.contracts,
        premium: toFormatPrice(item?.premium) + " ₽",
        menu: [
          {
            title: "Редактировать",
            icon: "edit",
            value: "edit",
            type: "black",
            isDisabled: false,
          },
          {
            title: "Удалить",
            icon: "delete",
            value: "delete",
            type: "black",
            isDisabled: false,
          },
        ],
      })) || [],
    [requestData]
  );

  const handleMenuItemClick = (cell: BonusType, menuItem: IHiddenNavigationItem) => {
    switch (menuItem.value) {
      case "edit":
        setSelectedBonus(cell.itemInfo);
        handleToggleModal("modalCreateBonusByContracts", true);
        break;
      case "delete":
        if (!cell.id) {
          break;
        }
        deleteBonusByContracts(
          { id: cell.id },
          {
            onSuccess: async () => {
              notify("Запись удалена", "success");
              refetch();
            },
            onError: (error) => {
              notify((error as ErrorEvent)?.message, "error");
            },
          }
        );
        break;
    }
  };

  return (
    <>
      <div className="space-20">
        <DiagnosticsCountFieldEditBox label="1 договор" inputName="coefLead" />
        <DiagnosticsCountFieldEditBox label="1 СПД" inputName="coefDiagnostic" />
      </div>
      <div className="space-20">
        <Button
          variant="secondary"
          color="gray"
          leftIcon={true}
          icon="plus"
          onClick={() => {
            setSelectedBonus(undefined);
            handleToggleModal("modalCreateBonusByContracts", true);
          }}
        >
          Добавить запись
        </Button>
      </div>
      <WrappedTable
        showMenu={true}
        headers={headers}
        data={preparedData || []}
        isLoading={isLoading}
        onMenuItemSelected={handleMenuItemClick}
      />
      {modalContext?.modalCreateBonusByContracts ? (
        <ModalCreateBonusByContracts
          selectedBonus={selectedBonus}
          onSuccess={() => {
            refetch();
            setSelectedBonus(undefined);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
