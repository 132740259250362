import React from "react";
import { Avatar } from "@yamaha-admin-sb/avatar";
import { Icon } from "@yamaha-admin-sb/icon";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import styled, { css } from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "@/shared/ui";
import { useGetMe } from "@/entities/session";
import { Notifications } from "./Notifications";
import { useGetNotifications } from "@/shared/lib/graphql";
import { useUpdateNotificationsMutation } from "@/shared/lib/graphql";
import { colors, media } from "@/shared/helpers";
import { IconButton } from "@yamaha-admin-sb/icon-button";

type Item = {
  label: string;
  icon: string;
  path: string;
};

interface IMenu {
  isMenuOpen: boolean;
  items: Item[];
  onCloseClick: () => void;
}

const StyledMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  left: ${({ isOpen }) => (isOpen ? 0 : -300)}px;
  transition: all 0.5s;
  background-color: ${colors.white};
  padding: 16px;
  height: 100vh;
  box-shadow: 0px 6px 40px rgba(22, 35, 70, 0.1);

  ${media.laptop} {
    box-shadow: none;
    position: sticky;
    z-index: 10;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid var(--color-gray-200);
  }
`;
const StyledContent = styled.div``;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  gap: 16px;
`;

const StyledMenuList = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledItem = styled.li<{ isActive: boolean }>`
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: 0.4s;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: var(--light-color-gray-200);
        `
      : css`
          &:hover,
          &:focus {
            background-color: var(--light-color-gray-200);
          }
        `}
`;

const StyledIcon = styled.svg`
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const StyledAvatar = styled.div`
  cursor: pointer;
`;

const StyledClose = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
  ${media.laptop} {
    display: none;
  }
`;

const Menu: React.FC<IMenu> = (props) => {
  const { items, isMenuOpen, onCloseClick } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { me } = useGetMe();
  const { notifications, updateNotifications } = useGetNotifications();
  const { mutate: hideNotifications } = useUpdateNotificationsMutation();

  const handleDeleteNotifications = () => {
    if (notifications?.getNotifications.data?.length) {
      hideNotifications(
        {
          input: {
            notifications: notifications?.getNotifications.data?.map((item) => ({
              id: item.id,
              isShown: true,
            })),
          },
        },
        { onSuccess: updateNotifications }
      );
    }
  };

  return (
    <StyledMenu isOpen={isMenuOpen}>
      <StyledContent>
        <StyledClose>
          <IconButton
            onClick={onCloseClick}
            icon="close"
            variant="tertiary"
            color="gray"
            size="s"
          />
        </StyledClose>
        <StyledTitle>
          <Logo width={151} height={16} />

          <Notifications
            allNotifications={notifications}
            onVisibleChange={(value) => !value && handleDeleteNotifications()}
          />
          <StyledAvatar onClick={() => navigate("/profile")}>
            <Avatar
              firstName={me?.getMe?.name}
              lastName={me?.getMe?.surname}
              isDisabled={true}
              size="xs"
            />
          </StyledAvatar>
        </StyledTitle>
        <StyledMenuList>
          {items.map((item, index) => {
            const { label, icon, path } = item;
            return (
              <StyledItem
                as={Link}
                to={path}
                key={index}
                isActive={!!location.pathname.match(path)}
              >
                <StyledIcon>
                  <Icon name={icon} color="gray-600" />
                </StyledIcon>
                <Paragraph size={12} color="gray-700" weight={500}>
                  {label}
                </Paragraph>
              </StyledItem>
            );
          })}
        </StyledMenuList>
      </StyledContent>
    </StyledMenu>
  );
};
export { Menu };
