import React from "react";
import { Auth } from "@/widgets/layouts";
import { LoginForm } from "@/features/auth";
import { useNavigate } from "react-router-dom";

const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Auth>
      <LoginForm onLogin={() => navigate("/")} />
    </Auth>
  );
};

export default Login;
