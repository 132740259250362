import React from "react";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Title } from "@yamaha-admin-sb/title";
import {
  WrappedLeadTitle,
  WrappedBlock,
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
} from "../leads.styled";
import { Avatar } from "@yamaha-admin-sb/avatar";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { BaseUserOutput } from "@/shared/lib/graphql";
import styled from "styled-components";
import moment from "moment";

interface ISelectedEmployee {
  departure?: string;
  inspectionTime?: string;
  inspectionPlace?: string;
  enableEdit: boolean;
  role: string;
  person?: BaseUserOutput;
  onEditClick: () => void;
}

const WrappedAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SelectedEmployee: React.FC<ISelectedEmployee> = (props) => {
  const { enableEdit, role, person, departure, inspectionTime, inspectionPlace, onEditClick } =
    props;

  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          {role}
        </Title>
        {enableEdit && person?.id ? (
          <IconButton icon="edit" variant="tertiary" color="gray" onClick={onEditClick} />
        ) : (
          <></>
        )}
      </WrappedLeadTitle>
      {person?.id || departure ? (
        <>
          {person?.id && (
            <WrappedAvatar className="space-12">
              <Avatar
                size="xs"
                isDisabled={true}
                firstName={person?.name}
                lastName={person?.surname}
              />
              <Paragraph size={12} weight={500}>{`${person?.name} ${person?.surname}`}</Paragraph>
            </WrappedAvatar>
          )}

          {departure ? (
            <StyledInfoItem>
              <StyledNameItem>
                <Paragraph size={12} color="gray-600" weight={500}>
                  Дата выезда
                </Paragraph>
              </StyledNameItem>
              <StyledValueItem>
                <Paragraph size={12} color="gray-700" weight={400}>
                  {moment(departure, "YYYY-MM-DD").format("DD.MM.YYYY")}
                </Paragraph>
              </StyledValueItem>
            </StyledInfoItem>
          ) : (
            <></>
          )}
          {inspectionTime ? (
            <StyledInfoItem>
              <StyledNameItem>
                <Paragraph size={12} color="gray-600" weight={500}>
                  Время выезда
                </Paragraph>
              </StyledNameItem>
              <StyledValueItem>
                <Paragraph size={12} color="gray-700" weight={400}>
                  {inspectionTime}
                </Paragraph>
              </StyledValueItem>
            </StyledInfoItem>
          ) : (
            <></>
          )}
          {inspectionPlace ? (
            <StyledInfoItem>
              <StyledNameItem>
                <Paragraph size={12} color="gray-600" weight={500}>
                  Место осмотра
                </Paragraph>
              </StyledNameItem>
              <StyledValueItem>
                <Paragraph size={12} color="gray-700" weight={400}>
                  {inspectionPlace}
                </Paragraph>
              </StyledValueItem>
            </StyledInfoItem>
          ) : (
            <></>
          )}
          {person?.phone ? (
            <StyledInfoItem>
              <StyledNameItem>
                <Paragraph size={12} color="gray-600" weight={500}>
                  Телефон
                </Paragraph>
              </StyledNameItem>
              <StyledValueItem>
                <a href={`tel:${person.phone}`}>
                  <Paragraph size={12} weight={400} color="blue-200">
                    {person?.phone}
                  </Paragraph>
                </a>
              </StyledValueItem>
            </StyledInfoItem>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Paragraph size={12} color="gray-500">
          Не выбран
        </Paragraph>
      )}
    </WrappedBlock>
  );
};
