import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { ModalContext } from "@/shared/lib/react";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { useForm } from "react-hook-form";
import { PaymentTypeToText, requiredValidationRule } from "@/shared/helpers/const";
import { PaymentEntity, useRefundPaymentsMutation } from "@/shared/lib/graphql";
import { toFormatPrice } from "@/shared/helpers/utils";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { IHandleSuccess } from "../../model/interfaces";

interface IModal {
  payments?: Pick<PaymentEntity, "amount" | "createdAt" | "type" | "method" | "status" | "id">[];
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 8px;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ModalPaymentReturn: React.FC<IModal> = (props): JSX.Element => {
  const { handleSuccess, handleError, payments } = props;
  const [selectedOptions, setSelectedOptions] = useState<IDropdownItem[]>();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ mode: "onBlur" });

  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: refundPayment } = useRefundPaymentsMutation();
  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalPaymentReturn: false,
    }));
  };

  const options: IDropdownItem[] = useMemo(
    () =>
      payments?.map((item) => ({
        name: `${PaymentTypeToText[item.type]}а - ${toFormatPrice(item.amount)} ₽`,
        value: item.amount,
        id: item.id,
      })) || [],
    [payments]
  );
  const submitCallback = () => {
    const preparedRefund =
      selectedOptions?.map((item) => ({
        id: item?.id?.toString() || "",
        amount: item.value,
      })) || [];

    if (preparedRefund && preparedRefund.length) {
      refundPayment(
        {
          input: {
            payments: preparedRefund,
          },
        },
        {
          onSuccess: () => {
            handleButtonUndoClick();
            handleSuccess({
              text: "Возврат осушествлен",
              disableCompleteStage: true,
            });
          },
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    }
  };

  return (
    <Modal name="modalPaymentReturn">
      <ModalTop title="Возврат оплаты" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(submitCallback)}>
        <StyledModalBody>
          <StyledFormGroup>
            <Controller<IDropdown>
              portal={document.querySelector("body") as HTMLElement}
              name="cost"
              label="Сумма *"
              placeholder="Выберите сумму"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={errors?.cost?.message}
              isMultiple={true}
              withTags={false}
              options={options}
              value={selectedOptions}
              isDisabled={false}
              onChange={(value) => setSelectedOptions(value)}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </StyledFormGroup>
          {selectedOptions?.length ? (
            <Paragraph size={14}>{`Вернуть: ${selectedOptions?.map(
              (item) => item.name
            )}`}</Paragraph>
          ) : (
            <></>
          )}
        </StyledModalBody>
        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button type="submit" isLoading={false}>
            Вернуть оплату
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
